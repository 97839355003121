import React from 'react';
import {
  Button,
  Input,
  InputGroup,
  InputRightAddon,
  Box,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import {
  useDashboardCRUD,
  useDashboardSWR,
  useLocation,
  useToast,
  useTLDParser,
} from '@hooks';
import { InputBox } from '@components/common';
import { preventSubmitOnEnter, isValidIdentifier, HTTPError } from '@utils';
import { IdentifiersPills } from './IdentifiersPills';

type IdentifierInputProps = {
  formId: string;
  name: string;
  isSupported: boolean;
};

export function IdentifierInput({
  name,
  formId,
  isSupported,
}: IdentifierInputProps): JSX.Element {
  const { parse } = useTLDParser();
  const { instanceId } = useLocation();
  const { showSuccessToast, showErrorToast } = useToast();
  const { del, create } = useDashboardCRUD();
  const [isUpdating, setIsUpdating] = React.useState(false);
  const identifiersPath = `/v1/instances/${instanceId}/${name}_identifiers`;

  const [input, setInput] = React.useState('');

  const { data: identifiers, mutate } = useDashboardSWR(identifiersPath);
  const formMethods = useForm();

  const {
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = formMethods;

  const updateApp = async () => {
    const tldResult = parse(input);
    const { isValid, value } = isValidIdentifier(input, tldResult);

    if (!isValid) {
      return setError(name, {
        type: 'manual',
        message:
          'Identifier is not valid. Must be a valid domain, email address, phone number or Web3 wallet.',
      });
    }

    setIsUpdating(true);

    try {
      await create(identifiersPath, { identifier: value });
      void mutate();
      showSuccessToast('Identifier was added successfully.');
    } catch (err) {
      const errorMessage = (err as HTTPError)?.globalErrors[0];
      if (errorMessage.message) {
        showErrorToast(errorMessage.message);
      } else {
        showErrorToast('Something went wrong, please try again later.');
      }
    } finally {
      setInput('');
      setIsUpdating(false);
      clearErrors(name);
    }
  };

  const onRemoveItem = async (id: string) => {
    setIsUpdating(true);
    try {
      await del(`${identifiersPath}/${id}`);
      void mutate();
      showSuccessToast('Identifier deleted successfully.');
    } catch (_) {
      showErrorToast('Something went wrong, please try again.');
    } finally {
      setIsUpdating(false);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value.length) {
      clearErrors(name);
    }

    setInput(e.target.value);
  };

  return (
    <form
      id={formId}
      onSubmit={handleSubmit(updateApp)}
      onKeyDown={preventSubmitOnEnter}
    >
      <FormControl isInvalid={!!errors[name]}>
        <Box mt={4}>
          <InputBox
            label='Identifiers'
            description={`Enter a domain, email address, phone number, or Web3 wallet address to add it to the ${name}`}
            p={0}
          >
            <InputGroup
              variant='addonAction'
              borderWidth='1px'
              borderRadius='md'
              mt={2}
            >
              <Input
                autoFocus
                type='text'
                name={name}
                value={input}
                placeholder='Enter identifer'
                autoComplete='off'
                isDisabled={!isSupported}
                cursor={!isSupported ? 'not-allowed' : 'auto'}
                bg='white'
                onChange={onChange}
                onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                  if (e.key === 'Enter' && input) {
                    void updateApp();
                  }
                }}
              />
              <InputRightAddon>
                <Button
                  variant='link'
                  onClick={updateApp}
                  isDisabled={!input.length || isUpdating}
                >
                  Add
                </Button>
              </InputRightAddon>
            </InputGroup>
          </InputBox>

          <FormErrorMessage>
            {(errors?.[name] as any)?.message}
          </FormErrorMessage>

          <IdentifiersPills
            identifiers={identifiers?.data}
            onRemoveItem={onRemoveItem}
            isDisabled={isUpdating}
          />
        </Box>
      </FormControl>
    </form>
  );
}
