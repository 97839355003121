import React from 'react';
import { Button, Flex, VStack } from '@chakra-ui/react';
import { Modal } from '@components/common';
import { SwitchField } from './SwitchField';

type FirstNameConfigModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function FirstNameConfigModal({
  isOpen,
  onClose,
}: FirstNameConfigModalProps): JSX.Element {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      autoFocus={false}
      size='xl'
      title='Name'
      subtitle='Configure first and last name attributes'
    >
      <Modal.Body>
        <VStack spacing='6'>
          <SwitchField
            name='attributes.first_name.required'
            label='Require'
            description='Users must provide a first and last name to sign up'
          />
        </VStack>
      </Modal.Body>

      <Modal.Footer mt='6'>
        <Flex direction='row' w='full'>
          <Flex justify='space-between' w='full' flexDir='row-reverse'>
            <Button onClick={onClose}>Continue</Button>
          </Flex>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
}
