import React from 'react';

import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  IconButton,
  Icon,
  Checkbox,
  VStack,
  Text,
  Box,
  useDisclosure,
} from '@chakra-ui/react';
import { FilterIcon } from '@heroicons/react/solid';

export type DropdownFilters = {
  [index: string]: { value: any; label: string };
};

export type DropdownFilterProps = {
  title: string;
  filters: DropdownFilters;
  onChange: (data: DropdownFilters) => void;
};

const DropdownFilter = ({
  title,
  filters,
  onChange,
}: DropdownFilterProps): JSX.Element => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [state, setState] = React.useState({ ...filters });
  const hasCheckedValues = Object.keys(state).some(key => state[key].value);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    const { checked } = e.target;

    setState(prev => ({ ...prev, [name]: { ...prev[name], value: checked } }));
    onChange({ ...state, [name]: { ...state[name], value: checked } });
  };
  return (
    <Box position='relative'>
      <Popover closeOnEsc isOpen={isOpen} onClose={onClose} onOpen={onOpen}>
        <PopoverTrigger>
          <IconButton
            sx={{
              '& svg': {
                ...(hasCheckedValues
                  ? {
                      color: 'primary.500',
                    }
                  : {}),
              },
            }}
            isActive={isOpen}
            _active={{
              bg: 'gray.50',
              '& svg': {
                color: 'primary.500',
              },
            }}
            _focus={{ boxShadow: 'none' }}
            _hover={{
              bg: 'none',
              '& svg': {
                color: 'gray.400',
              },
            }}
            bg='none'
            aria-label='Open filter popup'
            border='1px solid'
            borderColor='gray.200'
            icon={<Icon as={FilterIcon} boxSize={5} color='gray.300' />}
          />
        </PopoverTrigger>
        <PopoverContent
          boxShadow='0px 24px 48px rgba(0, 0, 0, 0.16)'
          borderRadius='lg'
          position='absolute'
          left={-5}
          p={4}
          width={56}
        >
          <PopoverHeader mb={2} p={0} border='0'>
            <Text textStyle='sm-medium'>{title}</Text>
          </PopoverHeader>
          <PopoverBody p={0}>
            <VStack alignItems='self-start' justifyContent='start'>
              {Object.keys(filters).map(key => (
                <Checkbox
                  aria-label={`${key} filter`}
                  onChange={handleOnChange}
                  name={key}
                  key={key}
                  variant='normal'
                  isChecked={state[key].value}
                >
                  <Text fontWeight='normal' fontSize='0.75rem'>
                    {state[key].label}
                  </Text>
                </Checkbox>
              ))}
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default DropdownFilter;
