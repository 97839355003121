import React from 'react';
import { getTargetElement } from '@utils';

export const useEventListener = (
  target: Element | React.MutableRefObject<Element>,
  handler: () => void,
  eventName: string,
): void => {
  const listenerRef = React.useRef(handler);
  listenerRef.current = handler;

  React.useEffect(() => {
    const el = getTargetElement(target);
    const listener = () => listenerRef.current();

    if (!target) {
      return;
    }

    el.addEventListener(eventName, listener);

    return () => {
      if (!target) {
        return;
      }
      el.removeEventListener(eventName, listener);
    };
  }, [target, eventName]);
};
