import React from 'react';
import {
  Flex,
  useRadio,
  useRadioGroup,
  Box,
  Radio,
  Grid,
  Tag,
  TagLabel,
  GridProps,
} from '@chakra-ui/react';
import { useFormContext, useController } from 'react-hook-form';
import { HelperLink } from '@components/common';
import _capitalize from 'lodash/capitalize';
import { PASSWORD_METER_OPTIONS, passwordStrengthOptions } from './constants';

const findPasswordMeterKeyByScore = (val: number): string => {
  return passwordStrengthOptions.filter(
    f => PASSWORD_METER_OPTIONS[f].score === val,
  )?.[0];
};

type PasswordMeterOptionsProps = {
  hasCheckedPasswordStrength: boolean;
  learnMoreLink?: string;
} & GridProps;

export const PasswordMeterOptions = ({
  hasCheckedPasswordStrength,
  learnMoreLink,
  ...rest
}: PasswordMeterOptionsProps): JSX.Element => {
  const { control } = useFormContext();

  const { field: hasCheckedPasswordStrengthField } = useController({
    name: 'password_settings.min_zxcvbn_strength',
    control,
  });

  const {
    value: currentValue,
    setValue,
    getRootProps,
    getRadioProps,
  } = useRadioGroup({
    name: 'password_strength_meter',
    defaultValue: 'weak',
    onChange: e =>
      hasCheckedPasswordStrengthField.onChange(PASSWORD_METER_OPTIONS[e].score),
  });

  const group = getRootProps();
  const passwordMeterValue = findPasswordMeterKeyByScore(
    hasCheckedPasswordStrengthField.value,
  );

  if (currentValue !== passwordMeterValue) {
    setValue(passwordMeterValue);
  }

  return (
    <>
      <Grid
        {...group}
        pointerEvents={hasCheckedPasswordStrength ? 'initial' : 'none'}
        opacity={hasCheckedPasswordStrength ? '1' : '.5'}
        {...rest}
      >
        {passwordStrengthOptions.map(value => {
          const radio = getRadioProps({ value });
          return (
            <RadioCard
              key={value}
              {...radio}
              hasCheckedPasswordStrength={hasCheckedPasswordStrength}
            >
              {value}
            </RadioCard>
          );
        })}
      </Grid>
      {learnMoreLink && (
        <HelperLink isExternal href={learnMoreLink} pl='9' mt='2!important'>
          Learn more
        </HelperLink>
      )}
    </>
  );
};

function RadioCard(props) {
  const { getInputProps, getCheckboxProps } = useRadio(props);
  const { hasCheckedPasswordStrength, isChecked, children } = props;

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as='label'>
      <input {...input} />
      <Flex
        {...checkbox}
        cursor='pointer'
        borderWidth='1px'
        borderRadius='md'
        minHeight='82px'
        direction='column'
        alignItems='center'
        p={3}
      >
        <Box>
          <Tag
            size='sm'
            borderRadius='full'
            variant='subtle'
            colorScheme={PASSWORD_METER_OPTIONS[children].color}
          >
            <TagLabel p={1}>{_capitalize(children)}</TagLabel>
          </Tag>
        </Box>
        <Box position='relative' mt={3} w='18px' h='18px'>
          <Box position='absolute' w='100%' h='100%' zIndex={1} />
          <Radio isChecked={hasCheckedPasswordStrength && isChecked}></Radio>
        </Box>
      </Flex>
    </Box>
  );
}
