import React from 'react';
import {
  Box,
  Icon,
  LinkBox,
  LinkBoxProps,
  Stack,
  Text,
} from '@chakra-ui/react';
import { formatTimestamp } from '@utils';
import NextLink from 'next/link';
import { useLocation } from '@hooks';
import { PlusIcon } from '@heroicons/react/solid';
import { JwtTemplate } from '@types';

const cardDimensions: LinkBoxProps = {
  maxW: '350px',
  minW: '234px',
  minH: '128px',
};

export interface AddTemplateCardProps {
  onClick: () => void;
}

export function AddTemplateCard({
  onClick,
}: AddTemplateCardProps): JSX.Element {
  return (
    <Box
      layerStyle='linkBoxCard'
      justifyContent='center'
      {...cardDimensions}
      onClick={onClick}
    >
      <Stack p='4' spacing='4' justify='center' align='center'>
        <Icon as={PlusIcon} color='primary.500' />
        <Text textStyle='md-medium'>New template</Text>
      </Stack>
    </Box>
  );
}

type TemplateCardProps = {
  template: JwtTemplate;
};

export function TemplateCard({ template }: TemplateCardProps): JSX.Element {
  const { applicationId, instanceId } = useLocation();
  const templateLink = `/apps/${applicationId}/instances/${instanceId}/jwt-templates/${template.id}`;

  return (
    <NextLink href={templateLink} passHref>
      <LinkBox layerStyle='linkBoxCard' {...cardDimensions}>
        <Stack py={8} px={6} spacing='4'>
          <Text display='inline-block' textStyle='lg-medium' noOfLines={1}>
            {template.name}
          </Text>

          <Text textStyle='sm-normal' color='gray.500' noOfLines={1}>
            {formatTimestamp(new Date(template.updated_at), { noTime: true })}
          </Text>
        </Stack>
      </LinkBox>
    </NextLink>
  );
}
