import React from 'react';

import { useDashboardSWR, useDashboardCRUD } from '@hooks';
import { useMembershipsPathAndKeys } from './hooks';

import {
  OrganizationMembershipResponse,
  OrganizationMembershipFLatUserData,
  MembershipRole,
  OrganizationMembership,
} from '@components/organizations/types';

import { makeFlatMembership } from './utils';

import { useSWRConfig } from 'swr';

type UseGetMembershipsReturn = {
  memberships: OrganizationMembershipFLatUserData[];
  hasLoaded: boolean;
  mutate: () => void;
  totalCount: number;
};

export const useGetMemberships = ({
  organizationID,
  orderBy,
}: {
  organizationID: string;
  orderBy?: string;
}): UseGetMembershipsReturn => {
  const { pathWithParams } = useMembershipsPathAndKeys({
    organizationID,
    orderBy,
  });
  const {
    data,
    mutate: SWRMutate,
    error,
  } = useDashboardSWR<OrganizationMembershipResponse>(() => {
    if (!pathWithParams || !organizationID) {
      return null;
    }

    return pathWithParams;
  }, {});
  const hasLoaded = !!data && !error;

  const membershipFlat: OrganizationMembershipFLatUserData[] =
    React.useMemo(() => {
      if (hasLoaded) {
        return makeFlatMembership(data.data);
      }
      return [];
    }, [data, hasLoaded]);

  const mutate = () => {
    void SWRMutate();
  };

  return {
    memberships: membershipFlat,
    hasLoaded,
    mutate,
    totalCount: data?.total_count || 0,
  };
};

type UseCreateMembershipReturn = {
  createMembership: (
    id: string,
    role: MembershipRole,
    customPath?: string,
  ) => Promise<OrganizationMembershipFLatUserData>;
  mutate: () => void;
};

export const useCreateMembership = (): UseCreateMembershipReturn => {
  const { cacheKey, path } = useMembershipsPathAndKeys();
  const { mutate: mutateSWR } = useSWRConfig();
  const { create } = useDashboardCRUD<OrganizationMembership>();

  const createMembership = async (
    id: string,
    role: MembershipRole,
    customPath: string,
  ) => {
    const membership = await create(customPath || path, {
      user_id: id,
      role,
    });
    return makeFlatMembership([membership])[0];
  };

  const mutate = () => {
    void mutateSWR(cacheKey);
  };

  return { createMembership, mutate };
};

type UseUpdateMembershipReturn = {
  updateMembership: (
    id: string,
    role: MembershipRole,
  ) => Promise<OrganizationMembershipFLatUserData>;
  mutate: () => void;
};

export const useUpdateMembership = (): UseUpdateMembershipReturn => {
  const { cacheKey, path } = useMembershipsPathAndKeys();
  const { mutate: mutateSWR } = useSWRConfig();
  const { update } = useDashboardCRUD<OrganizationMembership>();

  const updateMembership = async (id: string, role: MembershipRole) => {
    const membership = await update(`${path}/${id}`, { role });
    return makeFlatMembership([membership])[0];
  };

  const mutate = () => {
    void mutateSWR(cacheKey);
  };

  return { updateMembership, mutate };
};

type UseDeleteMembershipReturn = {
  deleteMembership: (id: string) => Promise<OrganizationMembershipFLatUserData>;
  mutate: () => void;
};

export const useDeleteMembership = ({
  organizationID,
}: {
  organizationID?: string;
} = {}): UseDeleteMembershipReturn => {
  const { cacheKey, path } = useMembershipsPathAndKeys({ organizationID });
  const { mutate: mutateSWR } = useSWRConfig();
  const { del } = useDashboardCRUD<OrganizationMembership>();

  const deleteMembership = async (id: string) => {
    const membership = await del(`${path}/${id}`);
    return makeFlatMembership([membership])[0];
  };

  const mutate = () => {
    void mutateSWR(cacheKey);
  };

  return { deleteMembership, mutate };
};
