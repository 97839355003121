import { formatRelative, subDays } from 'date-fns';
import { titleize } from '@utils/string';
import type { User } from '../../types';

export function formatName(
  user: Pick<User, 'first_name' | 'last_name'>,
): string {
  const name = [];

  if (user.first_name) {
    name.push(user.first_name);
  }

  if (user.last_name) {
    name.push(user.last_name);
  }

  return name.join(' ');
}

export function getInitials({
  first_name,
  last_name,
  name,
}: Pick<User, 'first_name' | 'last_name' | 'name'>): string {
  return (
    [(first_name || '')[0], (last_name || '')[0]].join('').trim() ||
    (name || '')[0]
  );
}

export function formatEmail(
  user: Pick<User, 'primary_email_address_id' | 'email_addresses'>,
): string {
  const primary = user.email_addresses.find(
    e => e.id === user.primary_email_address_id,
  );
  return (primary || user.email_addresses[0])?.email_address;
}

export function formatPhone(
  user: Pick<User, 'primary_phone_number_id' | 'phone_numbers'>,
): string {
  const primary = user.phone_numbers.find(
    p => p.id === user.primary_phone_number_id,
  );
  return (primary || user.phone_numbers[0])?.phone_number;
}

export function formatWeb3Wallet(
  user: Pick<User, 'primary_web3_wallet_id' | 'web3_wallets'>,
): string {
  const primary = user.web3_wallets.find(
    p => p.id === user.primary_web3_wallet_id,
  );
  return (primary || user.web3_wallets[0])?.web3_wallet;
}

export function formatIdentifier(
  user: Pick<
    User,
    | 'id'
    | 'first_name'
    | 'last_name'
    | 'primary_email_address_id'
    | 'email_addresses'
    | 'primary_phone_number_id'
    | 'phone_numbers'
    | 'web3_wallets'
    | 'primary_web3_wallet_id'
    | 'username'
  >,
): string {
  return (
    formatName(user) ||
    formatEmail(user) ||
    user.username ||
    formatPhone(user) ||
    formatWeb3Wallet(user) ||
    user.id ||
    ''
  );
}

export function formatRel(date: Date | number): string {
  return titleize(formatRelative(subDays(new Date(date), 0), new Date()));
}

export function formatLastSeen(user: Pick<User, 'updated_at'>): string {
  return titleize(
    formatRelative(subDays(new Date(user.updated_at), 0), new Date()),
  );
}

export function formatCardTitle(
  user: Pick<
    User,
    | 'id'
    | 'first_name'
    | 'last_name'
    | 'primary_email_address_id'
    | 'email_addresses'
    | 'primary_phone_number_id'
    | 'phone_numbers'
    | 'web3_wallets'
    | 'primary_web3_wallet_id'
    | 'username'
  >,
): string {
  const fallback = 'Profile';

  if (!user) {
    return fallback;
  }

  return formatIdentifier(user) || fallback;
}

export function formatJSON(o: Record<string, unknown>, len = 30): string {
  if (!o) {
    return '';
  }

  const json = JSON.stringify(o);
  if (!json || json.length <= len + 1) {
    return json;
  }
  return json.substring(0, len) + '...}';
}

export function isEmptyObj(o: Record<string, unknown>): boolean {
  return !o || Object.keys(o).length === 0;
}
