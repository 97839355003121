import React from 'react';
import { Button, Icon, Text } from '@chakra-ui/react';
import { ExclamationIcon } from '@heroicons/react/solid';
import { Modal } from '../common';

type DeleteSecretKeyModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  isDeleting?: boolean;
};

export function DeleteSecretKeyModal({
  isOpen,
  onClose,
  onDelete,
  isDeleting,
}: DeleteSecretKeyModalProps): JSX.Element {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title='Delete secret key'
      size='lg'
      autoFocus={false}
    >
      <Modal.Body>
        <Text textStyle='sm-normal' color='black'>
          Are you sure you want to delete this secret key?
        </Text>
      </Modal.Body>

      <Modal.Footer flexDir='row-reverse'>
        <Button
          colorScheme='danger'
          leftIcon={<Icon as={ExclamationIcon} />}
          onClick={onDelete}
          isLoading={isDeleting}
        >
          DELETE KEY
        </Button>

        <Button variant='ghost' onClick={onClose} px='6'>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
