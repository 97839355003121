import React from 'react';

export function useHTTPStatusCode(endpoint: string): {
  statusCode: number;
  isLoading: boolean;
} {
  const [statusCode, setStatusCode] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    async function makeRequest() {
      setIsLoading(true);
      try {
        const response = await fetch(endpoint);
        setStatusCode(response.status);
      } catch (err) {
        setStatusCode(null);
      } finally {
        setIsLoading(false);
      }
    }
    void makeRequest();
  }, [endpoint]);

  return { statusCode, isLoading };
}
