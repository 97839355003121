import React from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { useDashboardCRUD, useDashboardSWR } from '@hooks/useDashboardAPI';
import { useCanGoLive, useLocation, useToast } from '@hooks';

export function Deploy(): JSX.Element {
  const { instanceId } = useLocation();
  const { create } = useDashboardCRUD();
  const { canGoLive } = useCanGoLive();
  const { mutate: revalidateInstanceStatus } = useDashboardSWR(
    `/v1/instances/${instanceId}/status`,
  );
  const { showErrorToast } = useToast();

  const handleDeployInstance = async () => {
    try {
      await create(`/v1/instances/${instanceId}/go_live`, {});
      // TODO: Do we need a timeout to be sure of the updated statuses ?
      await revalidateInstanceStatus();
    } catch (_) {
      showErrorToast('Something went wrong, please try again later.');
    }
  };

  return (
    <Flex
      bg='gray.50'
      px='4'
      py='3'
      justify='space-between'
      mt='6'
      rounded='md'
    >
      <Box mr='4'>
        <Text textStyle='md-medium' mb='0.5'>
          Deploy certificates
        </Text>
        <Text textStyle='sm-normal'>
          When all above items have been completed, finalize your setup by
          deploying the instance SSL certificates
        </Text>
      </Box>

      <Button isDisabled={!canGoLive} onClick={handleDeployInstance}>
        Deploy certificates
      </Button>
    </Flex>
  );
}
