import React from 'react';
import NextLink from 'next/link';
import { Controller, useFormContext } from 'react-hook-form';
import { useLocation, useSupportedFeature } from '@hooks';
import { Link as ChakraLink, VStack } from '@chakra-ui/react';
import { CardDualPanel } from '@components/common';
import { SwitchInfoField } from '@components/common/Switch';
import { ComingSoonBadge } from '@components/common/Badges';
import { usePaymentRequired } from '@context/PaymentRequiredContext';
import { UPCOMING_MULTIFACTOR_FACTORS } from './constants';
import { SUPPORTED_FEATURES } from '@constants';

const PhoneNumberDisabledInfo = ({ href }) => (
  <>
    Cannot be enabled because{' '}
    <NextLink href={href} passHref>
      <ChakraLink textTransform='unset' color='primary.500' fontWeight='medium'>
        Phone number is off
      </ChakraLink>
    </NextLink>
  </>
);

const BackupCodeDisabledInfo = () => (
  <> Cannot be enabled because no other factor is enabled </>
);

const firstFactorsDisabledInfo =
  'Cannot be enabled because no Authentication Factors or Social Connections are enabled';

const getInfo = (
  allFirstFactorsDisabled,
  attribute,
  disabled,
  userObjectModelHref,
) => {
  if (allFirstFactorsDisabled) {
    return firstFactorsDisabledInfo;
  }

  if (attribute === 'phone_number' && disabled) {
    return <PhoneNumberDisabledInfo href={userObjectModelHref} />;
  } else if (attribute === 'backup_code' && disabled) {
    return <BackupCodeDisabledInfo />;
  }
};

type MultifactorFactorsFormProps = {
  allFirstFactorsDisabled?: boolean;
};

export function MultifactorFactorsForm({
  allFirstFactorsDisabled,
}: MultifactorFactorsFormProps): JSX.Element {
  const { isSupported, isPremium, isUnsupportedAndEnabled } =
    useSupportedFeature();
  const { showModal } = usePaymentRequired();
  const { instanceId, applicationId } = useLocation();
  const { getValues, watch, control, setValue } = useFormContext();

  const userObjectModelHref = `/apps/${applicationId}/instances/${instanceId}/user-authentication/email-phone-username`;

  const phoneNumberSecondFactors = watch(
    'attributes.phone_number.second_factors',
  );
  const authenticatorAppSecondFactors = watch(
    'attributes.authenticator_app.second_factors',
  );
  const anySecondFactorEnabled =
    phoneNumberSecondFactors?.length > 0 ||
    authenticatorAppSecondFactors?.length > 0;

  React.useEffect(() => {
    // If all second factors are disabled, disable also backup codes
    if (!anySecondFactorEnabled) {
      setValue(
        'attributes.backup_code',
        { enabled: false, used_for_second_factor: false },
        { shouldDirty: true },
      );
      // setValue with multiple values doesn't work for empty arrays, that's why we are forced to call it exclusively
      setValue('attributes.backup_code.second_factors', [], {
        shouldDirty: true,
      });
    }
  }, [anySecondFactorEnabled, setValue]);

  const handleMFAFactorChange = (
    feature,
    attribute,
    strategy,
    onChange,
    value,
  ) => {
    if (
      !isSupported(feature) &&
      !isUnsupportedAndEnabled({
        feature,
        currentValue: value?.includes(strategy),
      })
    ) {
      return () =>
        showModal({
          features: [feature],
        });
    }

    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const isEnabled = e.target.checked;

      const isUsedOnlyForSecondFactor = getValues(
        `attributes.${attribute}.is_used_only_for_second_factor`,
      );

      if (isUsedOnlyForSecondFactor) {
        setValue(`attributes.${attribute}.enabled`, isEnabled, {
          shouldDirty: true,
        });
      }

      setValue(`attributes.${attribute}.used_for_second_factor`, isEnabled, {
        shouldDirty: true,
      });

      const secondFactors = isEnabled ? [strategy] : [];
      setValue(`attributes.${attribute}.second_factors`, secondFactors, {
        shouldDirty: true,
      });

      const isVerifiable = getValues(`attributes.${attribute}.is_verifiable`);
      if (isEnabled && isUsedOnlyForSecondFactor && isVerifiable) {
        setValue(`attributes.${attribute}.verifications`, [strategy], {
          shouldDirty: true,
        });
      }

      if (
        isPremium(SUPPORTED_FEATURES.mfa_totp) &&
        !value?.includes(strategy)
      ) {
        showModal();
      }

      return onChange(secondFactors);
    };
  };

  return (
    <CardDualPanel
      title='Multi-factor authentication factors'
      subtitle='Select which factors a user can use as their additional security factor'
      docLink={{
        subject: 'Multi-factor authentication',
        url: 'https://clerk.com/docs/authentication/custom-flows/multifactor',
      }}
    >
      <VStack align='stretch'>
        <Controller
          control={control}
          name='attributes.phone_number.second_factors'
          render={({ field: { onChange, value } }) => {
            const isPhoneNumberDisabled = !getValues(
              'attributes.phone_number.enabled',
            );
            return (
              <SwitchInfoField
                name='phone_code'
                title='SMS verification code'
                description='Send the user an one-time verification code via SMS'
                info={getInfo(
                  allFirstFactorsDisabled,
                  'phone_number',
                  isPhoneNumberDisabled,
                  userObjectModelHref,
                )}
                onChange={handleMFAFactorChange(
                  SUPPORTED_FEATURES.mfa_phone_code,
                  'phone_number',
                  'phone_code',
                  onChange,
                  value,
                )}
                isPremiumFeature={
                  !isPhoneNumberDisabled &&
                  isPremium(SUPPORTED_FEATURES.mfa_phone_code)
                }
                isChecked={value?.includes('phone_code')}
                isDisabled={isPhoneNumberDisabled || allFirstFactorsDisabled}
              />
            );
          }}
        />

        <Controller
          control={control}
          name='attributes.authenticator_app.second_factors'
          render={({ field: { onChange, value } }) => {
            return (
              <SwitchInfoField
                name='totp'
                title='Authenticator application'
                description='Require the user to retrieve a time-based authentication code from a service such as Google Authenticator'
                info={getInfo(
                  allFirstFactorsDisabled,
                  'authenticator_app',
                  false,
                  userObjectModelHref,
                )}
                onChange={handleMFAFactorChange(
                  SUPPORTED_FEATURES.mfa_totp,
                  'authenticator_app',
                  'totp',
                  onChange,
                  value,
                )}
                isPremiumFeature={isPremium(SUPPORTED_FEATURES.mfa_totp)}
                isChecked={value?.includes('totp')}
                isDisabled={allFirstFactorsDisabled}
              />
            );
          }}
        />

        <Controller
          control={control}
          name='attributes.backup_code.second_factors'
          render={({ field: { onChange, value } }) => {
            return (
              <SwitchInfoField
                name='backup_code'
                title='Backup codes'
                description='Generate a list of unique codes a user can save and use once'
                info={getInfo(
                  allFirstFactorsDisabled,
                  'backup_code',
                  !anySecondFactorEnabled,
                  userObjectModelHref,
                )}
                onChange={handleMFAFactorChange(
                  SUPPORTED_FEATURES.mfa_backup_code,
                  'backup_code',
                  'backup_code',
                  onChange,
                  value,
                )}
                isPremiumFeature={
                  anySecondFactorEnabled &&
                  isPremium(SUPPORTED_FEATURES.mfa_backup_code)
                }
                isChecked={value?.includes('backup_code')}
                isDisabled={!anySecondFactorEnabled || allFirstFactorsDisabled}
              />
            );
          }}
        />

        {UPCOMING_MULTIFACTOR_FACTORS.map(({ title, description }) => (
          <SwitchInfoField
            key={title}
            name={title}
            title={title}
            description={description}
            isDisabled
            badges={[<ComingSoonBadge key='coming-soon-badge' />]}
          />
        ))}
      </VStack>
    </CardDualPanel>
  );
}
