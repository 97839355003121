import React from 'react';
import {
  useDashboardSWR,
  useDashboardCRUD,
  useLocation,
  useToast,
} from '@hooks';
import { useDisclosure } from '@chakra-ui/react';
import { ActiveIntegrationCard } from '../ActiveIntegrationCard';
import { FirebaseModal } from './FirebaseModal';
import { FirebaseIntegrationAttributes, Integration } from '../types';

export const FirebaseCard = (): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { showSuccessToast } = useToast();
  const { instanceId } = useLocation();
  const { data, isValidating, mutate } = useDashboardSWR(
    `/v1/instances/${instanceId}/jwt_services`,
  );
  const { update } = useDashboardCRUD();

  const existingFirebaseConfig = data?.firebase?.configuration;

  const handleSwitchChange = async e => {
    const isChecked = e.target.checked;

    if (isChecked) {
      onOpen();
    } else {
      try {
        const res = (await update(`/v1/instances/${instanceId}/jwt_services`, {
          firebase: {
            enabled: false,
          },
        })) as Integration;

        void mutate(res, false);
        showSuccessToast('Firebase settings saved.');
      } catch (error) {
        return;
      }
    }
  };

  const handleSave = async (
    settings: Partial<FirebaseIntegrationAttributes>,
  ) => {
    const res = await update(`/v1/instances/${instanceId}/jwt_services`, {
      firebase: settings,
    });

    void mutate(res, false);
    showSuccessToast('Firebase settings saved.');
  };

  return (
    <>
      <FirebaseModal
        existingSettings={existingFirebaseConfig}
        isOpen={isOpen}
        onClose={onClose}
        onSave={handleSave}
      />

      <ActiveIntegrationCard
        name='Firebase'
        logo='/assets/logos/firebase.svg'
        websiteUrl='firebase.google.com'
        description="Firebase is Google's mobile platform that helps you quickly develop high-quality apps and grow your business."
        docsUrl='https://firebase.google.com/docs/auth/web/custom-auth'
        switchValue={!!existingFirebaseConfig}
        onSwitchChange={handleSwitchChange}
        isLoading={isValidating}
        openModal={onOpen}
      />
    </>
  );
};
