import React from 'react';
import cn from 'classnames';
import { Card } from '@components/card';
import { Label, Spinner, ExpansionPanel } from '@clerk-ui/components';

import styles from './ServiceStatus.module.scss';
import { NonProductionStatusList, StatusList } from './StatusList';
import { Statuses } from '@types';
import { useDeployStatus } from '@hooks/useDeployStatus';
import { EnvironmentType } from '@utils';

const SERVICE_STATUS_POLLING_INTERVAL = 15000;

export function ServiceStatus(): JSX.Element {
  const { deployStatus, ssl, mail, environmentType } = useDeployStatus({
    refreshInterval: SERVICE_STATUS_POLLING_INTERVAL,
  });

  if (!deployStatus) {
    return <Spinner inverted />;
  }

  return (
    <Card className={styles.container}>
      <ExpansionPanel
        headingType='h5'
        title='Deploy status'
        id='dep-status'
        ActionComponent={<StatusIcon status={deployStatus} />}
        panelContainerClassname={styles.panelContainer}
        initialExpandedState={deployStatus !== Statuses.Complete}
        closedButtonClassname={styles.closedStateSurface}
      >
        {environmentType === EnvironmentType.Production ? (
          <StatusList sslStatus={ssl.status} emailStatus={mail.status} />
        ) : (
          <NonProductionStatusList deployStatus={deployStatus} />
        )}
      </ExpansionPanel>
    </Card>
  );
}

type StatusIconProps = {
  status: Statuses;
};

const StatusIcon = ({ status }: StatusIconProps): JSX.Element => {
  const messages = {
    [Statuses.InProgress]: 'Deploying',
    [Statuses.Complete]: 'All systems normal',
    [Statuses.Failed]: 'Problems detected',
  };

  return (
    <Label className={cn(styles.statusLabel)}>
      <span className={cn(styles.dot, styles[status])} />
      {messages[status]}
    </Label>
  );
};
