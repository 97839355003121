import React from 'react';
import { Center, useToast, VStack } from '@chakra-ui/react';
import { PageLoader } from '@components/common';
import {
  useApplication,
  useDashboardCRUD,
  useDashboardSWR,
  useLocation,
} from '@hooks';
import { CurrentPlan } from './CurrentPlan';
import { AvailablePlans } from './AvailablePlans';
import { Application } from '@types';
import { CheckoutStatusNotice, ErrorMessages } from './constants';

export function BillingSettings(): JSX.Element {
  const { applicationId } = useLocation();
  const { application, error: appError, mutate } = useApplication();
  const { create } = useDashboardCRUD();
  const {
    data: plans,
    error: plansError,
    mutate: getSubscriptionPlans,
  } = useDashboardSWR(`/v1/applications/${applicationId}/subscription_plans`);
  const toast = useToast();

  const showToast = (
    type: 'error' | 'success' | 'warning',
    title: string | React.ReactNode,
    description?: string | React.ReactNode,
  ) => {
    toast({
      title: title,
      description: description,
      status: type,
      duration: 10000,
      isClosable: true,
      position: 'top-right',
    });
  };

  const isLoading = !application || !plans;
  const currentPlanId = application ? application.subscription_plan_id : null;

  React.useEffect(() => {
    if (isLoading) {
      return;
    }

    const urlParams = new URLSearchParams(location.search);
    const currentCheckoutStatus = urlParams.get('checkout');
    urlParams.delete('checkout');
    const checkoutSessionId = urlParams.get('checkout_session_id');
    urlParams.delete('checkout_session_id');

    // prevent subsequent refresh_payment_status calls after the first one as
    // they can fail once the status is complete.
    const cleanUrl = new URL(window.location.href);
    cleanUrl.search = urlParams.toString();
    history.replaceState(null, null, cleanUrl);

    switch (currentCheckoutStatus) {
      case CheckoutStatusNotice.CANCELED:
        showToast('warning', 'Payment was cancelled');
        break;
      case CheckoutStatusNotice.SUCCESS:
        if (!checkoutSessionId) {
          return;
        }
        create(`/v1/applications/${application.id}/refresh_payment_status`, {
          checkout_session_id: checkoutSessionId,
        })
          .then((app: Application) => {
            void mutate(app);
            const newPlan = plans.find(p => p.id === app.subscription_plan_id);
            showToast(
              'success',
              'Payment was successful',
              <>
                The plan for this application has been changed to{' '}
                <strong>{newPlan.title}</strong>.
              </>,
            );
          })
          .catch(err => {
            const msg =
              ErrorMessages[err?.globalErrors[0].code] ||
              'Something went wrong, please try again later.';
            showToast('error', msg);
          });
        break;
    }
  }, [isLoading, application, plans]);

  React.useEffect(() => {
    if (!currentPlanId) {
      return;
    }
    void getSubscriptionPlans();
  }, [currentPlanId, getSubscriptionPlans]);

  if (appError || plansError) {
    return (
      <Center h='full' w='full'>
        <p>Something went wrong</p>
      </Center>
    );
  }

  if (isLoading) {
    return <PageLoader />;
  }

  const currentPlan = plans.find(
    p => p.id === application.subscription_plan_id,
  );

  return (
    <BillingPane
      application={application}
      plans={plans}
      currentPlan={currentPlan}
      handleDowngradeToFree={() => {
        mutate()
          .then((app: Application) => {
            const newPlan = plans.find(p => p.id === app.subscription_plan_id);
            showToast(
              'success',
              'Downgrade was successful',
              <>
                The plan for this application has been changed to{' '}
                <strong>{newPlan.title}</strong>.
              </>,
            );
          })
          .catch(() => {
            showToast('error', 'Something went wrong, please try again.');
          });
      }}
    />
  );
}

const BillingPane = ({
  application,
  plans,
  currentPlan,
  handleDowngradeToFree,
}) => {
  const disableCtas = application.payment_status === 'pending';

  return (
    <VStack spacing={8} align='stretch'>
      <CurrentPlan
        disabled={disableCtas}
        activePlanName={currentPlan.title}
        currentMau={application.current_mau}
        currentMao={application.current_mao}
        mauLimit={application.subscription_plan_mau_limit}
        maoLimit={application.subscription_plan_mao_limit}
        currentBillingCycle={application.current_billing_cycle}
        hasBillingAccount={application.has_billing_account}
      />

      <AvailablePlans
        disabled={disableCtas}
        plans={plans}
        activePlanId={currentPlan.id}
        currentMau={application.current_mau}
        currentMao={application.current_mao}
        handleDowngradeToFree={handleDowngradeToFree}
      />
    </VStack>
  );
};
