import React from 'react';
import { useUser } from '@clerk/nextjs';
import { CardInfoPanel, HelperLink } from '@components/common';
import { Box, Text } from '@chakra-ui/react';

const UPGRADE_GUIDE_URL = 'https://clerk.com/docs/upgrade-guides/api-keys';

export function LegacyKeyNotice(): JSX.Element | null {
  const { user } = useUser();

  if (user.unsafeMetadata?.legacy_api_key_notice_dismissed) {
    return null;
  }

  const onDismiss = async () => {
    return await user.update({
      unsafeMetadata: {
        ...user.unsafeMetadata,
        legacy_api_key_notice_dismissed: true,
      },
    });
  };

  return (
    <CardInfoPanel
      title='Do these keys look different?'
      titleContainerStyles={{ mb: 2 }}
      onDismiss={onDismiss}
    >
      <Box>
        <Text textStyle='sm-normal' mb={2}>
          We released new API keys in January 2023 to provide a more intuitive
          developer experience.
        </Text>

        <Text textStyle='sm-normal' mb={6}>
          The “Legacy API Keys” are still supported and can be accessed at the
          bottom of this page. However, our documentation has been updated to
          reflect the new keys, and we recommend upgrading to avoid potential
          confusion.
        </Text>

        <HelperLink display='block' href={UPGRADE_GUIDE_URL}>
          Read upgrade guide
        </HelperLink>
      </Box>
    </CardInfoPanel>
  );
}
