import React from 'react';
import { Text, Link } from '@chakra-ui/react';

const listItemStyles = {
  display: 'inline-flex',
  w: '100%',
  alignItems: 'center',
  rounded: 'md',
  px: 4,
  py: 3,
  _hover: {
    color: 'black',
    bg: 'gray.50',
  },
  _focus: {
    boxShadow: 'none',
  },
};

export function SupportButtonListItem({
  href,
  title,
  icon,
  onClick,
}: {
  href?: string;
  title: string;
  icon: React.ReactNode;
  onClick?: () => void;
}): JSX.Element {
  return (
    <Link
      onClick={onClick}
      target='_blank'
      rel='noopener'
      href={href}
      {...listItemStyles}
    >
      {icon}
      <Text textStyle='sm-medium'>{title}</Text>
    </Link>
  );
}
