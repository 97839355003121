import React from 'react';
import { Stack, StackProps } from '@chakra-ui/react';

type CardContainerProps = {
  children: React.ReactNode;
  disabled?: boolean;
  opaque?: boolean;
  nonClickable?: boolean;
} & StackProps;

const MAKE_OPAQUE_STYLE: React.CSSProperties = {
  opacity: '0.5',
};

const DISABLE_POINTER_STYLE: React.CSSProperties = {
  pointerEvents: 'none',
};

export function CardContainer({
  disabled,
  nonClickable,
  opaque,
  children,
  ...rest
}: CardContainerProps): JSX.Element {
  const getStyles = (): React.CSSProperties => {
    return {
      ...(nonClickable || disabled ? DISABLE_POINTER_STYLE : {}),
      ...(disabled || opaque ? MAKE_OPAQUE_STYLE : {}),
    };
  };
  return (
    <Stack layerStyle='card' style={getStyles()} {...rest}>
      {children}
    </Stack>
  );
}
