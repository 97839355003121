import { useApplication, useInstance, useUnsafeMetadata } from '@hooks';
import { isFeatureSupported } from '@utils/billing';

export const useSupportedFeature = (): any => {
  const { application } = useApplication();
  const { instance } = useInstance();
  const { shouldShowInfoModal } = useUnsafeMetadata();

  const isPremium = (name: string): boolean => {
    return instance?.premium_features.includes(name);
  };

  const isSupported = (name: string, value?: any): boolean => {
    return !!value || isFeatureSupported(instance?.supported_features, name);
  };

  const isAccessible = (name: string): boolean => {
    return application?.user_accessible_features.includes(name);
  };

  const hasNotSeenInfoBillingModal = (name: string): boolean => {
    const showInfoModal = shouldShowInfoModal(application?.id);

    return isPremium(name) && showInfoModal;
  };

  const isUnsupportedAndEnabled = ({ feature, currentValue }) => {
    return !isSupported(feature) && !!currentValue;
  };

  return {
    isAccessible,
    isSupported,
    isPremium,
    hasNotSeenInfoBillingModal,
    isUnsupportedAndEnabled,
  };
};
