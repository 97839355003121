import React from 'react';
import cn from 'classnames';
import { Center } from '@components/layouts';
import styles from './BlankSlate.module.scss';

export type BlankSlateProps = {
  children: React.ReactNode;
  className?: string;
};

export const BlankSlate: React.FC<BlankSlateProps> = ({
  children,
  className,
}: BlankSlateProps) => {
  return (
    <Center className={cn(styles.blankSlate, className)}>{children}</Center>
  );
};
