import React from 'react';
import { Button, Text, VStack } from '@chakra-ui/react';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { FormSubmitButton } from './StickyBottomCTA';
import { InformationBox } from './InformationBox';
import { Modal } from '@components/common';

interface ApplySetupWarningModalProps {
  isOpen: boolean;
  onClose: () => void;
  isLoading?: boolean;
  formId: string;
  warningMsg: string;
}

export function ApplySetupWarningModal({
  isOpen,
  onClose,
  formId,
  isLoading,
  warningMsg,
}: ApplySetupWarningModalProps): JSX.Element {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title='Confirmation'
      autoFocus={false}
    >
      <Modal.Body>
        <InformationBox icon={ExclamationCircleIcon} iconColor='warning.300'>
          <VStack align='start' spacing='1'>
            <Text textStyle='md-semibold'>{warningMsg}</Text>
            <Text textStyle='md-normal'>Are you sure you want to proceed?</Text>
          </VStack>
        </InformationBox>
      </Modal.Body>

      <Modal.Footer justifyContent='space-between' flexDir='row-reverse'>
        <FormSubmitButton
          formId={formId}
          isLoading={isLoading}
          onClick={onClose}
          tabIndex={1}
        />

        <Button onClick={onClose} variant='ghost' tabIndex={1}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
