import React from 'react';
import { Button, Flex, VStack } from '@chakra-ui/react';
import { Modal } from '@components/common';
import { SwitchField } from './SwitchField';

type UsernameConfigModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function UsernameConfigModal({
  isOpen,
  onClose,
}: UsernameConfigModalProps): JSX.Element {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      autoFocus={false}
      size='xl'
      title='Username'
      subtitle='Configure username attribute'
    >
      <Modal.Body>
        <VStack spacing='6'>
          <SwitchField
            name='attributes.username.required'
            label='Require'
            description='Users must provide a username to sign up'
          />

          <VStack spacing='1' w='full'>
            <SwitchField
              name='attributes.username.used_for_first_factor'
              label='Sign-in'
              description='Allow users to sign in with their username'
            />
          </VStack>
        </VStack>
      </Modal.Body>

      <Modal.Footer mt='6'>
        <Flex direction='row' w='full'>
          <Flex justify='space-between' w='full' flexDir='row-reverse'>
            <Button onClick={onClose}>Continue</Button>
          </Flex>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
}
