import React from 'react';
import { Box, Text } from '@chakra-ui/layout';
import { Link, Wrap, Badge } from '@chakra-ui/react';
import { InformationBox } from '@components/common';

type AttributeInfoProps = {
  title: React.ReactNode;
  description: string;
  docsUrl?: string;
  info?: React.ReactNode;
  htmlFor?: string;
  badges?: React.ReactNode[];
  minBadgeHeight?: string;
  isPremiumFeature?: boolean;
};

export function AttributeInfo({
  title,
  description,
  badges,
  docsUrl,
  info,
  htmlFor,
  minBadgeHeight,
  isPremiumFeature = false,
}: AttributeInfoProps): JSX.Element {
  return (
    <Box flexDirection='column' w='full'>
      <Text
        as='label'
        textStyle='md-medium'
        mb='0.25rem'
        htmlFor={htmlFor}
        display='block'
      >
        {title}
        {isPremiumFeature && <Badge ml='2'>Premium</Badge>}
      </Text>
      <Text textStyle='sm-normal' color='gray.500'>
        {description}
      </Text>
      {(badges || minBadgeHeight) && (
        <Wrap mt='2' minH={minBadgeHeight}>
          {badges.map((badge, index) => (
            <React.Fragment key={index}>{badge}</React.Fragment>
          ))}
        </Wrap>
      )}
      {docsUrl && (
        <Link
          href={docsUrl}
          textStyle='sm-medium'
          color='primary.500'
          target='_blank'
        >
          Documentation
        </Link>
      )}
      {info && (
        <InformationBox bgColor='transparent' px='0px' py='2' color='gray.300'>
          <Text
            textStyle='sm-normal'
            display='inline-block'
            color='gray.500'
            lineHeight='1.5'
          >
            {info}
          </Text>
        </InformationBox>
      )}
    </Box>
  );
}
