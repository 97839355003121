import { RecentActivity } from '@components/home';
import { TodosProvider } from '@components/instanceHome/gettingStarted/contexts';

import React from 'react';
import { HStack, Spinner, Center, Text } from '@chakra-ui/react';

import { useDashboardSWR } from '@hooks/useDashboardAPI';
import { useLocation } from '@hooks';

import { CardSinglePanel } from '@components/common';
import { enUS } from 'date-fns/locale';

const UsersAvailableStateDisplay = () => {
  const { instanceId } = useLocation();

  const {
    data: monthlyMetrics = {},
    error: monthlyMetricsError,
    mutate: mutateMonthlyMetrics,
    isValidating,
  } = useDashboardSWR(`/v1/instances/${instanceId}/analytics/monthly_metrics`);

  const {
    total_users = 0,
    month = new Date().getMonth(),
    year = new Date().getFullYear(),
    active_users = 0,
    signups = 0,
    signins = 0,
  } = monthlyMetrics;

  const date = `${enUS.localize.month(month - 1)} ${year}`;

  return (
    <TodosProvider>
      <HStack spacing={3} align='stretch'>
        <CardSinglePanel
          title='Total users'
          subtitle='All time'
          hasError={monthlyMetricsError}
          mutate={mutateMonthlyMetrics}
        >
          {isValidating ? (
            <Center>
              <Spinner />
            </Center>
          ) : (
            <Text textStyle='h2'>{total_users}</Text>
          )}
        </CardSinglePanel>

        <CardSinglePanel
          title='Active users'
          subtitle={date}
          hasError={monthlyMetricsError}
          mutate={mutateMonthlyMetrics}
        >
          {isValidating ? (
            <Center>
              <Spinner />
            </Center>
          ) : (
            <Text textStyle='h2'>{active_users}</Text>
          )}
        </CardSinglePanel>

        <CardSinglePanel
          title='Sign-ups'
          subtitle={date}
          hasError={monthlyMetricsError}
          mutate={mutateMonthlyMetrics}
        >
          {isValidating ? (
            <Center>
              <Spinner />
            </Center>
          ) : (
            <Text textStyle='h2'>{signups}</Text>
          )}
        </CardSinglePanel>

        <CardSinglePanel
          title='Sign-ins'
          subtitle={date}
          hasError={monthlyMetricsError}
          mutate={mutateMonthlyMetrics}
        >
          {isValidating ? (
            <Center>
              <Spinner />
            </Center>
          ) : (
            <Text textStyle='h2'>{signins}</Text>
          )}
        </CardSinglePanel>
      </HStack>

      <RecentActivity />
    </TodosProvider>
  );
};

export default UsersAvailableStateDisplay;
