import React, { FunctionComponent } from 'react';
import { useLocation } from '@hooks';
import { Link as LinkChakra } from '@chakra-ui/react';
import NextLink from 'next/link';
import { noop, applicationInstancePath } from '@utils';
import { NavItem } from './NavItem';

interface NavLinkProps {
  href?: string;
  icon?: FunctionComponent;
  isAccordionChild?: boolean;
  isExternal?: boolean;
  label: React.ReactNode;
  onClick?: () => void | undefined;
}

export const NavLink = ({
  href,
  isAccordionChild,
  isExternal,
  icon,
  label,
  onClick = noop,
}: NavLinkProps): JSX.Element => {
  const {
    applicationId,
    instanceId,
    currentPage,
    query: { templateType },
  } = useLocation();
  const isDisabled = !href;

  const currentUrl = currentPage?.replace('[templateType]', `${templateType}`);
  // format url so we can compare the subpages
  const isActive = currentUrl.startsWith(href) && currentUrl.endsWith(href);

  const item = (
    <LinkChakra
      w='236px'
      variant='no-focus'
      onClick={isDisabled ? undefined : onClick}
      target={href?.startsWith('https://') ? '_blank' : ''}
    >
      <NavItem
        isDisabled={isDisabled}
        icon={icon}
        isAccordionChild={isAccordionChild}
        isExternal={isExternal}
        isActive={(href !== '/' && isActive) || href === currentPage}
        label={label}
      />
    </LinkChakra>
  );

  if (isDisabled) {
    return item;
  }

  if (href.startsWith('https://')) {
    return (
      <NextLink passHref href={href}>
        {item}
      </NextLink>
    );
  }

  return (
    <NextLink
      passHref
      href={`${applicationInstancePath({
        applicationId,
        instanceId,
      })}${href}`}
    >
      {item}
    </NextLink>
  );
};
