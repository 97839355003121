import React from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
} from '@chakra-ui/react';
import { useTLDParser } from '@hooks/useTLDParser';
import { useFormContext } from 'react-hook-form';
import type { ProdInstanceForm } from './CreateProdInstanceModal';

type UrlInputProps = {
  onBlur?: () => void;
  autoFocus?: boolean;
};

export function UrlInput({
  onBlur,
  autoFocus = true,
}: UrlInputProps): JSX.Element {
  const {
    register,
    formState: { errors },
  } = useFormContext<ProdInstanceForm>();
  const { parse } = useTLDParser();

  const validateUrl = (value: string): boolean | string => {
    if (!value) {
      return 'Enter a valid Home URL';
    }
    const tldResult = parse(value);
    if (!tldResult.domain) {
      return 'Enter a valid Home URL';
    }
    return true;
  };

  return (
    <FormControl isInvalid={!!errors.home_url}>
      <FormLabel htmlFor='home_url' textStyle='md-medium' mb='2'>
        Home URL
      </FormLabel>
      <InputGroup outline='none !important' boxShadow='none !important'>
        <InputLeftAddon
          bg='gray.50'
          px='3'
          py='2.5'
          textStyle='sm-medium'
          outline='none !important'
          boxShadow='none !important'
          _invalid={{
            bg: 'error',
          }}
        >
          https://
        </InputLeftAddon>
        <Input
          autoFocus={autoFocus}
          name='home_url'
          id='home_url'
          type='text'
          placeholder='www.example.com'
          defaultValue=''
          {...register('home_url', {
            required: 'Home URL is required',
            validate: validateUrl,
            onBlur,
          })}
          outline='none !important'
          boxShadow='none !important'
          _active={{ outline: '0', boxShadow: 'none !important' }}
          _focus={{ outline: '0', boxShadow: 'none !important' }}
        />
      </InputGroup>
      <FormErrorMessage>{errors.home_url?.message}</FormErrorMessage>
    </FormControl>
  );
}
