import React from 'react';
import { Center, Spinner, Text } from '@chakra-ui/react';

import { Organization } from '@components/organizations/core/types';
import {
  Table as OrganizationTable,
  DumbPagination,
  EmptyTable,
} from '@components/common';
import { PAGE_SIZE } from '@components/organizations/constants';

import useTable from './useTable';

const Table = (): JSX.Element => {
  const {
    data,
    schema,
    page,
    isLoading,
    totalCount,
    sortingState,
    error,
    onRowClicked,
    onPaginate,
    onSorted,
  } = useTable();

  const hasNextPage = React.useMemo(() => {
    if (!totalCount) {
      return false;
    }

    return totalCount > PAGE_SIZE * page;
  }, [page, totalCount]);

  if (error) {
    return (
      <Text textStyle='sm-normal' textAlign='center' color='blackAlpha.700'>
        Something went wrong while fetching organizations.
      </Text>
    );
  }

  if (isLoading) {
    return (
      <Center w='full' h='full'>
        <Spinner aria-label='Loading organizations..' />
      </Center>
    );
  }

  return (
    <>
      <OrganizationTable<Organization>
        emptyTableFallback={
          <EmptyTable message='No organizations to display' />
        }
        data={data}
        tableSchema={schema}
        onRowClicked={onRowClicked}
        sortingState={sortingState}
        onSorted={onSorted}
      />
      <DumbPagination
        currentPage={page}
        itemsThisPage={data.length}
        hasNextPage={hasNextPage}
        onPaginate={onPaginate}
      />
    </>
  );
};

export default Table;
