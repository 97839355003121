import React from 'react';
import { Statuses } from '@types';
import { CheckIcon, ExclamationIcon } from '@heroicons/react/solid';
import { Center, Icon, Spinner } from '@chakra-ui/react';

type StatusIconProps = {
  status: Statuses;
};

export function StatusIcon({ status }: StatusIconProps): JSX.Element {
  switch (status) {
    case Statuses.Complete:
      return <Icon as={CheckIcon} color='success.500' boxSize='5' />;
    case Statuses.InProgress:
      return (
        <Center boxSize='5'>
          <Spinner w='16px' h='16px' size='sm' color='primary.500' />
        </Center>
      );
    default:
      return <Icon as={ExclamationIcon} color='warning.500' boxSize='5' />;
  }
}
