import React from 'react';
import { Breadcrumb, Text } from '@chakra-ui/react';

type ContainerProps = {
  children: React.ReactNode;
};

export function Container({ children }: ContainerProps): JSX.Element {
  return (
    <Breadcrumb
      spacing={5}
      separator={
        <Text as='span' color='gray.200' fontSize='2xl'>
          /
        </Text>
      }
      sx={{
        ol: {
          display: 'flex',
          margin: 0,
        },
      }}
    >
      {children}
    </Breadcrumb>
  );
}
