import React from 'react';

import { DebouncedSearchInput } from '../SearchInput';

import {
  Menu,
  MenuButton,
  MenuList,
  Flex,
  FlexProps,
  Text,
} from '@chakra-ui/react';

export type SearchWithDropdownProps<T> = {
  data: T[];
  value?: string;
  isLoading?: boolean;
  placeholder?: string;
  autoFocusInput?: boolean;
  onChange: (value: string) => void;
  onItemClick: (data: T) => void;
  ListItem: (data: T) => React.ReactElement;
};

/**We use Custom menu item here because the MenuItem from chakra are auto focusable
 * when we when the Menu Opens. We dont want this functionallity here because we
 * are losing the focus from the searchbox.
 */

type MenuItemProps = {
  children: React.ReactNode;
  onClick?: () => void;
} & FlexProps;

const MenuItem = ({
  children,
  onClick,
  ...rest
}: MenuItemProps): JSX.Element => {
  return (
    <Flex
      _hover={{ bg: 'rgba(0, 0, 0, 0.04)' }}
      width='100%'
      px={4}
      py={2}
      cursor='pointer'
      onClick={onClick}
      {...rest}
    >
      {children}
    </Flex>
  );
};

const SearchWithDropdown = <T extends { id: string }>({
  data,
  value = '',
  placeholder = '',
  isLoading,
  onChange,
  onItemClick,
  ListItem,
  autoFocusInput,
}: SearchWithDropdownProps<T>): JSX.Element => {
  const [searchValue, setSearchValue] = React.useState(value);

  const onClear = () => {
    setSearchValue('');
  };

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
    onChange(value);
  };

  const handleOnclickItem = (data: T) => {
    onItemClick(data);
    onClear();
  };

  return (
    <>
      <DebouncedSearchInput
        placeholder={placeholder}
        showIcon={false}
        value={searchValue}
        onChange={handleSearchChange}
        isLoading={isLoading}
        autoFocus={autoFocusInput}
      />
      <Menu matchWidth gutter={1} isOpen={!!searchValue}>
        <MenuButton
          autoFocus={false}
          position='absolute'
          width='100%'
          height={1}
        />
        {!isLoading && (
          <MenuList
            overflow='scroll'
            maxHeight={80}
            minHeight={12}
            border='1px solid rgba(0, 0, 0, 0.08)'
            borderRadius='lg'
          >
            {data.length === 0 && (
              <MenuItem
                alignItems='center'
                height={12}
                bg=' rgba(0, 0, 0, 0.04)'
              >
                <Text color='rgba(0, 0, 0, 0.65)' textStyle='sm-normal'>
                  No matching results
                </Text>
              </MenuItem>
            )}

            {data.map(data => (
              <MenuItem key={data.id} onClick={() => handleOnclickItem(data)}>
                {ListItem(data)}
              </MenuItem>
            ))}
          </MenuList>
        )}
      </Menu>
    </>
  );
};

export default SearchWithDropdown;
