import { useDashboardSWR } from '@hooks';
import { Application } from '@types';
import { useLocation } from './useLocation';

export const useApplication = () => {
  const { applicationId } = useLocation();

  const {
    data: application,
    error,
    mutate,
    isValidating,
  } = useDashboardSWR<Application>(`/v1/applications/${applicationId}`);

  return { application, error, mutate, isValidating };
};
