import React from 'react';
import {
  useDashboardCRUD,
  useDashboardSWR,
  useDeployStatus,
  useLocation,
  useToast,
} from '@hooks';
import { Badge } from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/react';
import { isProduction } from '@utils';
import { GoogleAnalyticsModal } from './GoogleAnalyticsModal';
import { ActiveIntegrationCard } from '../ActiveIntegrationCard';
import { GoogleAnalyticsIntegrationAttributes } from '../types';

export const GoogleAnalyticsCard = (): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { showSuccessToast, showErrorToast } = useToast();
  const { instanceId } = useLocation();
  const {
    data,
    isValidating: isDataValidating,
    mutate,
  } = useDashboardSWR(
    `/v1/instances/${instanceId}/integrations/google_analytics`,
  );
  const { put } = useDashboardCRUD();
  const { environmentType, isValidating: isDeployValidating } =
    useDeployStatus();

  const existingSettings = data?.metadata;

  const handleSwitchChange = async e => {
    const isChecked = e.target.checked;

    if (isChecked) {
      onOpen();
    } else {
      try {
        await put(`/v1/instances/${instanceId}/integrations/google_analytics`, {
          enabled: false,
        });

        void mutate(null, false);
        showSuccessToast('Google Analytics settings saved.');
      } catch (_) {
        showErrorToast('Something went wrong, please try again.');
      }
    }
  };

  const handleSave = async (settings: GoogleAnalyticsIntegrationAttributes) => {
    try {
      const res = await put(
        `/v1/instances/${instanceId}/integrations/google_analytics`,
        settings,
      );

      void mutate(res, false);
      showSuccessToast('Google Analytics settings saved.');
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <>
      <GoogleAnalyticsModal
        existingSettings={existingSettings}
        isOpen={isOpen}
        onClose={onClose}
        onSave={handleSave}
      />

      <ActiveIntegrationCard
        name='Google Analytics'
        logo='/assets/logos/google_analytics.svg'
        websiteUrl='analytics.google.com'
        docsUrl='https://developers.google.com/analytics/devguides/collection/protocol/v1'
        description='Google Analytics gives you the tools you need to analyze data for your business in one place, so you can make smarter decisions.'
        switchValue={!!existingSettings}
        onSwitchChange={handleSwitchChange}
        isDisabled={!isProduction(environmentType)}
        tooltipLabel={
          !isProduction(environmentType)
            ? 'Only available in production instances'
            : ''
        }
        isLoading={isDataValidating || isDeployValidating}
        tags={<Badge size='sm'>Production only</Badge>}
        openModal={onOpen}
      />
    </>
  );
};
