export function getSingleSessionFieldsOnly(pathOption) {
  return {
    ...pathOption,
    fields: pathOption.fields.filter(field => !field.availablelInMultiSession),
  };
}

export const filterOptionsThatRequireOrganizations =
  (organizationsEnabled: boolean) => (option: any) => {
    return option.requiresOrganizations ? organizationsEnabled : true;
  };

export function isSingleSessionMode(userSettings): boolean {
  if (userSettings === undefined) {
    return true;
  }

  return !!userSettings.single_session_mode;
}
