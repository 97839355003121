import { DropdownFilters } from '@components/common';

export enum ErrorCodes {
  AlreadyMember = 'already_a_member_in_organization',
  AtLeastOneAdmin = 'at_least_one_admin_needed',
}

export const PAGE_SIZE = 10;

export const dropdownFilter: DropdownFilters = {
  admin: { value: false, label: 'Admin' },
  basic_member: { value: false, label: 'Member' },
};
export const roles = { admin: 'Admin', basic_member: 'Member' };
