import React from 'react';

import { Text } from '@chakra-ui/react';

import { CardSinglePanel } from '@components/common';
import { useApplication } from '@hooks';

const NoUserDisplay = (): JSX.Element => {
  const { application } = useApplication();

  return (
    <CardSinglePanel
      title='Analytics'
      subtitle={`Once ${application.name} has users, this area will update with analytics data.`}
    >
      <Text textStyle='md-medium' color='gray.500'>
        No data
      </Text>
    </CardSinglePanel>
  );
};

export default NoUserDisplay;
