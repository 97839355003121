import React from 'react';

import { Flex, Icon, Box } from '@chakra-ui/react';
import { ExclamationCircleIcon } from '@heroicons/react/solid';

const WarningPanel = ({
  message,
}: {
  message: React.ReactNode;
}): JSX.Element => {
  if (!message) {
    return null;
  }
  return (
    <Box width='100%'>
      <Flex borderRadius='md' p={4} mt={3.5} mb={1} bg='rgba(0, 0, 0, 0.04)'>
        <Flex mt={0.5} mr={2}>
          <Icon as={ExclamationCircleIcon} color='red.500' />
        </Flex>
        {message}
      </Flex>
    </Box>
  );
};

export default WarningPanel;
