import React from 'react';
import {
  Badge,
  Box,
  Center,
  Icon,
  LinkBox,
  LinkOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  formatTimestamp,
  isProduction,
  stripProtocol,
  applicationInstancePath,
} from '@utils';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { Application, Instance } from '@types';
import { useDeployStatusForInstances } from '@hooks';
import { InstanceStatusItem } from '@components/applications/InstanceStatusItem';
import { MapIcon } from '@heroicons/react/solid';
import { useAnalytics } from '@hooks/useAnalytics';

type ApplicationCardProps = {
  application: Application;
};

function getProdInstance(instances: Instance[]): Instance | undefined {
  return instances.find(i => isProduction(i.environment_type));
}

function getApplicationUrl(instance: Instance) {
  return isProduction(instance.environment_type)
    ? stripProtocol(instance?.active_display_config?.home_url || 'N/A')
    : stripProtocol(instance?.active_domain?.frontend_api_url || 'N/A');
}

export function ApplicationCard({
  application,
}: ApplicationCardProps): JSX.Element {
  const { track } = useAnalytics();
  const {
    instances,
    card_background_color,
    id,
    name,
    updated_at,
    subscription_plan_title,
  } = application;
  const instancesDeployStatus = useDeployStatusForInstances(instances);
  const {
    query: { path },
  } = useRouter();
  const instance = getProdInstance(instances) || instances[0];
  const applicationDisplayUrl = getApplicationUrl(instance);
  const applicationNavigationUrl = applicationInstancePath({
    applicationId: id,
    instanceId: instance.id,
  });
  const pathUrl = path ? `/${path}` : '';

  return (
    <NextLink href={`${applicationNavigationUrl}${pathUrl}`} passHref>
      <LinkBox
        layerStyle='linkBoxCard'
        minH='364px'
        w='292px'
        onClick={() => {
          track('Dashboard_Applications_Application Card Clicked', {
            location: 'Applications',
            surface: 'Dashboard',
            instanceId: instance.id,
            applicationId: id,
            environmentType: instance.environment_type,
          });
        }}
      >
        <Center bg={card_background_color} h='164px' p={4}>
          <Text
            color='white'
            textStyle='md-medium'
            noOfLines={2}
            textAlign='center'
          >
            {name}
          </Text>
        </Center>
        <Stack justifyContent='space-between' flex='1' p={4}>
          <Box mb={4}>
            <LinkOverlay>
              <Text textStyle='md-medium' mb={1} color='black' noOfLines={2}>
                {name}
              </Text>
            </LinkOverlay>
            <Text textStyle='sm-medium' color='primary.500' mb={0.5}>
              {applicationDisplayUrl}
            </Text>
            <Text textStyle='sm-normal' color='gray.500'>
              {formatTimestamp(new Date(updated_at))}
            </Text>
          </Box>
          <Box flex='1'>
            {instancesDeployStatus.map(instanceStatusData => (
              <InstanceStatusItem
                key={instanceStatusData.environmentType}
                environmentType={instanceStatusData.environmentType}
                status={instanceStatusData.status}
              />
            ))}
          </Box>
          <Box>
            <Badge>
              <Icon as={MapIcon} mr={1} mb={0.5} />
              {subscription_plan_title}
            </Badge>
          </Box>
        </Stack>
      </LinkBox>
    </NextLink>
  );
}
