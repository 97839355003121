import React from 'react';

import { HStack, Input } from '@chakra-ui/react';
import { InputBox } from '@components/common';
import { useForm, FormProvider, useController } from 'react-hook-form';

import { SelectUserDropdown } from '@components/users';

import { preventSubmitOnEnter } from '@utils';

import { isSlug, createSlug } from '@utils/string';

export const FORM_ID = 'create_organization';

const validateSlug = (value: string) => {
  if (!value) {
    return true;
  }
  if (!isSlug(value)) {
    return 'Can contain only lowercase alphanumeric characters and the dash "-" symbol';
  }
};

export type CreateOrganizationForm = {
  name: string;
  slug: string;
  created_by: string;
};

type FormProps = {
  logoUploader: React.ReactElement;
  onSubmit: (
    value: CreateOrganizationForm,
    resetForm: () => void,
    setErrorForm: (
      param: keyof CreateOrganizationForm,
      errorMessage: string,
    ) => void,
  ) => void;
};
const Form = ({ onSubmit, logoUploader }: FormProps): JSX.Element => {
  const methods = useForm<CreateOrganizationForm>({
    mode: 'onChange',
    defaultValues: { created_by: '', name: '', slug: '' },
  });
  const { formState } = methods;
  const { register, control, setValue } = methods;
  const { field } = useController({
    name: 'created_by',
    control,
    rules: { required: 'Owner is required' },
  });

  /**
   * Currently we have no way to reset the dropdown with a function, however
   * react gives a nice solution by changing the key that is assigned to the component.
   * You can find more about it on the docs.
   * @link https://beta.reactjs.org/learn/preserving-and-resetting-state
   */
  const [resetUsersDropdown, setResetUsersDropdown] = React.useState(1);

  const resetForm = () => {
    methods.reset();
    setResetUsersDropdown(key => key + 1);
  };

  const setErrorForm = (
    param: keyof CreateOrganizationForm,
    errorMessage: string,
  ) => {
    methods.setError(param, { type: 'api', message: errorMessage });
  };

  const nameInput = register('name', { required: 'Name is required' });

  const onNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    void nameInput.onChange(e);
    setValue('slug', createSlug(e.target.value));
  };

  return (
    <FormProvider {...methods}>
      <form
        id={FORM_ID}
        onSubmit={methods.handleSubmit(data => {
          onSubmit(data, resetForm, setErrorForm);
        })}
        onKeyDown={preventSubmitOnEnter}
        autoComplete='off'
      >
        <HStack flexDirection='column' alignItems='initial' spacing={0} gap={5}>
          <InputBox
            error={formState?.errors['name']}
            width='100%'
            label='Name'
            p={0}
            mb={0}
          >
            <Input
              autoFocus
              width='100%'
              {...nameInput}
              onChange={onNameInputChange}
            />
          </InputBox>
          {logoUploader}
          <InputBox
            optional={true}
            error={formState.errors['slug']}
            p={0}
            mb={0}
            width='100%'
            label='Slug'
            info='A slug is a human-readable ID that must be unique.  It’s often used in URLs.'
          >
            <Input
              type='text'
              placeholder='my-org'
              defaultValue=''
              {...register('slug', {
                validate: validateSlug,
              })}
              outline='none !important'
              boxShadow='none !important'
              _active={{ outline: '0', boxShadow: 'none !important' }}
              _focus={{ outline: '0', boxShadow: 'none !important' }}
            />
          </InputBox>
          <InputBox
            error={formState.errors['created_by']}
            width='100%'
            p={0}
            label='Owner'
          >
            <SelectUserDropdown
              placeholder='Select owner'
              key={resetUsersDropdown}
              name='created_by'
              onUserSelected={field.onChange}
            />
          </InputBox>
        </HStack>
      </form>
    </FormProvider>
  );
};

export default Form;
