import React from 'react';
import cn from 'classnames';
import { Dropdown, DropdownSelection } from '../dropdown';
// @ts-ignore
import styles from './FontPicker.module.scss';
import { TypeIcon } from '../../assets/icons';

/* eslint-disable quotes */
const FONTS = Object.freeze([
  '"Alegreya", serif',
  '"Arial", sans-serif',
  '"BioRhyme", serif',
  '"Brush Script MT", cursive',
  '"Cairo", sans-serif',
  '"Cormorant", serif',
  '"Courier New", monospace',
  '"Crimson Text", serif',
  '"Garamond", serif',
  '"Georgia", serif',
  '"Helvetica", sans-serif',
  '"IBM Plex Sans", sans-serif',
  '"Inter", sans-serif',
  '"Josefin Sans", sans-serif',
  '"Karla", sans-serif',
  '"Lato", sans-serif',
  '"Lora", serif',
  '"Montserrat", sans-serif',
  '"Mulish", sans-serif',
  '"Open Sans", sans-serif',
  '"Oswald", sans-serif',
  '"Playfair Display", serif',
  '"Poppins", sans-serif',
  '"PT Sans", sans-serif',
  '"Raleway", sans-serif',
  '"Roboto", sans-serif',
  '"Rubik", sans-serif',
  '"Slabo 27px, serif',
  '"Source Code Pro", monospace',
  '"Source Sans Pro", sans-serif',
  '"Spectral", serif',
  '"Tahoma", sans-serif',
  '"Times New Roman", serif',
  '"Titillium Web", sans-serif',
  '"Trebuchet MS", sans-serif',
  '"Verdana", sans-serif',
  '"Vollkorn", serif',
  '"Work Sans", sans-serif',
]);

export type FontPickerProps = {
  name?: string;
  active?: boolean;
  className?: string;
  selectedFont?: string;
  defaultSelectedIndex?: number;
  handleChange?: (el: DropdownSelection) => void;
};

export const FontPicker: React.FC<FontPickerProps> = ({
  name,
  active,
  className,
  selectedFont = FONTS[0],
  handleChange,
}: FontPickerProps) => {
  const fontOptions = React.useMemo(() => {
    return FONTS.map(font => {
      const fontName = font.split(',')[0]?.replace(/['"]+/g, '') || '';
      return {
        value: font,
        label: <span style={{ fontFamily: font }}>{fontName}</span>,
      };
    });
  }, []);

  return (
    <Dropdown
      Icon={<TypeIcon />}
      name={name}
      active={active}
      className={cn(styles.dropdown, className)}
      options={fontOptions}
      selectedOption={selectedFont}
      handleChange={handleChange}
    />
  );
};
