import React from 'react';
import cn from 'classnames';

// @ts-ignore
import styles from './Notice.module.scss';
import { WarningIcon, CheckCircleIcon, XIcon } from '../../assets/icons';

function Nocomponent() {
  return <></>;
}

function Success() {
  return <CheckCircleIcon stroke='#36ca36' />;
}

function Warning() {
  return <WarningIcon stroke='#ff1d17' />;
}

const FLAVORS = Object.freeze({
  error: Warning,
  warning: Nocomponent,
  success: Success,
});

export type NoticeProps = {
  children: React.ReactNode;
  flavor: 'error' | 'warning' | 'success';
  title?: string;
  onClose?: () => void;
};

export const Notice: React.FC<NoticeProps> = ({
  children,
  flavor = 'error',
  title,
  onClose,
}) => (
  <div className={cn(styles.notice, styles[flavor])} title={title}>
    <div className={styles.gutter}></div>
    <div className={styles.content}>
      <div className={styles.icon}>{FLAVORS[flavor]()}</div>
      <div>{children}</div>
    </div>
    {onClose && (
      <button
        aria-label='Close'
        className={styles.closeButton}
        onClick={onClose}
      >
        <XIcon stroke='#808080' />
      </button>
    )}
  </div>
);
