import React from 'react';
import { InstanceStatusField } from '@components/applications/InstanceStatusItem';
import { determineDeployStatus, useDashboardCRUD } from '@hooks';
import { Instance, InstanceStatus, Statuses } from '@types';
import { devInstancesFirst, EnvironmentType, isProduction } from '@utils';

export type UseDeployStatusForInstance = {
  useDeployStatusForInstance: (
    instance: Instance,
  ) => Promise<InstanceStatusField>;
};

export type InstancesDeployStatus = {
  environmentType: EnvironmentType;
  status: Statuses;
  id: string;
};

export function useDeployStatusForInstances(
  instances: Instance[],
): InstancesDeployStatus[] {
  const [instancesDeployStatus, setInstancesDeployStatus] = React.useState<
    InstancesDeployStatus[]
  >(
    instances.sort(devInstancesFirst).map(({ environment_type, id }) => ({
      environmentType: environment_type,
      status: Statuses.NotStarted,
      id,
    })),
  );
  const { read } = useDashboardCRUD<InstanceStatus>();

  React.useEffect(() => {
    const getDeployStatusForInstance = async (
      instance: Instance,
    ): Promise<InstancesDeployStatus> => {
      const { environment_type: environmentType, id } = instance;

      if (!isProduction(environmentType)) {
        return { environmentType, status: Statuses.Complete, id };
      }

      // fetch deploy status only for non development instances
      const status = determineDeployStatus(
        await read(`/v1/instances/${id}/status`),
        environmentType,
      );
      return { environmentType, status, id };
    };

    const updateInstanceStatuses = async () => {
      const statuses = await Promise.all(
        instances.sort(devInstancesFirst).map(getDeployStatusForInstance),
        // TODO: Add error handling
      ).catch(() => []);

      setInstancesDeployStatus(statuses);
    };

    void updateInstanceStatuses();
  }, [instances]);

  return instancesDeployStatus;
}
