import React from 'react';
import { extension, SupportedMimeType } from '@utils/mimeTypeExtensions';
import { toSentence } from '@utils/array';
import { Button, Text } from '@chakra-ui/react';
import { FileDropArea } from './FileDropArea';
import { Modal } from '@components/common';

export type ImageUploaderProps = {
  itemName: string;
  handleSuccess: (file: File) => Promise<unknown>;
  isModalOpen: boolean;
  closeModal: () => void;
  acceptedTypes?: SupportedMimeType[];
  sizeLimitBytes?: number;
};

export const IMAGE_UPLOADER_IMAGE_TYPES: SupportedMimeType[] = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/webp',
];

export const IMAGE_UPLOADER_ICON_TYPES: SupportedMimeType[] = [
  'image/vnd.microsoft.icon',
  'image/x-icon',
];

function typesToSentence(types: SupportedMimeType[]): string {
  const exts = types.map(t => extension(t).toUpperCase());
  return toSentence([...new Set(exts)]);
}

export function ImageUploader({
  itemName,
  handleSuccess,
  isModalOpen,
  closeModal,
  acceptedTypes = IMAGE_UPLOADER_IMAGE_TYPES,
  sizeLimitBytes = 10 * 1000 * 1000,
}: ImageUploaderProps): JSX.Element {
  const [hasFileError, setHasFileError] = React.useState(false);

  const uploadSuccess = (img: any) => {
    void handleSuccess(img).then(() => closeModal());
  };
  const uploadError = () => {
    setHasFileError(true);
  };

  const typesSentence = typesToSentence(acceptedTypes);

  const helpTexts = {
    normal: (
      <Text textStyle='sm-normal' mt='4' color='gray.500'>
        Upload a {typesSentence} image smaller than 10 MB
      </Text>
    ),
    error: (
      <Text textStyle='sm-normal' mt='4' color='danger.500'>
        Upload error. Select a {typesSentence} image smaller than 10MB and try
        again.
      </Text>
    ),
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        size='lg'
        title='Upload file'
        subtitle={`Add a ${itemName}`}
      >
        <Modal.Body>
          <FileDropArea
            acceptedTypes={acceptedTypes}
            sizeLimitBytes={sizeLimitBytes}
            handleSuccess={uploadSuccess}
            handleError={uploadError}
          />

          {hasFileError ? helpTexts['error'] : helpTexts['normal']}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='ghost' onClick={closeModal} mt='8'>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
