import React from 'react';
import { Button, Code, Text } from '@chakra-ui/react';
import { Instance } from '@types';
import { titleize } from '@utils/string';
import { Modal } from '@components/common';

interface TemplateCopyConfirmationProps {
  instance: Instance;
  slug: string;
  isOpen: boolean;
  onClose: () => void;
  onCopyConfirm: () => void;
}

export const TemplateCopyConfirmationModal = ({
  instance,
  slug,
  isOpen,
  onClose,
  onCopyConfirm,
}: TemplateCopyConfirmationProps): JSX.Element => {
  const entTypeTitle = titleize(instance?.environment_type);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title='Copy template'
      autoFocus={false}
    >
      <Modal.Body>
        <Text>
          Are you sure you want to copy this template to {entTypeTitle}?
        </Text>
        <br />
        <Text>
          This action will override any current <Code>{slug}</Code> template on{' '}
          {entTypeTitle}.
        </Text>
      </Modal.Body>

      <Modal.Footer flexDir='row-reverse'>
        <Button onClick={onCopyConfirm}>Copy template</Button>

        <Button onClick={onClose} variant='ghost'>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
