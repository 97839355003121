import React from 'react';

import { Box, Square, useRadio } from '@chakra-ui/react';

export function RadioTile(props) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as='label'>
      <input {...input} />
      <Square
        size='120px'
        {...checkbox}
        cursor='pointer'
        borderRadius='md'
        border='1px solid transparent'
        _hover={{
          borderColor: 'gray.50',
          bg: 'gray.50',
          boxShadow: 'md',
        }}
        _checked={{
          borderColor: 'gray.50',
          bg: 'gray.50',
          boxShadow: 'md',
        }}
      >
        {props.children}
      </Square>
    </Box>
  );
}
