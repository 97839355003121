import React from 'react';
import { titleize } from '@utils';
import { Badge, BadgeProps } from '@chakra-ui/react';
import { VerificationStatus } from '@clerk/types';

type VerificationStatusBadgeProps = {
  status: VerificationStatus;
} & BadgeProps;

export function VerificationStatusBadge({
  status,
  ...rest
}: VerificationStatusBadgeProps): JSX.Element | null {
  if (status === 'transferable') {
    return null;
  }

  return (
    <Badge
      size='sm'
      colorScheme={status === 'verified' ? 'success' : 'danger'}
      {...rest}
    >
      {titleize(status)}
    </Badge>
  );
}
