import React from 'react';
import { useDashboardSWR } from '@hooks/useDashboardAPI';
import { Flex, Icon, VStack } from '@chakra-ui/react';
import { CheckIcon, XIcon } from '@heroicons/react/solid';
import { titleize } from '@utils';
import type { Settings } from '@types';

import styles from './OauthCredentials.module.scss';
import { useInstanceId } from '@hooks';

export function OauthCredentials(): JSX.Element {
  const { instanceId } = useInstanceId();
  const settings: Settings = useDashboardSWR(
    `/v1/instances/${instanceId}/user_settings`,
  ).data;

  if (!settings) {
    return <>Set custom OAuth2 credentials for enabled service providers</>;
  }

  const enabledOauthSettings = Object.values(settings.social || {}).filter(
    ({ enabled }) => enabled,
  );

  if (!enabledOauthSettings.length) {
    return null;
  }

  return (
    <>
      <span className={styles.activation}>
        <VStack align='flex-start'>
          {enabledOauthSettings.map(
            ({ provider, client_id, client_secret }, idx, arr) => (
              <Flex key={provider} align='center'>
                <StatusIcon completed={!!(client_id && client_secret)} />
                <span>Set custom {titleize(provider)} OAuth2 credentials</span>
                {idx !== arr.length - 1 && <br />}
              </Flex>
            ),
          )}
        </VStack>
      </span>
      Set custom OAuth2 credentials for enabled service providers
    </>
  );
}

type StatusIconProps = {
  completed: boolean;
};

function StatusIcon({ completed }: StatusIconProps): JSX.Element {
  return (
    <Icon
      as={completed ? CheckIcon : XIcon}
      color={completed ? 'success.500' : 'danger.500'}
      boxSize={5}
    />
  );
}
