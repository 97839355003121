import { AvatarConfig } from '@hooks';

const IMG_SERVICE_URL = process.env.NEXT_PUBLIC_IMG_SERVICE_URL;

type PreviewAvatarURLParams = AvatarConfig & {
  size: number;
  seed: string;
  initials: string;
  isSquare: boolean;
  type: string;
};

export const buildPreviewAvatarURL = (params: PreviewAvatarURLParams): URL => {
  const searchParams = new URLSearchParams({
    ...params,
    bgColor: params.bgColor.filter(Boolean).join(','),
  } as any);
  const url = `${IMG_SERVICE_URL}/preview.png?${searchParams.toString()}`;
  return new URL(url);
};
