import React from 'react';
import {
  Box,
  BoxProps,
  HStack,
  Icon as IconComponent,
  Text,
} from '@chakra-ui/react';
import { noop } from '@utils';

type ItemProps = {
  Icon?: React.ReactNode | React.FC<React.SVGProps<SVGSVGElement>>;
  isActive?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
} & BoxProps;

export function Item({
  Icon,
  onClick = noop,
  children,
  ...rest
}: ItemProps): JSX.Element {
  return (
    <Box
      onClick={onClick}
      cursor='pointer'
      {...rest}
      sx={{
        '&:hover svg': {
          fill: 'primary.500',
        },
      }}
    >
      <HStack>
        {typeof children === 'string' ? (
          <Text textStyle='md-medium'>{children}</Text>
        ) : (
          children
        )}

        {Icon ? (
          <IconComponent as={Icon as any} ml={8} color='blackAlpha.400' />
        ) : null}
      </HStack>
    </Box>
  );
}
