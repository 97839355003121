import React from 'react';
import { Code, Stack, Text, Wrap, WrapItem } from '@chakra-ui/react';

type VariableTagProps = {
  label: string;
  onClick: () => void;
  disabled: boolean;
};

const VariableTag = ({ label, onClick, disabled }: VariableTagProps) => (
  <WrapItem mb='0px !important'>
    <Code
      onClick={onClick}
      colorScheme='gray'
      cursor={disabled ? 'not-allowed' : 'pointer'}
      px='6px !important'
    >
      {label}
    </Code>
  </WrapItem>
);

type VariableWidgetProps = {
  variableName?: string;
  variables: string[];
  delimiters: string[];
  handleVariableClick: (text: string) => void;
  disabled?: boolean;
  helpSection?: React.ReactNode;
};

const formatVariable = (text: string, delimiters: string[]) =>
  `${delimiters[0]}${text}${delimiters[1]}`;

export function VariableWidget({
  variableName = 'variable',
  variables,
  delimiters,
  handleVariableClick,
  disabled = false,
  helpSection,
  ...rest
}: VariableWidgetProps): JSX.Element {
  return (
    <Stack pt='5' spacing='3' {...rest}>
      <Text textStyle='sm-medium' color='gray.500'>
        Insert {`${variableName}s`}
      </Text>

      <Wrap pb='8' spacing='4'>
        {variables.map((label, index) => (
          <VariableTag
            key={index}
            label={label}
            onClick={() =>
              !disabled &&
              handleVariableClick(formatVariable(label, delimiters))
            }
            disabled={disabled}
          />
        ))}
      </Wrap>

      {helpSection}
    </Stack>
  );
}
