import { useUser } from '@clerk/nextjs';
import { useDashboardCRUD } from '@hooks/useDashboardAPI';
import _merge from 'lodash/merge';
import { Todo } from './types';
import { compressOnboardingMetadata } from './bitUtils';

export const COMPLETE_STATUS = 1;

type UseUpdateOnboardingMetadata = {
  /** @modifies Clerk.user */
  update(
    instanceId: string,
    todoState: Todo[] | typeof COMPLETE_STATUS,
  ): Promise<unknown>;
};

/**
 * Update the onboarding part of the metadata stored for the current user.
 * New onboarding metadata fields are 'compressed' transforming their attributes to bitwise values.
 *
 * In the case every todo is completed for an instance, the caller passes the COMPLETE_STATUS, so the metadata
 * we have to store for completed onboarding are minimized.
 *
 * For now we do not handle unsuccessful changes or change things based on the progress of the update request.
 */
export function useUpdateOnboardingMetadata(): UseUpdateOnboardingMetadata {
  const { user } = useUser();
  const { put } = useDashboardCRUD();

  return {
    update(instanceId, todoState) {
      const updatedMetadata =
        todoState === COMPLETE_STATUS
          ? { [instanceId]: COMPLETE_STATUS }
          : compressOnboardingMetadata({ [instanceId]: todoState });

      const updatedOnboardingMetadata = _merge(
        user.publicMetadata.onboarding,
        updatedMetadata,
      );

      return put('/v1/preferences', {
        preferences: JSON.stringify({
          ...user.publicMetadata,
          onboarding: updatedOnboardingMetadata,
        }),
      })
        .then(() => user.update({}))
        .catch(err => {
          console.error(err);
          throw err;
        });
    },
  };
}
