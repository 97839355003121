import React from 'react';
import { VStack } from '@chakra-ui/react';
import { CardDualPanel } from '@components/common';
import { AUTHENTICATION_FACTORS_ATTRIBUTES_INFO } from './constants';
import {
  SwitchInfoField,
  SwitchInfoFieldWithBillingModal,
} from '@components/common/Switch';
import { GenericBadge as Badge } from '@components/common/Badges';
import { useFeatureFlags, useSupportedFeature } from '@hooks';
import { Controller, useFormContext } from 'react-hook-form';
import { usePaymentRequired } from '@context/PaymentRequiredContext';
import { SUPPORTED_FEATURES } from '@constants';
import { PASSWORD_SETTINGS_BADGE_LABELS } from './modals/PasswordConfigModal/constants';
import {
  PASSWORD_METER_OPTIONS,
  passwordStrengthOptions,
} from './modals/PasswordConfigModal/constants';

const handleCheckboxChange = (isChecked, onChange, value = [], key) =>
  onChange(isChecked ? [...value, key] : value.filter(v => v !== key));

const getFactorInfo = (field, isAttributeOff) => {
  if (isAttributeOff) {
    return AUTHENTICATION_FACTORS_ATTRIBUTES_INFO[field];
  }
  return null;
};

export function AuthenticationFactorsForm({
  openModal,
}: {
  openModal: (string: string) => void;
}): JSX.Element {
  const { getValues, control } = useFormContext();
  const { showModal } = usePaymentRequired();
  const { password_overhaul } = useFeatureFlags();

  const {
    isSupported,
    isPremium,
    hasNotSeenInfoBillingModal,
    isUnsupportedAndEnabled,
  } = useSupportedFeature();

  const isEmailAddressOff = !getValues(
    'attributes.email_address.used_for_first_factor',
  );
  const isPhoneNumberOff = !getValues(
    'attributes.phone_number.used_for_first_factor',
  );

  const passwordEnabled = getValues('attributes.password.required');
  const isUsernameOff = !getValues('attributes.username.used_for_first_factor');

  const shouldDisablePassword =
    isEmailAddressOff && isPhoneNumberOff && isUsernameOff;

  const handleEmailCode = (onChange, value, isChecked) => {
    const feature = SUPPORTED_FEATURES.email_code;

    if (
      !isSupported(feature) &&
      !isUnsupportedAndEnabled({
        feature,
        currentValue: value?.includes('email_code'),
      })
    ) {
      return showModal({ features: [feature] });
    }

    if (hasNotSeenInfoBillingModal(feature) && !value?.includes('email_code')) {
      showModal({
        callbackAfterClose: () =>
          handleCheckboxChange(isChecked, onChange, value, 'email_code'),
      });
    } else {
      return handleCheckboxChange(isChecked, onChange, value, 'email_code');
    }
  };

  const isPassportSupported =
    isSupported(SUPPORTED_FEATURES.password) ||
    isUnsupportedAndEnabled({
      feature: SUPPORTED_FEATURES.password,
      currentValue: passwordEnabled,
    });

  const findPasswordMeterKeyByScore = (val: number): string => {
    const strength = passwordStrengthOptions.filter(
      f => PASSWORD_METER_OPTIONS[f].score === val,
    )?.[0];

    return strength === 'strong' ? strength : `${strength}+`;
  };

  const passwordSettingsBadges = () => {
    const {
      disable_hibp: hibp,
      min_length,
      min_zxcvbn_strength,
      require_lowercase,
      require_numbers,
      require_special_char,
      require_uppercase,
      show_zxcvbn,
    } = getValues('password_settings');

    if (!passwordEnabled) {
      return;
    }

    const {
      HIBP,
      MIN_LENGTH,
      MIN_ZXCVBN_STRENGTH,
      LOWERCASE,
      UPPERCASE,
      NUMBERS,
      SPECIAL_CHAR,
    } = PASSWORD_SETTINGS_BADGE_LABELS;
    const badges = [];

    badges.push(<Badge label={`${min_length}${MIN_LENGTH}`} />);
    hibp && badges.push(<Badge label={HIBP} />);
    show_zxcvbn &&
      badges.push(
        <Badge
          label={`${MIN_ZXCVBN_STRENGTH} ${findPasswordMeterKeyByScore(
            min_zxcvbn_strength,
          )}`}
        />,
      );
    require_lowercase && badges.push(<Badge label={LOWERCASE} />);
    require_uppercase && badges.push(<Badge label={UPPERCASE} />);
    require_numbers && badges.push(<Badge label={NUMBERS} />);
    require_special_char && badges.push(<Badge label={SPECIAL_CHAR} />);

    return badges;
  };

  return (
    <CardDualPanel
      title='Authentication factors'
      subtitle='Select the authentication methods to present when a user signs in'
      docLink={{
        subject: 'authentication factors',
        url: 'https://clerk.com/docs/authentication/set-up-your-application',
      }}
    >
      <VStack align='stretch'>
        <SwitchInfoFieldWithBillingModal
          name='attributes.password.required'
          title='Password'
          description='Users can sign in with a password. Passwords are required during sign up unless the user signs up with a Social Connection or a Web3 wallet.'
          info={getFactorInfo('password', shouldDisablePassword)}
          isDisabled={shouldDisablePassword}
          isFeatureSupported={isPassportSupported}
          featureName='password'
          badges={password_overhaul ? passwordSettingsBadges() : null}
          onCogClick={password_overhaul ? () => openModal('password') : null}
        />

        <Controller
          control={control}
          name='attributes.email_address.first_factors'
          render={({ field: { onChange, value } }) => (
            <>
              <SwitchInfoField
                name='email_link'
                title='Email verification link'
                description='Users can sign in with an email verification link'
                info={getFactorInfo('email_link', isEmailAddressOff)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleCheckboxChange(
                    e.target.checked,
                    onChange,
                    value,
                    'email_link',
                  )
                }
                isChecked={value?.includes('email_link')}
                isDisabled={isEmailAddressOff}
              />
              <SwitchInfoField
                name='email_code'
                title='Email verification code'
                description='Users can sign in with an email verification code'
                info={getFactorInfo('email_code', isEmailAddressOff)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleEmailCode(onChange, value, e.target.checked)
                }
                isPremiumFeature={isPremium(SUPPORTED_FEATURES.email_code)}
                isChecked={value?.includes('email_code')}
                isDisabled={isEmailAddressOff}
              />
            </>
          )}
        />

        <Controller
          control={control}
          name='attributes.phone_number.first_factors'
          render={({ field: { onChange, value } }) => (
            <SwitchInfoField
              name='phone_code'
              title='SMS verification code'
              description='Users can sign in with an SMS verification code'
              info={getFactorInfo('phone_code', isPhoneNumberOff)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleCheckboxChange(
                  e.target.checked,
                  onChange,
                  value,
                  'phone_code',
                )
              }
              isChecked={value?.includes('phone_code')}
              isDisabled={isPhoneNumberOff}
            />
          )}
        />
      </VStack>
    </CardDualPanel>
  );
}
