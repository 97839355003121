import React from 'react';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Link as ChakraLink,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useSupportedFeature } from '@hooks';
import { Modal } from '@components/common';
import { ExclamationIcon, InformationCircleIcon } from '@heroicons/react/solid';
import { Controller, useFormContext } from 'react-hook-form';
import { InformationBox } from '../../../common';
import { Checkbox } from '@components/common/Checkbox';
import { noop } from '@utils';
import { SwitchField } from './SwitchField';
import Link from 'next/link';
import { SUPPORTED_FEATURES } from '@constants';

type EmailAddressConfigModalProps = {
  isOpen: boolean;
  onClose: () => void;
  isProgressiveSignUp: boolean;
};

const handleCheckboxChange = (isChecked, onChange, value = [], key) =>
  onChange(isChecked ? [...value, key] : value.filter(v => v !== key));

export function EmailAddressConfigModal({
  isOpen,
  onClose,
  isProgressiveSignUp,
}: EmailAddressConfigModalProps): JSX.Element {
  const { isSupported, isPremium } = useSupportedFeature();

  const {
    control,
    formState: { errors, isValid },
  } = useFormContext();

  return (
    <Modal
      isOpen={isOpen}
      onClose={isValid ? onClose : noop}
      autoFocus={false}
      size='xl'
      title='Email address'
      subtitle='Configure email address attribute'
    >
      <Modal.Body>
        <VStack spacing='6'>
          {isProgressiveSignUp && (
            <SwitchField
              name='attributes.email_address.required'
              label='Require'
              description='Users must provide an email address to sign up, and must maintain one on their account at all times'
              info={[
                {
                  Icon: InformationCircleIcon,
                  text: 'Enable this setting if you need to contact users by email (e.g. for notifications, updates, or correspondence).',
                },
                {
                  Icon: InformationCircleIcon,
                  text: (
                    <>
                      When a user signs up through{' '}
                      <Link
                        href={'../user-authentication/social-connections'}
                        passHref
                      >
                        <ChakraLink
                          textTransform='unset'
                          color='primary.500'
                          fontWeight='medium'
                        >
                          Social Connections
                        </ChakraLink>
                      </Link>
                      , the email address may be provided by the third-party.
                    </>
                  ),
                },
              ]}
            />
          )}
          <SwitchField
            name='attributes.email_address.used_for_first_factor'
            label='Sign-in'
            description='Allow users to sign in with their email address'
          />
          {isProgressiveSignUp && (
            <SwitchField
              name='attributes.email_address.verify_at_sign_up'
              label='Verify at sign-up'
              description='Require users to verify their email addresses before they can sign up'
              info={[
                {
                  Icon: ExclamationIcon,
                  text: 'Not verifying email addresses may result in low-quality email addresses in your user base',
                },
              ]}
            />
          )}
          <VStack spacing='1'>
            <Text textStyle='lg-medium' w='full'>
              Verification methods
            </Text>
            <Text color='gray.500' textStyle='md-normal' w='full' pb='2'>
              Select how users can verify an email address
            </Text>
            <InformationBox icon={InformationCircleIcon}>
              <Text
                textStyle='sm-normal'
                display='inline-block'
                color='gray.500'
              >
                We recommend keeping both verification methods enabled and
                choosing a preferred method on a case-by-case basis.
              </Text>
            </InformationBox>
            <Controller
              control={control}
              name='attributes.email_address.verifications'
              rules={{
                validate: v =>
                  v?.length > 0 || 'Select at least one verification method',
              }}
              render={({ field: { onChange, value, name } }) => (
                <FormControl isInvalid={!isValid}>
                  <VStack align='flex-start' w='full' p='4' spacing='4'>
                    <Checkbox
                      isChecked={value?.includes('email_link')}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleCheckboxChange(
                          e.target.checked,
                          onChange,
                          value,
                          'email_link',
                        )
                      }
                      name={name}
                      label='Email verification link'
                      description='Verify by clicking a link sent to the email address'
                    />

                    <Checkbox
                      isChecked={value?.includes('email_code')}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleCheckboxChange(
                          e.target.checked,
                          onChange,
                          value,
                          'email_code',
                        )
                      }
                      name={name}
                      label='Email verification code'
                      description='Verify by entering an one-time passcode sent to the email address'
                      isDisabled={!isSupported(SUPPORTED_FEATURES.email_code)}
                      showTag={isPremium(SUPPORTED_FEATURES.email_code)}
                    />
                    <FormErrorMessage>
                      {
                        errors?.attributes?.email_address?.verifications
                          ?.message
                      }
                    </FormErrorMessage>
                  </VStack>
                </FormControl>
              )}
            />
          </VStack>
        </VStack>
      </Modal.Body>

      <Modal.Footer mt='6'>
        <Flex direction='row' w='full'>
          <Flex justify='space-between' w='full' flexDir='row-reverse'>
            <Button onClick={onClose} isDisabled={!isValid}>
              Continue
            </Button>
          </Flex>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
}
