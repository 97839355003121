import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  CardDualPanel,
  ColorInput,
  VerticalInputBox,
} from '@components/common';
import { FontPicker } from './FontPicker';
import { FontWeightPicker } from './FontWeightPicker';
import { FormControl, FormErrorMessage, Stack } from '@chakra-ui/react';

export function Buttons(): JSX.Element {
  const {
    formState: { isSubmitting, errors },
    control,
  } = useFormContext();

  return (
    <CardDualPanel
      title='Buttons'
      subtitle='Change buttons appearance for your Clerk Hosted Components'
      disabled={isSubmitting}
    >
      <Stack spacing='6'>
        <VerticalInputBox label='Font' subLabel='Set the button font'>
          <FormControl isInvalid={!!errors?.font_family}>
            <Controller
              control={control}
              name='buttons.font_family'
              render={({ field: { onChange, value, name } }) =>
                value ? (
                  <FontPicker name={name} value={value} onChange={onChange} />
                ) : null
              }
            />
            <FormErrorMessage>{errors?.font_family?.message}</FormErrorMessage>
          </FormControl>
        </VerticalInputBox>

        <VerticalInputBox
          label='Font weight'
          subLabel='Set the button font weight'
        >
          <FormControl isInvalid={!!errors?.font_weight}>
            <Controller
              control={control}
              name='buttons.font_weight'
              render={({ field: { onChange, value, name } }) =>
                value ? (
                  <FontWeightPicker
                    name={name}
                    value={value}
                    onChange={onChange}
                  />
                ) : null
              }
            />
            <FormErrorMessage>{errors?.font_weight?.message}</FormErrorMessage>
          </FormControl>
        </VerticalInputBox>

        <VerticalInputBox
          label='Font color'
          subLabel='Set the button font color'
        >
          <FormControl isInvalid={!!errors?.font_color}>
            <Controller
              control={control}
              name='buttons.font_color'
              defaultValue='#000000'
              render={({ field: { onChange, value, name } }) => (
                <ColorInput name={name} value={value} onChange={onChange} />
              )}
            />
            <FormErrorMessage>{errors?.font_color?.message}</FormErrorMessage>
          </FormControl>
        </VerticalInputBox>
      </Stack>
    </CardDualPanel>
  );
}
