import React from 'react';
import cn from 'classnames';
import { Center } from '@components/layouts';
import { Heading } from '@clerk-ui/components';

import styles from './VercelScreen.module.scss';

export type VercelScreenProps = {
  children: React.ReactNode;
  footer: React.ReactNode;
  title: string;
  subtitle: string;
  disabled: boolean;
};

export function VercelScreen({
  children,
  footer,
  title,
  subtitle,
  disabled = false,
}: VercelScreenProps): JSX.Element {
  return (
    <Center contentClassname={styles.container}>
      <Heading as='h4'>{title}</Heading>
      <div className={styles.subtitle}>{subtitle}</div>
      <div className={cn({ [styles.disabled]: disabled })}>{children}</div>
      <div className={styles.footer}>{footer}</div>
    </Center>
  );
}
