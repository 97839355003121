import { useInstance, useApplication } from '@hooks';

export function useInstanceAndApplicationIsValid() {
  const { error: applicationError } = useApplication();
  const { error: instanceError } = useInstance();

  const hasError = !!instanceError || !!applicationError;

  return { hasError };
}
