import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';
import { useClipboard, UseClipboardOptions } from '@chakra-ui/hooks';

type CopyTextButtonProps = {
  valueToCopy: string;
  optionsOrTimeout?: UseClipboardOptions | number;
} & ButtonProps;

export function CopyTextButton({
  valueToCopy,
  optionsOrTimeout = 2000,
  ...rest
}: CopyTextButtonProps): JSX.Element {
  const { hasCopied, onCopy } = useClipboard(valueToCopy, optionsOrTimeout);

  // const {
  //   hasCopied,
  //   setValue: setValueToCopy,
  //   onCopy,
  // } = useClipboard('', optionsOrTimeout);

  // useEffect(() => {
  //   setValueToCopy(valueToCopy);
  // }, [valueToCopy]);

  return (
    <Button
      size='sm'
      variant='link'
      onClick={onCopy}
      textTransform='uppercase'
      {...rest}
    >
      {hasCopied ? 'Copied' : 'Copy'}
    </Button>
  );
}
