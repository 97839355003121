import { CardDualPanel } from '@components/common';
import { AvatarPreview } from '@components/customization/avatars/AvatarPreview';
import { OrganizationsDisabledInformationBox } from '@components/customization/avatars/OrganizationsDisabledInformationBox';
import React from 'react';
import { Stack, Text } from '@chakra-ui/react';
import { AvatarBackgroundRadioGroup } from '@components/customization/avatars/AvatarBackgroundRadioGroup';
import { AvatarForegroundRadioGroup } from '@components/customization/avatars/AvatarForegroundRadioGroup';

type OrganizationAvatarPanelProps = {
  orgEnabled: boolean;
  orgSettingsUrl: string;
};
export const OrganizationAvatarPanel = ({
  orgEnabled,
  orgSettingsUrl,
}: OrganizationAvatarPanelProps): JSX.Element => {
  return (
    <CardDualPanel
      title='Default organization logo'
      subtitle='Design the default organization logo.'
      leftPanelChildren={
        orgEnabled ? <AvatarPreview type='organization' /> : null
      }
    >
      {orgEnabled ? (
        <Stack spacing={4}>
          <Text fontWeight={500}>Background</Text>
          <AvatarBackgroundRadioGroup type='organization' />
          <Text fontWeight={500}>Foreground</Text>
          <AvatarForegroundRadioGroup type='organization' />
        </Stack>
      ) : (
        <OrganizationsDisabledInformationBox orgSettingsUrl={orgSettingsUrl} />
      )}
    </CardDualPanel>
  );
};
