import React from 'react';
import Link from 'next/link';
import { useDashboardCRUD, useDashboardSWR } from '@hooks/useDashboardAPI';
import { VercelScreen } from './VercelScreen';
import {
  Control,
  Dropdown,
  DropdownOption,
  DropdownSelection,
  Form,
} from '@clerk-ui/components';

import type { Application, Integration } from '@types';

import { buildQueryString, getNextUrl } from './utils';

export type VercelConnectApplicationProps = {
  integration: Integration;
  projectId: string;
  next: string;
};

export function VercelConnectApplication({
  integration,
  projectId,
  next,
}: VercelConnectApplicationProps): JSX.Element {
  const { create } = useDashboardCRUD();
  const [isLoading, setIsLoading] = React.useState(false);
  const { data } = useDashboardSWR('/v1/applications');
  const applications = data as Application[];
  const [selectedApplicationId, setSelectedApplicationId] =
    React.useState<string>();

  const handleChange = (el: DropdownSelection) =>
    setSelectedApplicationId(el.value);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      // Bind current application to Vercel
      await create(`/v1/integrations/${integration.id}/link`, {
        application_id: selectedApplicationId,
        project_id: projectId,
      });
    } catch (_) {
      setIsLoading(false);
    }

    // Redirect to Vercel to complete the integration
    window.location.assign(getNextUrl(next, integration));
  };

  const applicationOptions: DropdownOption[] = React.useMemo(() => {
    return applications?.reduce((memo, app) => {
      if (!app.integration_types.includes('vercel')) {
        memo.push({
          label: app.name,
          value: app.id,
        });
      }

      return memo;
    }, []);
  }, [applications]);

  const hasAnyApplications = applicationOptions?.length > 0;

  return (
    <VercelScreen
      disabled={isLoading}
      title='Integrate existing application'
      subtitle='Select application to integrate into this Vercel project'
      footer={
        <>
          {hasAnyApplications
            ? 'Integrating a new application? '
            : 'No Clerk applications were found. '}
          <Link
            href={`/vercel/${integration?.id}/new-app${buildQueryString({
              project_id: projectId,
              next,
            })}`}
          >
            Create application
          </Link>
        </>
      }
    >
      {hasAnyApplications && (
        <Form
          handleSubmit={handleSubmit}
          buttonGroupToTheRight
          submitButtonLabel='Integrate application'
        >
          <Control label='Clerk application'>
            <Dropdown
              name='application_id'
              options={applicationOptions}
              placeholder='Select application'
              handleChange={handleChange}
            />
          </Control>
        </Form>
      )}
    </VercelScreen>
  );
}
