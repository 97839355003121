import React from 'react';
import {
  Box,
  Flex,
  HStack,
  Text,
  BoxProps,
  FlexProps,
  TextProps,
} from '@chakra-ui/react';
import { CardContainer, Hint, Subtitle } from './CardElements';
import { CardSinglePanelError } from './CardErrorState';

type CardSinglePanelProps = {
  title: string | JSX.Element;
  titleContainerStyles?: FlexProps;
  subtitleStyles?: TextProps;
  subtitle?: string | JSX.Element;
  hint?: string;
  isHintFaded?: boolean;
  disabled?: boolean;
  nonClickable?: boolean;
  children: React.ReactNode;
  hasError?: boolean;
  mutate?: () => void;
} & Omit<BoxProps, 'title'>;

export function CardSinglePanel({
  title,
  titleContainerStyles,
  subtitleStyles,
  subtitle,
  hint,
  isHintFaded = true,
  children,
  hasError = false,
  mutate,
  ...rest
}: CardSinglePanelProps): JSX.Element {
  if (hasError) {
    return (
      <CardSinglePanelError
        title={title}
        subtitle={subtitle}
        mutate={mutate}
        titleContainerStyles={titleContainerStyles}
      />
    );
  }

  return (
    <CardContainer {...rest} pos='relative'>
      <HStack spacing={4} alignItems='flex-start'>
        <Box flex='1'>
          <Flex
            align='center'
            justify='space-between'
            mb={subtitle ? 1 : 6}
            {...titleContainerStyles}
          >
            {typeof title === 'string' ? (
              <Text textStyle='lg-medium'>{title}</Text>
            ) : (
              title
            )}
          </Flex>

          {subtitle && <Subtitle {...subtitleStyles}>{subtitle}</Subtitle>}

          {hint && <Hint text={hint} isFaded={isHintFaded} />}

          {children}
        </Box>
      </HStack>
    </CardContainer>
  );
}
