import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { HelperLink, InformationBox } from '@components/common';
import { BookOpenIcon } from '@heroicons/react/solid';

export function TemplateDocs(): JSX.Element {
  return (
    <>
      <Text textStyle='sm-medium' color='gray.500'>
        Need help?
      </Text>

      <InformationBox
        icon={BookOpenIcon}
        textStyle='sm-normal'
        color='gray.500'
      >
        <Box display='inline-block'>
          Learn how to use the{' '}
          <HelperLink
            href='https://clerk.com/docs/authentication/email-sms-templates'
            display='inline-block'
          >
            email & SMS templating feature
          </HelperLink>
          .
        </Box>
      </InformationBox>
    </>
  );
}
