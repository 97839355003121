import { AsyncDropdown } from '@clerk-ui/components';
import { useUsersInfinite } from './api';
import React from 'react';

import UserPublicInfo from './Table/UserPublicInfo';

type SelectUserDropdownProps = {
  name?: string;
  placeholder?: string;
  onSearchValueChange?: (value: string) => void;
  onUserSelected: (id: string) => void;
};

const SelectUserDropdown = ({
  placeholder,
  name,
  onSearchValueChange,
  onUserSelected,
}: SelectUserDropdownProps): JSX.Element => {
  const [search, setSearch] = React.useState('');
  const { users, isLoading, loadMore, totalCount, isLoadingMore } =
    useUsersInfinite({
      search,
    });

  const onSearchChange = (value: string) => {
    setSearch(value);
    onSearchValueChange?.(value);
  };

  const options = React.useMemo(() => {
    return users?.map(user => ({
      value: user.id,
      label: <UserPublicInfo user={user} />,
    }));
  }, [users]);

  return (
    <AsyncDropdown
      inputPlaceholder={placeholder}
      searchValue={search}
      onInputChange={onSearchChange}
      name={name}
      handleChange={data => onUserSelected(data.value)}
      options={options}
      isLoading={isLoading}
      isLoadingMore={isLoadingMore}
      infiniteScrollOptions={{
        hasDataEnd: users.length === totalCount,
        onLoadMore: loadMore,
      }}
    />
  );
};

export default SelectUserDropdown;
