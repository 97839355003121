import { useApplication, useCheckIsProdInstance, useLocation } from '@hooks';
import { useGetMemberships } from '@components/organizations/memberships';

type UseAddMemberManagementReturn = {
  changePlanMessage: string;
  isAddMemberDisabled: boolean;
  changePlanLink: string;
};
const useAddMemberPlan = ({
  organizationID,
}: {
  organizationID?: string;
} = {}): UseAddMemberManagementReturn => {
  const isRunningOnProduction = useCheckIsProdInstance();
  const { application } = useApplication();
  const { instanceId } = useLocation();

  const changePlanLink = `/apps/${application?.id}/instances/${instanceId}/plan-billing`;
  const { totalCount } = useGetMemberships({ organizationID });

  const limit = application?.subscription?.organization_memberships_limit;

  if (limit === 0 || limit > totalCount || !isRunningOnProduction) {
    return {
      changePlanMessage: '',
      isAddMemberDisabled: false,
      changePlanLink,
    };
  }

  const changePlanMessage = `Your plan limits organizations to ${limit} members, including outstanding invitations.`;

  return {
    changePlanMessage,
    isAddMemberDisabled: !!changePlanMessage,
    changePlanLink,
  };
};

export default useAddMemberPlan;
