import React from 'react';
import {
  DropdownV2 as Dropdown,
  DropdownSelection,
} from '@clerk-ui/components/dropdown/DropdownV2';

export const FONTS = Object.freeze([
  '"Alegreya", serif',
  '"Arial", sans-serif',
  '"BioRhyme", serif',
  '"Brush Script MT", cursive',
  '"Cairo", sans-serif',
  '"Cormorant", serif',
  '"Courier New", monospace',
  '"Crimson Text", serif',
  '"Garamond", serif',
  '"Georgia", serif',
  '"Helvetica", sans-serif',
  '"IBM Plex Sans", sans-serif',
  '"Inter", sans-serif',
  '"Josefin Sans", sans-serif',
  '"Karla", sans-serif',
  '"Lato", sans-serif',
  '"Lora", serif',
  '"Montserrat", sans-serif',
  '"Mulish", sans-serif',
  '"Open Sans", sans-serif',
  '"Oswald", sans-serif',
  '"Playfair Display", serif',
  '"Poppins", sans-serif',
  '"PT Sans", sans-serif',
  '"Raleway", sans-serif',
  '"Roboto", sans-serif',
  '"Rubik", sans-serif',
  '"Slabo 27px, serif',
  '"Source Code Pro", monospace',
  '"Source Sans Pro", sans-serif',
  '"Spectral", serif',
  '"Tahoma", sans-serif',
  '"Times New Roman", serif',
  '"Titillium Web", sans-serif',
  '"Trebuchet MS", sans-serif',
  '"Verdana", sans-serif',
  '"Vollkorn", serif',
  '"Work Sans", sans-serif',
]);

type FontPickerProps = {
  name: string;
  value: string;
  onChange: (el: DropdownSelection) => void;
};

export function FontPicker({ name, value, onChange }: FontPickerProps) {
  const fontOptions = React.useMemo(() => {
    return FONTS.map(font => {
      const fontName = font.split(',')[0]?.replace(/['"]+/g, '') || '';
      return {
        value: font,
        label: (
          <span style={{ fontFamily: font, fontWeight: 400 }}>{fontName}</span>
        ),
      };
    });
  }, []);

  const handleChange = selected => {
    onChange(selected.value);
  };

  return (
    <Dropdown
      name={name}
      options={fontOptions}
      selectedOption={value}
      handleChange={handleChange}
    />
  );
}
