/**
 * Compression utilities
 * To save some space on the onboarding publicMetadata storage, we decided to go with
 * bitmasking the completion and dismissal state.
 */

import { StoredTodos, Todo } from './types';

const COMPLETION = 1;
const DISMISSAL = 1 << 1;

export function isComplete(state): boolean {
  return Boolean(state & COMPLETION);
}

function setComplete(state): number {
  return state | COMPLETION;
}

export function isDismissed(state): boolean {
  return Boolean(state & DISMISSAL);
}

function setDismissed(state): number {
  return state | DISMISSAL;
}

type OnboardingState = {
  [instanceId: string]: Todo[];
};

type CompressedOnboardingState = {
  [instanceId: string]: StoredTodos;
};

export function compressOnboardingMetadata(
  onboardingState: OnboardingState,
): CompressedOnboardingState {
  const compressedOnboardingState = {};

  for (const instanceId in onboardingState) {
    const todoState = onboardingState[instanceId];
    compressedOnboardingState[instanceId] = {};

    todoState.forEach(todo => {
      const { completed, dismissed, id } = todo;
      if (completed) {
        compressedOnboardingState[instanceId][id] = setComplete(completed);
      }
      if (dismissed) {
        compressedOnboardingState[instanceId][id] = setDismissed(dismissed);
      }
    });
  }
  return compressedOnboardingState;
}
