import React from 'react';
import { Center, Spinner, VStack, Img, Text, HStack } from '@chakra-ui/react';
import { CardDualPanel, PageLoader } from '@components/common';
import {
  useDashboardCRUD,
  useDashboardSWR,
  useLocation,
  useToast,
  useInstance,
  useSupportedFeature,
} from '@hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { SwitchInfoField } from '@components/common/Switch';
import type { Settings, AttributeData } from '@types';
import {
  WEB3_PROVIDERS,
  Web3Strategy,
  getWeb3ProviderData,
} from '@clerk/types';
import {
  svgUrl,
  shouldCheckMaxSocialAndWeb3,
  hasReachedSocialAndWeb3Limit,
} from '@utils';
import { SUPPORTED_FEATURES } from '@constants';
import { usePaymentRequired } from '@context/PaymentRequiredContext';

const WEB3_FORM_ID = 'web3_form';

const ProviderName = ({ logo, name }) => (
  <HStack as='span' mb='2'>
    <Img src={logo} />
    <Text textStyle='md-medium' mb='0.5'>
      {name}
    </Text>
  </HStack>
);

export function Web3(): JSX.Element {
  const { instance } = useInstance();
  const { showSuccessToast } = useToast();
  const { isPremium } = useSupportedFeature();
  const { instanceId } = useLocation();
  const { update } = useDashboardCRUD();
  const formMethods = useForm({ mode: 'onSubmit' });
  const { data: settings, mutate } = useDashboardSWR<Settings>(
    `/v1/instances/${instanceId}/user_settings`,
  );
  const { showModal } = usePaymentRequired();

  const providers = WEB3_PROVIDERS;

  const handleSwitchChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const strategy = e.target.name as Web3Strategy;
    const provider = getWeb3ProviderData({ strategy });
    const enabled = e.target.checked;

    if (
      enabled && // this needs to have value of true to trigger the modal when we try to enable it
      shouldCheckMaxSocialAndWeb3(instance?.supported_features) &&
      hasReachedSocialAndWeb3Limit({
        social: settings.social,
        web3: settings.attributes.web3_wallet.first_factors,
      })
    ) {
      return showModal({
        features: [SUPPORTED_FEATURES.unlimited_web3],
      });
    }

    if (
      enabled &&
      hasReachedSocialAndWeb3Limit({
        social: settings.social,
        web3: settings.attributes.web3_wallet.first_factors,
      }) &&
      isPremium(SUPPORTED_FEATURES.unlimited_web3)
    ) {
      showModal();
    }

    const updatedSettings: Settings = {
      ...settings,
      attributes: {
        ...settings.attributes,
        web3_wallet: {
          ...settings.attributes.web3_wallet,
          enabled: enabled,
          required: false,
          used_for_first_factor: enabled,
          verify_at_sign_up: enabled,
          first_factors: enabled ? ['web3_metamask_signature'] : [],
          verifications: enabled ? ['web3_metamask_signature'] : [],
        } as AttributeData,
      },
    };

    try {
      await update(
        `/v1/instances/${instanceId}/user_settings`,
        updatedSettings,
      );
      await mutate(updatedSettings, false);

      const message = enabled
        ? `${provider.name} was enabled.`
        : `${provider.name} was disabled.`;
      showSuccessToast(message);
    } catch (err) {
      return;
    }
  };

  if (!settings) {
    return <PageLoader />;
  }

  return (
    <CardDualPanel
      title='Web3 providers'
      subtitle='Allow users to sign up and sign in using Web3 wallets'
    >
      <FormProvider {...formMethods}>
        <form id={WEB3_FORM_ID}>
          <VStack align='stretch'>
            {!providers ? (
              <Center h='430px' w='full'>
                <Spinner />
              </Center>
            ) : (
              providers.map(({ name, provider, strategy }) => (
                <SwitchInfoField
                  key={strategy}
                  name={strategy}
                  title={<ProviderName logo={svgUrl(provider)} name={name} />}
                  description={`Allow users to authenticate using their ${name} wallet`}
                  onChange={handleSwitchChange}
                  isChecked={settings.attributes.web3_wallet.enabled}
                  isDisabled={false}
                />
              ))
            )}
          </VStack>
        </form>
      </FormProvider>
    </CardDualPanel>
  );
}
