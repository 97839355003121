import React from 'react';
import {
  Box,
  Badge,
  Text,
  Icon,
  Stack,
  RadioGroup,
  Radio,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import { useInstance, useUnsafeMetadata, useApplication } from '@hooks';
import { useController, useFormContext } from 'react-hook-form';
import { CardDualPanel } from '@components/common';
import { usePaymentRequired } from '@context/PaymentRequiredContext';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';

const UNLIMITED_MEMBERSHIPS = 0;
export const OrganizationsMaxAllowedMembershipsForm = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle: string | JSX.Element;
}): JSX.Element => {
  const { shouldShowInfoModal } = useUnsafeMetadata();
  const { showModal } = usePaymentRequired();
  const { instance } = useInstance();
  const { application } = useApplication();

  const organizationMembershipsPerPlan =
    application?.subscription.organization_memberships_limit;

  const { control } = useFormContext<{
    max_allowed_memberships: number;
    is_unlimited_enabled: boolean;
  }>();
  const { field, fieldState: maxAllowedMembershipsState } = useController({
    control,
    name: 'max_allowed_memberships',
    rules: {
      validate: {
        maxAllowedMemberships: membershipValue => {
          if (
            organizationMembershipsPerPlan === UNLIMITED_MEMBERSHIPS ||
            membershipValue <= organizationMembershipsPerPlan ||
            !membershipValue
          ) {
            return true;
          }
          return `The maximum default membership limit for your current plan is
          ${organizationMembershipsPerPlan}, please upgrade your
          plan to increase the default membership limit.`;
        },
      },
    },
  });
  const { onChange: onChangeMembershipCount, value, ...rest } = field;

  const {
    field: { onChange: onChangeIsUnlimited, value: isUnlimitedEnabled },
  } = useController({
    control,
    name: 'is_unlimited_enabled',
  });

  const hasSeenBillingModal = !shouldShowInfoModal(instance?.application_id);
  const unlimitedMemberships = organizationMembershipsPerPlan === 0;

  const onChangeRadio = (val: string) => {
    const isUnlimited = val === 'unlimited';
    const feature = 'allowed_memberships';

    // TODO this is going to be cleaned up in a follow-up pr to take care some inconsistencies in billing modal
    if (
      !unlimitedMemberships &&
      isUnlimited &&
      !hasSeenBillingModal &&
      instance?.environment_type === 'development'
    ) {
      return showModal({
        features: [feature],
        callbackAfterClose: () => {
          onStateChange(isUnlimited, organizationMembershipsPerPlan);
        },
      });
    }

    onStateChange(isUnlimited, organizationMembershipsPerPlan);
  };

  const onStateChange = (isUnlimited: boolean, memberships: number) => {
    onChangeMembershipCount(isUnlimited ? 0 : memberships);

    onChangeIsUnlimited(isUnlimited);
  };

  return (
    <CardDualPanel title={title} subtitle={subtitle}>
      <Box>
        <RadioGroup
          onChange={onChangeRadio}
          value={isUnlimitedEnabled ? 'unlimited' : 'limited'}
        >
          <Stack direction='column'>
            <Radio value='unlimited' alignItems='flex-start'>
              <Text textStyle='md-medium' ml={2} mb={1} lineHeight={1}>
                Unlimited members
                {!unlimitedMemberships && <Badge ml='2'>Premium</Badge>}
              </Text>
              <Text textStyle='sm-normal' color='gray.500' mb={4} ml={2}>
                Organizations can have an unlimited number of members and
                pending invitations.
              </Text>
            </Radio>
            <Radio value='limited' alignItems='flex-start'>
              <Text textStyle='md-medium' ml={2} mb={1} lineHeight={1}>
                Limited members
              </Text>

              <Text textStyle='sm-normal' color='gray.500' ml={2}>
                Organizations are limited to the following number of members,
                including pending invitations.
              </Text>
            </Radio>
            <Box minH={8}>
              {!isUnlimitedEnabled && (
                <FormControl isInvalid={!!maxAllowedMembershipsState.error}>
                  <NumberInput
                    ml={8}
                    w={28}
                    isDisabled={isUnlimitedEnabled}
                    size='sm'
                    defaultValue={organizationMembershipsPerPlan}
                    min={1}
                    onChange={(_, value) => onChangeMembershipCount(value)}
                    value={value || ''}
                    {...rest}
                  >
                    <NumberInputField borderRadius={4} />
                    <NumberInputStepper>
                      <NumberIncrementStepper border='none'>
                        <Icon as={ChevronUpIcon} boxSize={4} color='gray.400' />
                      </NumberIncrementStepper>
                      <NumberDecrementStepper border='none'>
                        <Icon
                          as={ChevronDownIcon}
                          boxSize={4}
                          color='gray.400'
                        />
                      </NumberDecrementStepper>
                    </NumberInputStepper>
                  </NumberInput>
                  {maxAllowedMembershipsState.error && (
                    <FormErrorMessage ml={8}>
                      {maxAllowedMembershipsState.error.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
              )}
            </Box>
          </Stack>
        </RadioGroup>
      </Box>
    </CardDualPanel>
  );
};
