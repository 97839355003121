import React from 'react';

import { useUsersTotalCount } from '@components/users';

import { Flex, Text, Box } from '@chakra-ui/react';

import { Confetti } from '@clerk-ui/assets/icons';

import { StartBuilding } from '@components/home';
import DocumentationTiles from './DocumentationTiles';
import { PageLoader } from '@components/common';

import NoUserStateDisplay from './NoUsersStateDisplay';
import UsersAvailableStateDisplay from './UsersAvailableStateDisplay';
import ReactConfetti from 'react-confetti';
import { useLocation, useLocalStorage } from '@hooks';
import { useApplication } from '@hooks';

type States = 'no_user' | 'user';
type Content = {
  title: string;
  icon?: React.ReactNode;
  body: string | ((...args: any) => JSX.Element);
  docsComponent: JSX.Element;
  displayComponent: JSX.Element;
};
type ContentWithState = Record<States, Content>;

const onBoardingContent: ContentWithState = {
  no_user: {
    title: 'Your <SignIn/> is ready!',
    body: (appName: string) => (
      <Text> Install Clerk and become the first user of {appName}.</Text>
    ),

    docsComponent: <StartBuilding />,
    displayComponent: <NoUserStateDisplay />,
  },
  user: {
    icon: <Confetti />,
    title: 'Congratulations, your application now has users!',
    body: 'Clerk handles all the authentication and user management pieces for you. Next, you can learn how to access this data and use it in your application, or focus on customizing the look and feel of your components.',
    docsComponent: <DocumentationTiles />,
    displayComponent: <UsersAvailableStateDisplay />,
  },
};

const Header = (content: Omit<Content, 'component'>) => {
  const { application } = useApplication();
  return (
    <Flex gap={2} flexDirection='column'>
      <Flex alignItems='center' gap={2}>
        {content?.icon}
        <Text textStyle='lg-medium' fontSize='xl'>
          {content.title}
        </Text>
      </Flex>
      <Text textStyle='md-normal' color='rgba(0, 0, 0, 0.65)'>
        {typeof content.body === 'function'
          ? content.body(application?.name || '')
          : content.body}
      </Text>
    </Flex>
  );
};

const OnBoarding = (): JSX.Element => {
  const { instanceId } = useLocation();
  const [pollingInterval, setPollingInterval] = React.useState(5000);
  const { totalCount, isLoading: isLoadingUsers } = useUsersTotalCount({
    polling: pollingInterval,
  });
  const state: States = totalCount > 0 ? 'user' : 'no_user';
  const content = onBoardingContent[state];

  const [previousTotalUsers, setPreviousTotalUsers] = useLocalStorage(
    `${instanceId}-previous_total_users`,
    -1,
  );
  const [showConfetti, setShowConfetti] = React.useState(false);

  /**We want to show the confetti only when the first user signs up for the application.
Therefore, we initially set the previous users to the value of -1, which means that the total count of users
has not been initialized yet.
We set the previousUsers variable to 0 if there are no users in the application, assuming that the instance is newly created.
The request for the total number of users is running with polling when the number of total users is 0.
When a new user signs up, we check the previousTotal user count. If it is zero, it means that the user has been added to an instance that has never had users before.
Only in that situation do we show the confetti animation.
*/
  React.useEffect(() => {
    if (!isLoadingUsers && totalCount === 0) {
      setPreviousTotalUsers(0);
    } else if (!isLoadingUsers && totalCount > 0) {
      if (previousTotalUsers === 0) {
        setShowConfetti(true);
      }
      setPreviousTotalUsers(totalCount);
      setPollingInterval(0);
    }
  }, [isLoadingUsers, totalCount, previousTotalUsers]);

  if (isLoadingUsers) {
    return <PageLoader />;
  }

  return (
    <Flex gap={8} flexDirection='column'>
      {showConfetti && (
        <Box
          sx={{
            canvas: {
              position: 'fixed !important',
            },
          }}
        >
          <ReactConfetti
            recycle={false}
            numberOfPieces={300}
            gravity={0.3}
            tweenDuration={15000}
            colors={['#6c47ff', '#6A33F1', '#33BAF1', '#F1C933']}
          />
        </Box>
      )}
      <Header {...content} />
      {content.docsComponent}
      {content.displayComponent}
    </Flex>
  );
};

export default OnBoarding;
