import React from 'react';
import { useDashboardSWR, useLocation } from '@hooks';
import { SimpleGrid, useDisclosure } from '@chakra-ui/react';
import { JwtTemplate } from '@types';
import { TemplateCard, AddTemplateCard } from './TemplateCard';
import { AddNewTemplateModal } from './AddNewTemplateModal';
import { PageLoader } from '../common';

const descending = (a: JwtTemplate, b: JwtTemplate) =>
  b.updated_at - a.updated_at;

export function TemplatesList(): JSX.Element {
  const { instanceId } = useLocation();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { data: jwtTemplates, isValidating } = useDashboardSWR<JwtTemplate[]>(
    `/v1/instances/${instanceId}/jwt_templates`,
  );

  if (!jwtTemplates || isValidating) {
    return <PageLoader />;
  }

  return (
    <>
      <SimpleGrid
        templateColumns='repeat(auto-fill, minmax(280px, 1fr))'
        spacing='8'
      >
        <AddTemplateCard onClick={onOpen} />

        {jwtTemplates.sort(descending).map(template => (
          <TemplateCard key={template.id} template={template} />
        ))}
      </SimpleGrid>

      <AddNewTemplateModal isOpen={isOpen} onClose={onClose} />
    </>
  );
}
