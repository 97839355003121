import React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  HStack,
  Icon,
  Text,
} from '@chakra-ui/react';
import { ExclamationIcon } from '@heroicons/react/solid';

type DeleteUserModalProps = {
  isOpen: boolean;
  handleCancel: () => void;
  handleConfirm: () => void;
};

export function DeleteUserModal({
  isOpen,
  handleCancel,
  handleConfirm,
}: DeleteUserModalProps): JSX.Element {
  const cancelRef = React.useRef();
  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={handleCancel}
      leastDestructiveRef={cancelRef}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>Delete user</AlertDialogHeader>
          <AlertDialogBody>
            <Text textStyle='md-normal'>
              Are you sure you want to delete this user?
            </Text>
            <Text textStyle='md-normal'>
              This action is permanent and cannot be undone.
            </Text>
          </AlertDialogBody>
          <AlertDialogFooter>
            <HStack justify='space-between' width='full'>
              <Button
                variant='ghost'
                ref={cancelRef}
                onClick={handleCancel}
                autoFocus
              >
                Cancel
              </Button>
              <Button
                colorScheme='danger'
                onClick={handleConfirm}
                leftIcon={<Icon as={ExclamationIcon} />}
              >
                Delete user
              </Button>
            </HStack>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
