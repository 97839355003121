import React from 'react';
import { Icon, Text } from '@chakra-ui/react';
import { HStack } from '@chakra-ui/layout';
import { InformationCircleIcon } from '@heroicons/react/solid';

type PathEntryInfoProps = {
  info: string;
};

export function PathEntryInfo({ info }: PathEntryInfoProps): JSX.Element {
  return (
    <HStack alignItems='flex-start'>
      <Icon color='gray.300' as={InformationCircleIcon} />
      <Text textStyle='sm-normal'>{info}</Text>
    </HStack>
  );
}
