import React from 'react';

import { ImageResource } from '@types';
import { useDashboardCRUD } from '@hooks';

import { ImageUploaderBox, ImageUploaderPlaceholder } from '@components/common';

import { Image, FlexProps } from '@chakra-ui/react';

import { ProfileForm } from './types';

type LogoProps = {
  logoURL?: string;
  updateLogo: (changes: {
    logo_image_id: ProfileForm['logo_image_id'];
    logo_url: ProfileForm['logo_url'];
  }) => void;
  uploaderBoxRestProps?: FlexProps;
};

const LogoUploader = ({
  logoURL,
  updateLogo,
  uploaderBoxRestProps,
}: LogoProps): JSX.Element => {
  const { create: createImage } = useDashboardCRUD<ImageResource>();

  const handleUpload = async (file: File) => {
    const imgData = new FormData();
    imgData.append('file', file);
    const img = await createImage('v1/images', imgData);
    return updateLogo({ logo_image_id: img.id, logo_url: img.public_url });
  };

  const handleDelete = async () => {
    return updateLogo({ logo_image_id: '', logo_url: '' });
  };

  return (
    <ImageUploaderBox
      label='Logo'
      handleDelete={logoURL ? () => handleDelete() : null}
      handleUpload={handleUpload}
      uploaderBoxRestProps={uploaderBoxRestProps}
    >
      {logoURL ? (
        <Image src={logoURL} height='48px' width='48px' borderRadius='base' />
      ) : (
        <ImageUploaderPlaceholder size='lg' />
      )}
    </ImageUploaderBox>
  );
};

export default LogoUploader;
