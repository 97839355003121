import React from 'react';
import { useRouter } from 'next/router';
import { useDashboardCRUD } from '@hooks';
import { format } from 'date-fns';
import { PlanCard } from './PlanCard';
import { Text } from '@chakra-ui/react';
import { CardDualPanel } from '../common';
import { useLocation, useToast, useInstance } from '@hooks';
import { useOrganization } from '@clerk/nextjs';

type DateRange = {
  start: string;
  end: string;
};

type CurrentPlanProps = {
  activePlanName: string;
  currentMau: number;
  currentMao: number;
  mauLimit: number;
  maoLimit: number;
  currentBillingCycle: DateRange;
  hasBillingAccount: boolean;
  disabled?: boolean;
};

type CreateSession = {
  url: string;
};

const DATE_FORMAT = 'MMMM d';
const DATE_YEAR_FORMAT = 'MMMM d, YYY';

export function CurrentPlan({
  activePlanName,
  currentMau,
  currentMao,
  mauLimit,
  maoLimit,
  currentBillingCycle = { start: null, end: null },
  hasBillingAccount = false,
  disabled = false,
}: CurrentPlanProps): JSX.Element {
  const router = useRouter();
  const { applicationId } = useLocation();
  const { instance } = useInstance();
  const { create } = useDashboardCRUD<CreateSession>();
  const { showErrorToast } = useToast();
  const [ctaTriggered, setCtaTriggered] = React.useState(false);
  const { membership, organization } = useOrganization();

  const isOrganizationAdmin = membership?.role === 'admin';

  const billingCycleStartDate = new Date(currentBillingCycle.start);
  const billingCycleEndDate = new Date(currentBillingCycle.end);

  const startYear = billingCycleStartDate.getFullYear();
  const endYear = billingCycleEndDate.getFullYear();
  const startDate = format(
    billingCycleStartDate,
    startYear === endYear ? DATE_FORMAT : DATE_YEAR_FORMAT,
  );
  const endDate = format(billingCycleEndDate, DATE_YEAR_FORMAT);

  const handleManageClick = async () => {
    if (!hasBillingAccount) {
      return;
    }
    setCtaTriggered(true);
    try {
      const res = await create(
        `/v1/applications/${applicationId}/customer_portal_session`,
        {
          returnUrl: window.location.href,
        },
      );
      if (res && res.url) {
        await router.push(res.url);
      }
    } catch (_) {
      showErrorToast('Something went wrong, please try again later.');
      setCtaTriggered(false);
    }
  };

  const isDisabledForOrganizationMembers = organization && !isOrganizationAdmin;

  return (
    <CardDualPanel
      title='Current plan'
      subtitle='Manage and view your current plan'
    >
      <PlanCard
        title={activePlanName}
        description={
          <>
            {!!mauLimit && (
              <Text>
                <Text
                  as='span'
                  color={currentMau > mauLimit ? 'danger.500' : null}
                >
                  {currentMau}/{mauLimit}
                </Text>{' '}
                monthly active users
              </Text>
            )}

            {!!maoLimit && instance?.organization_settings.enabled && (
              <Text>
                <Text
                  as='span'
                  color={currentMao > maoLimit ? 'danger.500' : null}
                >
                  {currentMao}/{maoLimit}
                </Text>{' '}
                monthly active organizations
              </Text>
            )}
            <Text>
              {startDate} - {endDate}
            </Text>
          </>
        }
        ctaText={hasBillingAccount && 'Manage your billing account'}
        ctaDisabled={
          disabled || ctaTriggered || isDisabledForOrganizationMembers
        }
        ctaOnClick={handleManageClick}
        isDisabledForOrganizationMembers={isDisabledForOrganizationMembers}
      />
    </CardDualPanel>
  );
}
