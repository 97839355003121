import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { VStack } from '@chakra-ui/react';

import type { Settings } from '@types';

const SOCIAL_FORM_ID = 'social_form';

type FormProp = {
  providersField: React.ReactNode;
  socialSettings: Settings['social'];
};

const Form = ({ providersField, socialSettings }: FormProp): JSX.Element => {
  const formMethods = useForm({ mode: 'onSubmit' });
  const { reset } = formMethods;

  React.useEffect(() => {
    reset({
      social: socialSettings,
    });
  }, [socialSettings, reset]);

  return (
    <FormProvider {...formMethods}>
      <form id={SOCIAL_FORM_ID}>
        <VStack align='stretch'>{providersField}</VStack>
      </form>
    </FormProvider>
  );
};

export default Form;
