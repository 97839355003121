import { Link } from '@chakra-ui/react';
import React from 'react';

export type CardDocLinkProps = {
  url: string;
  subject: string;
};

export function CardDocLink({ url, subject }: CardDocLinkProps): JSX.Element {
  return (
    <Link href={url} target='_blank' textStyle='sm-medium' color='primary.500'>
      Learn more about {subject}
    </Link>
  );
}
