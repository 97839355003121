import React from 'react';

import { BaseSchema } from '@types';
import { OrganizationMembershipFLatUserData } from '@components/organizations/types';

import { formatRel } from '@components/users/utils';

import { RoleCell, NameCell } from './Cells';

export type MemberShipSchema = BaseSchema<OrganizationMembershipFLatUserData>;

export const membershipSchema = (): MemberShipSchema => [
  {
    header: 'Name',
    cell: user => <NameCell member={user} />,
    key: 'name',
    tdProps: {
      color: 'gray.900',
      borderTopLeftRadius: 'md',
      borderBottomLeftRadius: 'md',
    },
  },
  {
    header: 'Joined',
    cell: data => formatRel(data.created_at),
    key: 'created_at',
  },
  {
    header: 'Role',
    cell: data => (
      <RoleCell
        identifier={
          data.first_name
            ? `${data.first_name} ${data.last_name}`
            : data.identifier
        }
        key={`${data.user_id}-role-cell`}
        role={data.role}
        id={data.user_id}
      />
    ),
    key: 'role',
    tdProps: {
      width: 36,
      onClick: e => {
        e.stopPropagation();
      },
    },
  },
];
