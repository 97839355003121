import React from 'react';
import { Button, Text } from '@chakra-ui/react';
import { TemplatePreview } from '@types';
import { Modal } from '@components/common';

interface SMSTemplatePreviewProps {
  isOpen: boolean;
  onClose: () => void;
  templatePreview: TemplatePreview;
}

export function SMSTemplatePreviewModal({
  isOpen,
  onClose,
  templatePreview,
}: SMSTemplatePreviewProps): JSX.Element {
  const body = templatePreview.body.replace(/\n/g, '<br />');

  return (
    <Modal isOpen={isOpen} onClose={onClose} autoFocus={false}>
      <Modal.Body>
        <Text mb={8} dangerouslySetInnerHTML={{ __html: body }} />
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={onClose} variant='ghost'>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
