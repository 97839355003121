import React from 'react';

import { SearchInput } from '@components/common';
import { HStack, Box } from '@chakra-ui/react';

import { useRouter } from 'next/router';
import { useLocation, useDebounceCallback } from '@hooks';

const Filters = (): JSX.Element => {
  const router = useRouter();
  const { query } = useLocation();
  const [search, setState] = React.useState((query.query as string) || '');

  const updateQuery = () => {
    void router.push({
      query: {
        ...router.query,
        page: 1,
        query: search,
      },
    });
  };

  const debounceUpdateQuery = useDebounceCallback(updateQuery);

  React.useEffect(() => {
    if (query.query === '') {
      setState('');
    }
  }, [query.query]);

  return (
    <HStack flex={1}>
      <Box width={72}>
        <SearchInput
          autoFocus
          value={search}
          onChange={value => {
            setState(value);
            debounceUpdateQuery();
          }}
        />
      </Box>
    </HStack>
  );
};

export default Filters;
