import React from 'react';
import { Box, Switch, Text, Stack, Link } from '@chakra-ui/react';
import {
  useInstance,
  useDashboardCRUD,
  useToast,
  useLocation,
  useApplication,
} from '@hooks';
import {
  FormProvider,
  useController,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { CardWithSwitch, StickyBottomCTA } from '@components/common';
import { OrganizationsMaxAllowedMembershipsForm } from './OrganizationsMaxAllowedMembershipsForm';
const FORM_ID = 'organization_settings';

type OrganizationSettingsProps = {
  organizations_enabled: boolean;
  max_allowed_memberships: number;
  is_unlimited_enabled: boolean;
};

export function OrganizationsSettings(): JSX.Element {
  const { instance, mutate } = useInstance();
  const { application } = useApplication();
  const { instanceId } = useLocation();
  const { showSuccessToast, showErrorToast } = useToast();
  const { update } = useDashboardCRUD();

  const isEnabled = instance?.organization_settings.enabled;
  const memberships = instance?.organization_settings.max_allowed_memberships;

  const formMethods = useForm<OrganizationSettingsProps>();
  const { handleSubmit, formState, reset } = formMethods;
  const { isDirty, isSubmitting, isValid } = formState;

  const onSubmit = handleSubmit(async newSettings => {
    try {
      const { organizations_enabled, max_allowed_memberships } = newSettings;
      const res = (await update(
        `/v1/instances/${instanceId}/organization_settings`,
        {
          enabled: organizations_enabled,
          max_allowed_memberships,
        },
      )) as any;

      if (res.status === 402) {
        return;
      }

      await mutate();
      showSuccessToast('Settings successfully saved!');
    } catch (e) {
      showErrorToast('Something went wrong, please try again later.');
      return;
    }
  });

  React.useEffect(() => {
    resetForm();
  }, [isEnabled, memberships]);

  const resetForm = () => {
    reset({
      organizations_enabled: isEnabled,
      max_allowed_memberships: memberships,
      is_unlimited_enabled: memberships === 0,
    });
  };

  if (!instance || !application) {
    return null;
  }

  return (
    <FormProvider {...formMethods}>
      <Stack as='form' id={FORM_ID} spacing={8} onSubmit={onSubmit}>
        <OrganizationsSettingsForm />
        <OrganizationsMaxAllowedMembershipsForm
          title='Default membership limit'
          subtitle={
            <>
              <Text>
                Set the default for the maximum number of users allowed in an
                organization. This value can be overridden on a per-organization
                basis.
              </Text>
              <Text mt={3}>
                You can upgrade this application’s plan to increase the
                membership limit.{' '}
                <Link
                  isExternal
                  textStyle='sm-normal'
                  color='primary.500'
                  href='https://clerk.com/pricing'
                  fontWeight='500'
                >
                  Learn more
                </Link>
              </Text>
            </>
          }
        />
        <StickyBottomCTA
          formId={FORM_ID}
          isVisible={isDirty && isValid}
          onReset={resetForm}
          isSubmitting={isSubmitting}
        />
      </Stack>
    </FormProvider>
  );
}

const OrganizationsSettingsForm = () => {
  const { control, formState } = useFormContext<{
    organizations_enabled: boolean;
  }>();
  const { field } = useController({ control, name: 'organizations_enabled' });
  const { isSubmitting } = formState;
  const { onChange, value, ...rest } = field;

  return (
    <CardWithSwitch
      title={
        <>
          <Text textStyle='lg-medium' mr={2}>
            Enable organizations
          </Text>
        </>
      }
      subtitle='Allow users to be grouped in organizations with roles and permissions'
      docLink={{
        subject: 'this feature',
        url: 'https://clerk.com/docs/organizations/overview',
      }}
    >
      <Box>
        <Switch
          isDisabled={isSubmitting}
          onChange={e => onChange(e.target.checked)}
          isChecked={value}
          {...rest}
        />
      </Box>
    </CardWithSwitch>
  );
};
