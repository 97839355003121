import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid';
import React from 'react';
import { User } from '@types';
import { CardDualPanel, EditableMetadataField } from '..';
import { Text } from '@chakra-ui/react';

type UserMetadataFields =
  | 'private_metadata'
  | 'public_metadata'
  | 'unsafe_metadata';
type UserWithMetadata = Pick<User, UserMetadataFields>;
type UserMetadataForm = Partial<
  Record<UserMetadataFields, Record<string, unknown>>
>;

export type CardUserMetadataProps = {
  user: UserWithMetadata;
  updateUser: (changes: UserMetadataForm) => void;
};

export const CardUserMetadata = ({
  user,
  updateUser,
}: CardUserMetadataProps): JSX.Element => {
  return (
    <CardDualPanel
      title='Metadata'
      subtitle='Edit user metadata'
      docLink={{
        subject: 'custom user metadata',
        url: 'https://clerk.com/docs/users/overview#custom-user-metadata',
      }}
    >
      <EditableMetadataField
        label='Public'
        value={user.public_metadata}
        visibilityIcon={EyeIcon}
        visibilityDescription={
          <Text textStyle='md-normal' color='black'>
            Public metadata can be read from Backend API and Frontend API
          </Text>
        }
        saveHandler={md => updateUser({ public_metadata: md })}
      />
      <EditableMetadataField
        label='Private'
        value={user.private_metadata}
        visibilityIcon={EyeOffIcon}
        visibilityDescription='Private metadata can be read from Backend API only'
        saveHandler={md => updateUser({ private_metadata: md })}
      />
      <EditableMetadataField
        label='Unsafe'
        value={user.unsafe_metadata}
        visibilityIcon={EyeIcon}
        visibilityDescription='Unsafe metadata can be retrieved and modified from Backend API and Frontend API'
        saveHandler={md => updateUser({ unsafe_metadata: md })}
      />
    </CardDualPanel>
  );
};
