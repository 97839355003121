import React from 'react';
import { createTodos } from './createTodos';
import {
  COMPLETE_STATUS,
  useUpdateOnboardingMetadata,
} from './useUpdateOnboardingMetadata';
import { StoredTodos, Todo, TodoIds } from './types';
import { useUser } from '@clerk/nextjs';

export type UseTodos = {
  todos: Todo[];
  isComplete: boolean;
  /** @modifies Clerk.user */
  dismissTodo: (todoId: TodoIds) => void;
  /** @modifies Clerk.user */
  completeTodo: (todoId: TodoIds) => void;
  /** @modifies Clerk.user */
  completeAllTodos: () => void;
};

export function useTodos(
  instanceId: string,
  applicationId: string,
  environmentType: string,
): UseTodos {
  const { user } = useUser();
  const instanceStoredTodos = user.publicMetadata?.onboarding?.[
    instanceId
  ] as StoredTodos;
  const [todos, setTodos] = React.useState<Todo[]>([]);

  React.useEffect(() => {
    if (!instanceId || !environmentType) {
      return;
    }
    setTodos(
      createTodos(
        instanceId as string,
        applicationId as string,
        environmentType,
        instanceStoredTodos,
      ),
    );
  }, [instanceId, environmentType]);

  const { update } = useUpdateOnboardingMetadata();

  const dismissTodo = (todoId: TodoIds) => {
    const previousTodos = [...todos];
    const newTodos = todos.map(todo =>
      todo.id === todoId ? { ...todo, dismissed: true } : todo,
    );
    setTodos(newTodos);
    update(instanceId, newTodos).catch(() => setTodos(previousTodos));
  };

  const completeTodo = (todoId: TodoIds) => {
    const previousTodos = [...todos];
    const newTodos = todos.map(todo =>
      todo.id === todoId ? { ...todo, completed: true } : todo,
    );
    setTodos(newTodos);
    update(instanceId, newTodos).catch(() => setTodos(previousTodos));
  };

  const completeAllTodos = () => {
    setTodos(todos.map(todo => ({ ...todo, completed: true })));
  };

  const isComplete =
    (todos.length && todos.every(todo => todo.completed)) ||
    instanceStoredTodos === COMPLETE_STATUS;

  if (isComplete && instanceStoredTodos !== COMPLETE_STATUS) {
    // Errors are ignored in this case currently
    void update(instanceId, COMPLETE_STATUS);
  }

  return {
    todos,
    isComplete,
    dismissTodo,
    completeTodo,
    completeAllTodos,
  };
}
