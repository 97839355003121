import React from 'react';
import { Text } from '@chakra-ui/react';
import { isProduction } from '@utils';
import { useInstance } from '@hooks';

import { Row, Cell } from '@components/common';

export const EmptyTable = ({
  emptyTablePlaceholder,
}: {
  emptyTablePlaceholder: React.ReactNode;
}): JSX.Element => {
  const { instance } = useInstance();
  return (
    <>
      <Row>
        <Cell colSpan={4} borderBottomWidth={1} isLast={true}>
          <Text textStyle='sm-normal' textAlign='center' color='blackAlpha.700'>
            No users to display
          </Text>
        </Cell>
      </Row>
      <Row isDisabled={true}>
        <Cell colSpan={4} borderBottomWidth={1} isLast={false}>
          {!isProduction(instance?.environment_type) && emptyTablePlaceholder}
        </Cell>
      </Row>
    </>
  );
};
