import { useLocation } from '@hooks';

import { useDashboardSWR } from '@hooks';
import { makeOrganizationBasePath } from '@components/organizations/utils';
import { Organization } from '@types';

type UseGetOrganizationReturn = {
  data: Organization;
  hasLoaded: boolean;
};

export const useGetOrganization = (): UseGetOrganizationReturn => {
  const { instanceId, query } = useLocation();
  const { organizationIDorSlug } = query;

  const basePath = makeOrganizationBasePath(
    instanceId,
    organizationIDorSlug as string,
  );

  const { data, error } = useDashboardSWR<Organization>(() => {
    if (!instanceId || !organizationIDorSlug) {
      return null;
    }
    return basePath;
  });

  const hasLoaded = !!data && !error;

  return {
    data,
    hasLoaded,
  };
};
