import React from 'react';
import { Spacer, chakra, HStack, ButtonProps } from '@chakra-ui/react';
import { useRouter } from 'next/router';

// This is "dumb" because it doesn't know how many pages to show. Our DAPI doesn't
// return any pagination metadata.
export type DumbPaginationProps = {
  itemsThisPage: number;
  currentPage: number;
  hasNextPage: boolean;
  onPaginate?: (value: number) => void;
};

type PaginationButtonProps = {
  disabled: boolean;
  onClick?: (e: React.MouseEvent) => void;
  children: string | number;
};

const PaginationButton = ({
  disabled,
  onClick,
  children,
}: PaginationButtonProps) => {
  const disabledStyle: ButtonProps = disabled
    ? { color: 'gray.300', pointerEvents: 'none' }
    : {};

  return (
    <chakra.button
      type='button'
      color='gray.500'
      textStyle='sm-normal'
      p={2}
      onClick={onClick}
      disabled={disabled}
      {...disabledStyle}
    >
      {children}
    </chakra.button>
  );
};

export const DumbPagination = ({
  itemsThisPage,
  currentPage,
  hasNextPage,
  onPaginate,
}: DumbPaginationProps): JSX.Element => {
  const router = useRouter();

  const paginate = (page: number) => {
    if (onPaginate) {
      onPaginate(page);
      return;
    }
    return router.push({ query: { ...router.query, page } });
  };

  return (
    <HStack spacing={1} py={2}>
      <Spacer />
      <PaginationButton
        disabled={currentPage <= 1}
        onClick={() => paginate(currentPage - 1)}
      >
        Previous
      </PaginationButton>

      <PaginationButton disabled>{currentPage}</PaginationButton>

      <PaginationButton
        disabled={!itemsThisPage || !hasNextPage}
        onClick={() => paginate(currentPage + 1)}
      >
        Next
      </PaginationButton>
    </HStack>
  );
};
