import { ComponentStyleConfig } from '@chakra-ui/react';

const variants = {
  sidenav: {
    container: {
      border: 'none',
    },
    button: {
      p: 0,
      _hover: {
        bg: 'unset',
      },
    },
    panel: {
      p: 0,
    },
  },
};

export const Accordion: ComponentStyleConfig = {
  parts: ['container', 'button', 'panel'],
  variants,
};
