import { useDeployStatus } from './useDeployStatus';
import { isProduction } from '@utils';
import { useRouter } from 'next/router';
import { Statuses } from '@types';

export const useCheckIsProdInstance = (ignorePaths: string[] = []): boolean => {
  const router = useRouter();
  const { environmentType, deployStatus } = useDeployStatus();

  const isRunningOnProduction =
    isProduction(environmentType) &&
    deployStatus === Statuses.Complete &&
    !ignorePaths.find(part => router.pathname.includes(part));

  return isRunningOnProduction;
};
