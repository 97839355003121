import { SystemStyleObject } from '@chakra-ui/theme-tools';

const baseStyle: SystemStyleObject = {
  fontFamily: 'heading',
  fontWeight: 'medium',
};

const defaultProps = {
  size: 'xl',
};

export const Heading = {
  baseStyle,
  defaultProps,
};
