import { Web3Provider } from '@clerk/types';
import {
  EmailAddressIdentifier,
  PhoneNumberIdentifier,
  UsernameIdentifier,
} from '@clerk/types/dist/identifiers';

export type CreateAppIdentifier =
  | UsernameIdentifier
  | EmailAddressIdentifier
  | PhoneNumberIdentifier;

export type Web3ProviderListItem = {
  name: string;
  slug: Web3Provider | string; // avoid type update until we have more web3 providers
};

export const WEB3_PROVIDERS: Web3ProviderListItem[] = [
  {
    name: 'MetaMask',
    slug: 'metamask',
  },
];
