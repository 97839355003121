import React from 'react';
import { useRouter } from 'next/router';
import { useClerk } from '@clerk/nextjs';
import { useDashboardCRUD } from '@hooks/useDashboardAPI';
import { Center } from '@components/layouts';
import { Heading, Spinner } from '@clerk-ui/components';
import { VercelLogos } from './VercelLogos';
import type { Integration } from '@types';
import { buildQueryString } from './utils';

import styles from './VercelStartInstallation.module.scss';

type UserInfo = {
  email: string;
  user_exists: boolean;
};

export function VercelStartInstallation(): JSX.Element {
  const clerk = useClerk();
  const { client } = clerk;
  const { query } = useRouter();
  const { create, read } = useDashboardCRUD();

  const { code, configurationId, next } = query as {
    [queryKey: string]: string | null | undefined;
  };

  React.useEffect(() => {
    async function initializeVercelFlow() {
      try {
        const client = await createClient();
        const integration = await createIntegration(client);
        const userInfo = await getUserInfo(client, integration);
        const hasMatchingExistingSession = await checkAndSetSession(userInfo);
        setTimeout(
          () =>
            window.location.assign(
              getRedirectUrl(integration, userInfo, hasMatchingExistingSession),
            ),
          2000,
        );
      } catch (err) {
        console.error(err);
      }
    }
    void initializeVercelFlow();
  }, []);

  function createClient() {
    if (client.isNew()) {
      return client.create();
    }
    return client;
  }

  // Complete Vercel integration by exchanging the Vercel OAuth token
  function createIntegration(client): Promise<Integration> {
    return create(`/v1/integrations?client_id=${client.id}`, {
      code,
      configuration_id: configurationId,
    }) as Promise<Integration>;
  }

  // Retrieve user info to see if a user with this email already exists on our end
  function getUserInfo(client, integration: Integration): Promise<UserInfo> {
    return read(
      `/v1/integrations/${integration.id}/userinfo?client_id=${client.id}`,
    ) as Promise<UserInfo>;
  }

  // Sets the current session if necessary
  async function checkAndSetSession(userInfo: UserInfo): Promise<boolean> {
    const { user_exists: userExists, email: vercelEmail } = userInfo;

    if (!userExists) {
      return false;
    }

    const matchingExistingSession = clerk.client?.activeSessions.find(session =>
      session.user.emailAddresses.some(e => e.emailAddress === vercelEmail),
    );

    if (!matchingExistingSession) {
      return false;
    }

    if (clerk.session.id && clerk.session.id !== matchingExistingSession.id) {
      await clerk.setSession(matchingExistingSession);
    }

    return true;
  }

  // Get redirect url to auth hosted pages (accounts.example.com) to authenticate user
  function getRedirectUrl(
    integration: Integration,
    userInfo: UserInfo,
    hasMatchingExistingSession: boolean,
  ): string {
    if (!integration) {
      throw 'Missing vercel integration.';
    }

    const { user_exists: userExists } = userInfo;
    const returnTo = getReturnTo(integration);

    if (!userExists) {
      return `${
        window.location.origin
      }/sign-up?redirect_url=${encodeURIComponent(
        returnTo,
      )}&disabled_strategies=email_link`;
    }

    if (hasMatchingExistingSession) {
      return returnTo;
    }

    return `${window.location.origin}/sign-in?redirect_url=${encodeURIComponent(
      returnTo,
    )}&disabled_strategies=email_link`;
  }

  function getReturnTo(integration: Integration): string {
    // Marketplace flow
    return `${window.location.origin}/vercel/${
      integration.id
    }/select-project${buildQueryString({
      next: next,
    })}`;
  }

  return (
    <Center centerContent>
      <VercelLogos />
      <Heading as='h4'>Integrate Clerk into your Vercel project</Heading>
      <p className={styles.message}>
        Clerk adds user management capabilities to your project in mere minutes.
      </p>
      <Spinner />
      <p>Initializing...</p>
    </Center>
  );
}
