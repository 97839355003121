import React from 'react';
import { ChakraProvider as ChakraOriginalProvider } from '@chakra-ui/react';
import theme from '@theme';

export function ChakraProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  return (
    <ChakraOriginalProvider theme={theme}>{children}</ChakraOriginalProvider>
  );
}
