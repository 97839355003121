import React from 'react';
import Link from 'next/link';
import { useDashboardCRUD, useDashboardSWR } from '@hooks/useDashboardAPI';
import { AppForm } from './AppForm';
import { VercelScreen } from './VercelScreen';
import type { Integration, VercelProject } from '@types';
import { buildQueryString, getNextUrl } from './utils';

export type VercelCreateApplicationProps = {
  integration: Integration;
  projectId: string;
  next: string;
};

export function VercelCreateApplication({
  integration,
  projectId,
  next,
}: VercelCreateApplicationProps): JSX.Element {
  const { data } = useDashboardSWR(
    `/v1/integrations/${integration.id}/objects/${projectId}`,
  );
  const project = data as VercelProject;
  const { data: applications } = useDashboardSWR('/v1/applications');
  const hasAnyApplications = applications?.length > 0;
  const { create } = useDashboardCRUD();
  const [isLoading, setIsLoading] = React.useState(false);

  const handleSubmit = async newApp => {
    try {
      setIsLoading(true);

      // Bind current application to Vercel
      await create(`/v1/integrations/${integration.id}/link`, {
        application_id: newApp.id,
        project_id: projectId,
      });
    } catch (_) {
      setIsLoading(false);
    }

    // Redirect to Vercel to complete the integration
    window.location.assign(getNextUrl(next, integration));
  };

  return (
    <VercelScreen
      disabled={isLoading}
      title='Create application'
      subtitle='Configure a new application to integrate into this Vercel project'
      footer={
        hasAnyApplications && (
          <>
            {' '}
            Application already exists?{' '}
            <Link
              href={`/vercel/${integration.id}/link-app${buildQueryString({
                project_id: projectId,
                next,
              })}`}
            >
              Integrate existing application
            </Link>
          </>
        )
      }
    >
      <AppForm
        buttonGroupToTheRight
        submitButtonLabel='Create application'
        handleSubmit={handleSubmit}
        applicationName={project ? project.name : ''}
      />
    </VercelScreen>
  );
}
