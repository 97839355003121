import { useDashboardSWR, useLocation } from '@hooks';
import { FeatureFlags } from '@types';

export const useFeatureFlags = (): FeatureFlags => {
  const { instanceId } = useLocation();
  const { data: featureFlags } = useDashboardSWR<FeatureFlags>(
    `/v1/instances/${instanceId}/feature_flags`,
  );

  return (
    featureFlags || {
      allow_custom_template_creation: false,
      allow_organization_details: false,
      password_overhaul: false,
      allow_members_tab: false,
      create_organization_dashboard: false,
      show_users_memberships_dashboard: false,
      allow_customization_avatars: false,
      allow_development_origin: false,
      allow_change_subdomain_domain: false,
    }
  );
};
