import { NextRouter, useRouter } from 'next/router';

type UseLocation = NextRouter & {
  applicationId: string | undefined;
  instanceId: string | undefined;
  currentPage: string;
  isHome: boolean;
  isV2: boolean;
};

const V1_PREFIXES = ['/applications', '/instances'];

export const useLocation = (): UseLocation => {
  const { query, pathname, ...rest } = useRouter();
  const { applicationId, instanceId } = query as Record<
    string,
    string | undefined
  >;

  return {
    ...rest,
    query,
    pathname,
    applicationId,
    instanceId,
    currentPage: pathname.split('[instanceId]')[1] || '/',
    isHome: !applicationId || !instanceId,
    isV2: !V1_PREFIXES.find(pre => pathname.startsWith(pre)),
  };
};
