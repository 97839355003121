import { replacements } from './replacements';

const escapeStringRegexp = (string: string): string =>
  string.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&').replace(/-/g, '\\x2d');

const doCustomReplacements = (
  string: string,
  replacements: string[][],
): string => {
  for (const [key, value] of replacements) {
    string = string.replace(new RegExp(escapeStringRegexp(key), 'g'), value);
  }

  return string;
};

export const transliterate = (string: string): string => {
  string = string.normalize();
  string = doCustomReplacements(string, replacements);
  string = string
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .normalize();

  return string;
};
