import React, { useLayoutEffect, useRef, useState } from 'react';
import { Button, Flex, Image, Switch, Text, Badge } from '@chakra-ui/react';
import { OAuthStrategy, sortedOAuthProviders } from '@clerk/types';
import { OAuthProviderSortingArray } from '@types';
import { svgUrl } from '@utils';
import {
  maxThreeSocialAndWeb3,
  shouldCheckMaxSocialAndWeb3,
} from '@utils/billing';
import { useFormContext } from 'react-hook-form';
import { useAnalytics } from '@hooks/useAnalytics';

type OAuthProviderListProps = {
  supportedFeatures: string[];
  oauthProviderStrategies: OAuthStrategy[];
  web3Providers: JSX.Element;
  identifiers: JSX.Element;
};

const NUMBER_OF_PROVIDERS_BEFORE_COLLAPSE = 3;
const WEB3_PROVIDERS_LENGTH = 1;

export function OAuthProviderList({
  supportedFeatures,
  oauthProviderStrategies,
  web3Providers,
  identifiers,
}: OAuthProviderListProps): JSX.Element {
  const { track } = useAnalytics();
  const { register, watch, getValues } = useFormContext();
  const [showAll, setShowAll] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const trackToggle = (name: string, checked: boolean) => {
    track('Dashboard_Applications_Application Configuration Toggle Selected', {
      surface: 'Dashboard',
      location: 'Applications',
      clickedOn: name,
      state: checked ? 'On' : 'Off',
    });
  };

  useLayoutEffect(() => {
    containerRef.current?.scroll({
      top: showAll ? 150 : 0,
      behavior: 'smooth',
    });
  }, [showAll]);

  const oAuthValues = watch('oauth');

  return (
    <>
      <Flex
        ref={containerRef}
        mb={12}
        overflow={showAll ? 'scroll' : 'hidden'}
        flexDirection='column'
        gap={1}
      >
        {identifiers}
        {sortedOAuthProviders(OAuthProviderSortingArray)
          .filter(oauthProviderData =>
            oauthProviderStrategies.includes(oauthProviderData.strategy),
          )
          .slice(
            0,
            showAll
              ? oauthProviderStrategies.length
              : NUMBER_OF_PROVIDERS_BEFORE_COLLAPSE,
          )
          .map(({ provider, name }) => (
            <Flex
              key={provider}
              borderRadius='lg'
              p={4}
              align='center'
              justify='space-between'
              bg={oAuthValues[provider] ? 'gray.50' : 'transparent'}
            >
              <Flex gap={3}>
                <Image src={svgUrl(provider)} boxSize='20px' />
                <Text textStyle='md-medium'>{name}</Text>
                {provider === 'google' && (
                  <Badge
                    color='primary.500'
                    fontSize='0.625rem'
                    fontWeight='600'
                  >
                    Popular
                  </Badge>
                )}
              </Flex>

              <Switch
                size='md'
                isDisabled={
                  shouldCheckMaxSocialAndWeb3(supportedFeatures)
                    ? maxThreeSocialAndWeb3('oauth', provider, getValues)
                    : false
                }
                {...register(`oauth.${provider}` as any)}
                onChange={e => {
                  trackToggle(e.target.name, e.target.checked);
                  void register(`oauth.${provider}` as any).onChange(e);
                }}
              />
            </Flex>
          ))}
        {showAll && web3Providers}
      </Flex>
      <Button
        position='absolute'
        bottom={6}
        pl={4}
        mr='auto'
        mt={2}
        variant='link'
        textTransform='none'
        onClick={() => {
          setShowAll(show => !show);
        }}
        aria-label={!showAll ? 'show providers' : 'hide providers'}
      >
        {!showAll &&
          `Show ${
            oauthProviderStrategies.length +
            WEB3_PROVIDERS_LENGTH -
            NUMBER_OF_PROVIDERS_BEFORE_COLLAPSE
          } more`}

        {showAll &&
          `Hide ${
            oauthProviderStrategies.length +
            WEB3_PROVIDERS_LENGTH -
            NUMBER_OF_PROVIDERS_BEFORE_COLLAPSE
          } items`}
      </Button>
    </>
  );
}
