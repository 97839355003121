import React from 'react';
import { Container, Flex } from '@chakra-ui/react';
import { ChakraProvider } from '@context/ChakraContext';
import { Header } from '@components/header';
import { Sidebar } from '@components/sidebar';
import { useLocation } from '@hooks';
import { useDashboardSWR } from '@hooks/useDashboardAPI';
import { PaymentRequiredProvider } from '@context/PaymentRequiredContext';
import {
  UserExcededContent,
  GracePeriodBanner,
  GracePeriodBannerExpired,
} from '@components/banners/NagBanners';

export interface LayoutProps {
  children: React.ReactNode;
}

export function Layout({ children }: LayoutProps): JSX.Element {
  const { isHome, pathname, applicationId } = useLocation();

  const { data: application } = useDashboardSWR(
    applicationId ? `/v1/applications/${applicationId}` : null,
  );

  const hasExceededMau =
    application &&
    Number(application.subscription_plan_mau_limit) &&
    Number(application.current_mau) >
      Number(application.subscription_plan_mau_limit);

  const userBanner = hasExceededMau ? <UserExcededContent /> : null;
  const gracePeriodBanner = () => {
    if (
      application?.entered_grace_period_at &&
      !application?.grace_period_expired
    ) {
      return <GracePeriodBanner />;
    }

    if (
      application?.entered_grace_period_at &&
      application?.grace_period_expired
    ) {
      return <GracePeriodBannerExpired />;
    }

    return null;
  };

  const bannerComponent = userBanner || gracePeriodBanner();

  const isVercelFlow = pathname.startsWith('/vercel');

  const homeLayout = isHome && (
    <Flex
      width='100%'
      flex={1}
      pt={bannerComponent ? 16 : 12}
      pb={12}
      px={{ base: 6, md: 16 }}
      height='100%'
      flexDirection='column'
      alignItems='center'
      maxHeight='100%'
    >
      <Flex
        flex={1}
        flexDirection='column'
        maxHeight='100%'
        width='100%'
        as='main'
        height='100%'
        p={0}
        position='relative'
        maxW='container.xxl'
      >
        {children}
      </Flex>
    </Flex>
  );

  const layout = !isHome && (
    <Container
      p={12}
      pt={bannerComponent ? 16 : 12}
      maxW='container.xxl'
      display='flex'
    >
      <Sidebar />
      <Container as='main' maxW='container.lg'>
        <RemountOnApplicationInstanceSwitch>
          {children}
        </RemountOnApplicationInstanceSwitch>
      </Container>
    </Container>
  );

  return (
    <ChakraProvider>
      <PaymentRequiredProvider>
        <Flex direction='column' minH='100vh' align='stretch'>
          {!isVercelFlow ? <Header banner={bannerComponent} /> : null}
          {homeLayout}
          {layout}
        </Flex>
      </PaymentRequiredProvider>
    </ChakraProvider>
  );
}

function RemountOnApplicationInstanceSwitch(
  props: React.PropsWithChildren<{}>,
) {
  const { applicationId, instanceId } = useLocation();
  const key = applicationId + instanceId;
  return <React.Fragment key={key}>{props.children}</React.Fragment>;
}
