import React from 'react';
import { Statuses } from '@types';
import { useDashboardCRUD } from '@hooks/useDashboardAPI';
import { useLocation } from '@hooks';
import { Box, Button, Flex, HStack, Text } from '@chakra-ui/react';
import { StatusIcon } from './StatusIcon';

type EmailAndTLSStatusProps = {
  sslStatus: Statuses;
  emailStatus: Statuses;
};

export function EmailAndTLSStatus({
  sslStatus,
  emailStatus,
}: EmailAndTLSStatusProps): JSX.Element {
  const { instanceId } = useLocation();
  const { create } = useDashboardCRUD();
  const [isSSLLoading, setSSLLoading] = React.useState(false);
  const [isEmailLoading, setEmailLoading] = React.useState(false);

  const retrySSL = async () => {
    setSSLLoading(true);
    try {
      await create(`/v1/instances/${instanceId}/status/ssl/retry`);
    } catch (_) {
      // TODO: when  create fails the legacy error notification is shown
      // revisit and formalize error handling
      // showErrorToast('Something went wrong, please try again later.');
    } finally {
      setSSLLoading(false);
    }
  };

  const retryEmail = async () => {
    setEmailLoading(true);
    try {
      await create(`/v1/instances/${instanceId}/status/mail/retry`);
    } catch (_) {
      // showErrorToast('Something went wrong, please try again later2.');
    } finally {
      setEmailLoading(false);
    }
  };

  return (
    <Box mt='3'>
      <Flex px='2' pt='1' align='center' justify='space-between'>
        <HStack spacing='1.5'>
          <StatusIcon status={sslStatus} />
          <Text textStyle='sm-normal'>TLS certificate issued</Text>
        </HStack>

        <RetryServiceButton
          loading={isSSLLoading}
          status={sslStatus}
          onClick={async () => await retrySSL()}
        />
      </Flex>
      <Flex px='2' pt='1' align='center' justify='space-between'>
        <HStack spacing='1.5'>
          <StatusIcon status={emailStatus} />
          <Text textStyle='sm-normal'>Email server initialization</Text>
        </HStack>

        <RetryServiceButton
          loading={isEmailLoading}
          status={emailStatus}
          onClick={async () => await retryEmail()}
        />
      </Flex>
    </Box>
  );
}

type RetryServiceButtonProps = {
  onClick: () => void;
  status: Statuses;
  loading: boolean;
};

function RetryServiceButton({
  onClick,
  status,
  loading,
}: RetryServiceButtonProps): JSX.Element {
  return (
    status === Statuses.Failed && (
      <Button variant='ghost' size='sm' onClick={onClick} isLoading={loading}>
        Retry
      </Button>
    )
  );
}
