import { useToast as useChakraToast } from '@chakra-ui/react';

type ShowErrorToast = (
  title: string | React.ReactNode,
  description?: string | React.ReactNode,
) => void;

type ShowSuccessToast = ShowErrorToast;

type ShowToast = (
  type: 'error' | 'success' | 'warning',
  title: string | React.ReactNode,
  description?: string | React.ReactNode,
) => void;

type UseToast = {
  showToast: ShowToast;
  showErrorToast: ShowErrorToast;
  showSuccessToast: ShowSuccessToast;
};

export function useToast(): UseToast {
  const toast = useChakraToast();

  const showToast: ShowToast = (type, title, description) => {
    toast({
      title: title,
      description: description,
      status: type,
      duration: 5000,
      isClosable: true,
      position: 'top-right',
    });
  };

  const showErrorToast: ShowErrorToast = (title, description) =>
    showToast('error', title, description);

  const showSuccessToast: ShowSuccessToast = (title, description) =>
    showToast('success', title, description);

  return {
    showToast,
    showErrorToast,
    showSuccessToast,
  };
}
