import React from 'react';

import { VStack, Text } from '@chakra-ui/react';

import { useDashboardCRUD } from '@hooks';
import { useToast } from '@hooks';

export const FORM_ID = 'confirmation_change_domain';

type ConfirmChangeProps = {
  newDomain: string;
  instanceId: string;
  setIsSubmitting: (isSubmitting: boolean) => void;
  setError: ({ message }: { message: string }) => void;
  onDomainChangeConfirmed: () => void;
};

const ConfirmChange = ({
  newDomain,
  instanceId,
  setIsSubmitting,
  setError,
  onDomainChangeConfirmed,
}: ConfirmChangeProps): JSX.Element => {
  const { create } = useDashboardCRUD();
  const { showErrorToast, showSuccessToast } = useToast();

  const onConfirmChange = async e => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const domainWithScheme = `https://${newDomain}`;

      await create(`/v1/instances/${instanceId}/change_domain`, {
        home_url: `${domainWithScheme}`,
      });
      showSuccessToast(`Domain has been updated to ${domainWithScheme}`);
      onDomainChangeConfirmed();
    } catch (err) {
      if (err?.fieldErrors && err?.fieldErrors.length > 0) {
        if (err?.fieldErrors[0].code === 'form_identifier_exists') {
          setError({
            message: 'This domain is already taken, please try another.',
          });
        } else {
          setError({ message: err?.fieldErrors[0].long_message });
        }
      } else {
        showErrorToast('Could not update subdomain');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form id={FORM_ID} onSubmit={onConfirmChange}>
      <VStack alignItems='start'>
        <Text as='span' textStyle='md-normal'>
          Are you sure you want to change the domain to {''}
          <Text as='span' textStyle='md-semibold'>
            {newDomain}?
          </Text>
        </Text>
      </VStack>
    </form>
  );
};

export default ConfirmChange;
