import { useDashboardSWR } from './useDashboardAPI';
import { useInstanceId } from './useInstanceId';
import type { Settings } from '@types';

function checkOauthSetup(userSettings: Settings) {
  const enabledOauthSettings = Object.values(userSettings.social || {}).filter(
    ({ enabled }) => enabled,
  );

  const invalidSetup = enabledOauthSettings.some(
    ({ client_id, client_secret }) => !(client_id && client_secret),
  );

  return {
    validSetup: !invalidSetup,
    hasEnabledProviders: enabledOauthSettings.length,
  };
}

function isDnsRecordsVerified(dnsRecords): boolean {
  return (
    Object.keys(dnsRecords).length &&
    Object.values(dnsRecords).every(({ verified }) => verified === true)
  );
}

/**
 * Provide information on if the instance can go live, exposing also the status of the individual checks.
 * To be used in production.
 */
export function useCanGoLive() {
  const { instanceId } = useInstanceId();
  const { data: { dns: dnsRecords } = {} } = useDashboardSWR(
    `/v1/instances/${instanceId}/status`,
  );
  const settings: Settings = useDashboardSWR(
    `/v1/instances/${instanceId}/user_settings`,
  ).data;

  if (!dnsRecords || !settings) {
    return {};
  }

  const dnsRecordsVerified = isDnsRecordsVerified(dnsRecords);
  const { validSetup, hasEnabledProviders } = checkOauthSetup(settings);

  const canGoLive =
    dnsRecordsVerified && (hasEnabledProviders ? validSetup : true);

  return {
    canGoLive,
    dnsRecordsVerified,
    oauthSetupVerified: validSetup,
    hasEnabledOauthProviders: hasEnabledProviders,
  };
}
