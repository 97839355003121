import { HelperLink, InformationBox } from '@components/common';
import { Box } from '@chakra-ui/react';
import React from 'react';

type OrganizationsDisabledInformationBoxProps = {
  orgSettingsUrl: string;
};

export const OrganizationsDisabledInformationBox = ({
  orgSettingsUrl,
}: OrganizationsDisabledInformationBoxProps): JSX.Element => {
  return (
    <InformationBox textStyle='sm-normal' color='gray.500' mb={8}>
      <Box>
        Organizations have not been enabled for this instance.{' '}
        <HelperLink display='span' href={orgSettingsUrl}>
          Go to Organization Settings
        </HelperLink>
      </Box>
    </InformationBox>
  );
};
