import { SUPPORTED_FEATURES } from '@constants';

export const SECTION_ATTRIBUTES = [
  {
    title: 'Contact information',
    subtitle:
      'Specify whether your users should have email addresses or phone numbers',
    content: [
      {
        title: 'Email address',
        description: 'Users can add email addresses to their account',
        key: 'email_address',
        hasExtraSettings: true,
        featureName: '',
      },
      {
        title: 'Phone number',
        description: 'Users can add phone numbers to their account',
        key: 'phone_number',
        hasExtraSettings: true,
        featureName: SUPPORTED_FEATURES.phone_code,
      },
    ],
  },
  {
    title: 'Username',
    subtitle: 'Specify whether your users have a unique username',
    content: [
      {
        title: 'Username',
        description: 'Users can set usernames to their account',
        key: 'username',
        hasExtraSettings: true,
      },
    ],
  },
  {
    title: 'Personal information',
    subtitle: 'Specify whether your users have extra personal information',
    content: [
      {
        title: 'Name',
        description: 'Users can set their first and last name',
        key: 'first_name',
        hasExtraSettings: true,
      },
    ],
  },
];

export const FORM_KEYS = []
  .concat(
    SECTION_ATTRIBUTES[0].content,
    SECTION_ATTRIBUTES[1].content,
    SECTION_ATTRIBUTES[2].content,
  )
  .map(({ key }) => key);

export const USED_FOR_FIRST_FACTOR_LABEL = 'Used for sign-in';

export const VERIFY_AT_SIGN_UP_LABEL = 'Verify at sign-up';

export const VERIFICATION_METHOD_LABELS = Object.freeze({
  email_link: 'Email verification link',
  email_code: 'Email verification code',
  phone_code: 'SMS verification code',
});

export const AUTHENTICATION_FACTORS_ATTRIBUTES_INFO = {
  email_link:
    'Cannot be enabled because Email address contact information is not used for sign-in',
  email_code:
    'Cannot be enabled because Email address contact information is not used for sign-in',
  phone_code:
    'Cannot be enabled because Phone number contact information is not used for sign-in',
  password:
    'Cannot be enabled because Email address, Phone number and Username contact information are not used for sign-in',
};
