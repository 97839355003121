import React from 'react';

import { noop } from '@utils';
import { UseTodos } from '../useTodos/useTodos';

export const TodosContext = React.createContext<UseTodos>({
  todos: [],
  isComplete: false,
  dismissTodo: noop,
  completeTodo: noop,
  completeAllTodos: noop,
});
