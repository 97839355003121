import React from 'react';
import { Modal as SharedModal, ModalProps } from '@clerk-ui/components';
import { CloseButtonProps } from '@clerk-ui/components/modal/CloseButton';

type DashboardModal = React.FunctionComponent<ModalProps> & {
  CloseButton?: (props: CloseButtonProps) => JSX.Element | null;
};

/*
  Extends our shared Modal component
  making it target NextJs root element `div#__next`
  as the inert target by default
 */
const Modal: DashboardModal = ({
  rootSelector = '#__next',
  children,
  ...rest
}: ModalProps) => (
  <SharedModal {...rest} rootSelector={rootSelector}>
    {children}
  </SharedModal>
);

Modal.CloseButton = SharedModal.CloseButton;

export { Modal };
