import { stripProtocol as stripProtocolHelper } from '@utils';
import { useInstance } from '@hooks';

export function useFrontendAPI(stripProtocol = true): string {
  const { instance } = useInstance();

  if (!instance) {
    return '';
  }

  const fapiURL = instance.active_domain?.frontend_api_url;

  if (stripProtocol) {
    return stripProtocolHelper(fapiURL);
  }
  return fapiURL;
}
