import React from 'react';
import {
  useDashboardCRUD,
  useLocation,
  useToast,
  useSupportedFeature,
} from '@hooks';
import { usePaymentRequired } from '@context/PaymentRequiredContext';
import {
  Instance,
  TemplateExtended,
  TemplatePreview,
  SMSTemplateFormFields,
} from '@types';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Textarea,
  useDisclosure,
  SimpleGrid,
  Spacer,
  VStack,
} from '@chakra-ui/react';
import { useForm, FormProvider } from 'react-hook-form';
import { titleize } from '@utils/string';
import {
  HTTPError,
  billingPath,
  isFeatureSupported,
  EnvironmentType,
} from '@utils';
import {
  DeliveredByClerkSwitch,
  SMSTemplatePreviewModal,
  TemplateCopyConfirmationModal,
  TemplateDocs,
  TemplateStickyCTAs,
  TemplateRevertConfirmationModal,
} from '@components/customization';
import { SUPPORTED_FEATURES } from '@constants';
import { VariableWidget } from '@components/common';

interface SMSTemplateFormProps {
  template: TemplateExtended;
  instances: Instance[];
  updateTemplate: React.Dispatch<React.SetStateAction<TemplateExtended>>;
}

interface ErrorProps {
  err: Error;
}

export function SMSTemplateForm({
  template,
  instances,
  updateTemplate,
}: SMSTemplateFormProps): JSX.Element {
  const { instanceId, applicationId, push } = useLocation();
  const { hasNotSeenInfoBillingModal } = useSupportedFeature();

  const formMethods = useForm<SMSTemplateFormFields>();
  const {
    clearErrors,
    reset,
    getValues,
    setError,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const bodyRef = React.useRef(null);
  const { create, put } = useDashboardCRUD();
  const { showSuccessToast, showErrorToast } = useToast();

  const previewModalDisclosure = useDisclosure();
  const copyConfirmModalDisclosure = useDisclosure();
  const revertConfirmModalDisclosure = useDisclosure();
  const { showModal: showUpgradePlanModal } = usePaymentRequired();

  const [templatePreview, setTemplatePreview] = React.useState<TemplatePreview>(
    {
      body: '',
    } as TemplatePreview,
  );

  const [targetInstance, setTargetInstance] = React.useState<Instance>(null);

  const currentInstance = React.useMemo(() => {
    return instances.find(instance => instance.id == instanceId);
  }, [instances, instanceId]);

  const otherInstances = React.useMemo(() => {
    return instances.filter(instance => instance.id !== instanceId);
  }, [instances, instanceId]);

  const isFeatureEnabled =
    currentInstance &&
    (currentInstance.environment_type === EnvironmentType.Development ||
      isFeatureSupported(
        currentInstance.supported_features,
        SUPPORTED_FEATURES.custom_sms_template,
      ));

  React.useEffect(() => {
    if (!template || !instances) {
      return;
    }

    reset({
      name: template.name,
      body: template.body,
      delivered_by_clerk: template.delivered_by_clerk,
    });
  }, [template, instances, reset]);

  const handleError = ({ err }: ErrorProps) => {
    if (err instanceof HTTPError) {
      if (err.code == 402) {
        showUpgradePlanModal({
          features: [SUPPORTED_FEATURES.custom_sms_template],
        });
      } else if (err.code == 422) {
        err.fieldErrors.forEach(e => {
          const param = e?.meta?.param_name;

          if (param) {
            // @ts-ignore
            setError(param, { type: 'api', message: e.long_message });
          }
        });

        showErrorToast('Please check the form for field errors');
      }
    } else {
      showErrorToast(err.message);
    }
  };

  const handleTemplateUpdate = async fields => {
    try {
      const upsertParams = {
        name: fields.name,
        body: fields.body,
        delivered_by_clerk: fields.delivered_by_clerk,
      };

      const res = (await put(
        `/v1/instances/${instanceId}/templates/${template.template_type}/${template.slug}`,
        upsertParams,
      )) as TemplateExtended;
      updateTemplate(res);

      showSuccessToast('Template was updated successfully');
    } catch (err) {
      handleError({ err });
    }
  };

  const onSubmit = fields => {
    if (hasNotSeenInfoBillingModal(SUPPORTED_FEATURES.custom_sms_template)) {
      return showUpgradePlanModal({
        callbackAfterClose: () => handleTemplateUpdate(fields),
      });
    }

    void handleTemplateUpdate(fields);
  };

  const onPreview = async () => {
    clearErrors();

    try {
      const previewParams = {
        body: getValues('body'),
      };

      const preview = (await create(
        `/v1/instances/${instanceId}/templates/${template.template_type}/${template.slug}/preview`,
        previewParams,
      )) as TemplatePreview;

      setTemplatePreview(preview);

      previewModalDisclosure.onOpen();
    } catch (err) {
      handleError({ err });
    }
  };

  const onCopyPrompt = (instance: Instance) => {
    setTargetInstance(instance);
    copyConfirmModalDisclosure.onOpen();
  };

  const onRevertPrompt = () => {
    revertConfirmModalDisclosure.onOpen();
  };

  const onCopyConfirm = async () => {
    try {
      const upsertParams = {
        name: getValues('name'),
        body: getValues('body'),
        delivered_by_clerk: getValues('delivered_by_clerk'),
      };

      await put(
        `/v1/instances/${targetInstance.id}/templates/${template.template_type}/${template.slug}`,
        upsertParams,
      );

      copyConfirmModalDisclosure.onClose();
      setTargetInstance(null);

      showSuccessToast(
        `Template was successfully copied to ${titleize(
          targetInstance.environment_type,
        )}`,
      );
    } catch (err) {
      copyConfirmModalDisclosure.onClose();
      handleError({ err });
    }
  };

  const onRevertConfirm = async () => {
    try {
      const res = (await create(
        `/v1/instances/${instanceId}/templates/${template.template_type}/${template.slug}/revert`,
      )) as TemplateExtended;
      updateTemplate(res);

      revertConfirmModalDisclosure.onClose();

      showSuccessToast('Template was successfully reverted to default');
    } catch (err) {
      revertConfirmModalDisclosure.onClose();
      handleError({ err });
    }
  };

  const onReset = () => {
    reset();
  };

  const insertVariable = (variableText: string) => {
    const currentBody = getValues('body');

    const currentCursorPosition = bodyRef.current.selectionStart;

    const textBeforeCursorPosition = currentBody.substring(
      0,
      currentCursorPosition,
    );

    const textAfterCursorPosition = currentBody.substring(
      currentCursorPosition,
      currentBody.length,
    );

    const newBody =
      textBeforeCursorPosition + variableText + textAfterCursorPosition;

    setValue('body', newBody, {
      shouldDirty: true,
    });

    // Advance cursor position
    const nextCursorPosition = currentCursorPosition + variableText.length;
    bodyRef.current.selectionStart = nextCursorPosition;
    bodyRef.current.selectionEnd = nextCursorPosition;
    bodyRef.current.focus();
  };

  const containsRequiredVars = (value: string): boolean | string => {
    const missingVars = template.required_variables.reduce(
      (memo: string[], requiredVariable) => {
        const formattedVar = `{{${requiredVariable}}}`;

        if (!value.includes(formattedVar)) {
          memo.push(formattedVar);
        }

        return memo;
      },
      [],
    );

    return (
      missingVars.length == 0 ||
      `Message should contain the following variables: ${missingVars.join(
        ', ',
      )}`
    );
  };

  const { ref, ...rest } = register('body', {
    required: 'Message is required',
    validate: {
      containsRequiredVars,
    },
  });

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack align='baseline' spacing='3'>
            <TemplateDocs />

            <DeliveredByClerkSwitch type='sms' template={template} />

            <SimpleGrid columns={2} templateColumns='60fr 40fr' spacing='4'>
              <FormControl flex='2' isInvalid={!!errors.name}>
                <FormLabel htmlFor='name'>Name</FormLabel>
                <HStack>
                  <Input
                    type='text'
                    id='name'
                    placeholder='Name'
                    {...register('name', {
                      required: 'Name is required',
                    })}
                    disabled={!isFeatureEnabled}
                  />
                </HStack>
                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl>

              <Spacer flex='1'></Spacer>

              <FormControl flex='2' isInvalid={!!errors.body}>
                <FormLabel>Message</FormLabel>
                <HStack>
                  <Textarea
                    rows={6}
                    height='auto'
                    id='body'
                    placeholder='Message'
                    {...rest}
                    ref={e => {
                      ref(e);
                      bodyRef.current = e;
                    }}
                    disabled={!isFeatureEnabled}
                  />
                </HStack>
                <FormErrorMessage>{errors.body?.message}</FormErrorMessage>
              </FormControl>

              <VariableWidget
                variables={template.available_variables}
                delimiters={['{{', '}}']}
                handleVariableClick={insertVariable}
                disabled={!isFeatureEnabled}
              />
            </SimpleGrid>

            <TemplateStickyCTAs
              template={template}
              otherInstances={otherInstances}
              onPreview={onPreview}
              onCopyPrompt={onCopyPrompt}
              onRevertPrompt={onRevertPrompt}
              onUpgradePrompt={() =>
                push(billingPath(applicationId, instanceId))
              }
              onReset={onReset}
              disabled={!isFeatureEnabled}
            />
          </VStack>
        </form>
      </FormProvider>

      <SMSTemplatePreviewModal
        isOpen={previewModalDisclosure.isOpen}
        onClose={previewModalDisclosure.onClose}
        templatePreview={templatePreview}
      />

      <TemplateCopyConfirmationModal
        instance={targetInstance}
        slug={template.slug}
        isOpen={copyConfirmModalDisclosure.isOpen}
        onClose={copyConfirmModalDisclosure.onClose}
        onCopyConfirm={onCopyConfirm}
      />

      <TemplateRevertConfirmationModal
        slug={template.slug}
        isOpen={revertConfirmModalDisclosure.isOpen}
        onClose={revertConfirmModalDisclosure.onClose}
        onRevertConfirm={onRevertConfirm}
      />
    </>
  );
}
