import React from 'react';

import {
  Box,
  Flex,
  FormControl,
  HStack,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react';

import { useFormContext, useWatch } from 'react-hook-form';
import { UseFormRegisterReturn } from 'react-hook-form/dist/types/form';
import { InformationCircleIcon } from '@heroicons/react/solid';
import { InformationBox } from '@components/common';

interface JwtCustomSigningKeySwitchProps {
  field: UseFormRegisterReturn;
}

export function JwtCustomSigningKeySwitch({
  field,
  ...rest
}: JwtCustomSigningKeySwitchProps): JSX.Element {
  const {
    formState: { isSubmitting },
    control,
  } = useFormContext();

  const customSigningKey = useWatch({ control, name: 'custom_signing_key' });

  return (
    <Flex direction='column' w='100%' {...rest}>
      <HStack>
        <VStack alignItems='baseline' spacing='2'>
          <Text as='label' htmlFor='custom_signing_key' textStyle='md-medium'>
            Custom signing key
          </Text>

          <Text textStyle='sm-medium' fontWeight='normal' color='gray.500'>
            Enable if using a third-party authentication service that requires
            custom signing keys
          </Text>

          <InformationBox
            icon={InformationCircleIcon}
            textStyle='sm-normal'
            color='gray.500'
            bgColor='inherit'
            px='0'
            py='0'
          >
            <Box>
              If disabled, the default private key of your instance will be
              used.
            </Box>
          </InformationBox>
        </VStack>

        <FormControl flex='1' alignSelf='baseline'>
          <Switch
            id='custom_signing_key'
            isChecked={customSigningKey}
            isDisabled={isSubmitting}
            {...field}
          />
        </FormControl>
      </HStack>
    </Flex>
  );
}
