export const exampleJsonData = `{
    "example": "data"
}`;

export const isExampleData = (value: string): boolean => {
  try {
    return (
      JSON.stringify(JSON.parse(value)) ===
      JSON.stringify(JSON.parse(exampleJsonData))
    );
  } catch (_) {
    return false;
  }
};

const removeJsonComments = (jsonStr: string) =>
  jsonStr.replace(/\\"|"(?:\\"|[^"])*"|(\/\/.*|\/\*[\s\S]*?\*\/)/g, (m, g) =>
    g ? '' : m,
  );

const removeTrailingCommas = (jsonStr: string) =>
  jsonStr.replace(/,(?!\s*?[{["'\w])/g, '');

const removeJsonFormatting = (jsonStr: string) =>
  JSON.stringify(JSON.parse(jsonStr));

const pipe =
  (...fns) =>
  x =>
    fns.reduce((v, f) => f(v), x);
export const normalizeJson: (json: string) => string = pipe(
  removeJsonComments,
  removeTrailingCommas,
  removeJsonFormatting,
);
