import React from 'react';

import { Flex } from '@chakra-ui/react';

import { Instance, TemplateExtended } from '@types';

import {
  TemplateFullCTAs,
  TemplateRestrictedCTAs,
} from '@components/customization';

interface TemplateCTAProps {
  template: TemplateExtended;
  otherInstances: Instance[];
  onPreview: () => void;
  onCopyPrompt: (Instance) => void;
  onRevertPrompt: () => void;
  onUpgradePrompt: () => void;
  onReset: () => void;
  disabled?: boolean;
}

export function TemplateStickyCTAs({
  template,
  otherInstances,
  onPreview,
  onCopyPrompt,
  onRevertPrompt,
  onUpgradePrompt,
  onReset,
  disabled,
}: TemplateCTAProps): JSX.Element {
  return (
    <>
      <Flex
        shadow='dropShadow3'
        bg='white'
        borderTop='1px solid'
        borderColor='gray.200'
        pos='fixed'
        bottom='0'
        left='0'
        py={4}
        px={{ base: 6, md: 16 }}
        opacity={'1'}
        w='100vw'
        zIndex='overlay'
      >
        <Flex width='100%'>
          {disabled && (
            <TemplateRestrictedCTAs
              onPreview={onPreview}
              onUpgradePrompt={onUpgradePrompt}
            />
          )}

          {!disabled && (
            <TemplateFullCTAs
              template={template}
              otherInstances={otherInstances}
              onPreview={onPreview}
              onCopyPrompt={onCopyPrompt}
              onRevertPrompt={onRevertPrompt}
              onReset={onReset}
            />
          )}
        </Flex>
      </Flex>
    </>
  );
}
