import React from 'react';
import { Button, Code, Text } from '@chakra-ui/react';
import { Modal } from '@components/common';

interface TemplateRevertConfirmationProps {
  slug: string;
  isOpen: boolean;
  onClose: () => void;
  onRevertConfirm: () => void;
}

export const TemplateRevertConfirmationModal = ({
  slug,
  isOpen,
  onClose,
  onRevertConfirm,
}: TemplateRevertConfirmationProps): JSX.Element => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title='Revert template'
      autoFocus={false}
    >
      <Modal.Body>
        <Text>
          Are you sure you want to revert back to the default{' '}
          <Code>{slug}</Code> template? All current local overrides will be
          lost.
        </Text>
      </Modal.Body>

      <Modal.Footer flexDir='row-reverse'>
        <Button onClick={onRevertConfirm}>Revert template</Button>

        <Button onClick={onClose} variant='ghost'>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
