import React, { useRef } from 'react';

import {
  Box,
  Button,
  Flex,
  Icon,
  Table as ChakraTable,
  TableCellProps,
  Tbody,
  Td as ChakraTd,
  Th as ChakraTh,
  Thead,
  Tr,
  Text,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import { TableSortingData, TableSortingDirections } from '@types';

export const Table = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <Box overflowX='auto'>
      <ChakraTable>{children}</ChakraTable>
    </Box>
  );
};

export const Head = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  return <Thead>{children}</Thead>;
};

export const HeadCell = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <ChakraTh borderColor='gray.200' color='gray.500' textStyle='sm-normal'>
      {children}
    </ChakraTh>
  );
};

export const SortableHeadCell = ({
  children,
  enableSorting,
  onSorted,
  column,
  sortingState,
}: {
  children: React.ReactNode;
  enableSorting: boolean;
  onSorted?: (data) => void;
  column: string;
  sortingState: TableSortingData;
}): JSX.Element => {
  const direction = useRef(sortingState?.direction);

  const toggleSorting = () => {
    const { ASC, DESC } = TableSortingDirections;
    const isSortingByDifferentColumn = column !== sortingState?.column;

    const nextDirection =
      direction.current === DESC
        ? isSortingByDifferentColumn
          ? DESC
          : ASC
        : DESC;

    direction.current = nextDirection;

    return onSorted({
      column,
      direction: nextDirection,
    });
  };

  const getSortingIconColour = direction => {
    if (
      sortingState?.column === column &&
      direction === sortingState?.direction
    ) {
      return 'gray.600';
    }

    return 'gray.300';
  };

  return (
    <ChakraTh
      position='relative'
      borderColor='gray.200'
      color='gray.500'
      textStyle='sm-normal'
    >
      {children}
      {enableSorting && (
        <Button
          position='absolute'
          bg='none'
          _hover={{ bg: 'none' }}
          _active={{ bg: 'none' }}
          _focus={{ bg: 'none' }}
          onClick={toggleSorting}
          py={0}
          px={2}
          height='auto'
          minWidth='auto'
          my={-3}
          ml='2'
          cursor='pointer'
        >
          <Flex direction='column' display='inline-flex'>
            <Icon
              as={ChevronUpIcon}
              boxSize={3}
              color={getSortingIconColour(TableSortingDirections.ASC)}
            />
            <Icon
              as={ChevronDownIcon}
              boxSize={3}
              color={getSortingIconColour(TableSortingDirections.DESC)}
            />
          </Flex>
        </Button>
      )}
    </ChakraTh>
  );
};

export const EmptyHeadCell = (): JSX.Element => {
  return (
    <ChakraTh borderColor='gray.200' color='gray.500' textStyle='sm-normal' />
  );
};

export const Body = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  return <Tbody>{children}</Tbody>;
};

export const Row = ({
  children,
  onClick,
  isDisabled,
  ...rest
}: {
  onClick?: () => void;
  children: React.ReactNode;
  isDisabled?: boolean;
}): JSX.Element => {
  return (
    <Tr
      cursor={isDisabled ? 'default' : 'pointer'}
      overflow='hidden'
      onClick={onClick}
      _hover={!isDisabled && { bg: 'gray.50' }}
      {...rest}
    >
      {children}
    </Tr>
  );
};

type TdProps = {
  children: React.ReactNode;
  isLast: boolean;
} & TableCellProps;

export const Cell = ({ children, isLast, ...rest }: TdProps): JSX.Element => {
  const borderStyle = isLast ? { borderColor: 'gray.200' } : { border: 'none' };

  return (
    <ChakraTd {...borderStyle} textStyle='sm-normal' color='gray.500' {...rest}>
      {children}
    </ChakraTd>
  );
};

export const EmptyTable = ({
  message,
  placeholder,
}: {
  message: string;
  placeholder?: React.ReactNode;
}): JSX.Element => {
  return (
    <>
      <Row>
        <Cell colSpan={4} borderBottomWidth={1} isLast={true}>
          <Text textStyle='sm-normal' textAlign='center' color='blackAlpha.700'>
            {message}
          </Text>
        </Cell>
      </Row>
      <Row isDisabled={true}>
        <Cell colSpan={4} borderBottomWidth={1} isLast={false}>
          {placeholder}
        </Cell>
      </Row>
    </>
  );
};
