import React from 'react';
import { Line as LineChart } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from 'chart.js';

Chart.register(
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Legend,
  Title,
  Tooltip,
  SubTitle,
);

const options: any = {
  responsive: true,
  scales: {
    y: {
      display: false,
    },
    x: {
      display: false,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      mode: 'index',
      intersect: false,
    },
    datalabels: {
      formatter: function (value, context) {
        const isFirstPoint = context.dataIndex === 0;
        const isLastPoint =
          context.dataset.data.length === context.dataIndex + 1;
        if (isFirstPoint || isLastPoint) {
          return value;
        }
        return null;
      },
      color: 'gray.500',
      align: function (context) {
        return context.dataIndex === 0 ? 'bottom' : 'top';
      },
      anchor: 'center',
      clamp: true,
      offset: 4,
      clip: false,
      font: {
        size: '12',
        weight: '500',
      },
    },
  },
  hover: {
    mode: 'nearest',
    intersect: true,
  },
  elements: {
    point: {
      radius: 1,
    },
  },
  pointHoverRadius: 5,
  layout: {
    padding: { top: 25, bottom: 20, left: 4, right: 4 },
  },
};

const VerticalLinePlugin: any = {
  afterDraw: function (chart) {
    if (chart.tooltip._active && chart.tooltip._active.length) {
      const activePoint = chart.tooltip._active[0];
      const {
        element: { x },
      } = activePoint;

      const ctx = chart.ctx;
      ctx.beginPath();
      ctx.lineWidth = 1;
      ctx.strokeStyle = '#a9a9a9';
      ctx.setLineDash([4]);
      ctx.moveTo(x, 0);
      ctx.lineTo(x, 10000);
      ctx.stroke();
    }
  },
};

export type GraphDataset = {
  label: string;
  data: string[];
  fill: boolean;
  backgroundColor: string;
  borderColor: string;
};

export type ActiveUsersGraphProps = {
  labels: string[];
  datasets: GraphDataset[];
};

export const ActiveUsersGraph = React.memo((props: ActiveUsersGraphProps) => {
  return (
    <LineChart
      data={props}
      options={options}
      plugins={[VerticalLinePlugin, ChartDataLabels]}
    />
  );
});

ActiveUsersGraph.displayName = 'ActiveUsersGraph';
