import React from 'react';
import { Control, Form, Input } from '@clerk-ui/components';
import { useDashboardCRUD } from '@hooks/useDashboardAPI';
import type { HTTPError } from '@utils/errors';
import type { Application } from '@types';

const defaultState = {
  name: '',
  color: '#6c47ff',
  authentication_strategy: 'passwordless_v2',
  identifiers: ['email_address'],
};

export type AppFormProps = {
  handleSubmit: (application: Application) => void;
  handleReset?: () => void;
  submitButtonLabel?: string;
  resetButtonLabel?: string;
  buttonGroupToTheRight?: boolean;
  initialState?: Partial<Application>;
  applicationName?: string;
};

export function AppForm({
  handleSubmit,
  handleReset,
  submitButtonLabel = 'Create',
  resetButtonLabel = 'Cancel',
  buttonGroupToTheRight,
  applicationName = '',
}: AppFormProps): JSX.Element {
  const [error, setError] = React.useState('');
  const [settings, setSettings] = React.useState<Partial<Application>>({
    ...defaultState,
    name: applicationName,
  });
  const { create } = useDashboardCRUD();

  React.useEffect(() => {
    setSettings({
      ...settings,
      name: applicationName,
    });
  }, [applicationName]);

  const handleChange = React.useCallback(
    ({ name, value }) => {
      setSettings({
        ...settings,
        [name]: value,
      });
    },
    [settings, setSettings],
  );

  const handleFormSubmit = async () => {
    try {
      setError('');

      const newApplication = (await create(
        '/v1/applications',
        settings,
      )) as Application;

      if (typeof handleSubmit === 'function') {
        handleSubmit(newApplication);
      }
    } catch (err) {
      const error = err as HTTPError;
      setError(error.getFieldErrorMessageByName('name'));
    }
  };

  return (
    <Form
      handleSubmit={handleFormSubmit}
      handleReset={handleReset}
      submitButtonLabel={submitButtonLabel}
      resetButtonLabel={resetButtonLabel}
      buttonGroupToTheRight={buttonGroupToTheRight}
    >
      <div>
        <Control error={error} htmlFor='name' label='Application name'>
          <Input
            id='name'
            name='name'
            type='text'
            value={settings.name}
            handleChange={handleChange}
            autoFocus
          />
        </Control>
      </div>
    </Form>
  );
}
