import React from 'react';
import { Flex, Switch, Text, VStack } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { OAuthProviderSettings } from '@types';

export type AuthenticatableSwitchFieldProps = {
  oauthProviderSettings: OAuthProviderSettings;
  isDisabled: boolean;
};

export const AuthenticatableSwitchField = ({
  oauthProviderSettings,
  isDisabled,
}: AuthenticatableSwitchFieldProps): JSX.Element => {
  const { control, register } = useFormContext();

  const name = 'authenticatable';
  const defaultAuthenticatable = oauthProviderSettings.enabled
    ? oauthProviderSettings.authenticatable
    : true;
  const authenticatableField = register(name);
  const authenticatable = useWatch({
    control,
    name: 'authenticatable',
    defaultValue: defaultAuthenticatable,
  });

  return (
    <VStack spacing='1' w='full'>
      <Flex w='full' direction='row' justify='space-between'>
        <Text as='label' htmlFor={name} textStyle='lg-medium' display='block'>
          Enable for sign-up and sign-in
        </Text>
        <Switch
          id='authenticatable'
          isChecked={authenticatable}
          isDisabled={isDisabled}
          {...authenticatableField}
        />
      </Flex>
      <Text color='gray.500' textStyle='md-normal' w='full'>
        Allow users to sign up and sign in to your application using this Social
        Connection, otherwise users will be able to connect their Social profile
        post authentication.
      </Text>
    </VStack>
  );
};
