export const Tabs = {
  parts: ['tablist', 'tab', 'tabpanel'],
  baseStyle: {
    tab: {
      paddingInlineStart: '0',
      paddingInlineEnd: '0',
      pt: '0 !important',
      padding: '0',
      pb: '2',
      mr: '8',
    },
    tabpanel: {
      p: '0',
      pt: '12',
    },
  },
  variants: {
    line: {
      tablist: {
        borderBottomWidth: '1px',
        borderColor: 'gray.200',
      },
      tab: {
        color: 'gray.500',
        fontSize: '0.875rem', // font-sizes dont work
        textStyle: 'md-medium',
        mb: '-1px', // Aligns tabs' border-bottom with container's border-bottom
        _selected: {
          color: 'black',
          borderBottomWidth: '1px',
        },
        _active: {
          bg: 'unset',
        },
        _focus: {
          boxShadow: 'none',
        },
      },
    },
  },
};
