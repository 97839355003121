import React from 'react';
import { Notification, NotificationFlavors } from '@clerk-ui/components';

export interface NotificationData {
  flavor?: NotificationFlavors;
  message: string;
  title: string;
  duration?: number;
}

export type NotificationDispatcher = (notification: NotificationData) => void;

export interface NotificationsDispatchContext {
  showNotification: NotificationDispatcher;
  clearNotification: () => void;
}

const NotificationStateContext = React.createContext<NotificationData>(null);
const NotificationDispatchContext =
  React.createContext<NotificationsDispatchContext>(null);

function NotificationsProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [notification, setNotification] =
    React.useState<NotificationData>(null);

  const dispatchValue: NotificationsDispatchContext = React.useMemo(() => {
    return {
      showNotification: notification => setNotification(notification),
      clearNotification: () => setNotification(null),
    };
  }, [notification]);

  React.useEffect(() => {
    let timer;
    if (notification?.duration > 0) {
      timer = setTimeout(() => {
        setNotification(null);
      }, notification?.duration);
    }
    return () => clearTimeout(timer);
  }, [notification]);

  const handleCloseNotificationClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setNotification(null);
  };

  return (
    <NotificationStateContext.Provider value={notification}>
      <NotificationDispatchContext.Provider value={dispatchValue}>
        {notification && (
          <Notification
            title={notification.title}
            message={notification.message}
            flavor={notification.flavor}
            handleCloseClick={handleCloseNotificationClick}
          />
        )}
        {children}
      </NotificationDispatchContext.Provider>
    </NotificationStateContext.Provider>
  );
}

const showErrorNotification =
  (notificationDispatcher: NotificationDispatcher) =>
  (message?: string, longMessage?: string): void => {
    notificationDispatcher?.({
      duration: 5000,
      flavor: 'error',
      title: 'Oops, something went wrong...',
      message: longMessage || message || 'Please try again.',
    });
  };

const showSuccessNotification =
  (notificationDispatcher: NotificationDispatcher) =>
  (message = 'Action saved'): void => {
    notificationDispatcher?.({
      duration: 3500,
      flavor: 'success',
      title: 'Success!',
      message: message,
    });
  };

function useNotificationsDispatch(): NotificationsDispatchContext & {
  showSuccessNotification: (message?: string) => void;
  showErrorNotification: (message?: string, longMessage?: string) => void;
} {
  const context = React.useContext(NotificationDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useNotificationDispatch must be used within a NotificationsProvider',
    );
  }
  const dispatcher = context?.showNotification;
  return {
    ...context,
    showErrorNotification: showErrorNotification(dispatcher),
    showSuccessNotification: showSuccessNotification(dispatcher),
  };
}

export {
  NotificationsProvider,
  useNotificationsDispatch,
  showErrorNotification,
  showSuccessNotification,
};
