import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  CardDualPanel,
  ColorInput,
  VerticalInputBox,
} from '@components/common';
import { FontPicker } from './FontPicker';
import { Stack, FormControl, FormErrorMessage } from '@chakra-ui/react';

export function Font(): JSX.Element {
  const {
    formState: { isSubmitting, errors },
    control,
  } = useFormContext();

  return (
    <CardDualPanel
      title='Font'
      subtitle='Configure font settings for your Clerk Hosted Components'
      disabled={isSubmitting}
    >
      <Stack spacing='6'>
        <VerticalInputBox label='Font' subLabel='Set the body font'>
          <FormControl isInvalid={!!errors?.general?.font_family}>
            <Controller
              control={control}
              name='general.font_family'
              render={({ field: { onChange, value, name } }) =>
                value ? (
                  <FontPicker name={name} value={value} onChange={onChange} />
                ) : null
              }
            />
            <FormErrorMessage>
              {errors?.general?.color?.font_family}
            </FormErrorMessage>
          </FormControl>
        </VerticalInputBox>
        <VerticalInputBox label='Font color' subLabel='Set the body font color'>
          <FormControl isInvalid={!!errors?.general?.font_color}>
            <Controller
              control={control}
              name='general.font_color'
              defaultValue='#000000'
              render={({ field: { onChange, value, name } }) => (
                <ColorInput name={name} value={value} onChange={onChange} />
              )}
            />
            <FormErrorMessage>
              {errors?.general?.font_color?.message}
            </FormErrorMessage>
          </FormControl>
        </VerticalInputBox>
      </Stack>
    </CardDualPanel>
  );
}
