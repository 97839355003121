import { AttributeSettings, OauthProviders, PasswordSettings } from '@types';

export const handlePasswordSettingsPayload = (
  formData: AttributeSettings & OauthProviders & PasswordSettings,
): any => {
  if (!formData?.password_settings) {
    return;
  }

  if (!formData.password_settings.show_zxcvbn) {
    return {
      ...formData.password_settings,
      disable_hibp: !formData.password_settings.disable_hibp,
      min_zxcvbn_strength: 0,
    };
  } else {
    return {
      ...formData.password_settings,
      disable_hibp: !formData.password_settings.disable_hibp,
    };
  }
};
