import React from 'react';
import { useForm } from 'react-hook-form';
import {
  useDashboardCRUD,
  useDashboardSWR,
  useLocation,
  useToast,
} from '@hooks';
import { CardDualPanel, InputBox, PageLoader } from '@components/common';
import { isValidURL, preventSubmitOnEnter } from '@utils';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightAddon,
  Tag,
  TagCloseButton,
  TagLabel,
  VStack,
} from '@chakra-ui/react';

const DEFAULT_VALUES = {
  redirect_urls: [],
};

const FORM_ID = 'social_login_settings';

export function SocialConnectionSettings(): JSX.Element {
  const [input, setInput] = React.useState('');
  const { instanceId } = useLocation();

  const redirectURLPath = `/v1/instances/${instanceId}/redirect_urls`;

  const { data: settings, mutate } = useDashboardSWR(redirectURLPath);
  const { del, create } = useDashboardCRUD();
  const { showSuccessToast, showErrorToast } = useToast();
  const isLoading = !settings;
  const {
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: DEFAULT_VALUES,
    mode: 'onChange',
  });

  React.useEffect(() => {
    reset(settings);
  }, [settings]);

  if (isLoading) {
    return <PageLoader />;
  }

  const updateApp = async () => {
    if (!isValidURL(input)) {
      return setError('redirect_urls', {
        type: 'manual',
        message: 'Invalid url',
      });
    }

    try {
      await create(redirectURLPath, { url: input });
      void mutate();
      showSuccessToast('Redirect URL was added successfully.');
    } catch (_) {
      showErrorToast('Something went wrong, please try again.');
    } finally {
      setInput('');
      clearErrors('redirect_urls');
    }
  };

  const onRemoveItem = async (id: string) => {
    try {
      await del(`${redirectURLPath}/${id}`);
      void mutate();
      showSuccessToast('Redirect URL deleted successfully.');
    } catch (_) {
      showErrorToast('Something went wrong, please try again.');
    }
  };

  return (
    <CardDualPanel
      title='Whitelist for mobile OAuth redirect'
      subtitle='Whitelist your redirect URLs for maximum security during native OAuth flows.'
      docLink={{
        subject: 'OAuth for Native applications',
        url: 'https://clerk.com/docs/authentication/social-connections-oauth#o-auth-for-native-application',
      }}
    >
      <form
        id={FORM_ID}
        onSubmit={handleSubmit(updateApp)}
        onKeyDown={preventSubmitOnEnter}
      >
        <FormControl isInvalid={!!errors.redirect_urls}>
          <Box>
            <InputBox label='Redirect URLs' p={0}>
              <InputGroup
                variant='addonAction'
                borderWidth='1px'
                borderRadius='md'
              >
                <Input
                  autoFocus
                  type='text'
                  name='redirect_urls'
                  value={input}
                  placeholder='https://example.com/oauth-callback'
                  autoComplete='off'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (!e.target.value.length) {
                      clearErrors('redirect_urls');
                    }
                    setInput(e.target.value);
                  }}
                  onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                    if (e.key === 'Enter') {
                      void updateApp();
                    }
                  }}
                />
                <InputRightAddon>
                  <Button
                    variant='link'
                    onClick={updateApp}
                    isDisabled={!input.length}
                  >
                    Add
                  </Button>
                </InputRightAddon>
              </InputGroup>
            </InputBox>

            <FormErrorMessage>
              {(errors?.redirect_urls as any)?.message}
            </FormErrorMessage>

            <VStack mt={4} gap={1} align='flex-start'>
              {settings &&
                settings.map(tag => (
                  <Tag key={tag.id} colorScheme='gray'>
                    <TagLabel>{tag.url}</TagLabel>
                    <TagCloseButton
                      onClick={() => onRemoveItem(tag.id)}
                      _disabled={{
                        cursor: 'not-allowed',
                      }}
                    />
                  </Tag>
                ))}
            </VStack>
          </Box>
        </FormControl>
      </form>
    </CardDualPanel>
  );
}
