import { Checkbox, CheckboxProps } from './Checkbox';
import { useController, useFormContext } from 'react-hook-form';
import React from 'react';

export const FormCheckbox = ({
  name,
  label,
  subtitleLink,
  description,
  isSupportedFeature = true,
  unsupportedFeatureAction,
  ...rest
}: Omit<CheckboxProps, 'isChecked' | 'onChange'> & {
  control?: any;
  isSupportedFeature?: boolean;
  unsupportedFeatureAction?: () => void;
}): JSX.Element => {
  const { control } = useFormContext();
  const { field } = useController({
    name: name,
    control,
  });

  const isPremiumFeature = rest?.showTag || false;
  const shouldTriggerUnsupportedFeatureAction =
    !field.value && (!isSupportedFeature || isPremiumFeature);

  const onChangeSupported = e => {
    if (shouldTriggerUnsupportedFeatureAction) {
      unsupportedFeatureAction?.();
    }

    if (isSupportedFeature) {
      return field.onChange(e.target.checked);
    }
  };

  return (
    <Checkbox
      isChecked={field.value}
      name={name}
      label={label}
      subtitleLink={subtitleLink}
      onChange={onChangeSupported}
      description={description}
      {...rest}
    />
  );
};
