import React from 'react';
import { Box } from '@chakra-ui/react';

import BreadCrumbs, { BreadCrumbProps } from './BreadCrumbs';
import Header, { HeaderProps } from './PageHeader';

type PageContainerProps = {
  PageHeader?: React.ComponentType<HeaderProps>;
  marginBottom?: string;
  children: React.ReactNode;
} & HeaderProps &
  BreadCrumbProps;

export function PageContainer({
  slug,
  title,
  icon,
  description,
  marginBottom = '12',
  children,
  PageHeader = Header,
  avatar,
}: PageContainerProps): JSX.Element {
  const hideTitleAndBreadcrumb = !slug && !title && !icon;

  return (
    <Box pt={!hideTitleAndBreadcrumb && 2.5} pb={14}>
      {!hideTitleAndBreadcrumb && (
        <BreadCrumbs slug={slug} fallbackTitle={title} icon={icon} />
      )}

      <PageHeader avatar={avatar} title={title} description={description} />

      <Box mt={!hideTitleAndBreadcrumb && marginBottom}>{children}</Box>
    </Box>
  );
}
