import { ThemeComponents } from '@chakra-ui/react';
import { Accordion } from './accordion';
import { Badge } from './badge';
import { Button } from './button';
import { Checkbox } from './checkbox';
import { Code } from './code';
import { Divider } from './divider';
import { Heading } from './heading';
import { Input } from './input';
import { Link } from './link';
import { Menu } from './menu';
import { Modal } from './modal';
import { Radio } from './radio';
import { Spinner } from './spinner';
import { Switch } from './switch';
import { Select } from './select';
import { Table } from './table';
import { Tabs } from './tabs';
import { Textarea } from './textarea';
import { Tooltip } from './tooltip';
import { Popover } from './popover';
import { FormError } from './formError';
import { Text } from './text';

const components: ThemeComponents = {
  Accordion,
  Badge,
  Button,
  Checkbox,
  Code,
  Divider,
  Heading,
  Input,
  Link,
  Menu,
  Modal,
  Radio,
  Spinner,
  Switch,
  Select,
  Table,
  Tabs,
  Textarea,
  Tooltip,
  Popover,
  FormError,
  Text,
};

export default components;
