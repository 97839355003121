import React from 'react';
import {
  Flex,
  Text,
  IconButton,
  Icon as ChakraIcon,
  useClipboard,
  IconButtonProps,
  Icon,
  Tooltip,
  Center,
  Spinner,
} from '@chakra-ui/react';

import {
  ClipboardCheckIcon,
  ClipboardIcon,
  EyeIcon,
  EyeOffIcon,
  TrashIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/solid';

const ActionButton = ({
  Icon,
  onClick,
  description,

  ...rest
}: {
  Icon: (props: React.ComponentProps<'svg'>) => JSX.Element;
  onClick: () => void;
  description: string;
} & IconButtonProps) => {
  return (
    <Tooltip hasArrow label={description} placement='top'>
      <IconButton
        icon={<ChakraIcon as={Icon} boxSize={5} />}
        onClick={onClick}
        variant='ghost'
        color='white'
        h='auto'
        minW='none'
        bg='none'
        _focus={{ bg: 'none' }}
        _hover={{ bg: 'none' }}
        {...rest}
      />
    </Tooltip>
  );
};

export type APIKeyPanelProps = {
  id: string;
  title: string;
  keyValue: string;
  isSensitive?: boolean;
  description?: string;
  isLoading?: boolean;
  onDelete?: () => void;
  onVisibilityChange?: () => void;
};

export const APIKeyPanel = (apiKey: APIKeyPanelProps): JSX.Element => {
  const { onCopy, hasCopied } = useClipboard(apiKey.keyValue);
  const [isKeyHidden, setIsKeyHidden] = React.useState(apiKey.isSensitive);

  const onToggle = () => {
    setIsKeyHidden(state => !state);
    apiKey?.onVisibilityChange();
  };

  if (apiKey.isLoading) {
    return (
      <Center height={14} maxWidth='358px' alignSelf='center'>
        <Spinner size='md' />
      </Center>
    );
  }

  return (
    <Flex
      overflow='hidden'
      gap={2}
      width='100%'
      p={4}
      backgroundColor='gray.900'
      borderTopLeftRadius='md'
      borderTopRightRadius='md'
      borderBottomLeftRadius='md'
      borderBottomRightRadius='md'
      flexDirection='column'
    >
      <Flex justifyContent='space-between' width='100%'>
        <Flex>
          <Text
            overflow='hidden'
            whiteSpace='nowrap'
            textOverflow='ellipsis'
            textTransform='capitalize'
            color='white'
            textStyle='md-medium'
          >
            {apiKey.title}
          </Text>
          {apiKey.description && (
            <Tooltip label={apiKey.description} placement='bottom'>
              <Icon
                ml={2}
                as={QuestionMarkCircleIcon}
                boxSize={5}
                color='rgba(255, 255, 255, 0.8)'
              />
            </Tooltip>
          )}
        </Flex>

        <Flex ml='auto' gap={1}>
          {apiKey.isSensitive && (
            <ActionButton
              description='Show'
              aria-label='Show Key'
              Icon={isKeyHidden ? EyeIcon : EyeOffIcon}
              onClick={onToggle}
            />
          )}

          <ActionButton
            description='Copy'
            aria-label='Copy Key'
            Icon={hasCopied ? ClipboardCheckIcon : ClipboardIcon}
            onClick={onCopy}
          />

          {apiKey.onDelete && (
            <ActionButton
              description='Delete'
              aria-label='Delete Key'
              Icon={TrashIcon}
              onClick={apiKey.onDelete}
            />
          )}
        </Flex>
      </Flex>
      <Flex position='relative'>
        <Text
          aria-label={isKeyHidden ? 'secret blur overlay' : 'secret revealed'}
          as='pre'
          p={0}
          color='white'
          textStyle='md-normal'
          whiteSpace='pre-wrap'
          sx={{
            ...(isKeyHidden
              ? { textShadow: '0 0 6px white', color: 'transparent' }
              : {}),
          }}
        >
          {apiKey.keyValue}
        </Text>
      </Flex>
    </Flex>
  );
};
