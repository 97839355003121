import React from 'react';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';

import { useRouter } from 'next/router';

import { ProfileTab } from './ProfileTab';
import { DangerTab } from './DangerTab';
import { MembersTab } from './Members';

import { useLocation, useFeatureFlags } from '@hooks';

const makeVisibleTabs = (isMemberTabVisible: boolean) => ({
  profile: <ProfileTab />,
  ...(isMemberTabVisible && { members: <MembersTab /> }),
  danger: <DangerTab />,
});

const buildTabs = (isMemberTabVisible: boolean) => {
  const visibleTabs = makeVisibleTabs(isMemberTabVisible);
  const tabNames = Object.keys(visibleTabs);
  const tabComponents = Object.values(visibleTabs);
  return { tabNames, tabComponents };
};

export const OrganizationDetailsTabs = (): JSX.Element => {
  const router = useRouter();
  const { query } = useLocation();
  const { allow_members_tab } = useFeatureFlags();

  const { tabNames, tabComponents } = buildTabs(allow_members_tab);

  const currentTab = query.tab as string;
  const tabIndex = Math.max(tabNames.indexOf(currentTab), 0);

  const handleTabChange = (i: number) => {
    void router.replace(
      { query: { ...router.query, tab: tabNames[i] } },
      undefined,
      { scroll: false },
    );
  };

  return (
    <Tabs index={tabIndex} onChange={handleTabChange}>
      <TabList>
        {tabNames.map(name => (
          <Tab textTransform='capitalize' key={name}>
            {name}
          </Tab>
        ))}
      </TabList>

      <TabPanels>
        {tabComponents.map(comp => (
          <TabPanel pt={8} key={comp.type}>
            {comp}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export default OrganizationDetailsTabs;
