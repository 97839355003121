export enum InstanceKeyObject {
  InstanceKey = 'instance_key',
  PublicKey = 'public_key',
  PublicKeyPEM = 'public_key_pem',
  FapiKey = 'fapi_key',
  SecretKey = 'secret_key',
}

export type InstanceKey = {
  id: string;
  instance_id: string;
  name: string;
  object: InstanceKeyObject;
  secret: string;
  created_at: number;
  updated_at: number;
};
