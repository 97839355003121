import React from 'react';

/** It returns the current value if the given condition is truthy, and
 *  returns the last stored value if the value is falsy.
 *  The condition controls whether the new value will be stored as the previous one,
 *  and by default it is pass through, meaning that any new value will be stores as previous.
 *
 * Reference: https://usehooks.com/usePrevious/
 */

const passThrough = () => true;

export function usePreviousWhenFalsy<T>(
  value: T,
  allowChangeCondition: (x: any) => boolean = passThrough,
): T {
  const ref: any = React.useRef<T>(value);

  React.useEffect(() => {
    if (allowChangeCondition(value)) {
      ref.current = value;
    }
  }, [value]);

  if (value) {
    return value;
  }

  return ref.current;
}
