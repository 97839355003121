import React from 'react';
import { Flex, Icon, Switch, Text, Badge } from '@chakra-ui/react';
import { MailIcon, PhoneIcon, UserCircleIcon } from '@heroicons/react/solid';
import { useFormContext } from 'react-hook-form';
import { FORM_FIELDS_V2 } from './AddApplicationFormV2';
import { isFeatureSupported } from '@utils/billing';
import { SUPPORTED_FEATURES } from '@constants';

export function Identifiers({
  supportedFeatures,
}: {
  supportedFeatures: string[];
}): JSX.Element {
  const { register, getValues } = useFormContext();

  const isPhoneNumberSupported = isFeatureSupported(
    supportedFeatures,
    SUPPORTED_FEATURES.phone_code,
  );

  return (
    <Flex gap={1} flexDirection='column'>
      <Flex
        borderRadius='lg'
        p={4}
        key='email_address'
        align='center'
        justify='space-between'
        bg={
          getValues()['identifiers']['email_address']
            ? 'gray.50'
            : 'transparent'
        }
      >
        <Flex justifyContent='center' alignItems='center' gap={3}>
          <Icon
            as={MailIcon}
            color='gray.300'
            position='relative'
            boxSize={5}
          />

          <Text textStyle='md-medium'>Email address</Text>
          <Badge color='primary.500' fontSize='0.625rem' fontWeight='600'>
            Popular
          </Badge>
        </Flex>

        <Switch
          size='md'
          {...register(`${FORM_FIELDS_V2.identifiers}.email_address`)}
        />
      </Flex>

      <Flex
        borderRadius='lg'
        p={4}
        key='phone_number'
        align='center'
        justify='space-between'
        bg={
          getValues()['identifiers']['phone_number'] ? 'gray.50' : 'transparent'
        }
      >
        <Flex gap={3} alignItems='center'>
          <Icon
            as={PhoneIcon}
            color='gray.300'
            position='relative'
            boxSize={5}
          />
          <Text textStyle='md-medium'>Phone number</Text>{' '}
          {!isPhoneNumberSupported && <Badge ml='2'>Premium</Badge>}
        </Flex>

        <Switch
          size='md'
          isDisabled={!isPhoneNumberSupported}
          {...register(`${FORM_FIELDS_V2.identifiers}.phone_number`)}
        />
      </Flex>

      <Flex
        borderRadius='lg'
        p={4}
        key='username'
        align='center'
        justify='space-between'
        bg={getValues()['identifiers']['username'] ? 'gray.50' : 'transparent'}
      >
        <Flex gap={3}>
          <Icon
            as={UserCircleIcon}
            color='gray.300'
            position='relative'
            boxSize={5}
          />

          <Text textStyle='md-medium'>Username</Text>
        </Flex>

        <Switch
          size='md'
          {...register(`${FORM_FIELDS_V2.identifiers}.username`)}
        />
      </Flex>
    </Flex>
  );
}
