import React from 'react';

import { CardDualPanel } from '@components/common';
import { EditableMetadataField } from '@components/users/EditableMetadataField';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid';
import { Text } from '@chakra-ui/react';
import { OrganizationMetadata } from '@types';

type MetadataProps = OrganizationMetadata & {
  update: (
    changes: Partial<
      Record<keyof OrganizationMetadata, Record<string, unknown>>
    >,
  ) => void;
} & {
  hasError: boolean;
  mutate: () => void;
};

const Metadata = ({
  update,
  public_metadata,
  private_metadata,
  hasError,
  mutate,
}: MetadataProps): JSX.Element => {
  return (
    <CardDualPanel
      title='Metadata'
      subtitle='Edit Organization metadata'
      hasError={hasError}
      mutate={mutate}
    >
      <EditableMetadataField
        label='Public'
        value={public_metadata}
        visibilityIcon={EyeIcon}
        visibilityDescription={
          <Text textStyle='md-normal' color='black'>
            Public metadata can be read from Backend API and Frontend API
          </Text>
        }
        saveHandler={md => update({ public_metadata: md })}
      />
      <EditableMetadataField
        label='Private'
        value={private_metadata}
        visibilityIcon={EyeOffIcon}
        visibilityDescription='Private metadata can be read from Backend API only'
        saveHandler={md => update({ private_metadata: md })}
      />
    </CardDualPanel>
  );
};

export default Metadata;
