import React from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightAddon,
  useClipboard,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useDashboardCRUD } from '@hooks/useDashboardAPI';
import { useLocation } from '@hooks';
import type { InstanceKey } from '@types';
import { InformationCircleIcon } from '@heroicons/react/solid';
import { removeAutoComplete } from '@utils';
import { Modal } from '../common';

type CreateInstanceKeyModalProps = {
  isOpen: boolean;
  onClose: ({ revalidateKeys }: { revalidateKeys: boolean }) => void;
};

enum ModalSteps {
  CreateInstanceKey = 'CreateInstanceKey',
  ViewInstanceKey = 'ViewInstanceKey',
}

const modalContent = {
  [ModalSteps.CreateInstanceKey]: {
    closeCta: 'Cancel',
    closeVariant: 'ghost',
  },
  [ModalSteps.ViewInstanceKey]: {
    closeCta: 'Close',
    closeVariant: 'solid',
  },
};

type CreateInstanceKeyForm = {
  keyName: string;
};

const FORM_ID = 'create-instance-key-form';
const INPUT_ID = 'keyName';

export function CreateInstanceKeyModal({
  isOpen,
  onClose,
}: CreateInstanceKeyModalProps): JSX.Element {
  const { instanceId } = useLocation();
  const [modalStep, setModalStep] = React.useState<ModalSteps>(
    ModalSteps.CreateInstanceKey,
  );
  const {
    handleSubmit,
    register,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<CreateInstanceKeyForm>();

  const { create } = useDashboardCRUD<InstanceKey>();
  const [instanceKey, setInstanceKey] = React.useState<InstanceKey>();
  const { onCopy, hasCopied } = useClipboard(instanceKey?.secret);

  const createNewInstanceKey = async ({ keyName }: CreateInstanceKeyForm) => {
    const newInstanceKey = await create(
      `/v1/instances/${instanceId}/instance_keys`,
      { name: keyName },
    );
    setInstanceKey(newInstanceKey);
    setModalStep(ModalSteps.ViewInstanceKey);
  };

  const handleCancel = () => {
    reset();
    setModalStep(ModalSteps.CreateInstanceKey);
    setInstanceKey(undefined);
    onClose({ revalidateKeys: modalStep === ModalSteps.ViewInstanceKey });
  };

  return (
    <Modal isOpen={isOpen} onClose={handleCancel} title='Create secret key'>
      <Modal.Body>
        {modalStep === ModalSteps.CreateInstanceKey ? (
          <form id={FORM_ID} onSubmit={handleSubmit(createNewInstanceKey)}>
            <FormControl
              isInvalid={!!errors[INPUT_ID]}
              {...removeAutoComplete(INPUT_ID)}
            >
              <FormLabel fontSize='sm'>Name</FormLabel>
              <Input
                type='text'
                {...register(INPUT_ID, {
                  required: 'Name is required',
                })}
              />
              <FormErrorMessage>{errors[INPUT_ID]?.message}</FormErrorMessage>
            </FormControl>
          </form>
        ) : (
          <FormControl>
            <FormLabel fontSize='sm'>{instanceKey.name}</FormLabel>
            <InputGroup
              variant='addonAction'
              borderWidth='1px'
              borderRadius='md'
            >
              <Input placeholder={instanceKey.secret} tabIndex={-1} />
              <InputRightAddon>
                <Button w='49px' variant='link' onClick={onCopy}>
                  {hasCopied ? 'Copied' : 'Copy'}
                </Button>
              </InputRightAddon>
            </InputGroup>
            <FormHelperText as={Flex}>
              <Icon as={InformationCircleIcon} mt='1px' mr='1' />
              <Box>
                Set this key as the CLERK_API_KEY environment variable for Clerk
                Backend API SDKs
              </Box>
            </FormHelperText>
          </FormControl>
        )}
      </Modal.Body>
      <Modal.Footer flexDir='row-reverse'>
        {modalStep === ModalSteps.CreateInstanceKey ? (
          <Button type='submit' form={FORM_ID} isLoading={isSubmitting}>
            CONTINUE
          </Button>
        ) : null}
        <Button
          variant={modalContent[modalStep].closeVariant}
          px='6'
          onClick={handleCancel}
        >
          {modalContent[modalStep].closeCta}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
