import React from 'react';
import { useDisclosure } from '@chakra-ui/hooks';
import { Button, IconButton, Text } from '@chakra-ui/react';
import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import { Modal } from '@components/common';

export function DNSProxyWarning(): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <IconButton
        aria-label='proxy warning'
        icon={<QuestionMarkCircleIcon />}
        color='gray.300'
        size='navigationIcons'
        bg='none'
        _focus={{ bg: 'none' }}
        onClick={onOpen}
        boxSize={5}
        colorScheme='gray'
        variant='ghost'
        mt='-3px'
      />
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title='Do not proxy'
        subtitle='Clerk uses a DNS check to validate this CNAME record.'
      >
        <Modal.Body>
          <Text textStyle='md-medium' fontWeight='normal'>
            If this subdomain is reverse proxied behind a service that points to
            generic hostnames, such as Cloudflare, the DNS check will fail.
            Please set the DNS record for this subdomain to a "DNS only" mode on
            your host to prevent proxying.
          </Text>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
