import React from 'react';

import { Button, Icon, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { DotsHorizontalIcon } from '@heroicons/react/solid';

import { OrganizationMembershipFLatUserData } from '@components/organizations/types';

import RemoveMemberAction from './RemoveMemberAction';
import ViewProfileAction from './ViewProfileAction';

//TODO Make this dynamic and generated from an object
//TODO add userRow actions to remove all the logic from here.

type RowActionsProps = {
  member: OrganizationMembershipFLatUserData;
};

const RowActions = ({ member }: RowActionsProps): JSX.Element => {
  const memberForDeletion = member.first_name
    ? `${member.first_name} ${member.last_name}`
    : member.identifier;

  return (
    <Menu placement='left-start'>
      <MenuButton
        aria-label='table action button'
        as={Button}
        variant='link'
        _hover={{
          svg: {
            color: 'primary.500',
          },
        }}
      >
        <Icon as={DotsHorizontalIcon} boxSize={5} color='gray.300' />
      </MenuButton>
      <MenuList>
        <ViewProfileAction id={member.user_id} />
        <RemoveMemberAction
          memberForDeletion={memberForDeletion}
          id={member.user_id}
        />
      </MenuList>
    </Menu>
  );
};

export default React.memo(RowActions);
