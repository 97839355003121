export function isEmptyObj(o: Record<string, unknown>): boolean {
  return !o || Object.keys(o).length === 0;
}

export const removeEmptyValuesFromObject = <T = Record<string, unknown>>(
  obj: T,
): T => {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([, value]) => value !== null && value !== undefined && value !== '',
    ),
  ) as T;
};
