import React from 'react';
import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Td,
  Text,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { DotsHorizontalIcon } from '@heroicons/react/solid';
import type { InstanceKey } from '@types';
import { CopyTextButton } from '../common';
import { formatRel } from '../users';

type TableRowProps = {
  onDelete: (instanceKeyId: string) => void;
  onShowKey: (instanceKeyId: string) => void;
  onHideKey: (instanceKeyId: string) => void;
  onCopyToken: (instanceKeyId: string) => void;
} & InstanceKey;

export const HIDDEN_SECRET_VALUE = '•••';

const isHidden = (secret: string) => secret.startsWith(HIDDEN_SECRET_VALUE);

export function TableRow({
  name,
  id,
  secret,
  onDelete,
  onShowKey,
  onHideKey,
  onCopyToken,
  created_at,
}: TableRowProps): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleShowKey = () => {
    onShowKey(id);
    onOpen();
  };
  const handleHideKey = () => {
    onHideKey(id);
    onClose();
  };
  const handleCopyToken = () => onCopyToken(id);
  const handleDeleteToken = () => onDelete(id);

  return (
    <Tr>
      <Td borderBottom='none'>
        <Text textStyle='sm-medium' noOfLines={1} maxWidth='200px'>
          {name}
        </Text>
      </Td>

      <Td textStyle='sm-normal' borderBottom='none'>
        {isHidden(secret) ? (
          <Button
            variant='link'
            height='unset'
            fontSize='xs'
            onClick={handleShowKey}
            textTransform='none'
          >
            Show token
          </Button>
        ) : (
          <>
            <Text as='span' textStyle='code' m={0} mr='1.5'>
              {secret}
            </Text>
            <CopyTextButton valueToCopy={secret} />
          </>
        )}
      </Td>

      <Td borderBottom='none'>
        <Text textStyle='sm-medium' borderBottom='none'>
          {formatRel(created_at)}
        </Text>
      </Td>

      <Td isNumeric onClick={ev => ev.stopPropagation()} borderBottom='none'>
        <Menu placement='left-start'>
          <MenuButton as={Button} data-testid='menu' variant='link'>
            <Icon as={DotsHorizontalIcon} />
          </MenuButton>
          <MenuList>
            <MenuItem onClick={!isOpen ? handleShowKey : handleHideKey}>
              {!isOpen ? 'Show token' : 'Hide token'}
            </MenuItem>
            <MenuItem onClick={handleCopyToken}>Copy</MenuItem>
            <MenuItem color='danger.500' onClick={handleDeleteToken}>
              Delete
            </MenuItem>
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
}
