import React from 'react';

import {
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  IconButton,
} from '@chakra-ui/react';

import { SearchIcon, XIcon } from '@heroicons/react/solid';
import debounce from 'lodash/debounce';

export type SearchInputProps = {
  onChange: (value: string) => void;
  value: string;
  showIcon?: boolean;
  placeholder?: string;
  isLoading?: boolean;
  autoFocus?: boolean;
};

export type DebouncedSearchInput = {
  delay?: number;
} & SearchInputProps;

export const DebouncedSearchInput = React.forwardRef(
  (
    {
      onChange,
      value,
      delay = 300,
      placeholder = 'Search',
      showIcon = true,
      isLoading,
      autoFocus,
    }: DebouncedSearchInput,
    ref: React.MutableRefObject<HTMLElement>,
  ): JSX.Element => {
    const searchInputRef = React.useRef<HTMLInputElement>();
    const [inputValue, setValue] = React.useState(value || '');
    const handleChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
      onChange(e.target.value);
    }, delay);

    const clear = () => {
      if (searchInputRef) {
        searchInputRef.current.value = '';
        setValue('');
        onChange('');
      }
    };

    return (
      <InputGroup>
        {showIcon && (
          <InputLeftElement aria-label='search icon'>
            <Icon as={SearchIcon} color='gray.300' />
          </InputLeftElement>
        )}
        <Input
          ref={el => {
            searchInputRef.current = el;
            if (ref) {
              ref.current = el;
            }
          }}
          type='text'
          autoFocus={autoFocus}
          aria-label='search input'
          defaultValue={value}
          placeholder={placeholder}
          onChange={handleChange}
        />

        {!isLoading && inputValue && (
          <InputRightElement onClick={clear} aria-label='search input clear'>
            <IconButton
              _active={{
                bg: 'none',
                '& svg': {
                  color: 'primary.500',
                },
              }}
              _focus={{ boxShadow: 'none' }}
              _hover={{
                bg: 'none',
                '& svg': {
                  color: 'gray.400',
                },
              }}
              bg='none'
              color='primary.500'
              aria-label='clear search'
              size='xs'
              icon={<Icon as={XIcon} />}
            />
          </InputRightElement>
        )}

        {isLoading && inputValue && (
          <InputRightElement aria-label='search input loading data'>
            <Spinner size='xs' />
          </InputRightElement>
        )}
      </InputGroup>
    );
  },
);

DebouncedSearchInput.displayName = 'DebouncedSearchInput';

export const SearchInput = React.forwardRef(
  (
    {
      onChange,
      value,
      placeholder = 'Search',
      showIcon = true,
      isLoading,
      autoFocus,
    }: SearchInputProps,
    ref: React.MutableRefObject<HTMLElement>,
  ): JSX.Element => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    };

    const clear = () => {
      onChange('');
    };

    return (
      <InputGroup>
        {showIcon && (
          <InputLeftElement aria-label='search icon'>
            <Icon as={SearchIcon} color='gray.300' />
          </InputLeftElement>
        )}
        <Input
          ref={el => {
            if (ref) {
              ref.current = el;
            }
          }}
          type='text'
          autoFocus={autoFocus}
          aria-label='search input'
          value={value}
          placeholder={placeholder}
          onChange={handleChange}
        />

        {!isLoading && value && (
          <InputRightElement onClick={clear} aria-label='search input clear'>
            <IconButton
              _active={{
                bg: 'none',
                '& svg': {
                  color: 'primary.500',
                },
              }}
              _focus={{ boxShadow: 'none' }}
              _hover={{
                bg: 'none',
                '& svg': {
                  color: 'gray.400',
                },
              }}
              bg='none'
              color='primary.500'
              aria-label='clear search'
              size='xs'
              icon={<Icon as={XIcon} />}
            />
          </InputRightElement>
        )}

        {isLoading && value && (
          <InputRightElement aria-label='search input loading data'>
            <Spinner size='xs' />
          </InputRightElement>
        )}
      </InputGroup>
    );
  },
);

SearchInput.displayName = 'SearchInput';
