import React from 'react';

import NextLink from 'next/link';

import { useLocation } from '@hooks';

import { Link, MenuItem } from '@chakra-ui/react';

type ViewProfileActionProps = { id: string };

const ViewProfileAction = ({ id }: ViewProfileActionProps): JSX.Element => {
  const { query } = useLocation();
  const { applicationId, instanceId } = query;

  return (
    <NextLink
      href={{
        pathname: `/apps/${applicationId}/instances/${instanceId}/users/${id}`,
        query: { userId: id },
      }}
      passHref
    >
      <MenuItem
        as={Link}
        _hover={{
          color: 'currentColor',
          bg: 'gray.50',
        }}
      >
        View profile
      </MenuItem>
    </NextLink>
  );
};

export default ViewProfileAction;
