import React from 'react';
import { AppleExtraOAuthSettings, OAuthProviderSettings } from '@types';
import { ProviderTextInput } from './ProviderTextInput';
import {
  Box,
  Button,
  Input,
  InputGroup,
  InputRightAddon,
  Text,
  useClipboard,
} from '@chakra-ui/react';
import { getModalFields } from './constants';

interface ExtraOauthSettingInputProps {
  oauthProviderSettings: OAuthProviderSettings;
  customProfile: boolean;
}

export function ExtraOauthSettingInputs({
  oauthProviderSettings,
  customProfile,
}: ExtraOauthSettingInputProps): JSX.Element {
  const { strategy } = oauthProviderSettings;

  switch (strategy) {
    case 'oauth_apple':
      return (
        <AppleExtraOauthSettingInputs
          oauthProviderSettings={oauthProviderSettings}
          customProfile={customProfile}
        />
      );
    default:
      return <></>;
  }
}

function AppleExtraOauthSettingInputs({
  oauthProviderSettings,
  customProfile,
}: ExtraOauthSettingInputProps): JSX.Element {
  const appleExtraOAuthSettings =
    oauthProviderSettings.extra_settings as AppleExtraOAuthSettings;
  const appleEmailSource = appleExtraOAuthSettings.apple_email_source;

  const appleEmailSourceClipboard = useClipboard(appleEmailSource);
  const modalFields = getModalFields(oauthProviderSettings.provider);

  if (!customProfile) {
    return <></>;
  }

  return (
    <>
      {['team_id', 'key_id'].map(field => (
        <ProviderTextInput
          key={field}
          oauthProviderSettings={oauthProviderSettings}
          field={field}
          isExtraSetting={true}
          customProfile={customProfile}
        />
      ))}

      <Box>
        <Text textStyle='md-medium' mb='0.5'>
          {modalFields.apple_email_source.label}
        </Text>

        <Text textStyle='sm-normal' mb='2'>
          {modalFields.apple_email_source.description}
        </Text>

        <InputGroup variant='addonAction' borderWidth='1px' borderRadius='md'>
          <Input
            readOnly
            placeholder={
              appleEmailSource
                ? appleEmailSource
                : 'Available once the instance is deployed'
            }
            tabIndex={-1}
            isDisabled={true}
          />

          <InputRightAddon>
            <Button
              variant='link'
              onClick={appleEmailSourceClipboard.onCopy}
              isDisabled={!appleEmailSource}
            >
              {appleEmailSourceClipboard.hasCopied ? 'Copied' : 'Copy'}
            </Button>
          </InputRightAddon>
        </InputGroup>
      </Box>
    </>
  );
}
