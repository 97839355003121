import React from 'react';
import {
  DropdownV2 as Dropdown,
  DropdownSelection,
} from '@clerk-ui/components/dropdown/DropdownV2';

export const FONT_WEIGHT_OPTIONS = [
  {
    label: '100 / Thin',
    value: '100',
  },
  {
    label: '200 / Extra-light',
    value: '200',
  },
  {
    label: '300 / Light',
    value: '300',
  },
  {
    label: '400 / Regular',
    value: '400',
  },
  {
    label: '500 / Medium',
    value: '500',
  },
  {
    label: '600 / Semi-bold',
    value: '600',
  },
  {
    label: '700 / Bold',
    value: '700',
  },
  {
    label: '800 / Extra-Bold',
    value: '800',
  },
  {
    label: '900 / Black',
    value: '900',
  },
];

type FontWeightPickerProps = {
  name: string;
  value: string;
  onChange: (el: DropdownSelection) => void;
};

export function FontWeightPicker({
  name,
  value,
  onChange,
}: FontWeightPickerProps): JSX.Element {
  const handleChange = selected => {
    onChange(selected.value);
  };

  return (
    <Dropdown
      name={name}
      options={FONT_WEIGHT_OPTIONS}
      selectedOption={value}
      handleChange={handleChange}
    />
  );
}
