import React from 'react';
import { Center, Icon, useBoolean } from '@chakra-ui/react';
import { UploadIcon, PhotographIcon } from '@heroicons/react/solid';

interface ImageUploaderPlaceholderProps {
  size: 'sm' | 'lg';
}

export function ImageUploaderPlaceholder({
  size,
}: ImageUploaderPlaceholderProps): JSX.Element {
  const [isHovering, setIsHovering] = useBoolean(false);
  const containerSize = size === 'sm' ? '32px' : '48px';
  const iconSize = size === 'sm' ? '4' : '6';

  return (
    <Center
      boxSize={containerSize}
      rounded='base'
      bg='gray.100'
      role='group'
      _hover={{
        bg: 'gray.200',
      }}
      onMouseEnter={setIsHovering.on}
      onMouseLeave={setIsHovering.off}
    >
      <Icon
        as={isHovering ? UploadIcon : PhotographIcon}
        color='gray.500'
        boxSize={iconSize}
        _groupHover={{
          color: 'gray.600',
        }}
      />
    </Center>
  );
}
