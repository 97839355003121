import React from 'react';
import { TodosContext } from './TodosContext';
import { TodosProvider } from './TodosProvider';

export const useTodosContext = () => React.useContext(TodosContext);
export * from './TodosProvider';

export function withTodosContext(Component: React.ReactNode): JSX.Element {
  return <TodosProvider>{Component}</TodosProvider>;
}
