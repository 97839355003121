import React from 'react';
import cn from 'classnames';
import { Link } from '../link/Link';
import { Button } from '../button/Button';
import {
  WarningIcon,
  CheckCircleIcon,
  AlertOctagonIcon,
  InfoIcon,
  XIcon,
} from '../../assets/icons';

// @ts-ignore
import styles from './Notification.module.scss';

export type NotificationFlavors = 'info' | 'warning' | 'success' | 'error';

export type NotificationProps = {
  actionLabel?: string;
  className?: string;
  flavor?: NotificationFlavors;
  handleActionClick?: (e: React.MouseEvent) => void;
  handleCloseClick?: (e: React.MouseEvent) => void;
  message: string;
  title: string;
};

export const Notification: React.FC<NotificationProps> = ({
  actionLabel,
  className,
  flavor = 'info',
  handleActionClick,
  handleCloseClick,
  message,
  title,
}) => {
  return (
    <div className={cn(styles.notifications, className)} tabIndex={-1}>
      <div
        aria-modal='true'
        role='dialog'
        tabIndex={-1}
        className={styles.content}
      >
        <h4 className={styles.title}>{title}</h4>
        <p>{message}</p>
        {actionLabel && handleActionClick && (
          <Link onClick={handleActionClick}>{actionLabel}</Link>
        )}
      </div>
      <Button
        flavor='icon'
        // Use onMousedown instead of onClick to prevent closing modals or overlays
        // upon clicking on close notification button.
        // More info at /packages/shared/hooks/useDetectClickOutside.ts#L30
        onMouseDown={handleCloseClick}
        className={styles.closeButton}
      >
        <XIcon />
      </Button>
      <div className={cn(styles.icon, styles[flavor])}>
        {flavor === 'warning' && <WarningIcon />}
        {flavor === 'error' && <AlertOctagonIcon />}
        {flavor === 'success' && <CheckCircleIcon />}
        {flavor === 'info' && <InfoIcon />}
      </div>
    </div>
  );
};
