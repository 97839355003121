import { useUser } from '@clerk/nextjs';

export const useTracking = () => {
  const { user } = useUser();

  const trackEvent = async (event: string) => {
    try {
      await user.update({
        unsafeMetadata: { ...user.unsafeMetadata, [event]: true },
      });
    } catch (_) {
      // All good
    }
  };

  return { trackEvent };
};
