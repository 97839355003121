import {
  dnsTodo,
  installClerkTodo,
  oauthTodo,
  hostedPagesTodo,
  userManagementTodo,
} from './todoDefinitions';
import { Todo, StoredTodos } from './types';
import { isComplete, isDismissed } from './bitUtils';

export const createTodos = (
  instanceId: string,
  applicationId: string,
  environmentType: string,
  storedTodos?: StoredTodos,
): Todo[] => {
  const todos =
    environmentType === 'production'
      ? createProductionTodos(instanceId, applicationId)
      : createNonProductionTodos(instanceId, applicationId);

  return typeof storedTodos === 'undefined'
    ? todos
    : adjustStoredTodos(todos, storedTodos);
};

function adjustStoredTodos(allTodos: Todo[], completedTodos: StoredTodos) {
  return allTodos.map(todo => {
    const storedTodo = completedTodos[todo.id];
    if (storedTodo) {
      todo.completed = isComplete(storedTodo);
      todo.dismissed = isDismissed(storedTodo);
    }
    return todo;
  });
}

export function createNonProductionTodos(
  instanceId: string,
  applicationId: string,
): Todo[] {
  return [
    installClerkTodo,
    hostedPagesTodo(instanceId, applicationId),
    userManagementTodo(instanceId, applicationId),
  ];
}

export function createProductionTodos(
  instanceId: string,
  applicationId: string,
): Todo[] {
  return [
    oauthTodo(instanceId, applicationId),
    dnsTodo(instanceId, applicationId),
  ];
}
