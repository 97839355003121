import React from 'react';

import { useLocation } from '@hooks';

import { useRevolvapp } from '@hooks/useRevolvapp';

import type { TemplateExtended } from '@types';

import { DisplayThemeJSON } from '@clerk/types';

interface EmailEditorProps {
  updateKey: string;
  template: TemplateExtended;
  theme: DisplayThemeJSON;
  hasError: boolean;
  onChange: (markup: string, body: string) => void;
  disabled?: boolean;
}

export function EmailEditor({
  updateKey,
  template,
  theme,
  hasError,
  onChange,
  disabled,
}: EmailEditorProps): JSX.Element {
  const { instanceId } = useLocation();

  const editorId = `${template.slug}-email-editor-${instanceId}`;

  const { updateMarkup } = useRevolvapp(`#${editorId}`, {
    theme,
    variables: template.available_variables,
    markup: template.markup,
    onChange,
    disabled,
  });

  React.useEffect(() => {
    updateMarkup(template.markup);
  }, [template, updateKey]);

  const editorStyle = {
    width: '100%',
    borderWidth: '1px',
    borderColor: hasError ? '#E53E3E' : 'inherit',
    boxShadow: hasError ? '0 0 0 1px #e53e3e' : 'none',
  };

  return <div id={editorId} style={editorStyle}></div>;
}
