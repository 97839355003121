import { useUser } from '@clerk/nextjs';

export const useUnsafeMetadata = () => {
  const { user } = useUser();

  const shouldShowInfoModal = user.unsafeMetadata?.shouldShowInfoModal || [];

  const getShouldShowInfoModalArray = (id: string) => {
    return shouldShowInfoModal.includes(id);
  };

  const removeAppIdFromInfoModalArray = async (appId: string) => {
    const result = shouldShowInfoModal.filter(id => id !== appId);

    await updateUnsafeMetadata('shouldShowInfoModal', result);
  };

  const addAppIdToSeeInfoModal = async (appId: string) => {
    const result = [...shouldShowInfoModal, appId];

    await updateUnsafeMetadata('shouldShowInfoModal', result);
  };

  const updateUnsafeMetadata = async (field: string, value: any) => {
    await user.update({
      unsafeMetadata: {
        ...user.unsafeMetadata,
        [field]: value,
      },
    });
  };

  return {
    shouldShowInfoModal: getShouldShowInfoModalArray,
    removeAppIdFromInfoModalArray,
    addAppIdToSeeInfoModal,
    updateUnsafeMetadata,
  };
};
