import React from 'react';
import { Box, Flex, Image, Switch, Text } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { WEB3_PROVIDERS, Web3ProviderListItem } from './types';
import { svgUrl } from '@utils';
import {
  maxThreeSocialAndWeb3,
  shouldCheckMaxSocialAndWeb3,
} from '@utils/billing';
import { ComingSoonBadge } from '@components/common';
import { useAnalytics } from '@hooks/useAnalytics';

type Web3ProviderListProps = {
  supportedFeatures: string[];
  web3Providers: string[];
};

export function Web3ProviderList({
  supportedFeatures,
  web3Providers,
}: Web3ProviderListProps): JSX.Element {
  return (
    <Box mb={0}>
      {WEB3_PROVIDERS.map(web3Provider => (
        <Web3ProviderToggle
          key={web3Provider.slug}
          supportedFeatures={supportedFeatures}
          web3Provider={web3Provider}
          available={web3Providers.includes(`web3_${web3Provider.slug}`)}
        />
      ))}
    </Box>
  );
}

type Web3ProviderToggleProps = {
  supportedFeatures: string[];
  web3Provider: Web3ProviderListItem;
  available: boolean;
};

const Web3ProviderToggle = function ({
  supportedFeatures,
  web3Provider,
  available,
}: Web3ProviderToggleProps): JSX.Element {
  const { track } = useAnalytics();
  const { register, getValues } = useFormContext();

  const trackToggle = (name: string, checked: boolean) => {
    track('Dashboard_Applications_Application Configuration Toggle Selected', {
      surface: 'Dashboard',
      location: 'Applications',
      clickedOn: name,
      state: checked ? 'On' : 'Off',
    });
  };

  return (
    <Flex
      borderRadius='lg'
      p={4}
      key={web3Provider.slug}
      align='center'
      justify='space-between'
      bg={getValues('web3')[web3Provider.slug] ? 'gray.50' : 'transparent'}
    >
      <Flex>
        <Image src={svgUrl(web3Provider.slug)} mr='4' boxSize='20px' />

        <Text textStyle='md-medium'>{web3Provider.name}</Text>

        {!available && <ComingSoonBadge ml={1} mt={0.25} />}
      </Flex>

      <Switch
        size='md'
        isDisabled={
          shouldCheckMaxSocialAndWeb3(supportedFeatures)
            ? !available ||
              maxThreeSocialAndWeb3('web3', web3Provider.slug, getValues)
            : !available
        }
        {...register(`web3.${web3Provider.slug}` as any)}
        onChange={e => {
          trackToggle(e.target.name, e.target.checked);
          void register(`web3.${web3Provider.slug}` as any).onChange(e);
        }}
      />
    </Flex>
  );
};
