import type { Settings } from '@types';
import { sortedOAuthProviders } from '@clerk/types';
import type { OAuthProviderSettings } from '@types';

import { OAuthProviderSortingArray } from '@types';

export const makeProviders = (
  socialSettings: Settings['social'],
): OAuthProviderSettings[] => {
  if (!socialSettings) {
    return [];
  }

  const socialSettingsKeys = Object.keys(socialSettings);

  return sortedOAuthProviders(OAuthProviderSortingArray)
    .filter(
      oauth_provider => socialSettingsKeys.includes(oauth_provider.strategy), // only show providers in server payload
    )
    .map(oauth_provider => ({
      ...socialSettings[oauth_provider.strategy],
      ...oauth_provider,
    }));
};
