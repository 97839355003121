import React from 'react';
import Link from 'next/link';
import {
  Box,
  Button,
  Link as ChakraLink,
  Stack,
  Tooltip,
} from '@chakra-ui/react';
import { useCanGoLive, useDashboardCRUD, useLocation, useToast } from '@hooks';
import { Statuses } from '@types';
import { sleep } from '@utils';

type DeployButtonProps = {
  deployStatus: Statuses;
};

export function DeployButton({ deployStatus }: DeployButtonProps): JSX.Element {
  const { create } = useDashboardCRUD();
  const { instanceId, applicationId } = useLocation();
  const { canGoLive, dnsRecordsVerified, oauthSetupVerified } = useCanGoLive();
  const { showErrorToast } = useToast();

  const [isDeploying, setIsDeploying] = React.useState(false);
  const [showHomeLink, setShowHomeLink] = React.useState(false);

  const isDeployInProgress =
    deployStatus === Statuses.InProgress || isDeploying;

  const handleDeploy = async () => {
    try {
      setIsDeploying(true);
      await create(`/v1/instances/${instanceId}/go_live`, {});
      await sleep(2000);
      setShowHomeLink(true);
    } catch (_) {
      setIsDeploying(false);
      showErrorToast('Something went wrong, please try again later.');
    }
  };

  let tooltipLabel =
    'Set all DNS records before deploying the SSL certificates for this instance.';

  if (dnsRecordsVerified && !oauthSetupVerified) {
    tooltipLabel =
      'This instance is using Social connection providers. Set all the necessary custom OAuth v2 credentials before deploying the SSL certificates for this instance.';
  }

  return (
    <Stack spacing={3}>
      <Tooltip
        label={tooltipLabel}
        isDisabled={canGoLive || isDeployInProgress}
        placement='top'
      >
        <Box mt={6} w='fit-content'>
          <Button
            isDisabled={!canGoLive || isDeployInProgress}
            isLoading={isDeployInProgress}
            loadingText='Deploying'
            onClick={handleDeploy}
          >
            Deploy certificates
          </Button>
        </Box>
      </Tooltip>

      {showHomeLink && (
        <Link href={`/apps/${applicationId}/instances/${instanceId}`} passHref>
          <ChakraLink color='primary.500' textStyle='sm-medium' w='fit-content'>
            Go to Home to see the deployment status
          </ChakraLink>
        </Link>
      )}
    </Stack>
  );
}
