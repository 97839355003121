import React from 'react';
import { useDashboardSWR, useLocation } from '@hooks';
import { SimpleGrid } from '@chakra-ui/react';
import { Instance, Template } from '@types';
import { TemplateCard } from '@components/customization';
import { PageLoader } from '@components/common';

interface TemplateListProps {
  templateType: string;
}

export function TemplateList({ templateType }: TemplateListProps): JSX.Element {
  const { instanceId } = useLocation();

  // Load templates
  const { data: templates, isValidating: isValidatingTemplate } =
    useDashboardSWR<Template[]>(
      `/v1/instances/${instanceId}/templates/${templateType}`,
    );

  // Load instance
  const instanceReadUrl = `/v1/instances/${instanceId}`;

  const { data: instance, isValidating: isValidatingInstance } =
    useDashboardSWR<Instance>(instanceReadUrl);

  if (!templates || isValidatingTemplate || !instance || isValidatingInstance) {
    return <PageLoader />;
  }

  return (
    <SimpleGrid
      templateColumns='repeat(auto-fill, minmax(280px, 1fr))'
      spacing='8'
    >
      {templates.map(template => {
        return (
          <TemplateCard
            key={template.slug}
            instance={instance}
            template={template}
          />
        );
      })}
    </SimpleGrid>
  );
}
