import React from 'react';
import { Box, Flex, StackProps, Text, Link } from '@chakra-ui/react';
import { CardContainer, Subtitle } from '../CardElements';

type CardDualPanelPropsError = {
  title: string;
  subtitle: string | JSX.Element;
  mutate?: () => any;
} & StackProps;

export function CardDualPanelError({
  title,
  subtitle,
  mutate,
  ...rest
}: CardDualPanelPropsError): JSX.Element {
  return (
    <CardContainer spacing={6} direction='row' {...rest}>
      <Box flex='1'>
        <Flex align='center' justify='flex-start' mb={1}>
          <Text textStyle='lg-medium'>{title}</Text>
        </Flex>
        <Subtitle>{subtitle}</Subtitle>
      </Box>
      <Box flex='1'>
        <Flex align='center' justify='center' h='100%'>
          <Text textStyle='sm-normal' color='gray.500' textAlign='center'>
            The resource could not be loaded.{' '}
            <Link color='primary.500' onClick={() => void mutate()}>
              Refetch resource
            </Link>
          </Text>
        </Flex>
      </Box>
    </CardContainer>
  );
}
