import React from 'react';

import { User } from '@types';

import { Flex, IconButton, Icon } from '@chakra-ui/react';
import UserPublicInfo from '@components/users/Table/UserPublicInfo';

type SelectedUserProps = {
  selectedUser: User;
  clearSelectedUser: () => void;
};
const SelectedUser = ({
  selectedUser,
  clearSelectedUser,
}: SelectedUserProps): JSX.Element => {
  if (!selectedUser) {
    return null;
  }
  return (
    <Flex
      aria-label='selected user'
      width='100%'
      borderRadius={'lg'}
      _hover={{
        bg: 'rgba(0, 0, 0, 0.04)',
        '& #diselect-user': {
          color: 'danger.500',
          ml: 5,
        },
      }}
      cursor='pointer'
      mt={2}
      py={2}
      px={4}
      height={12}
      justifyContent='space-between'
      onClick={clearSelectedUser}
    >
      <UserPublicInfo user={selectedUser} />
      <Flex alignItems='center'>
        <IconButton
          id='diselect-user'
          size='lg'
          _hover={{ bg: 'none', border: 'none' }}
          _active={{ bg: 'none', border: 'none' }}
          _focus={{ bg: 'none', border: 'none' }}
          color='transparent'
          variant='ghost'
          aria-label='diselect-user'
          icon={
            <Icon height={4} width={4}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M6 18L18 6M6 6l12 12'
                />
              </svg>
            </Icon>
          }
        />
      </Flex>
    </Flex>
  );
};

export default SelectedUser;
