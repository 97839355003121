import React from 'react';
import {
  Text,
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalProps as ChakraModalProps,
  ModalCloseButton,
  ModalFooterProps,
  Button,
  Flex,
  ButtonProps,
} from '@chakra-ui/react';

import { RequireAllOrNone } from '@types';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  title?: string | JSX.Element;
  subtitle?: string | JSX.Element;
  children: JSX.Element[] | JSX.Element;
} & ChakraModalProps;

type ActionButtonProps = {
  onLeftButtonClicked?: () => void;
  onRightButtonClicked?: () => void;
  leftButtonMessage: string;
  rightButtonMessage: string;
  isRightButtonLoading?: boolean;
  isLeftButtonLoading?: boolean;
  isRightButtonDisabled?: boolean;
  isLeftButtonDisabled?: boolean;
  rightButtonRestProps?: ButtonProps;
  leftButtonRestProps?: ButtonProps;
};

type ActionFooterProps =
  | RequireAllOrNone<ActionButtonProps, 'leftButtonMessage'>
  | (RequireAllOrNone<ActionButtonProps, 'rightButtonMessage'> &
      Omit<ModalFooterProps, 'children'>);

const ActionFooter = ({
  onLeftButtonClicked,
  onRightButtonClicked,
  leftButtonMessage,
  rightButtonMessage,
  isRightButtonDisabled,
  isLeftButtonDisabled,
  isLeftButtonLoading,
  isRightButtonLoading,
  rightButtonRestProps,
  leftButtonRestProps,
  ...rest
}: ActionFooterProps) => {
  return (
    <ModalFooter {...rest}>
      <Flex justifyContent='space-between' width='100%'>
        {leftButtonMessage && (
          <Button
            autoFocus={false}
            variant='ghost'
            fontSize='xs'
            fontWeight='bold'
            onClick={onLeftButtonClicked}
            disabled={isLeftButtonDisabled}
            isLoading={isLeftButtonLoading}
            {...leftButtonRestProps}
          >
            {leftButtonMessage}
          </Button>
        )}
        {rightButtonMessage && (
          <Button
            isLoading={isRightButtonLoading}
            fontSize='xs'
            fontWeight='bold'
            disabled={isRightButtonDisabled}
            onClick={onRightButtonClicked}
            ml='auto'
            {...rightButtonRestProps}
          >
            {rightButtonMessage}
          </Button>
        )}
      </Flex>
    </ModalFooter>
  );
};

export function Modal({
  isOpen,
  onClose,
  title,
  subtitle,
  children,
  ...rest
}: ModalProps): JSX.Element {
  return (
    <ChakraModal isOpen={isOpen} onClose={onClose} {...rest}>
      <ModalOverlay />
      <ModalContent>
        {title ? (
          <ModalHeader mb='6'>
            {title}
            {subtitle ? (
              <Text textStyle='md-normal' mt='1' color='gray.500'>
                {subtitle}
              </Text>
            ) : null}
          </ModalHeader>
        ) : null}

        {children}
      </ModalContent>
    </ChakraModal>
  );
}

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;
Modal.CloseButton = ModalCloseButton;
Modal.ActionFooter = ActionFooter;
