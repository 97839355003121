import useSWR from 'swr';
import { useSession, useAuth } from '@clerk/nextjs';
import type { ActiveSessionResource } from '@clerk/types';
import { buildURL } from '@utils/api';

export type AvatarConfigBgType = 'marble' | 'solid';
export type AvatarConfigFgType = 'initials' | 'silhouette' | 'none';

export type AvatarConfig = {
  bgType: AvatarConfigBgType;
  bgColor: string[];
  fgType: AvatarConfigFgType;
  fgColor: string;
};

export type AvatarSettings = {
  user: AvatarConfig;
  organization: AvatarConfig;
};

export const useAvatarConfig = (instanceId: string) => {
  const { getToken } = useAuth();
  const { session } = useSession();

  const fetcher = async (url: string): Promise<AvatarSettings> => {
    return getToken()
      .then(token =>
        fetch(url, { headers: { Authorization: `Bearer ${token}` } }),
      )
      .then(res => res.json());
  };

  const key = instanceId ? `/v1/instances/${instanceId}/image_settings` : null;
  const url = buildURL(key, {
    session: session as ActiveSessionResource,
  });
  const { data, mutate, ...rest } = useSWR(url, fetcher);

  const upsertAvatarConfig = ({ arg }: { arg: any }) => {
    return getToken()
      .then(token => {
        return fetch(url, {
          method: 'POST',
          body: JSON.stringify(arg),
          headers: { Authorization: `Bearer ${token}` },
        });
      })
      .then(() => mutate());
  };

  return {
    avatarConfig: data,
    upsertAvatarConfig,
    ...rest,
  };
};
