import React from 'react';
import { Tag, TagCloseButton, TagLabel, VStack, Icon } from '@chakra-ui/react';
import { PhoneIcon, AtSymbolIcon } from '@heroicons/react/solid';
import { Web3Icon } from '@clerk-ui/assets/icons';

export type Identifier = {
  created_at: number;
  id: string;
  identifier: string;
  identifier_type: 'email_address' | 'phone_number' | 'web3_wallet';
  object: string;
  updated_at: number;
};

type IdentifierPillsProps = {
  identifiers: Identifier[];
  onRemoveItem: (string: string) => Promise<void>;
  isDisabled: boolean;
};

const identifierIcons = {
  phone_number: PhoneIcon,
  web3_wallet: Web3Icon,
  email_address: AtSymbolIcon,
};

export function IdentifiersPills({
  identifiers,
  onRemoveItem,
  isDisabled,
}: IdentifierPillsProps): JSX.Element {
  return (
    <VStack mt={4} align='flex-start'>
      {identifiers &&
        identifiers.map(tag => (
          <Tag key={tag.id} colorScheme='gray'>
            <Icon
              as={identifierIcons[tag.identifier_type]}
              color='gray.300'
              mr={1}
              position='relative'
              boxSize={3}
            />
            <TagLabel style={{ lineBreak: 'anywhere' }} noOfLines={2} py={1}>
              {tag.identifier}
            </TagLabel>
            <TagCloseButton
              isDisabled={isDisabled}
              role='button'
              onClick={() => onRemoveItem(tag.id)}
              _disabled={{
                cursor: 'not-allowed',
              }}
            />
          </Tag>
        ))}
    </VStack>
  );
}
