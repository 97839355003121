import { useOrganization } from '@clerk/nextjs';
import {
  useOrganizationSubscription,
  useOrganizationSubscriptionPlans,
} from '@hooks';

export function useCustomOrganizationSwitcherProps(): {
  isLoaded;
  billingUrlHref: string | (() => string);
  billingUrlText: string | (() => string);
  membershipLimit: number;
  key: string;
} {
  const {
    membership,
    isLoaded: isOrganizationLoaded,
    organization,
  } = useOrganization();
  const isAdmin = membership?.role === 'admin';

  const { data: plans } = useOrganizationSubscriptionPlans();
  const { data: organizationSubscription } = useOrganizationSubscription();

  const isLoaded = isOrganizationLoaded;

  if (
    membership &&
    organization &&
    organizationSubscription &&
    plans &&
    isAdmin
  ) {
    const planId = plans.find(p => p.action === 'upgrade')?.id;
    const { is_paid: isPaid, organization_membership_limit: membershipLimit } =
      organizationSubscription;

    const billingUrlHref = () => {
      const url = new URL('/redirect/stripe', window.location.origin);

      url.searchParams.set('org_id', organization.id);
      url.searchParams.set('plan_id', planId);
      url.searchParams.set('is_paid', String(isPaid));
      url.searchParams.set('return_url', window.location.href);

      return url.toString();
    };

    const billingUrlText = () =>
      isPaid ? 'Manage Billing' : 'Upgrade to unlimited members';

    return {
      // this key is needed in order for the organization switcher component to rerender whenever any of the stated parameters
      // has changed. This way we ensure fresh state for any operation that might follow
      key: `${organization.id}-${planId}-${String(isPaid)}`,
      isLoaded,
      billingUrlHref,
      billingUrlText,
      membershipLimit,
    };
  }

  return {
    isLoaded: true,
    key: 'personal-space',
    billingUrlHref: '',
    billingUrlText: '',
    membershipLimit: 0,
  };
}
