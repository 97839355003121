import React from 'react';

import styles from './VercelLogos.module.scss';

export function VercelLogos(): JSX.Element {
  return (
    <div className={styles.logos}>
      <img src='/assets/logos/clerk.svg' alt='Clerk' />
      <span className={styles.plus}>+</span>
      <img src='/assets/logos/vercel.svg' alt='Vercel' />
    </div>
  );
}
