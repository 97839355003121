import React from 'react';

export type DetectClickOutsideResponse = [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
] & {
  isActive: boolean;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useDetectClickOutside = (
  el: React.MutableRefObject<HTMLElement | undefined | null>,
  defaultActive: boolean,
  callback?: () => void,
  nodes?: HTMLElement[] | null,
) => {
  const [isActive, setIsActive] = React.useState(defaultActive);

  React.useEffect(() => {
    const pageClickEvent = (e: MouseEvent) => {
      if (el && el.current && !el.current.contains(e.target as Node)) {
        setIsActive(!isActive);

        if (typeof callback === 'function') {
          callback();
        }
      }
      if (Array.isArray(nodes)) {
        const isOutside = nodes.every(
          node => !!node && !e.composedPath().includes(node),
        );
        isOutside && setIsActive(!isActive);

        if (typeof callback === 'function' && isOutside) {
          callback();
        }
      }
    };

    if (isActive) {
      window.addEventListener('mousedown', pageClickEvent);
    }

    return () => {
      window.removeEventListener('mousedown', pageClickEvent);
    };
  }, [isActive, el, nodes, callback]);

  const result = [isActive, setIsActive] as DetectClickOutsideResponse;

  result.isActive = result[0];
  result.setIsActive = result[1];

  return result;
};
