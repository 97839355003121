import { colors } from './colors';
import { textStyles } from './textStyles';
import { layerStyles } from './layerStyles';
import { sizes } from './sizes';
import { fonts } from './fonts';
import { fontSizes } from './fontSizes';
import { shadows } from './shadows';
import { lineHeights } from './lineHeights';
import { borders } from './border';

export { colors } from './colors';
export { borders } from './border';
export { shadows } from './shadows';

const foundations = {
  colors,
  textStyles,
  layerStyles,
  sizes,
  fonts,
  fontSizes,
  shadows,
  lineHeights,
  borders,
};

export default foundations;
