import React from 'react';

export function useInterval(
  callback: () => void,
  delay: number | null,
  immediate?: boolean,
): [number] {
  const startTime = React.useRef(Date.now());
  const [elapsedTimeInMs, setElapsedTime] = React.useState(0);
  const intervalRef = React.useRef(null);
  const savedCallback = React.useRef(callback);

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    if (typeof delay === 'number' && delay > 0) {
      intervalRef.current = setInterval(() => {
        setElapsedTime(Date.now() - startTime.current);
        savedCallback.current();
      }, delay);

      if (immediate) {
        savedCallback.current();
      }

      return () => {
        setElapsedTime(0);
        clearInterval(intervalRef.current);
      };
    }
  }, [delay]);

  return [elapsedTimeInMs];
}
