import React from 'react';

import RowActions from './Actions/RowActions';

import useTable from './useTable';

import { OrganizationMembershipFLatUserData } from '@components/organizations/types';

import { Center, Spinner } from '@chakra-ui/react';

import { Table, DumbPagination, EmptyTable } from '@components/common';

const MembersTable = (): JSX.Element => {
  const {
    data,
    schema,
    onRowClicked,
    isLoading,
    sortingState,
    page,
    hasNextPage,
    onPaginate,
    onSorted,
  } = useTable();

  return (
    <>
      {isLoading ? (
        <Center w='full' h='full'>
          <Spinner />
        </Center>
      ) : (
        <>
          <Table
            emptyTableFallback={<EmptyTable message='No members to display' />}
            tableSchema={schema}
            data={data}
            rowActions={(member: OrganizationMembershipFLatUserData) => (
              <RowActions member={member} />
            )}
            onRowClicked={onRowClicked}
            onSorted={onSorted}
            sortingState={sortingState}
          />

          <DumbPagination
            currentPage={page}
            itemsThisPage={data.length}
            hasNextPage={hasNextPage}
            onPaginate={onPaginate}
          />
        </>
      )}
    </>
  );
};

export default MembersTable;
