import React from 'react';

import { Box, Text, HStack } from '@chakra-ui/react';

import { Organization } from '@types';
import { OrganizationsAvatar } from '../../OrganizationAvatar';

type NameCellProps = {
  organization: Pick<Organization, 'logo_url' | 'name'>;
};
const NameCell = ({ organization }: NameCellProps): JSX.Element => {
  return (
    <HStack>
      <OrganizationsAvatar organizationsLogo={organization.logo_url} />;
      <Box maxW='20rem' flex='1'>
        <Text textStyle='sm-medium' noOfLines={1}>
          {organization.name}
        </Text>
      </Box>
    </HStack>
  );
};

export default NameCell;
