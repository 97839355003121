export const passwordStrengthOptions = ['weak', 'average', 'strong'] as const;

type PasswordMeterOptionsObject = {
  color: 'red' | 'orange' | 'green';
  score: number;
};

type PasswordMeterOptions = {
  [K in (typeof passwordStrengthOptions)[number]]: PasswordMeterOptionsObject;
};

export const PASSWORD_METER_OPTIONS: PasswordMeterOptions = {
  weak: {
    color: 'red',
    score: 1,
  },
  average: {
    color: 'orange',
    score: 2,
  },
  strong: {
    color: 'green',
    score: 3,
  },
};

export const PASSWORD_SETTINGS_BADGE_LABELS = {
  HIBP: 'Reject compromised',
  MIN_LENGTH: '+ characters',
  MIN_ZXCVBN_STRENGTH: 'Enforce',
  LOWERCASE: 'Require lowercase',
  UPPERCASE: 'Require uppercase',
  NUMBERS: 'Require number',
  SPECIAL_CHAR: 'Require special',
};
