import React from 'react';
import { stripProtocol } from '@utils';
import { useFormContext } from 'react-hook-form';
import { UrlInput } from './UrlInput';
import type { ProdInstanceForm } from './CreateProdInstanceModal';

export function HomeUrlForm(): JSX.Element {
  const { setValue, getValues } = useFormContext<ProdInstanceForm>();

  const onHomeUrlBlur = () => {
    setValue('home_url', stripProtocol(getValues('home_url')));
  };

  return <UrlInput onBlur={onHomeUrlBlur} />;
}
