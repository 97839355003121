import React from 'react';
import cn from 'classnames';

// @ts-ignore
import styles from './Center.module.scss';

export type CenterProps = {
  children?: React.ReactNode;
  className?: string;
  centerContent?: boolean;
  contentClassname?: string;
};

export function Center({
  centerContent,
  children,
  className,
  contentClassname,
}: CenterProps): JSX.Element {
  return (
    <div
      className={cn(
        styles.container,
        { [styles.textCentered]: centerContent },
        className,
      )}
    >
      <div className={contentClassname}>{children}</div>
    </div>
  );
}
