import React from 'react';

import Table from './Table/Table';
import Filters from './Filters';

import { HStack, VStack, Box } from '@chakra-ui/react';

import { AddMemberModal } from './components';

const Tab = (): JSX.Element => {
  return (
    <VStack spacing={2} width='100%' flexDirection='column'>
      <HStack justifyContent='space-between' width='100%'>
        <Filters />
        <AddMemberModal />
      </HStack>
      <Box width='100%'>
        <Table />
      </Box>
    </VStack>
  );
};

export default Tab;
