import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import _capitalize from 'lodash/capitalize';
import { Statuses } from '@types';
import { EnvironmentType } from '@utils';
import { DEPLOY_STATUS } from '../deployStatus';

export type InstanceStatusField = {
  environmentType: EnvironmentType;
  status: Statuses;
};

export const InstanceStatusItem = ({
  environmentType,
  status,
}: InstanceStatusField): JSX.Element => {
  return (
    <Flex key={environmentType} align='center' mb={1}>
      <Box
        boxSize={2}
        bg={DEPLOY_STATUS[status].color}
        mr='1.5'
        rounded='full'
      />
      <Text textStyle='sm-normal' color='gray.500'>
        {_capitalize(environmentType)}
      </Text>
    </Flex>
  );
};
