export interface DAPIError {
  code: string;
  message: string;
  long_message?: string;
  meta?: {
    param_name?: string;
  };
}
export class HTTPError extends Error {
  code: number;
  fieldErrors: DAPIError[];
  globalErrors: DAPIError[];

  constructor(message, code, errorData = []) {
    super(message);

    this.code = code;
    this.name = 'HTTPError';

    Object.setPrototypeOf(this, HTTPError.prototype);

    const { fieldErrors, globalErrors } = this.parseErrorData(errorData);
    this.fieldErrors = fieldErrors;
    this.globalErrors = globalErrors;
  }

  getFirstGlobalError() {
    if (this.globalErrors?.length > 0) {
      return this.globalErrors[0];
    }
    return undefined;
  }

  getFieldErrorByName(name: string): DAPIError | undefined {
    return this.fieldErrors.find(err => err.meta!.param_name === name);
  }

  getFieldErrorMessageByName(name: string): string {
    return this.getFieldErrorByName(name)?.message;
  }

  getFieldErrorsMessages(): Record<string, string> {
    return this.fieldErrors.reduce((memo, err) => {
      const name = err.meta.param_name;
      memo[name] = err.message;
      return memo;
    }, {});
  }

  getFieldErrorsNames(): string[] {
    return this.fieldErrors.map(err => err.meta.param_name);
  }

  private parseErrorData(data) {
    return (data || []).reduce(
      (memo, err) => {
        if (err.meta?.param_name) {
          memo.fieldErrors.push(err);
        } else {
          memo.globalErrors.push(err);
        }
        return memo;
      },
      {
        fieldErrors: [],
        globalErrors: [],
      },
    );
  }
}
