import React from 'react';
import {
  Box,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { useDashboardSWR } from '@hooks/useDashboardAPI';
import { ActiveUsersGraph } from './ActiveUsersGraph';
import { parseISO } from 'date-fns';
import { usePreviousWhenFalsy } from '@hooks/usePreviousWhenFalsy';
import { CardSinglePanel } from '@components/common';
import { useLocation } from '@hooks';
import { getAggregateDiff, getGraphData, getSinceRange } from './util';
import {
  initialState,
  metricOptions,
  rangeAggregateOptions,
  rangeOptions,
} from './constants';

export function ActiveUsers(): JSX.Element {
  const [liveData, setLiveData] = React.useState({
    graph: initialState,
    metric: 0,
    subMetric: null,
  });
  const [isLocked, setIsLocked] = React.useState(false);
  const [activeMetric, setActiveMetric] = React.useState('active_users');

  const { instanceId } = useLocation();

  const [rangeInterval, setRangeInterval] = React.useState(
    rangeAggregateOptions[0].interval,
  );
  const [range, setRange] = React.useState(rangeOptions[5]);

  const {
    data = {},
    isValidating,
    error,
  } = useDashboardSWR(
    `/v1/instances/${instanceId}/analytics/user_activity/${activeMetric}?since=${getSinceRange(
      range,
    )}&interval=${rangeInterval}`,
    {
      onSuccess: () => {
        setIsLocked(false);
      },
    },
  );

  const activeUsers = usePreviousWhenFalsy(data, () => !isValidating);

  const startDateOfData =
    activeUsers && activeUsers.length > 0
      ? parseISO(activeUsers[0].start_date)
      : new Date();

  const graphData = React.useMemo(() => {
    if (isValidating) {
      return null;
    }

    return getGraphData(data, range);
  }, [data, range, isValidating]);

  const handleRangeChange = rangeOption => {
    setIsLocked(true);
    setTimeout(() => {
      setRange(rangeOption);
      setRangeInterval(rangeAggregateOptions[0].interval);
    });
  };

  const handleRangeΑggregateChange = rangeAggregateOption => {
    setIsLocked(true);
    setTimeout(() => {
      setRangeInterval(rangeAggregateOption);
    });
  };

  const handleMetricChange = value => {
    setIsLocked(true);
    setActiveMetric(value);
  };

  React.useEffect(() => {
    if (!isLocked && !isValidating && !error && data) {
      const metric =
        activeUsers && activeUsers.length > 0
          ? activeUsers[activeUsers.length - 1].count
          : 0;
      const subMetric = getAggregateDiff(activeUsers);
      setLiveData({ graph: graphData, metric, subMetric });
    }
  }, [graphData, activeUsers, isValidating, isLocked, error, data]);

  return (
    <CardSinglePanel
      title={
        <Menu>
          <MenuButton textStyle='lg-medium'>
            {metricOptions.find(({ value }) => value === activeMetric)?.label ||
              'Choose metric'}
            <Icon as={ChevronDownIcon} boxSize='5' ml='1' />
          </MenuButton>

          <MenuList>
            {metricOptions.map(({ label, value }) => (
              <MenuItem key={label} onClick={() => handleMetricChange(value)}>
                {label}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      }
    >
      <Text textStyle='h2'>{liveData.metric}</Text>

      <Text textStyle='md-medium' color='success.500' mt='0.5'>
        {liveData.subMetric}
      </Text>

      <Box mt='4'>
        {rangeAggregateOptions
          .filter(option => option.shouldDisplay(startDateOfData))
          .map(({ value: aggregateValue, interval }) => (
            <Text
              key={aggregateValue}
              as='button'
              textStyle='md-medium'
              color={interval === rangeInterval ? 'primary.500' : 'gray.500'}
              onClick={() => handleRangeΑggregateChange(interval)}
              disabled={isValidating}
              cursor={isValidating && 'not-allowed'}
              mr='8'
            >
              {aggregateValue}
            </Text>
          ))}
      </Box>

      <ActiveUsersGraph {...liveData.graph} />

      <Flex justify='space-between'>
        {rangeOptions.map(rangeOption => (
          <Text
            key={rangeOption.value}
            as='button'
            textStyle='md-medium'
            color={
              rangeOption.value === range.value ? 'primary.500' : 'gray.500'
            }
            onClick={() => handleRangeChange(rangeOption)}
            disabled={isValidating}
            cursor={isValidating && 'not-allowed'}
          >
            {rangeOption.value}
          </Text>
        ))}
      </Flex>
    </CardSinglePanel>
  );
}
