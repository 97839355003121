import React, { ReactNode } from 'react';
import { Text, TextProps } from '@chakra-ui/react';

type SubtitleProps = {
  children: ReactNode;
} & TextProps;

export function Subtitle({ children, ...rest }: SubtitleProps): JSX.Element {
  return (
    <Text textStyle='sm-normal' color='gray.500' mb={4} {...rest}>
      {children}
    </Text>
  );
}
