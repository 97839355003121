import { Box, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react';
import { MultipleColorFields } from '@components/customization/avatars/MultipleColorFields';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

type AvatarBackgroundRadioGroupSubLabels = {
  marble: string;
  solid: string;
};

type AvatarBackgroundRadioGroupProps = {
  type: 'organization' | 'user';
};

const USER_SUBLABEL_TEXTS: AvatarBackgroundRadioGroupSubLabels = {
  marble:
    'Display a marble gradient in the background. The marble gradient is unique per user.',
  solid: 'Display a solid color in the background.',
};

const ORG_SUBLABEL_TEXTS: AvatarBackgroundRadioGroupSubLabels = {
  marble:
    'Display a marble gradient in the background. The marble gradient is unique per organization.',
  solid: 'Display a solid color in the background.',
};

const subLabels = {
  user: USER_SUBLABEL_TEXTS,
  organization: ORG_SUBLABEL_TEXTS,
};

export const AvatarBackgroundRadioGroup = ({
  type,
}: AvatarBackgroundRadioGroupProps): JSX.Element => {
  const { watch, control } = useFormContext();
  const { field } = useController({ name: `${type}.bgType`, control });
  const values = watch();
  return (
    <RadioGroup
      onChange={nextValue => field.onChange(nextValue)}
      value={field.value}
    >
      <Stack direction='column' spacing={4}>
        <Radio value='marble' alignItems={'flex-start'}>
          <Text textStyle='md-medium'>Marble</Text>
          <Text textStyle='sm-medium' fontWeight='normal' color='gray.500'>
            {subLabels[type].marble}
          </Text>
        </Radio>
        {values[type].bgType === 'marble' && (
          <Box pl={6}>
            <MultipleColorFields name={`${type}.bgColor`} />
          </Box>
        )}
        <Radio value='solid' alignItems={'flex-start'}>
          <Text textStyle='md-medium'>Solid</Text>
          <Text textStyle='sm-medium' fontWeight='normal' color='gray.500'>
            {subLabels[type].solid}
          </Text>
        </Radio>
        {values[type].bgType === 'solid' && (
          <Box pl={6}>
            <MultipleColorFields name={`${type}.bgColor`} maxFields={1} />
          </Box>
        )}
      </Stack>
    </RadioGroup>
  );
};
