import React from 'react';

import { CardDualPanel } from '@components/common';

import { ConfirmationModal } from '@components/modal';
import { Button, useDisclosure } from '@chakra-ui/react';

import { useDashboardCRUD, useToast } from '@hooks';

import { Organization } from '@types';

import { useRouter } from 'next/router';

type DeleteOrganizationProps = {
  organizationName: string;
  onDelete: () => void;
  deletePath: string;
  navigateTo: string;
};

const DeleteOrganization = ({
  organizationName,
  deletePath,
  navigateTo,
  onDelete,
}: DeleteOrganizationProps): JSX.Element => {
  const { isOpen, onClose, getButtonProps } = useDisclosure();
  const { del } = useDashboardCRUD<Organization>();
  const router = useRouter();
  const { showErrorToast } = useToast();

  async function handleDelete() {
    try {
      await del(deletePath);
      onDelete();
      onClose();
      void router.push(navigateTo);
    } catch (err) {
      showErrorToast(
        'Error while trying to delete organization',
        'Organization has not been deleted',
      );
    }
  }

  return (
    <CardDualPanel
      alignItems='center'
      rightPanelStyles={{ justifyContent: 'space-between' }}
      title='Delete organization'
      subtitle='This action is permanent and irreversible.'
    >
      <Button
        fontWeight='bold'
        fontSize='xs'
        ml='auto'
        {...getButtonProps()}
        colorScheme='red'
      >
        Delete Organization
      </Button>
      <ConfirmationModal
        verification={{
          stringToMatch: organizationName,
          label: 'Organization',
          text: 'Type the name of the organization below to confirm deletion.',
        }}
        isOpen={isOpen}
        handleCancelation={onClose}
        handleConfirmation={handleDelete}
        confirmationMessage='Delete organization'
        cancelationMessage='Cancel'
        message='Are you sure you want to delete this organization? This action is
            permanent and irreversible.'
        headerTitle={`Delete organization ${organizationName}`}
      />
    </CardDualPanel>
  );
};

export default React.memo(DeleteOrganization);
