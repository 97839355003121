import React from 'react';
import { Text } from '@chakra-ui/react';
import { CardDualPanel, CopyTextInput } from '@components/common';
import { useFrontendAPI } from '@hooks';

export function FrontendAPIKey(): JSX.Element {
  const frontendAPI = useFrontendAPI();

  return (
    <CardDualPanel
      title='Legacy Frontend API key'
      subtitle={
        <>
          <Text as='span' textStyle='sm-normal' mt='2'>
            This is the public key to be used from your frontend code.
          </Text>
        </>
      }
      rightPanelStyles={{ flex: '2', alignSelf: 'stretch' }}
      bgColor='gray.50'
    >
      <CopyTextInput label='Key' valueToCopy={frontendAPI} paddingTop={0} />
    </CardDualPanel>
  );
}
