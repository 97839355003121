import React from 'react';
import { VStack, Flex, Text, Icon } from '@chakra-ui/react';
import { CardDualPanel } from '@components/common';
import { UserSettings } from '@types';
import { SwitchInfoField } from '@components/common/Switch';
import { usePaymentRequired } from '@context/PaymentRequiredContext';
import {
  useDashboardCRUD,
  useDashboardSWR,
  useInstance,
  useSupportedFeature,
} from '@hooks';
import { SUPPORTED_FEATURES } from '@constants';
import { IdentifierInput } from './IdentifierInput';
import { InformationCircleIcon } from '@heroicons/react/outline';

const FORM_ID = 'restrictions_blocklist_form';

type RestrictionsResponse = {
  allowlist: boolean;
  blocklist: boolean;
};

export function Blocklist(): JSX.Element {
  const { instance } = useInstance();
  const { isSupported, isPremium, isUnsupportedAndEnabled } =
    useSupportedFeature();
  const instanceId = instance?.id;
  const { showModal: showUpgradePlanModal } = usePaymentRequired();

  const [isUpdatingBlocklist, setIsUpdatingBlocklist] = React.useState(false);

  const blocklistPath = `/v1/instances/${instanceId}/user_settings/restrictions`;

  const { update } = useDashboardCRUD();
  const { data: settings, mutate: mutateUserSettings } =
    useDashboardSWR<UserSettings>(
      instanceId ? `/v1/instances/${instanceId}/user_settings` : null,
    );

  if (!settings) {
    return null;
  }

  const isBlocklistEnabled = settings.restrictions.blocklist.enabled;
  const isBlocklistSupported = isSupported(SUPPORTED_FEATURES.blocklist);

  const onChange = async e => {
    const feature = SUPPORTED_FEATURES.blocklist;

    if (
      !isBlocklistSupported &&
      !isUnsupportedAndEnabled({
        feature,
        currentValue: isBlocklistEnabled,
      })
    ) {
      return showUpgradePlanModal({
        features: [feature],
      });
    }

    if (isPremium(feature) && !isBlocklistEnabled) {
      showUpgradePlanModal();
    }

    setIsUpdatingBlocklist(true);

    try {
      await mutateUserSettings(
        async settings => {
          const restrictions = (await update(blocklistPath, {
            blocklist: e.target.checked,
          })) as RestrictionsResponse;
          return {
            ...settings,
            restrictions: {
              allowlist: { enabled: restrictions.allowlist },
              blocklist: { enabled: restrictions.blocklist },
            },
          };
        },
        { revalidate: false },
      );
    } catch (_) {
      return;
    } finally {
      setIsUpdatingBlocklist(false);
    }
  };

  return (
    <CardDualPanel
      width='100%'
      title='Blocklist'
      subtitle='Block accounts with certain identifiers'
    >
      <VStack align='stretch'>
        <Flex bg='gray.50' borderRadius={4} px={4} py={2} alignItems='center'>
          <Icon
            as={InformationCircleIcon}
            color='gray.300'
            boxSize={5}
            mr={2}
          />
          <Text textStyle='sm-normal' color='gray.500'>
            When both enabled, allowlist takes priority over blocklist
          </Text>
        </Flex>

        <SwitchInfoField
          name='blocklist'
          title='Enable blocklist'
          description='Enable to specify which account identifiers should be blocked from signing up'
          onChange={onChange}
          isDisabled={isUpdatingBlocklist}
          isChecked={isBlocklistEnabled}
          isPremiumFeature={isPremium(SUPPORTED_FEATURES.blocklist)}
        />

        <IdentifierInput
          name='blocklist'
          formId={FORM_ID}
          isSupported={isBlocklistSupported}
        />
      </VStack>
    </CardDualPanel>
  );
}
