import React from 'react';
import {
  Box,
  Flex,
  Menu as MenuChakra,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import _capitalize from 'lodash/capitalize';
import { EnvironmentType } from '@utils';
import { Selector } from '@clerk-ui/assets/icons';
import { StatusBox } from '../../common';
import { Statuses } from '@types';
import { Item } from './Item';
import { useAnalytics } from '@hooks/useAnalytics';

type MenuProps = {
  handleOnSelect: (instanceId: string | null) => void;
  options: Array<{ environmentType: string; id: string; status: Statuses }>;
  selectedOptionId: string;
  redirectHighlighting: string | undefined;
};

export function Menu({
  handleOnSelect,
  options,
  selectedOptionId,
  redirectHighlighting,
}: MenuProps): JSX.Element {
  const { track } = useAnalytics();
  const hasProdInstance = options.some(
    ({ environmentType }) => environmentType === EnvironmentType.Production,
  );
  const activeInstance = options.find(({ id }) => id === selectedOptionId);

  return (
    <MenuChakra id='menu'>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Box}
            animation={redirectHighlighting}
            cursor='pointer'
            padding='0 12px'
            height='32px'
            borderRadius={5}
            display='flex'
            alignItems='center'
            _hover={{ background: 'gray.50' }}
            _active={{ background: 'gray.50' }}
            outline='0'
            sx={{
              '&:hover svg': {
                fill: 'primary.500',
              },
            }}
            onClick={() => {
              track('Dashboard_Top Nav_Menu Item Clicked', {
                surface: 'Dashboard',
                location: 'Top Nav',
                clickedOn: 'Instance',
                instanceId: selectedOptionId,
                environmentType: activeInstance
                  ? activeInstance.environmentType
                  : '',
              });
            }}
          >
            <Item Icon={Selector} isActive={isOpen}>
              <Flex align='center'>
                <StatusBox status={activeInstance?.status} />
                <Skeleton
                  isLoaded={!!activeInstance?.environmentType}
                  height={4}
                  minW='3.25rem'
                >
                  <Text textStyle='sm-medium' cursor='pointer' ml='0.75rem'>
                    {_capitalize(activeInstance?.environmentType)}
                  </Text>
                </Skeleton>
              </Flex>
            </Item>
          </MenuButton>
          <MenuList>
            <MenuGroup title='select instance'>
              {options
                .sort((a, b) =>
                  a.environmentType.localeCompare(b.environmentType),
                )
                .map(({ environmentType, id, status }) => (
                  <MenuItem
                    key={id}
                    onClick={() => handleOnSelect(id)}
                    data-active={id === activeInstance?.id ? true : undefined}
                    _active={{
                      borderColor: 'gray.50',
                      bgColor: 'gray.50',
                    }}
                  >
                    <StatusBox status={status} mr='0.50rem' />
                    {_capitalize(environmentType)}
                  </MenuItem>
                ))}
              {!hasProdInstance ? (
                <MenuItem onClick={() => handleOnSelect(null)}>
                  <StatusBox status='unconfigured' mr='0.50rem' />
                  Production
                </MenuItem>
              ) : null}
            </MenuGroup>
          </MenuList>
        </>
      )}
    </MenuChakra>
  );
}
