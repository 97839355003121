import React from 'react';
import { Flex } from '@chakra-ui/react';
import type { InstanceKey } from '@types';
import { useAnalytics, useLocation } from '@hooks';

import { APIKeyPanel } from './components';

type KeysTableProps = {
  secretKeys: InstanceKey[];
  onDelete: (instanceKeyId: string) => void;
};

export function SecretKeyList({
  secretKeys,
  onDelete,
}: KeysTableProps): JSX.Element {
  const { track } = useAnalytics();
  const { instanceId } = useLocation();

  const onVisibilityChange = () =>
    track('Dashboard_API Keys Screen_Secret Keys Reveal Button Clicked', {
      surface: 'Dashboard',
      location: 'API Keys Screen',
      instanceId,
    });

  return (
    <Flex overflow='hidden' gap={2} flexDirection='column'>
      {secretKeys.map(secretKey => (
        <APIKeyPanel
          id={secretKey.id}
          isSensitive={true}
          title={secretKey.name}
          keyValue={secretKey.secret}
          key={secretKey.id}
          onDelete={() => onDelete(secretKey.id)}
          onVisibilityChange={onVisibilityChange}
        />
      ))}
    </Flex>
  );
}
