import React from 'react';
import cn from 'classnames';
import type { DropdownOption } from './types';

// @ts-ignore
import styles from './DropdownList.module.scss';
import { getOptionLabel } from './util';

import { Box } from '@chakra-ui/react';

const NoResultsItem = () => (
  <Box
    textStyle='sm-normal'
    as='li'
    className={cn(styles.item, styles.noResults)}
  >
    No matches found
  </Box>
);

export type DropdownListProps = {
  dropdownRef: React.RefObject<HTMLUListElement>;
  selectionRef: React.RefObject<HTMLLIElement>;
  listClassName?: string;
  options: DropdownOption[];
  handleListSelection: (selectedOption: DropdownOption) => void;
  selectedIndex: number;
  listId?: string;
  isLoading?: boolean;
};

export function DropdownList({
  dropdownRef,
  selectionRef,
  listClassName,
  options,
  handleListSelection,
  selectedIndex,
  listId,
  isLoading,
}: DropdownListProps): JSX.Element {
  if (isLoading) {
    return <></>;
  }
  return (
    <ul
      ref={dropdownRef}
      className={cn(styles.itemList, listClassName)}
      role='listbox'
      id={listId}
      tabIndex={-1}
    >
      {options.length > 0 &&
        options.map((option, index) => (
          <li
            key={index}
            ref={index === selectedIndex ? selectionRef : null}
            aria-selected={index === selectedIndex}
            role='option'
            className={cn(styles.item, {
              [styles.selected]: index === selectedIndex,
            })}
            onMouseDown={e => {
              e.preventDefault();
              handleListSelection(option);
            }}
          >
            {getOptionLabel(option)}
          </li>
        ))}

      {!isLoading && !options.length && <NoResultsItem />}
    </ul>
  );
}
