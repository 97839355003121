import { Instance } from '@types';
import { EnvironmentType } from '@utils';

const envTypeOrder = {
  [EnvironmentType.Development]: 0,
  [EnvironmentType.Staging]: 1,
  [EnvironmentType.Production]: 2,
};

type InstanceItem = Partial<Instance> & { environment_type: EnvironmentType };

export const devInstancesFirst = (a: InstanceItem, b: InstanceItem) =>
  envTypeOrder[a.environment_type] - envTypeOrder[b.environment_type];
