import React from 'react';
import { Button, Flex, Icon, Text, useDisclosure } from '@chakra-ui/react';
import { CardDualPanel } from '@components/common';
import { ExclamationIcon, UserIcon } from '@heroicons/react/solid';
import { Modal } from '@components/common';
import { SUPPORTED_FEATURES } from '@constants';
import { useSupportedFeature } from '@hooks';
import { usePaymentRequired } from '@context/PaymentRequiredContext';

export type CardBanUserWithConfirmationProps = {
  banUser: (onClose: () => void) => void;
  isUserBanned: boolean;
  isLoading: boolean;
};

const modalContent = {
  ban: {
    title: 'Ban user',
    subtitle:
      'This action makes a user unable to sign in and clears all their active sessions',
    message: 'Are you sure you want to ban this user?',
  },
  unban: {
    title: 'Unban user',
    subtitle: 'This action restores the ability for a user to sign in',
    message: 'Are you sure you want to unban this user?',
  },
};

export const CardBanUserWithConfirmation = ({
  banUser,
  isUserBanned,
  isLoading,
}: CardBanUserWithConfirmationProps): JSX.Element => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { isSupported, isPremium, hasNotSeenInfoBillingModal } =
    useSupportedFeature();
  const { showModal } = usePaymentRequired();
  const content = isUserBanned ? 'unban' : 'ban';

  const handleClick = () => {
    if (!isSupported(SUPPORTED_FEATURES.ban_user)) {
      return showModal({ features: [SUPPORTED_FEATURES.ban_user] });
    }

    if (hasNotSeenInfoBillingModal(SUPPORTED_FEATURES.ban_user)) {
      return showModal({
        callbackAfterClose: () => onOpen(),
      });
    }

    return onOpen();
  };

  return (
    <>
      <CardDualPanel
        title={modalContent[content].title}
        subtitle={modalContent[content].subtitle}
        rightPanelStyles={{ flex: 'unset' }}
        showPremiumBadge={isPremium(SUPPORTED_FEATURES.ban_user)}
      >
        <Flex justify='flex-end'>
          <Button
            onClick={handleClick}
            colorScheme='danger'
            leftIcon={<Icon as={UserIcon} />}
          >
            {modalContent[content].title}
          </Button>
        </Flex>
      </CardDualPanel>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={modalContent[content].title}
      >
        <Modal.Body>
          <Text textStyle='md-normal'>{modalContent[content].message}</Text>

          <Text textStyle='md-normal'>
            This action is not permanent and can be undone.
          </Text>
        </Modal.Body>

        <Modal.Footer flexDir='row-reverse'>
          <Button
            onClick={() => banUser(onClose)}
            colorScheme='danger'
            leftIcon={<Icon as={ExclamationIcon} />}
            isLoading={isLoading}
          >
            {modalContent[content].title}
          </Button>

          <Button onClick={onClose} variant='ghost' autoFocus>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
