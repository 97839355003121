import { useRouter } from 'next/router';

type UseInstanceId = {
  instanceId: string | string[];
};

export const useInstanceId: any = (): UseInstanceId => {
  const { query } = useRouter();
  const { id, instanceId } = query;

  return {
    instanceId: id || instanceId,
  };
};
