import React from 'react';
import { APIKeyPanelProps, APIKeyPanel } from './APIKeyPanel';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';

type APIKeyTabsViewProps = {
  apiKeys: APIKeyPanelProps[];
};
export const APIKeyTabbedPanel = ({
  apiKeys,
}: APIKeyTabsViewProps): JSX.Element => {
  return (
    <Tabs variant='enclosed'>
      <TabList>
        {apiKeys.map(({ title, id }) => (
          <Tab
            mr={1}
            bg='gray.700'
            p={3}
            color='white'
            borderBottom={0}
            fontSize='sm'
            _selected={{ color: 'white', bg: 'gray.900' }}
            key={id}
          >
            {title}
          </Tab>
        ))}
      </TabList>

      <TabPanels
        width='xl'
        backgroundColor='gray.800'
        borderTopRightRadius='md'
        borderBottomLeftRadius='md'
        borderBottomRightRadius='md'
        fontSize='sm'
      >
        {apiKeys.map(({ id, ...rest }) => (
          <TabPanel pt={0} key={id}>
            <APIKeyPanel id={id} {...rest} />
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};
