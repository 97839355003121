import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  CardDualPanel,
  ColorInput,
  VerticalInputBox,
} from '@components/common';
import { FormControl, FormErrorMessage, Stack } from '@chakra-ui/react';

export function Colors(): JSX.Element {
  const {
    clearErrors,
    formState: { isSubmitting, errors },
    control,
  } = useFormContext();

  return (
    <CardDualPanel
      title='Colors'
      subtitle='Set colors for your Clerk Hosted Components'
      disabled={isSubmitting}
    >
      <Stack spacing='6'>
        <VerticalInputBox
          label='Primary color'
          subLabel='Set the primary color to match your branding'
        >
          <FormControl isInvalid={!!errors?.general?.color || errors?.color}>
            <Controller
              control={control}
              name='general.color'
              defaultValue='#000000'
              render={({ field: { onChange, value, name } }) => {
                const handleOnChange = (
                  e: React.ChangeEvent<HTMLInputElement> | string,
                ) => {
                  clearErrors('color');
                  onChange(e);
                };
                return (
                  <ColorInput
                    name={name}
                    value={value}
                    onChange={handleOnChange}
                  />
                );
              }}
            />
            <FormErrorMessage>
              {errors?.general?.color?.message || errors?.color?.message}
            </FormErrorMessage>
          </FormControl>
        </VerticalInputBox>
        <VerticalInputBox
          label='Background color'
          subLabel='Set page background color'
        >
          <FormControl
            isInvalid={
              !!errors?.accounts?.background_color || errors?.background_color
            }
          >
            <Controller
              control={control}
              name='accounts.background_color'
              defaultValue='#000000'
              render={({ field: { onChange, value, name } }) => {
                const handleOnChange = (
                  e: React.ChangeEvent<HTMLInputElement> | string,
                ) => {
                  clearErrors('background_color');
                  onChange(e);
                };
                return (
                  <ColorInput
                    name={name}
                    value={value}
                    onChange={handleOnChange}
                  />
                );
              }}
            />
            <FormErrorMessage>
              {errors?.accounts?.background_color?.message ||
                errors?.background_color?.message}
            </FormErrorMessage>
          </FormControl>
        </VerticalInputBox>
      </Stack>
    </CardDualPanel>
  );
}
