import React from 'react';
import { HStack, Text, VStack } from '@chakra-ui/react';
import { useDashboardSWR } from '@hooks/useDashboardAPI';
import { enUS } from 'date-fns/locale';
import { CardSinglePanel } from '@components/common';
import { useLocation } from '@hooks';
import { LoadingSpinner } from './LoadingSpinner';

export function MonthlyTiles(): JSX.Element {
  const { instanceId } = useLocation();

  const { data: monthlyMetrics = {}, isValidating } = useDashboardSWR(
    `/v1/instances/${instanceId}/analytics/monthly_metrics`,
  );

  const {
    total_users = 0,
    month = new Date().getMonth(),
    year = new Date().getFullYear(),
    active_users = 0,
    signups = 0,
    signins = 0,
  } = monthlyMetrics;

  const date = `${enUS.localize.month(month - 1)} ${year}`;

  return (
    <VStack spacing={7} align='stretch' flex={1}>
      <HStack spacing={7} align='stretch'>
        <CardSinglePanel title='Total users' subtitle='All time'>
          {isValidating ? (
            <LoadingSpinner />
          ) : (
            <Text textStyle='h2'>{total_users}</Text>
          )}
        </CardSinglePanel>

        <CardSinglePanel title='Active users' subtitle={date}>
          {isValidating ? (
            <LoadingSpinner />
          ) : (
            <Text textStyle='h2'>{active_users}</Text>
          )}
        </CardSinglePanel>
      </HStack>

      <HStack spacing={7} align='stretch'>
        <CardSinglePanel title='Sign-ups' subtitle={date}>
          {isValidating ? (
            <LoadingSpinner />
          ) : (
            <Text textStyle='h2'>{signups}</Text>
          )}
        </CardSinglePanel>

        <CardSinglePanel title='Sign-ins' subtitle={date}>
          {isValidating ? (
            <LoadingSpinner />
          ) : (
            <Text textStyle='h2'>{signins}</Text>
          )}
        </CardSinglePanel>
      </HStack>
    </VStack>
  );
}
