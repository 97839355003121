import React, { useState } from 'react';
import NextLink from 'next/link';
import {
  Box,
  Flex,
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Checkbox,
  Link as ChakraLink,
} from '@chakra-ui/react';
import { Heading } from '@components/common';
import NextImage from 'next/image';
import { BillingModalBackground } from '@clerk-ui/assets/icons';
import { useLocation, useUnsafeMetadata } from '@hooks';
import { billingPath } from '@utils';

type InfoBillingModalProps = {
  isOpen: boolean;
  onClose: () => void;
  callbackAfterClose: () => void;
};

export function InfoBillingModal({
  isOpen,
  onClose,
  callbackAfterClose,
}: InfoBillingModalProps): JSX.Element {
  const initialRef = React.useRef();
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);
  const { applicationId, instanceId } = useLocation();
  const { removeAppIdFromInfoModalArray } = useUnsafeMetadata();

  const handleClose = async () => {
    if (doNotShowAgain) {
      try {
        await removeAppIdFromInfoModalArray(applicationId);
      } catch (err) {
        console.error('error', err);
      } finally {
        onClose();
      }
    }

    onClose();

    if (callbackAfterClose) {
      callbackAfterClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      initialFocusRef={initialRef}
      size='lg'
    >
      <ModalOverlay />
      <ModalContent px={6} py={8} pos='relative' overflow='hidden'>
        <Icon
          as={BillingModalBackground}
          position='absolute'
          top='0'
          right='0'
          w='226px'
          h='288px'
          zIndex={-1}
        />
        <ModalHeader p={0} mb={4}>
          <Box mb={4}>
            <NextImage
              src='/assets/logos/clerk.svg'
              alt="Clerk's logo"
              height='42px'
              width='42px'
              layout='fixed'
            />
          </Box>
          <Heading as='h3'>Premium feature</Heading>
        </ModalHeader>
        <ModalBody p={0}>
          <Text textStyle='md-normal' mb={3}>
            Premium features are free to enable on{' '}
            <strong>development instances</strong> for developing and testing
            purposes.
          </Text>
          <Text textStyle='md-normal' mb={3}>
            <NextLink href={billingPath(applicationId, instanceId)}>
              <ChakraLink
                textTransform='unset'
                color='primary.500'
                fontWeight='medium'
              >
                View plans
              </ChakraLink>
            </NextLink>{' '}
            to learn more about available plans and features.
          </Text>

          <Flex alignItems='center' mt={6}>
            <Checkbox
              size='lg'
              isChecked={doNotShowAgain}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setDoNotShowAgain(e.target.checked)
              }
              mb={0}
            >
              <Flex pt={1} alignItems='center'>
                <Text textStyle='md-normal'>
                  Do not show this message again
                </Text>
              </Flex>
            </Checkbox>
          </Flex>
        </ModalBody>

        <ModalFooter justifyContent='space-between' p={0} mt={10}>
          <Button variant='ghost' onClick={handleClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
