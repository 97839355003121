import React from 'react';
import { Icon } from '@chakra-ui/react';
import { useIntercom, IntercomProvider } from 'react-use-intercom';
import { ChatIcon } from '@heroicons/react/solid';

import { useUser } from '@clerk/nextjs';
import { useDashboardSWR } from '@hooks';

import { SupportButtonListItem } from './SupportButtonListItem';

const DashboardIntercomProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const intercomAppId = process.env.NEXT_PUBLIC_INTERCOM_APP_ID;
  const { user, isLoaded: isUserLoaded } = useUser();
  const { id, fullName, primaryEmailAddress, phoneNumbers } = user || {};
  const { data, error } = useDashboardSWR<{ user_hash: string }>(
    '/v1/intercom/user_hash',
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
    },
  );

  if (!intercomAppId || !isUserLoaded || !data || error) {
    return null;
  }

  return (
    <IntercomProvider
      appId={intercomAppId}
      autoBoot
      autoBootProps={{
        hideDefaultLauncher: true,
        userId: id,
        email: primaryEmailAddress?.emailAddress,
        name: fullName,
        phone: phoneNumbers[0]?.phoneNumber,
        userHash: data.user_hash,
      }}
    >
      {children}
    </IntercomProvider>
  );
};

export function Feedback(): JSX.Element {
  const { show } = useIntercom();

  return (
    <SupportButtonListItem
      title='Chat with us'
      icon={<Icon as={ChatIcon} color='gray.300' boxSize={4} mr='0.5rem' />}
      onClick={show}
    />
  );
}

export const FeedbackWithIntercom = (): JSX.Element => (
  <DashboardIntercomProvider>
    <Feedback />
  </DashboardIntercomProvider>
);

export const MemoizedFeedbackWithIntercom = React.memo(FeedbackWithIntercom);
