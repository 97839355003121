import React from 'react';
import { Box, Button, Icon, Link } from '@chakra-ui/react';
import { PlusIcon } from '@heroicons/react/solid';

export function CreateAppListItem(): JSX.Element {
  return (
    <Box bg='white' mt={1.5} mx={-3}>
      <Box borderBottom='1px solid' borderColor='gray.200' />
      <Box pl={3.5}>
        <Button
          href='/apps/new'
          as={Link}
          variant='link'
          fontSize='xs'
          mt={3.5}
          py={2}
          px={2}
        >
          <Icon as={PlusIcon} boxSize={3.5} mr={1} />
          Create application
        </Button>
      </Box>
    </Box>
  );
}
