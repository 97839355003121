import React from 'react';
import Link from 'next/link';
import { Box, Button, Flex, Icon, Text } from '@chakra-ui/react';
import { CardSinglePanel } from '../common/Card/CardSinglePanel';
import { ArrowRightIcon, CheckIcon, MinusSmIcon } from '@heroicons/react/solid';
import { useTodosContext } from '@components/instanceHome/gettingStarted/contexts';
import { TodoIds } from '@components/instanceHome/gettingStarted/useTodos/types';
import { useCanGoLive, useDeployStatus } from '@hooks';
import { EnvironmentType, noop } from '@utils';
import { Deploy } from './Deploy';

export function DeployChecklist(): JSX.Element {
  const { environmentType } = useDeployStatus();
  const { todos, completeTodo, completeAllTodos } = useTodosContext();
  const { dnsRecordsVerified, hasEnabledOauthProviders, oauthSetupVerified } =
    useCanGoLive();

  React.useEffect(() => {
    if (oauthSetupVerified) {
      completeTodo(TodoIds.OAuth);
    }
    if (dnsRecordsVerified) {
      completeTodo(TodoIds.DNS);
    }
  }, [dnsRecordsVerified, oauthSetupVerified]);

  const isProduction = environmentType === EnvironmentType.Production;
  const title = isProduction ? 'Deploy production instance' : 'Get started';

  return (
    <CardSinglePanel title={title}>
      {todos.map(({ id, title, content, link, completed, target }) => {
        let isComplete = completed;
        let onLinkVisit = completeTodo;

        // Some Todos have complete conditions external to user-interaction
        if (id === TodoIds.OAuth) {
          if (!hasEnabledOauthProviders) {
            return null;
          }
          isComplete = oauthSetupVerified;
          onLinkVisit = noop;
        }
        if (id === TodoIds.DNS) {
          isComplete = dnsRecordsVerified;
          onLinkVisit = noop;
        }

        return (
          <Link href={link} key={title} passHref>
            <Flex
              as='a'
              target={target || '_blank'}
              role='group'
              px='4'
              py='3'
              justify='space-between'
              rounded='md'
              _hover={{ bg: 'gray.50', color: 'currentColor' }}
              onClick={() => onLinkVisit(id)}
            >
              <Flex align='flex-start' mr='5'>
                <Icon
                  as={isComplete ? CheckIcon : MinusSmIcon}
                  mr='2'
                  boxSize='5'
                  color={isComplete ? 'success.500' : 'gray.500'}
                />

                <Box>
                  <Text textStyle='md-medium' mb='0.5'>
                    {title}
                  </Text>
                  <Text textStyle='sm-normal' color='gray.500'>
                    {content}
                  </Text>
                </Box>
              </Flex>

              <Flex
                visibility='hidden'
                _groupHover={{ visibility: 'visible' }}
                align='center'
              >
                <Icon as={ArrowRightIcon} color='primary.500' boxSize='5' />
              </Flex>
            </Flex>
          </Link>
        );
      })}

      {isProduction ? (
        <Deploy />
      ) : (
        <Button variant='ghost' mt={3} onClick={completeAllTodos}>
          Close
        </Button>
      )}
    </CardSinglePanel>
  );
}
