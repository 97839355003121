import React from 'react';
import {
  Badge,
  Box,
  BoxProps,
  Flex,
  StackProps,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { CardContainer, Hint, Subtitle } from './CardElements';
import { CardDocLink, CardDocLinkProps } from './CardDocLink';
import { CardDualPanelError } from './CardErrorState';

type CardDualPanelProps = {
  title: string;
  subtitle: string | JSX.Element;
  isUserBanned?: boolean;
  showPremiumBadge?: boolean;
  hint?: string | JSX.Element;
  leftPanelChildren?: JSX.Element;
  docLink?: CardDocLinkProps;
  isHintFaded?: boolean;
  rightPanelStyles?: BoxProps;
  disabled?: boolean;
  nonClickable?: boolean;
  children: React.ReactNode;
  subTitleProps?: TextProps;
  hasError?: boolean;
  mutate?: () => void;
} & StackProps;

export function CardDualPanel({
  title,
  subtitle,
  isUserBanned,
  hint,
  docLink,
  isHintFaded = true,
  leftPanelChildren = null,
  rightPanelStyles = { flex: '1' },
  showPremiumBadge,
  children,
  subTitleProps,
  hasError = false,
  mutate,
  ...rest
}: CardDualPanelProps): JSX.Element {
  if (hasError) {
    return (
      <CardDualPanelError title={title} subtitle={subtitle} mutate={mutate} />
    );
  }

  return (
    <CardContainer spacing={6} align='flex-start' direction='row' {...rest}>
      <Box flex='1'>
        <Flex align='center' justify='flex-start' mb={1}>
          <Text textStyle='lg-medium'>{title}</Text>
          {isUserBanned && (
            <Badge ml='2' colorScheme='red'>
              Banned
            </Badge>
          )}
          {showPremiumBadge && <Badge ml='2'>Premium</Badge>}
        </Flex>
        <Subtitle {...subTitleProps}>{subtitle}</Subtitle>
        {hint && <Hint text={hint} isFaded={isHintFaded} />}
        {docLink && <CardDocLink {...docLink} />}

        {leftPanelChildren}
      </Box>
      <Box {...rightPanelStyles}>{children}</Box>
    </CardContainer>
  );
}
