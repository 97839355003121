import React from 'react';
import { Button, Text } from '@chakra-ui/react';
import { TemplatePreview } from '@types';
import { Modal } from '@components/common';

interface EmailTemplatePreviewProps {
  isOpen: boolean;
  onClose: () => void;
  templatePreview: TemplatePreview;
}

export function EmailTemplatePreviewModal({
  isOpen,
  onClose,
  templatePreview,
}: EmailTemplatePreviewProps): JSX.Element {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size='2.5xl'
      title={templatePreview.subject}
      autoFocus={false}
    >
      <Modal.CloseButton />
      <Modal.Body>
        <Text>From: {templatePreview.from_email_address}</Text>

        <Text dangerouslySetInnerHTML={{ __html: templatePreview.body }} />
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={onClose} variant='ghost'>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
