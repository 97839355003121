export enum Statuses {
  Failed = 'failed',
  InProgress = 'in_progress',
  Complete = 'complete',
  NotStarted = 'not_started',
}

export type ServiceStatus = {
  status: Statuses;
};

export type DNSRecord = {
  from: string;
  to: string;
  verified: boolean;
  clerk_subdomain: string;
};

export type DNSRecords = Record<string, DNSRecord>;

export type DeployStatuses = {
  mail: ServiceStatus;
  ssl: ServiceStatus;
};

export type InstanceStatus = DeployStatuses & DNSRecords;
