import React from 'react';
import { Input } from '@chakra-ui/react';
import { Control, Controller } from 'react-hook-form';

type InputWithTransformProps = {
  control: Control;
  transform: {
    input: (v: string | null | undefined) => string;
    output: (e: React.ChangeEvent<HTMLInputElement>) => string;
  };
  name: string;
  placeholder: string;
};

export function InputWithTransform({
  control,
  transform,
  name,
  placeholder,
}: InputWithTransformProps): JSX.Element {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <Input
          // Chakra has that set on 36px and does not conform to plain props for some reason
          onChange={e => field.onChange(transform.output(e))}
          value={transform.input(field.value)}
          style={{ paddingInlineStart: 48 }}
          placeholder={placeholder}
          isInvalid={Boolean(fieldState?.error)}
          errorBorderColor={'danger.500'}
        />
      )}
    />
  );
}
