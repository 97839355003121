import { CardDualPanel } from '@components/common';
import { AvatarPreview } from '@components/customization/avatars/AvatarPreview';
import React from 'react';
import { Stack, Text } from '@chakra-ui/react';
import { AvatarBackgroundRadioGroup } from '@components/customization/avatars/AvatarBackgroundRadioGroup';
import { AvatarForegroundRadioGroup } from '@components/customization/avatars/AvatarForegroundRadioGroup';

export const UserAvatarPanel = (): JSX.Element => {
  return (
    <CardDualPanel
      title='Default user avatar'
      subtitle='Design the default user avatar.'
      leftPanelChildren={<AvatarPreview type='user' />}
    >
      <Stack spacing={4}>
        <Text fontWeight={500}>Background</Text>
        <AvatarBackgroundRadioGroup type='user' />
        <Text fontWeight={500}>Foreground</Text>
        <AvatarForegroundRadioGroup type='user' />
      </Stack>
    </CardDualPanel>
  );
};
