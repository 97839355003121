import React from 'react';
import { useRouter } from 'next/router';
import { PopupButton } from 'react-calendly';
import {
  ListItem,
  Text,
  UnorderedList,
  useTheme,
  Flex,
} from '@chakra-ui/react';
import { PlanCard } from './PlanCard';
import { CardDualPanel } from '../common';
import { usePaymentRequired } from '@context/PaymentRequiredContext';
import { useLocation, useDashboardCRUD, useToast } from '@hooks';
import { useOrganization } from '@clerk/nextjs';

type Plan = {
  id: string;
  mau_limit: number;
  mao_limit: number;
  object: string;
  title: string;
  description_html: string;
  action: string;
};

type AvailablePlansProps = {
  plans: Plan[];
  activePlanId: string;
  currentMau: number;
  currentMao: number;
  disabled?: boolean;
  handleDowngradeToFree: () => void;
};

type CreateSession = {
  url: string;
};

// formats `1000` as `1,000`
const formatNumber = number => new Intl.NumberFormat('en-IN').format(number);

export function AvailablePlans({
  plans = [],
  activePlanId,
  currentMau = 0,
  currentMao = 0,
  disabled = false,
  handleDowngradeToFree,
}: AvailablePlansProps): JSX.Element {
  const router = useRouter();
  const { applicationId } = useLocation();
  const { create } = useDashboardCRUD<CreateSession>();
  const { showErrorToast } = useToast();
  const { showModal } = usePaymentRequired();
  const [isLoading, setIsLoading] = React.useState(false);
  const theme = useTheme();
  const { membership, organization } = useOrganization();

  const isOrganizationAdmin = membership?.role === 'admin';
  const { colors, fontSizes, fontWeights } = theme;

  const handleClick = async (planId: string) => {
    try {
      setIsLoading(true);

      await create(
        `/v1/applications/${applicationId}/checkout/${planId}/validate`,
      );

      await handleCtaClick(planId);
    } catch {
      showModal({
        downgradeAction: () => handleCtaClick(planId),
        downgradeTo: plans.find(p => p.id === planId).title,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCtaClick = async (planId: string) => {
    setIsLoading(true);

    try {
      const res = await create(
        `/v1/applications/${applicationId}/checkout/${planId}/session`,
        {
          returnUrl: window.location.href,
        },
      );

      const pathname = new URL(window.location.href)?.pathname;
      if (res && res.url && !(res.url as string).includes(pathname)) {
        void router.push(res.url);
        return;
      }

      handleDowngradeToFree();
    } catch (_) {
      showErrorToast('Something went wrong, please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const availablePlans = plans.filter(p => p.id !== activePlanId);
  const isDisabledForOrganizationMembers = organization && !isOrganizationAdmin;

  return (
    <CardDualPanel
      title='Available plans'
      subtitle='View available plans and change subscription'
    >
      {availablePlans.map(
        ({ title, id, mau_limit, mao_limit, description_html, action }) => {
          const ctaText = `${
            action.charAt(0).toUpperCase() + action.slice(1)
          } to ${title}`;
          const exceededMau = mau_limit > 0 && currentMau > mau_limit;
          const exceededMao = mao_limit > 0 && currentMao > mao_limit;
          const description = (
            <>
              <Flex>
                {!!mau_limit && (
                  <Text color={exceededMau ? 'danger.500' : null}>
                    {formatNumber(mau_limit)} MAU{!!mao_limit && ', '}
                  </Text>
                )}

                {!!mao_limit && (
                  <Text color={exceededMao ? 'danger.500' : null} ml={1}>
                    {formatNumber(mao_limit)} MAO
                  </Text>
                )}
              </Flex>
              <Text dangerouslySetInnerHTML={{ __html: description_html }} />
            </>
          );

          return (
            <PlanCard
              key={id}
              title={title}
              description={description}
              ctaText={ctaText}
              ctaDisabled={
                exceededMao ||
                exceededMau ||
                isLoading ||
                disabled ||
                isDisabledForOrganizationMembers
              }
              ctaOnClick={() => handleClick(id)}
              isDisabledForOrganizationMembers={
                isDisabledForOrganizationMembers
              }
            />
          );
        },
      )}
      <PlanCard
        title='Enterprise'
        description={
          <>
            <Text>All Business features, plus:</Text>
            <UnorderedList>
              <ListItem>Volume discounts</ListItem>
              <ListItem>Dedicated support team</ListItem>
              <ListItem>SOC2, HIPAA compliance</ListItem>
              <ListItem>Security reports</ListItem>
              <ListItem>Custom solutions on-demand</ListItem>
            </UnorderedList>
          </>
        }
        ctaComponent={
          <PopupButton
            styles={{
              color: colors.primary[500],
              fontSize: fontSizes.xs,
              fontWeight: fontWeights.medium,
            }}
            url='https://calendly.com/braden-clerk/intro-call'
            rootElement={document.getElementById('__next')}
            text='Contact us'
          />
        }
      />
    </CardDualPanel>
  );
}
