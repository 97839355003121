import React from 'react';
import { Box, Flex, Image, Text } from '@chakra-ui/react';

interface ComingSoonIntegrationCardProps {
  name: string;
  logo: string;
  websiteUrl: string;
}

export const ComingSoonIntegrationCard = ({
  name,
  logo,
  websiteUrl,
}: ComingSoonIntegrationCardProps): JSX.Element => (
  <Flex key={name} py='4' px='8'>
    <Image boxSize='9' src={logo} alt={name} mr='4' />

    <Box>
      <Text textStyle='md-medium' mb='0.5'>
        {name}
      </Text>

      <Text textStyle='sm-normal' color='gray.500'>
        {websiteUrl}
      </Text>
    </Box>
  </Flex>
);
