import React from 'react';
import { Button, Text, VStack } from '@chakra-ui/react';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { FormSubmitButton } from './StickyBottomCTA';
import { InformationBox } from './InformationBox';
import { Modal } from '@components/common';
import { ConfirmationModal, ConfirmationModalProps } from '@components/modal';

interface ApplyProdChangesModalProps {
  isOpen: boolean;
  onClose: () => void;
  isLoading?: boolean;
  formId: string;
  warningMsg?: string;
}

const ProdChangesMessage = ({
  warningMsg,
}: {
  warningMsg?: string;
}): JSX.Element => {
  return (
    <InformationBox icon={ExclamationCircleIcon} iconColor='warning.300'>
      <VStack align='start' spacing='1'>
        <Text textStyle='md-semibold'>
          The applied changes will affect the deployed production instance.
        </Text>
        <Text textStyle='md-normal'>
          {warningMsg && (
            <>
              {warningMsg}
              <br />
            </>
          )}
          Are you sure you want to proceed?
        </Text>
      </VStack>
    </InformationBox>
  );
};

//We can use this modal to automatically call the submit in a form. Because it uses button type=submit
export function ApplyProdChangesModal({
  isOpen,
  onClose,
  formId,
  isLoading,
  warningMsg,
}: ApplyProdChangesModalProps): JSX.Element {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title='Confirmation'
      autoFocus={false}
    >
      <Modal.Body>
        <ProdChangesMessage warningMsg={warningMsg} />
      </Modal.Body>

      <Modal.Footer justifyContent='space-between' flexDir='row-reverse'>
        <FormSubmitButton
          formId={formId}
          isLoading={isLoading}
          onClick={onClose}
          tabIndex={1}
        />

        <Button onClick={onClose} variant='ghost' tabIndex={1}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

type ApplyProdChangesModalWithCustomParamsProps = Pick<
  ConfirmationModalProps,
  'isOpen' | 'handleCancelation' | 'handleConfirmation'
> &
  Omit<
    Partial<ConfirmationModalProps>,
    'isOpen' | 'handleCancelation' | "'handleConfirmation'"
  >;

//We use this modal when we want to pass the handleConfimartion on our own.
//We can use this modal when we are not inside in a form or the form is not using the submit method
export const ApplyProdChangesModalWithCustomParams = ({
  isOpen,
  handleConfirmation,
  handleCancelation,
  message = <ProdChangesMessage />,
  cancelationMessage = 'Cancel',
  headerTitle = 'Confirmation',
  confirmationMessage = 'Apply Changes',
  colorScheme,
}: ApplyProdChangesModalWithCustomParamsProps): JSX.Element => {
  return (
    <ConfirmationModal
      colorScheme={colorScheme}
      headerTitle={headerTitle}
      message={message}
      isOpen={isOpen}
      cancelationMessage={cancelationMessage}
      confirmationMessage={confirmationMessage}
      handleConfirmation={handleConfirmation}
      handleCancelation={handleCancelation}
    />
  );
};
