import React from 'react';

import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Spacer,
} from '@chakra-ui/react';
import {
  ChevronDownIcon,
  ClipboardIcon,
  EyeIcon,
  ReplyIcon,
} from '@heroicons/react/solid';

import { useFormContext } from 'react-hook-form';

import { titleize } from '@utils';

import { Instance, TemplateExtended } from '@types';

interface TemplateFullCTAProps {
  template: TemplateExtended;
  otherInstances: Instance[];
  onPreview: () => void;
  onCopyPrompt: (Instance) => void;
  onRevertPrompt: () => void;
  onReset: () => void;
}

const buttonStyles = {
  color: 'gray.300',
  variant: 'ghost',
  _focus: {
    boxShadow: 'none',
  },
  _hover: {
    color: 'unset',
    background: 'gray.50',
  },
  _active: {
    bg: 'unset',
  },
};

export function TemplateFullCTAs({
  template,
  otherInstances,
  onPreview,
  onCopyPrompt,
  onRevertPrompt,
  onReset,
}: TemplateFullCTAProps): JSX.Element {
  const {
    formState: { isDirty, isSubmitting },
  } = useFormContext();

  return (
    <>
      {/* TODO show as loading when preview endpoint is hit */}

      <Button
        aria-label='Preview'
        isDisabled={isSubmitting}
        onClick={onPreview}
        textTransform='none'
        {...buttonStyles}
        mr='4'
      >
        <Icon as={EyeIcon} boxSize='5' mr='1' />
        Preview
      </Button>

      {otherInstances.length > 0 && (
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<Icon as={ChevronDownIcon} boxSize='5' />}
            aria-label='Copy to another instance'
            textTransform='none'
            {...buttonStyles}
          >
            <Icon as={ClipboardIcon} boxSize='5' mr='1' />
            Copy
          </MenuButton>

          <MenuList>
            <MenuGroup title='Copy to'>
              {otherInstances.map(otherInstance => (
                <MenuItem
                  key={otherInstance.environment_type}
                  onClick={() => onCopyPrompt(otherInstance)}
                >
                  {titleize(otherInstance.environment_type)}
                </MenuItem>
              ))}
            </MenuGroup>
          </MenuList>
        </Menu>
      )}

      <Button
        aria-label='Revert'
        role='group'
        isDisabled={!template.can_revert || isSubmitting}
        onClick={onRevertPrompt}
        textTransform='none'
        {...buttonStyles}
      >
        <Icon
          as={ReplyIcon}
          boxSize='5'
          mr='1'
          _groupHover={{ color: 'red' }}
        />
        Revert
      </Button>

      <Spacer />

      <Button
        type='button'
        variant='ghost'
        tabIndex={-1}
        mr='4'
        onClick={onReset}
        isDisabled={!isDirty || isSubmitting}
      >
        Reset
      </Button>

      <Button type='submit' isLoading={isSubmitting}>
        Apply changes
      </Button>
    </>
  );
}
