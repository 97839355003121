import React from 'react';
import { VStack, Flex, Text, Icon } from '@chakra-ui/react';
import { CardDualPanel, PageLoader } from '@components/common';
import { UserSettings } from '@types';
import { SwitchInfoField } from '@components/common/Switch';
import { usePaymentRequired } from '@context/PaymentRequiredContext';
import {
  useDashboardCRUD,
  useDashboardSWR,
  useInstance,
  useSupportedFeature,
} from '@hooks';
import { SUPPORTED_FEATURES } from '@constants';
import { IdentifierInput } from './IdentifierInput';
import { InformationCircleIcon } from '@heroicons/react/outline';

const FORM_ID = 'restrictions_allowlist_form';

type RestrictionsResponse = {
  allowlist: boolean;
  blocklist: boolean;
};

export function Allowlist(): JSX.Element {
  const { instance } = useInstance();
  const { isSupported, isPremium, isUnsupportedAndEnabled } =
    useSupportedFeature();
  const instanceId = instance?.id;
  const { showModal: showUpgradePlanModal } = usePaymentRequired();

  const [isUpdatingAllowlist, setIsUpdatingAllowlist] = React.useState(false);

  const allowlistPath = `/v1/instances/${instanceId}/user_settings/restrictions`;

  const { update } = useDashboardCRUD();
  const { data: settings, mutate: mutateUserSettings } =
    useDashboardSWR<UserSettings>(
      instanceId ? `/v1/instances/${instanceId}/user_settings` : null,
    );

  const isLoading = !settings;

  if (isLoading) {
    return <PageLoader />;
  }

  const isAllowlistEnabled = settings.restrictions.allowlist.enabled;
  const isAllowlistSupported = isSupported(SUPPORTED_FEATURES.allowlist);

  const onChange = async e => {
    const feature = SUPPORTED_FEATURES.allowlist;

    if (
      !isAllowlistSupported &&
      !isUnsupportedAndEnabled({
        feature,
        currentValue: isAllowlistEnabled,
      })
    ) {
      return showUpgradePlanModal({
        features: [feature],
      });
    }

    if (isPremium(feature) && !isAllowlistEnabled) {
      showUpgradePlanModal();
    }

    setIsUpdatingAllowlist(true);

    try {
      await mutateUserSettings(
        async settings => {
          const restrictions = (await update(allowlistPath, {
            allowlist: e.target.checked,
          })) as RestrictionsResponse;
          return {
            ...settings,
            restrictions: {
              allowlist: { enabled: restrictions.allowlist },
              blocklist: { enabled: restrictions.blocklist },
            },
          };
        },
        { revalidate: false },
      );
    } catch (_) {
      return;
    } finally {
      setIsUpdatingAllowlist(false);
    }
  };

  return (
    <CardDualPanel
      width='100%'
      title='Allowlist'
      subtitle='Restrict sign-ups to accounts with pre-approved identifiers'
    >
      <VStack align='stretch'>
        <Flex bg='gray.50' borderRadius={4} px={4} py={2} alignItems='center'>
          <Icon
            as={InformationCircleIcon}
            color='gray.300'
            boxSize={5}
            mr={2}
          />
          <Text textStyle='sm-normal' color='gray.500'>
            When enabled, only identifiers on the allowlist will be able to sign
            up
          </Text>
        </Flex>

        <SwitchInfoField
          name='allowlist'
          title='Enable allowlist'
          description='Enable to specify which account identifiers should be pre-approved to sign up'
          onChange={onChange}
          isDisabled={isUpdatingAllowlist}
          isChecked={isAllowlistEnabled}
          isPremiumFeature={isPremium(SUPPORTED_FEATURES.allowlist)}
        />

        <IdentifierInput
          name='allowlist'
          formId={FORM_ID}
          isSupported={isAllowlistSupported}
        />
      </VStack>
    </CardDualPanel>
  );
}
