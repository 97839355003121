import React from 'react';
import { BillingModal, InfoBillingModal } from '@components/common';
import { useDisclosure } from '@chakra-ui/react';
import { useInstance, useUnsafeMetadata } from '@hooks';

interface ModalData {
  features?: string[];
  downgradeAction?: () => Promise<void>;
  downgradeTo?: string;
  isValidatingCloningInstance?: boolean;
  exceptionForUpgradeModal?: boolean;
  callbackAfterClose?: () => void;
}

interface PaymentRequiredContext {
  showModal: (params?: ModalData) => void;
}

const PaymentRequiredContext =
  React.createContext<PaymentRequiredContext>(null);

function PaymentRequiredProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const {
    isOpen: isUpgradeModalOpen,
    onOpen: onUpgradeModalOpen,
    onClose: onUpgradeModalClose,
  } = useDisclosure();
  const [modalInfo, setModalInfo] = React.useState<ModalData>(null);
  const { instance } = useInstance();
  const { shouldShowInfoModal } = useUnsafeMetadata();

  const isDevInstance = instance?.environment_type === 'development';

  const value: PaymentRequiredContext = React.useMemo(() => {
    return {
      showModal: params => {
        const {
          features,
          downgradeAction,
          downgradeTo,
          exceptionForUpgradeModal,
          callbackAfterClose,
        } = params || {};
        setModalInfo({
          features,
          downgradeAction,
          downgradeTo,
          exceptionForUpgradeModal,
          callbackAfterClose,
        });

        onUpgradeModalOpen();
      },
    };
  }, [onUpgradeModalOpen]);

  // exceptionForUpgradeModal is used in order to handle the exception of showing the
  // billing modal we normally show in production, but in dev instances just before we create a prod instance
  const hasExceptionForUpgradeModal =
    isDevInstance && modalInfo?.exceptionForUpgradeModal;

  const hasSeenBillingModal = !shouldShowInfoModal(instance?.application_id);

  const showInfoBillingModal =
    isDevInstance &&
    !hasSeenBillingModal &&
    !modalInfo?.exceptionForUpgradeModal;

  return (
    <PaymentRequiredContext.Provider value={value}>
      {showInfoBillingModal && (
        <InfoBillingModal
          isOpen={isUpgradeModalOpen}
          onClose={onUpgradeModalClose}
          callbackAfterClose={modalInfo?.callbackAfterClose}
        />
      )}

      {(!isDevInstance || hasExceptionForUpgradeModal) && (
        <BillingModal
          features={modalInfo?.features}
          downgradeAction={modalInfo?.downgradeAction}
          downgradeTo={modalInfo?.downgradeTo}
          isOpen={isUpgradeModalOpen}
          onClose={onUpgradeModalClose}
        />
      )}

      {children}
    </PaymentRequiredContext.Provider>
  );
}

function usePaymentRequired(): PaymentRequiredContext {
  const context = React.useContext(PaymentRequiredContext);
  if (context === undefined) {
    throw new Error(
      'usePaymentRequired must be used within a PaymentRequiredProvider',
    );
  }

  return {
    ...context,
  };
}

export { PaymentRequiredProvider, usePaymentRequired };
