import React from 'react';

import { InputBox, InputBoxProps } from '@components/common';

import {
  Button,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  useClipboard,
} from '@chakra-ui/react';

type CopyTextInputProps = {
  valueToCopy: string;
  label: string;
  description?: string;
  helpText?: JSX.Element;
  inputProps?: InputProps;
} & InputBoxProps;

export const CopyTextInput = ({
  valueToCopy,
  label,
  description,
  helpText,
  inputProps,
  ...rest
}: CopyTextInputProps): JSX.Element => {
  const { onCopy, hasCopied } = useClipboard(valueToCopy);

  return (
    <InputBox description={description} label={label} {...rest}>
      <InputGroup mt={description ? 2 : 0}>
        <Input
          textOverflow='ellipsis'
          isReadOnly={true}
          tabIndex={-1}
          _readOnly={{ color: 'rgba(0, 0, 0, 0.65)' }}
          _hover={{ borderColor: 'none' }}
          bg={'gray.50'}
          value={valueToCopy}
          {...inputProps}
        />

        <InputRightElement width='49px'>
          <Button fontSize='xxs' variant='link' onClick={onCopy}>
            {hasCopied ? 'Copied' : 'Copy'}
          </Button>
        </InputRightElement>
      </InputGroup>

      {helpText}
    </InputBox>
  );
};
