import React from 'react';
import { stripProtocol } from '@utils/url';

export interface TLDData {
  domain: string;
  domainWithoutSuffix: string;
  hostname: string;
  isIcann: boolean;
  isIp: boolean;
  isPrivate: boolean;
  publicSuffix: string;
  subdomain: string;
  isETLD: boolean;
}

// More info at https://web.dev/same-site-same-origin/
function isDomainETLD(result: TLDData) {
  if (result.domain === null) {
    return false;
  }
  return result.domain?.length > 0 && result.domain === result.hostname;
}

export function dynamicTLDTSImport() {
  return import(
    /* webpackIgnore: true */
    // @ts-ignore
    'https://unpkg.com/tldts/dist/es6/index.js?module'
  );
}

export function useTLDParser(): { parse: (url: string) => Partial<TLDData> } {
  const tldParser = React.useRef(null);

  React.useEffect(() => {
    async function loadTLDParser() {
      // TODO: Detect browser capabilities about dynamic imports
      try {
        tldParser.current = await dynamicTLDTSImport();
      } catch (err) {
        console.error(err);
      }
    }
    loadTLDParser();
  }, []);

  const parse = React.useCallback(function parse(
    url: string,
  ): Partial<TLDData> {
    url = stripProtocol(url);

    if (tldParser.current) {
      const result: TLDData = tldParser.current.parse(url, {
        allowPrivateDomains: true,
      });

      result.isETLD = isDomainETLD(result);
      result.subdomain = result.subdomain || '';

      return result;
    }

    // Fallback:Poor mans domain parsing in case PLS library fails to load
    const parts = url.split('.');

    if (parts.length > 2) {
      const [subdomain, ...domainParts] = parts;

      return {
        subdomain,
        domain: domainParts.join('.'),
        isETLD: false,
      };
    }

    return {
      subdomain: 'api',
      domain: url,
      isETLD: true,
    };
  },
  []);

  return { parse };
}
