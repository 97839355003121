import React from 'react';
import { Button, Flex, Icon, Text, useDisclosure } from '@chakra-ui/react';
import { CardDualPanel } from '@components/common';
import { ExclamationIcon } from '@heroicons/react/solid';
import { Modal } from '@components/common';

export type CardDeleteUserWithConfirmationProps = {
  delUser: () => void;
};

export const CardDeleteUserWithConfirmation = ({
  delUser,
}: CardDeleteUserWithConfirmationProps): JSX.Element => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <CardDualPanel
        title='Delete user'
        subtitle='This action is permanent and cannot be undone'
        rightPanelStyles={{ flex: 'unset' }}
      >
        <Flex justify='flex-end'>
          <Button
            onClick={onOpen}
            colorScheme='danger'
            leftIcon={<Icon as={ExclamationIcon} />}
          >
            Delete user
          </Button>
        </Flex>
      </CardDualPanel>

      <Modal isOpen={isOpen} onClose={onClose} title='Delete user'>
        <Modal.Body>
          <Text textStyle='md-normal'>
            Are you sure you want to delete this user?
          </Text>

          <Text textStyle='md-normal'>
            This action is permanent and cannot be undone.
          </Text>
        </Modal.Body>

        <Modal.Footer flexDir='row-reverse'>
          <Button
            onClick={delUser}
            colorScheme='danger'
            leftIcon={<Icon as={ExclamationIcon} />}
          >
            Delete user
          </Button>

          <Button onClick={onClose} variant='ghost' autoFocus>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
