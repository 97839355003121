import React from 'react';
import { OrganizationSwitcher } from '@clerk/nextjs';
import {
  useLocation,
  useVisibleInViewport,
  useCustomOrganizationSwitcherProps,
} from '@hooks';
import {
  Box,
  BoxProps,
  Flex,
  BreadcrumbItem,
  Skeleton,
} from '@chakra-ui/react';
import { LogoLink } from '@components/header/LogoLink';
import { ApplicationInstance, Container } from '@components/header/Breadcrumb';
import { Navigation } from '@components/header/Navigation';
import { HeaderContextProvider } from './headerContext';
import { useAnalytics } from '@hooks/useAnalytics';

export function Header({ banner = null }): JSX.Element {
  const { track } = useAnalytics();
  const { isHome, instanceId } = useLocation();
  const headerRef = React.useRef<HTMLDivElement>();
  const { isVisible: isSticky } = useVisibleInViewport(headerRef, {
    threshold: 0,
    rootMargin: '-40px',
  });
  const {
    key: switcherKey,
    billingUrlHref,
    billingUrlText,
    membershipLimit,
    isLoaded,
  } = useCustomOrganizationSwitcherProps();

  const stickyStyles: BoxProps = {
    py: 0,
    boxShadow: 'header',
    bg: 'whiteAlpha.600',
  };

  return (
    <>
      <HeaderContextProvider value={{ isSticky }}>
        {/* A spacer to which we bind the scroll probe */}
        <Box ref={headerRef} w='full' h='100px' />
        {!isHome && banner}
        <Box
          as='header'
          zIndex='sticky'
          pos='fixed'
          top={banner ? '36px' : 0}
          left={0}
          w='full'
          py={5}
          px={{ base: 3, '2xl': 16 }}
          borderBottom='1px solid'
          borderColor='gray.200'
          transition='all 150ms'
          // Firefox 96 doesn't support https://caniuse.com/?search=backdrop-filter
          sx={{
            '@supports (backdrop-filter: blur(9px))': {
              backdropFilter: 'blur(9px)',
            },
            '@supports not (backdrop-filter: blur(9px))': {
              backgroundColor: 'white',
            },
          }}
          {...(isSticky ? stickyStyles : {})}
        >
          <Flex
            maxW='container.xxl'
            px={8}
            display={{ base: 'flex', lg: 'flex' }}
            justify='space-between'
            align='center'
            mx='auto'
            minH='60px'
          >
            <Container>
              <BreadcrumbItem>
                <LogoLink />
              </BreadcrumbItem>
              <BreadcrumbItem>
                <Skeleton
                  isLoaded={isLoaded}
                  minW={isLoaded ? 'fit-content' : '9rem'}
                >
                  <Flex
                    lineHeight={1}
                    onClick={() => {
                      track('Dashboard_Top Nav_Menu Item Clicked', {
                        surface: 'Dashboard',
                        location: 'Top Nav',
                        instanceId,
                        clickedOn: 'Organization',
                      });
                    }}
                  >
                    <OrganizationSwitcher
                      afterSwitchOrganizationUrl='/'
                      key={switcherKey}
                      // @ts-expect-error
                      __unstable_manageBillingMembersLimit={membershipLimit}
                      __unstable_manageBillingUrl={billingUrlHref}
                      __unstable_manageBillingLabel={billingUrlText}
                    />
                  </Flex>
                </Skeleton>
              </BreadcrumbItem>
              {isHome ? null : <ApplicationInstance />}
            </Container>
            <Navigation />
          </Flex>
        </Box>
      </HeaderContextProvider>
    </>
  );
}
