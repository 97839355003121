import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import { DEPLOY_STATUS } from '../deployStatus';
import { Statuses } from '@types';

type StatusBoxProps = {
  status: Statuses | 'unconfigured';
} & BoxProps;

export function StatusBox({ status, ...rest }: StatusBoxProps): JSX.Element {
  return (
    <Box
      as='span'
      boxSize={2}
      bg={DEPLOY_STATUS[status]?.color}
      pos='relative'
      rounded='full'
      {...rest}
    />
  );
}
