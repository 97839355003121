import React from 'react';
import { Grid, Flex } from '@chakra-ui/react';

import { SocialButtonIcon, SocialButtonBlock } from './SocialButtons';
import { AnimatePresence } from 'framer-motion';
import { motion } from 'framer-motion';

const SOCIAL_BUTTON_BLOCK_THRESHOLD = 2;

const ButtonGrid = (props: React.PropsWithChildren<any>) => {
  return (
    <Grid
      as={motion.div}
      gap={2}
      templateColumns='repeat(auto-fit, minmax(3rem, 1fr))'
      layout
      layoutId='buttonGrid'
    >
      {props.children}
    </Grid>
  );
};

const ButtonRows = (props: React.PropsWithChildren<any>) => {
  return (
    <Flex layoutId='buttonRow' layout as={motion.div} flexDirection='column'>
      {props.children}
    </Flex>
  );
};

const SocialProviders = ({
  socialProviders,
}: {
  socialProviders: string[];
}): JSX.Element => {
  const WrapperElement =
    socialProviders?.length > SOCIAL_BUTTON_BLOCK_THRESHOLD
      ? ButtonGrid
      : ButtonRows;
  const ButtonElement =
    socialProviders?.length > SOCIAL_BUTTON_BLOCK_THRESHOLD
      ? SocialButtonIcon
      : SocialButtonBlock;

  return (
    <WrapperElement>
      <AnimatePresence>
        {socialProviders.map(provider => (
          <ButtonElement key={provider} provider={provider} />
        ))}
      </AnimatePresence>
    </WrapperElement>
  );
};

export default SocialProviders;
