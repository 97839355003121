import { stripProtocol as stripProtocolHelper } from '@utils';
import { useInstance } from '@hooks';

export function useAccountsUrl(stripProtocol = true): string {
  const { instance } = useInstance();

  if (!instance) {
    return '';
  }

  const accountsUrl = instance.active_domain?.accounts_portal_url;

  if (stripProtocol) {
    return stripProtocolHelper(accountsUrl);
  }
  return accountsUrl;
}
