import React from 'react';

import {
  DebouncedSearchInput,
  DropdownFilter,
  DropdownFilters,
} from '@components/common';
import { HStack, Box } from '@chakra-ui/react';

import { useRouter } from 'next/router';
import { useLocation } from '@hooks';

import { getRoleFiltersFromParams } from '@components/organizations/memberships';

const Filters = (): JSX.Element => {
  const router = useRouter();
  const { query } = useLocation();

  const roleFilters = React.useMemo(() => {
    return getRoleFiltersFromParams(query.role);
  }, [query.role]);

  const onSearchChange = (search: string) => {
    void router.push({
      query: {
        ...router.query,
        page: 1,
        query: search,
      },
    });
  };

  const onFilterChange = (filters: DropdownFilters) => {
    const roles = Object.keys(filters).reduce((prev, currentKey) => {
      const { value } = filters[currentKey];
      if (value) {
        prev.push(currentKey);
        return prev;
      }
      return prev;
    }, []);
    void router.push({
      query: {
        ...router.query,
        page: 1,
        role: roles,
      },
    });
  };

  return (
    <HStack flex={1}>
      <DropdownFilter
        title='Role'
        filters={roleFilters}
        onChange={onFilterChange}
      />
      <Box width='100%' maxWidth={72}>
        <DebouncedSearchInput
          value={(query.query as string) || ''}
          onChange={onSearchChange}
        />
      </Box>
    </HStack>
  );
};

export default Filters;
