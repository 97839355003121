import React from 'react';
import { Flex, FlexProps, Icon, Text } from '@chakra-ui/react';
import { ExclamationCircleIcon } from '@heroicons/react/solid';

type InformationBoxProps = {
  children: string | JSX.Element;
  iconColor?: string;
  icon?: React.FunctionComponent;
} & FlexProps;

export const InformationBox = ({
  children,
  icon = ExclamationCircleIcon,
  iconColor = 'gray.300',
  bgColor = 'gray.50',
  ...rest
}: InformationBoxProps): JSX.Element => (
  <Flex
    align='flex-start'
    p='3'
    gridGap='2'
    rounded='md'
    bg={bgColor}
    {...rest}
  >
    <Icon as={icon} color={iconColor} mt='0.5' />

    {typeof children === 'string' ? (
      <Text textStyle='md-normal' display='inline-block'>
        {children}
      </Text>
    ) : (
      children
    )}
  </Flex>
);
