import React from 'react';
import {
  Badge,
  Box,
  Flex,
  HStack,
  Spacer,
  Text,
  VStack,
  Link,
} from '@chakra-ui/react';
import { CardContainer, Hint, Subtitle } from './CardElements';
import {
  CardDocLink,
  CardDocLinkProps,
} from '@components/common/Card/CardDocLink';
import { InformationBox } from '@components/common/InformationBox';
import { CardWithSwitchError } from './CardErrorState/CardWithSwitchError';

type CardWithSwitchProps = {
  title: string | JSX.Element;
  subtitle?: string | JSX.Element;
  subtitleLink?: string;
  info?: string | JSX.Element;
  hint?: string;
  isHintFaded?: boolean;
  disabled?: boolean;
  nonClickable?: boolean;
  opaque?: boolean;
  showPremiumBadge?: boolean;
  docLink?: CardDocLinkProps;
  children: React.ReactNode;
  hasError?: boolean;
  mutate?: () => void;
};

export function CardWithSwitch(props: CardWithSwitchProps): JSX.Element {
  const {
    title,
    subtitle,
    subtitleLink,
    info,
    hint,
    isHintFaded = true,
    showPremiumBadge,
    children,
    docLink,
    hasError = false,
    mutate,
    ...rest
  } = props;

  const switchEl = React.Children.only(children) as any;
  const switchChild = React.cloneElement(switchEl, {
    ...switchEl.props,
    disabled: rest.nonClickable || switchEl.props.isDisabled,
  });

  if (hasError) {
    return (
      <CardWithSwitchError
        title={title}
        subtitle={subtitle}
        subtitleLink={subtitleLink}
        mutate={mutate}
      />
    );
  }

  return (
    <CardContainer {...rest}>
      <HStack w='full' justify='space-between' align='flex-start' spacing={8}>
        <VStack spacing={2} align='flex-start'>
          <Flex align='center'>
            {typeof title === 'string' ? (
              <Text textStyle='lg-medium'>{title}</Text>
            ) : (
              title
            )}
            {showPremiumBadge && <Badge ml='2'>Premium</Badge>}
          </Flex>
          <Flex>
            {subtitle && (
              <Subtitle>
                {subtitle}
                {subtitleLink && (
                  <Link
                    isExternal
                    textStyle='sm-normal'
                    color='primary.500'
                    href={subtitleLink}
                    ml={1}
                    fontWeight='500'
                  >
                    Learn more
                  </Link>
                )}
              </Subtitle>
            )}
          </Flex>
          {info && (
            <InformationBox bgColor='white' px='0px' py='2' color='gray.300'>
              <Text
                textStyle='sm-normal'
                display='inline-block'
                color='gray.500'
                mt='0.5'
              >
                {info}
              </Text>
            </InformationBox>
          )}
          {hint && (
            <>
              <Spacer />
              <Hint text={hint} isFaded={isHintFaded} />
            </>
          )}
          {docLink && (
            <>
              <Spacer />
              <CardDocLink {...docLink} />
            </>
          )}
        </VStack>
        <Box>{switchChild}</Box>
      </HStack>
    </CardContainer>
  );
}
