export const colors = {
  gray: {
    '50': '#0000000a',
    '100': '#00000014',
    '200': '#00000029',
    '300': '#0000004d',
    '400': '#00000080',
    '500': '#6E6E6E',
    '600': '#666666',
    '700': '#4D4D4D',
    '800': '#333333',
    '900': '#1A1A1A',
  },
  primary: {
    '50': '#f8f6ff',
    '100': '#f0edff',
    '200': '#dad1ff',
    '300': '#c4b5ff',
    '400': '#987eff',
    '500': '#6c47ff',
    '600': '#6140e6',
    '700': '#5135bf',
    '800': '#412b99',
    '900': '#35237d',
  },
  danger: {
    '50': '#ffe4e3',
    '100': '#ffe8e8',
    '200': '#ff8e8b',
    '300': '#ff615d',
    '400': '#ff3f3a',
    '500': '#ff1d17',
    '600': '#ff1a14',
    '700': '#ff1511',
    '800': '#ff110d',
    '900': '#ff0a07',
  },
  warning: {
    '50': '#fef8e3',
    '100': '#fdf2cb',
    '200': '#fae28c',
    '300': '#f8d65d',
    '400': '#f7cd3b',
    '500': '#f5c418',
    '600': '#f4be15',
    '700': '#f2b611',
    '800': '#f0af0e',
    '900': '#eea208',
  },
  success: {
    '50': '#e7f9e7',
    '100': '#ccefcc',
    '200': '#9be59b',
    '300': '#72da72',
    '400': '#54d254',
    '500': '#36ca36',
    '600': '#30c530',
    '700': '#29bd29',
    '800': '#22b722',
    '900': '#16ab16',
  },
  // Adding this temporarily for the needs of create new prod instance and will come back with Cooper's feedback
  orange: {
    '50': '#FFF3E0',
    '100': '#FFE0B2',
    '200': '#FFCC80',
    '300': '#FFB74D',
    '400': '#FFA726',
    '500': '#FF9800',
    '600': '#FB8C00',
    '700': '#F57C00',
    '800': '#EF6C00',
    '900': '#E65100',
  },
};
