import React from 'react';
import { Flex, useTheme } from '@chakra-ui/react';
import { useLocation } from '@hooks';
import { Text, Link } from '@chakra-ui/react';
import { WarningIcon, RoundWarning } from '@clerk-ui/assets/icons';
import NextLink from 'next/link';
import { billingPath } from '@utils';

export const Banner = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const theme = useTheme();

  return (
    <Flex
      pos='fixed'
      top={0}
      left={0}
      right={0}
      background='white'
      height='36px'
      zIndex={theme.zIndices.docked}
      justify='center'
      align='center'
    >
      {children}
    </Flex>
  );
};

export const UserExcededContent = (): JSX.Element => {
  const { applicationId, instanceId } = useLocation();
  const theme = useTheme();
  const { colors } = theme;

  return (
    <Banner>
      <WarningIcon stroke='white' fill={colors.red[500]} />
      <Text textStyle='sm-normal' color='black.500'>
        <strong>User count exceeded</strong>. Upgrade plan to prevent service
        interruption.{' '}
        <NextLink href={billingPath(applicationId, instanceId)}>
          <Link textStyle='sm-normal' color='primary.500' fontWeight={500}>
            View plans
          </Link>
        </NextLink>
      </Text>
    </Banner>
  );
};

export const GracePeriodBanner = (): JSX.Element => {
  const { applicationId, instanceId } = useLocation();
  return (
    <Banner>
      <RoundWarning stroke='white' />
      <Text textStyle='sm-normal' color='black.500' ml={1}>
        This application is using features unsupported by the current plan.{' '}
        <NextLink href={billingPath(applicationId, instanceId)}>
          <Link textStyle='sm-normal' color='primary.500'>
            Upgrade your plan
          </Link>
        </NextLink>{' '}
        or contact us to avoid service interruption.
      </Text>
    </Banner>
  );
};

export const GracePeriodBannerExpired = (): JSX.Element => {
  const { applicationId, instanceId } = useLocation();
  const theme = useTheme();
  const { colors } = theme;

  return (
    <Banner>
      <WarningIcon stroke='white' fill={colors.red[500]} />
      <Text textStyle='sm-normal' color='black.500' ml={1}>
        Features unsupported by current plan have been disabled and may lead to
        breakage.{' '}
        <NextLink href={billingPath(applicationId, instanceId)}>
          <Link textStyle='sm-normal' color='primary.500'>
            Upgrade your plan
          </Link>
        </NextLink>{' '}
        or contact us to restore them for you.
      </Text>
    </Banner>
  );
};
