import React from 'react';
import cn from 'classnames';
//@ts-ignore
import styles from './Link.module.scss';

export type LinkProps = {
  href?: string;
  target?: string;
  children: React.ReactNode;
} & React.HTMLProps<HTMLAnchorElement>;

export const Link: React.FC<LinkProps> = ({
  className,
  children,
  href,
  ...rest
}: LinkProps) => {
  return (
    <a href={href} className={cn(styles.link, className)} {...rest}>
      {children}
    </a>
  );
};
