import React from 'react';
import { Flex, HStack, Text, Link } from '@chakra-ui/react';
import { CardContainer, Subtitle } from '../CardElements';

type CardWithSwitchProps = {
  title: string | JSX.Element;
  subtitle?: string | JSX.Element;
  subtitleLink?: string;
  mutate?: () => void;
};

export function CardWithSwitchError(props: CardWithSwitchProps): JSX.Element {
  const { title, subtitle, subtitleLink, mutate, ...rest } = props;

  return (
    <CardContainer {...rest}>
      <HStack w='full' justify='space-between' spacing={8}>
        <Flex flexDirection='column'>
          <Flex align='center'>
            {typeof title === 'string' ? (
              <Text textStyle='lg-medium'>{title}</Text>
            ) : (
              title
            )}
          </Flex>
          <Flex>
            {subtitle && (
              <Subtitle>
                {subtitle}
                {subtitleLink && (
                  <Link
                    isExternal
                    textStyle='sm-normal'
                    color='primary.500'
                    href={subtitleLink}
                    ml={1}
                    fontWeight='500'
                  >
                    Learn more
                  </Link>
                )}
              </Subtitle>
            )}
          </Flex>
        </Flex>

        <Flex align='center' justify='center' h='100%' flexDirection='column'>
          <Text textStyle='sm-normal' color='gray.500' textAlign='center'>
            The resource could not be loaded.{' '}
          </Text>
          <Link
            color='primary.500'
            onClick={() => void mutate()}
            textStyle='sm-normal'
          >
            Refetch resource
          </Link>
        </Flex>
      </HStack>
    </CardContainer>
  );
}
