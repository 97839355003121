import React from 'react';
import { useDashboardCRUD, useDashboardSWR } from '@hooks/useDashboardAPI';
import { VercelScreen } from './VercelScreen';
import {
  Control,
  Dropdown,
  DropdownOption,
  DropdownSelection,
  Form,
} from '@clerk-ui/components';
import { BlankSlate } from '@components/blankSlate';
import { useRouter } from 'next/router';

import { Integration, VercelProject } from '@types';
import { getNextUrl } from './utils';

export type VercelSelectProjectProps = {
  integration: Integration;
  next: string;
};

export function VercelSelectProject({
  integration,
  next,
}: VercelSelectProjectProps): JSX.Element {
  const { create } = useDashboardCRUD();
  const router = useRouter();
  const [isLoading, setIsLoading] = React.useState(false);
  const { data, isValidating } = useDashboardSWR(
    `/v1/integrations/${integration.id}/objects`,
  );
  const projects = data as VercelProject[];
  const [selectedProjectId, setSelectedProjectId] = React.useState<string>();

  const handleChange = (el: DropdownSelection) =>
    setSelectedProjectId(el.value);

  const handleSubmit = async () => {
    await router.push({
      pathname: 'new-app',
      query: {
        ...router.query,
        id: integration.id,
        project_id: selectedProjectId,
        next,
      },
    });
  };

  // TODO Handle retry => re-fetch projects

  const handleSkip = async () => {
    try {
      setIsLoading(true);

      // Bind current user to Vercel
      await create(`/v1/integrations/${integration.id}/link`);
    } catch (_) {
      setIsLoading(false);
    }

    // Redirect to Vercel to complete the integration
    window.location.assign(getNextUrl(next, integration));
  };

  const projectOptions: DropdownOption[] = React.useMemo(() => {
    return projects?.reduce((memo, prj) => {
      memo.push({
        label: prj.name,
        value: prj.id,
      });

      return memo;
    }, []);
  }, [projects]);

  const hasAnyProjects = projectOptions?.length > 0;

  return (
    <VercelScreen
      disabled={isLoading}
      title='Connect project'
      subtitle='Select Vercel project to connect to Clerk'
      footer={
        <VercelFooter
          hasAnyProjects={hasAnyProjects}
          isValidating={isValidating}
          handleSkip={handleSkip}
        />
      }
    >
      {hasAnyProjects && (
        <Form
          handleSubmit={handleSubmit}
          buttonGroupToTheRight
          submitButtonLabel='Continue'
        >
          <Control label='Vercel project'>
            <Dropdown
              name='project_id'
              options={projectOptions}
              placeholder='Select project'
              handleChange={handleChange}
            />
          </Control>
        </Form>
      )}
    </VercelScreen>
  );
}

type VercelFooterProps = {
  hasAnyProjects: boolean;
  isValidating: boolean;
  handleSkip: () => Promise<void>;
};

function VercelFooter({
  hasAnyProjects,
  isValidating,
  handleSkip,
}: VercelFooterProps): JSX.Element | null {
  if (hasAnyProjects) {
    return null;
  }

  if (isValidating) {
    return <BlankSlate>Loading..</BlankSlate>;
  }

  return (
    <>
      No projects found &nbsp;
      <a onClick={handleSkip}>Skip this step</a>
    </>
  );
}
