import {
  subDays,
  subMonths,
  subYears,
  startOfYear,
  differenceInDays,
} from 'date-fns';

export const initialState = {
  labels: [],
  datasets: [
    {
      label: '# of Users',
      data: [],
      fill: false,
      backgroundColor: '#6c47ff',
      borderColor: '#6c47ff',
    },
  ],
};

export const rangeAggregateOptions = [
  { value: 'Daily', interval: 'day', shouldDisplay: () => true },
  {
    value: 'Weekly',
    interval: 'week',
    shouldDisplay: date => differenceInDays(new Date(), date) > 7,
  },
  {
    value: 'Monthly',
    interval: 'month',
    shouldDisplay: date => differenceInDays(new Date(), date) > 30,
  },
  {
    value: 'Quarterly',
    interval: 'quarter',
    shouldDisplay: date => differenceInDays(new Date(), date) > 120,
  },
  {
    value: 'Yearly',
    interval: 'year',
    shouldDisplay: date => differenceInDays(new Date(), date) > 365,
  },
] as const;

export const rangeOptions = [
  { value: '1W', getDate: () => subDays(new Date(), 7) },
  { value: '1M', getDate: () => subMonths(new Date(), 1) },
  { value: '6M', getDate: () => subMonths(new Date(), 6) },
  { value: 'YTD', getDate: () => startOfYear(new Date()) },
  { value: '1Y', getDate: () => subYears(new Date(), 1) },
  { value: 'Max', getDate: () => new Date(2021, 0, 1) },
] as const;

export const metricOptions = [
  {
    label: 'Active users',
    value: 'active_users',
  },
  {
    label: 'Sign-ups',
    value: 'signups',
  },
  {
    label: 'Sign-ins',
    value: 'signins',
  },
] as const;
