import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

const BrowserNav = ({
  applicationName,
}: {
  applicationName: string;
}): JSX.Element => {
  return (
    <Flex
      bg='primary.50'
      height='40px'
      position='absolute'
      top={0}
      right={0}
      left={0}
      borderTopRadius='2xl'
      alignItems='center'
      border='1px solid'
      borderColor='gray.100'
      zIndex={10}
    >
      <svg
        width='72'
        height='40'
        viewBox='0 0 72 40'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle cx='19' cy='21' r='6' fill='#ED6559' />
        <circle cx='39' cy='21' r='6' fill='#E1C04C' />
        <circle cx='59' cy='21' r='6' fill='#72BE47' />
      </svg>

      <Text
        ml='auto'
        mr='auto'
        alignSelf='center'
        textStyle='md-normal'
        color='gray.600'
      >
        Sign in to {applicationName}
      </Text>
      <Box width='4.5rem'></Box>
    </Flex>
  );
};

export default BrowserNav;
