import React, { FC } from 'react';

import { TodosContext } from './TodosContext';
import { useTodos } from '../useTodos';
import { useRouter } from 'next/router';
import { useDashboardSWR } from '@hooks/useDashboardAPI';
import { useInstanceId } from '@hooks';

export const TodosProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const router = useRouter();
  const { instanceId } = useInstanceId();
  const { applicationId = '' } = router.query;
  const {
    data: { environment_type: environmentType },
  } = useDashboardSWR(`/v1/instances/${instanceId}`);
  const todos = useTodos(
    instanceId as string,
    applicationId as string,
    environmentType,
  );

  return (
    <TodosContext.Provider value={todos}>{children}</TodosContext.Provider>
  );
};
