import React from 'react';
import { Flex, Text, Box, Button } from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { DEFAULT_ANIMATION_DURATION, DEFAULT_ANIMATION_TYPE } from '@constants';

import IdentifierField from './IdentifierField';

const Identifiers = ({
  identifiers,
  showDivider,
}: {
  identifiers: string[];
  showDivider: boolean;
}): JSX.Element => {
  return (
    <Flex
      gap={8}
      as={motion.div}
      layout
      flexDirection='column'
      transform='translate3d(0,0,0)'
    >
      <AnimatePresence>
        {identifiers.length > 0 && (
          <Flex
            flexDirection='column'
            key='identifiers'
            layoutId='identifiers'
            as={motion.div}
            layout
            exit={{
              height: 0,
              opacity: 0,
              transition: { duration: DEFAULT_ANIMATION_DURATION },
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <AnimatePresence>
              {showDivider && (
                <Flex
                  layoutId='divider'
                  layout
                  exit={{
                    height: 0,
                    opacity: 0,
                    transition: { duration: DEFAULT_ANIMATION_DURATION },
                    gap: 0,
                    margin: 0,
                  }}
                  as={motion.div}
                  alignItems='center'
                  mt={8}
                  mb={8}
                  gap={4}
                  key='divider'
                >
                  <Box height='1px' width='100%' bg='rgba(0, 0, 0, 0.16)' />
                  <Text textStyle='sm-medium' color='gray.500'>
                    or
                  </Text>
                  <Box bg='rgba(0, 0, 0, 0.16)' height='1px' width='100%' />
                </Flex>
              )}
            </AnimatePresence>
            <Flex
              as={motion.div}
              layout
              direction='column'
              transform='translate3d(0,0,0)'
            >
              <AnimatePresence>
                <Flex
                  layoutId='identifier'
                  mb={4}
                  layout
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: 1,
                    transition: {
                      duration: DEFAULT_ANIMATION_DURATION,
                      type: DEFAULT_ANIMATION_TYPE,
                    },
                  }}
                  exit={{
                    margin: 0,
                    height: 0,
                    opacity: 0,
                  }}
                  as={motion.div}
                  direction='column'
                >
                  <IdentifierField identifiers={identifiers} />
                </Flex>
              </AnimatePresence>
              <Button
                cursor='initial'
                disabled
                _disabled={{ bg: 'primary.500' }}
                fontSize='0.6875rem'
                fontWeight='bold'
              >
                Continue
              </Button>
            </Flex>
          </Flex>
        )}
      </AnimatePresence>
    </Flex>
  );
};

export default Identifiers;
