import React from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { CardDualPanel, PageLoader } from '@components/common';
import { ApplyProdChangesModalWithCustomParams } from '@components/common/ApplyProdChangesModal';
import { ConfigureProviderModal } from './ConfigureProviderModal';
import {
  useDeployStatus,
  useInstance,
  useLocation,
  useToast,
  useSupportedFeature,
} from '@hooks';
import { useDashboardCRUD, useDashboardSWR } from '@hooks/useDashboardAPI';
import { useCheckIsProdInstance } from '@hooks';

import { OAuthStrategy } from '@clerk/types';
import type { Settings, OAuthProviderSettings } from '@types';
import {
  hasReachedSocialAndWeb3Limit,
  shouldCheckMaxSocialAndWeb3,
} from '@utils';
import { SUPPORTED_FEATURES } from '@constants';
import { usePaymentRequired } from '@context/PaymentRequiredContext';

import {
  SocialProvidersForm,
  SocialProviderFields,
  makeProviders,
} from './ProvidersTab';

export function SocialConnectionProviders(): JSX.Element {
  const { instance } = useInstance();
  const configureProviderDisclosure = useDisclosure();
  const { hasNotSeenInfoBillingModal } = useSupportedFeature();
  const [currentProvider, setCurrentProvider] =
    React.useState<OAuthProviderSettings | null>(null);
  const [showProdChangesModal, setShowProdChangesModal] = React.useState(false);

  const { showSuccessToast, showErrorToast } = useToast();
  const { showModal } = usePaymentRequired();
  const isRunningOnProduction = useCheckIsProdInstance();

  const { environmentType, isValidating: isValidatingDeployStatus } =
    useDeployStatus();

  const { instanceId } = useLocation();
  const { data: settings, mutate } = useDashboardSWR<Settings>(
    `/v1/instances/${instanceId}/user_settings`,
  );
  const { update } = useDashboardCRUD();

  const providers = makeProviders(settings?.social);
  const isProdChangesModallVisible =
    isRunningOnProduction && currentProvider && showProdChangesModal;

  const getProvider = React.useCallback(
    (strat: OAuthStrategy) => {
      return providers?.find(({ strategy }) => strategy === strat);
    },
    [providers],
  );

  const handleClose = () => {
    configureProviderDisclosure.isOpen && configureProviderDisclosure.onClose();
    setCurrentProvider(null);
  };

  const handleSave = async (data: Partial<OAuthProviderSettings>) => {
    const { strategy, ...rest } = data;
    const provider = getProvider(strategy);

    try {
      await update(
        `/v1/instances/${instanceId}/user_settings/social/${strategy}`,
        rest,
      );

      await mutate();

      // close modal in case it was open
      configureProviderDisclosure.isOpen &&
        configureProviderDisclosure.onClose();

      const message = rest.enabled
        ? `${provider.name} settings were saved.`
        : `${provider.name} was disabled.`;

      showSuccessToast(message);
      setCurrentProvider(null);
    } catch (_) {
      showErrorToast('Could not update the change on auth provider');
    }
  };

  const handleConfirmation = async () => {
    await handleSave({
      strategy: currentProvider.strategy,
      enabled: false,
      required: false,
      authenticatable: false,
    });
    setShowProdChangesModal(false);
  };

  const openConfigurationModal = strategy => {
    const provider = getProvider(strategy);
    setCurrentProvider(provider);
    configureProviderDisclosure.onOpen();
  };

  const handleSwitchChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const strategy = e.target.name as OAuthStrategy;

    const provider = getProvider(strategy);
    setCurrentProvider(provider);

    if (
      isChecked && // this needs to have value of true to trigger the modal when we try to enable it
      shouldCheckMaxSocialAndWeb3(instance?.supported_features) &&
      hasReachedSocialAndWeb3Limit({
        social: settings.social,
        web3: settings.attributes.web3_wallet.first_factors,
      })
    ) {
      return showModal({
        features: [SUPPORTED_FEATURES.unlimited_social],
      });
    }

    if (
      isChecked &&
      hasReachedSocialAndWeb3Limit({
        social: settings.social,
        web3: settings.attributes.web3_wallet.first_factors,
      }) &&
      hasNotSeenInfoBillingModal(SUPPORTED_FEATURES.unlimited_social)
    ) {
      return showModal({
        callbackAfterClose: () => openConfigurationModal(strategy),
      });
    }

    if (isChecked) {
      // Enable provider, open modal for further configuration
      configureProviderDisclosure.onOpen();
    } else {
      // // Disable provider
      if (isRunningOnProduction) {
        setShowProdChangesModal(true);
        return;
      }
      await handleSave({
        strategy,
        enabled: false,
        required: false,
        authenticatable: false,
      });
    }
  };

  if (!settings || isValidatingDeployStatus) {
    return <PageLoader />;
  }

  return (
    <CardDualPanel
      title='Social connection providers'
      subtitle='Enable a provider to connect to your application'
      docLink={{
        subject: 'social connections',
        url: 'https://clerk.com/docs/authentication/social-connections-oauth',
      }}
    >
      <SocialProvidersForm
        socialSettings={settings.social}
        providersField={
          <SocialProviderFields
            onCogClick={openConfigurationModal}
            providers={providers}
            handleSwitchChange={handleSwitchChange}
          />
        }
      />

      {currentProvider && (
        <ConfigureProviderModal
          isOpen={configureProviderDisclosure.isOpen}
          onClose={handleClose}
          onSave={handleSave}
          oauthProviderSettings={currentProvider}
          oauthCallbackUrl={settings.oauth_callback_url}
          environmentType={environmentType}
        />
      )}
      <ApplyProdChangesModalWithCustomParams
        colorScheme='primary'
        isOpen={isProdChangesModallVisible}
        handleConfirmation={handleConfirmation}
        handleCancelation={() => setShowProdChangesModal(false)}
      />
    </CardDualPanel>
  );
}
