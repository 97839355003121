import React from 'react';
import { Text } from '@chakra-ui/react';

const HIDDEN_CHAR = '•';

type KeyValuePairProps = {
  name: string;
  value: string;
  index: number;
  isShown?: boolean;
};

const SECRET_KEY_REGEX = /(sk_(?:live|test)_)(.+)/;

export function EnvVar({
  name,
  value,
  index,
  isShown = false,
}: KeyValuePairProps): JSX.Element {
  let valueToShow = '';

  const secretKeyMatch = value.match(SECRET_KEY_REGEX);

  if (secretKeyMatch) {
    const [prefix, suffix] = secretKeyMatch.slice(1);
    valueToShow = isShown
      ? value
      : `${prefix}${HIDDEN_CHAR.repeat(suffix.length)}`;
  } else {
    valueToShow = isShown ? value : HIDDEN_CHAR.repeat(value.length);
  }

  return (
    <div>
      <Text
        as='span'
        color='white'
        opacity={0.5}
        userSelect='none'
        pr={3}
        fontFamily='sourceCodePro'
      >
        {index}
      </Text>

      <Text as='span' color='#5A9BCF'>
        {name}
      </Text>

      <Text as='span' color='white'>
        =
      </Text>

      <Text as='span' color='white'>
        {valueToShow}
      </Text>
    </div>
  );
}
