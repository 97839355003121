import React, { FunctionComponent } from 'react';
import { Flex, Icon, Text } from '@chakra-ui/react';
import { ChevronDownIcon, ExternalLinkIcon } from '@heroicons/react/solid';

export interface NavItemProps {
  icon?: FunctionComponent;
  isAccordionChild?: boolean;
  isAccordionParent?: boolean;
  isActive?: boolean;
  isExpanded?: boolean;
  isExternal?: boolean;
  label: React.ReactNode;
  isDisabled?: boolean;
}

export function NavItem({
  isAccordionParent,
  isAccordionChild,
  isActive,
  isExpanded,
  isExternal,
  icon,
  label,
  isDisabled,
}: NavItemProps): JSX.Element {
  return (
    <Flex
      align='center'
      bg={isActive && !isAccordionParent ? 'gray.50' : 'unset'}
      justify='space-between'
      ml='-18px'
      py={2}
      px={4}
      rounded='base'
      w='236px'
      color={isActive ? 'black' : isDisabled ? 'gray.300' : 'gray.500'}
      _hover={{
        color: !isDisabled && 'black',
      }}
      cursor={isDisabled && 'default'}
      className='nav-item'
    >
      <Flex align='center' pl={8} position={'relative'}>
        {!isAccordionChild && (
          <Icon
            as={icon}
            color={isActive ? 'black' : isDisabled ? 'gray.200' : 'gray.300'}
            // override the color when user hovers the parent
            sx={{
              '.nav-item:hover &': {
                color: !isDisabled && 'black !important',
              },
            }}
            boxSize={5}
            mr={4}
            position={'absolute'}
            left={0}
          />
        )}

        <Text textStyle={isAccordionChild ? 'sm-medium' : 'md-medium'}>
          {label}
        </Text>
      </Flex>

      {isExternal && (
        <Icon
          as={ExternalLinkIcon}
          color={isActive ? 'black' : isDisabled ? 'gray.200' : 'gray.300'}
          sx={{
            '.nav-item:hover &': {
              color: !isDisabled && 'black !important',
            },
          }}
        />
      )}

      {isAccordionParent && (
        <Icon
          as={ChevronDownIcon}
          boxSize={5}
          color={isActive ? 'black' : 'gray.300'}
          transform={`rotate(${isExpanded ? '0' : '-90'}deg)`}
          transition='transform 0.2s'
        />
      )}
    </Flex>
  );
}
