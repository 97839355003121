import React from 'react';

import {
  Spinner,
  Flex,
  Text,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItemOption,
  MenuOptionGroup,
  Button,
  ButtonProps,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';

type SelectMenuProps = {
  isLoading?: boolean;
  items: Record<string, string>;
  value: string;
  onChange: (value: string | string[]) => void;
  buttonVariant?: string;
  buttonProps?: ButtonProps;
};

const SelectMenu = ({
  isLoading,
  items,
  value,
  buttonVariant = 'selectGhost',
  onChange,
  buttonProps,
}: SelectMenuProps): JSX.Element => {
  return (
    <Flex width='100%'>
      {!isLoading ? (
        <Menu matchWidth>
          <MenuButton
            as={Button}
            variant={buttonVariant}
            borderRadius='base'
            rightIcon={<Icon as={ChevronDownIcon} />}
            textTransform='unset'
            fontFamily='inter'
            aria-label='open change role menu'
            {...buttonProps}
          >
            <Text textStyle='sm-normal'>{items[value]}</Text>
          </MenuButton>
          <MenuList
            boxShadow='0px 24px 48px rgba(0, 0, 0, 0.16);'
            border='1px solid rgba(0, 0, 0, 0.16)'
            width={36}
            minWidth={36}
          >
            <MenuOptionGroup value={value} onChange={onChange} type='radio'>
              {Object.keys(items).map((itemKey: string) => (
                <MenuItemOption key={itemKey} value={itemKey}>
                  <Text>{items[itemKey]}</Text>
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </MenuList>
        </Menu>
      ) : (
        <Spinner ml={3} size='xs' />
      )}
    </Flex>
  );
};

export default SelectMenu;
