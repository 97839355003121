import React from 'react';
import { Link as ChakraLink, LinkProps } from '@chakra-ui/react';
import Link from 'next/link';

export type HelperLinkProps = {
  href: string;
  children?: React.ReactNode;
} & LinkProps;

export const HelperLink = ({
  children = 'Learn where to find this',
  color = 'primary.500',
  href,
  textStyle = 'sm-medium',
  ...rest
}: HelperLinkProps) => (
  <Link href={href} passHref>
    <ChakraLink textStyle={textStyle} color={color} target='_blank' {...rest}>
      {children}
    </ChakraLink>
  </Link>
);
