import { SystemStyleObjectRecord } from '@chakra-ui/react';

export const layerStyles: SystemStyleObjectRecord = {
  card: {
    border: '1px solid',
    borderColor: 'gray.200',
    rounded: '2xl',
    py: 8,
    px: 6,
    flex: 1,
  },
  linkBoxCard: {
    display: 'flex',
    flexDirection: 'column',
    bg: 'white',
    border: '1px solid',
    borderColor: 'gray.200',
    cursor: 'pointer',
    rounded: '2xl',
    overflow: 'hidden',
    transition: 'all 150ms',
    _hover: {
      boxShadow: 'dropShadow2',
    },
  },
};
