import type { MutableRefObject } from 'react';

type TargetValue<T> = T | undefined | null;
type TargetType = HTMLElement | Element;
export type BasicTarget<T extends TargetType = Element> =
  | TargetValue<T>
  | MutableRefObject<TargetValue<T>>;

export const getTargetElement = <T extends TargetType>(
  target: BasicTarget<T>,
): TargetType => {
  if (!target) {
    return undefined;
  }

  let targetElement: TargetValue<T>;

  if ('current' in target) {
    targetElement = target.current;
  } else {
    targetElement = target;
  }

  return targetElement;
};
