import { useEventListener } from './useEventListener';
import React from 'react';
import { getTargetElement } from '@utils';

export type UseInfiniteScrollProps = {
  target: Element | React.MutableRefObject<Element>;
  threshold?: number;
  onLoadMore?: () => void; // if this is undefined infinite scroll will be disabled
  isLoading?: boolean;
  hasDataEnd?: boolean;
};

export const useInfiniteScroll = ({
  target,
  threshold = 50,
  onLoadMore,
  isLoading,
  hasDataEnd,
}: UseInfiniteScrollProps): void => {
  const scrollMethod = () => {
    const el = getTargetElement(target);

    if (!el) {
      return;
    }

    const scrollTop = el.scrollTop;
    const scrollHeight = el.scrollHeight;
    const clientHeight = el.clientHeight;

    if (scrollHeight - scrollTop <= clientHeight + threshold) {
      onLoadMore();
    }
  };

  useEventListener(
    target,
    () => {
      if (isLoading || !onLoadMore || hasDataEnd) {
        return;
      }
      scrollMethod();
    },
    'scroll',
  );
};
