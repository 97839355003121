import { useDashboardSWRInfinite, useDashboardSWR } from '@hooks';
import { User } from '@types';

import { useUsersPaths, UseUsersPathsProps } from './hooks';

import {
  OrganizationMembershipFLatUserData,
  OrganizationMembershipResponse,
} from '@components/organizations/types';

import { makeFlatMembership } from '@components/organizations/memberships/utils';

import { useCreateMembership } from '@components/organizations/memberships';

import { MembershipRole } from '@components/organizations/types';

type UseUsersInfiniteReturn = {
  users: User[];
  loadMore: () => void;
  size: number;
  isLoading: boolean;
  totalCount: number;
  isLoadingMore: boolean;
};

export const useUsersInfinite = ({
  search = '',
  organizationID = '',
}: UseUsersPathsProps = {}): UseUsersInfiniteReturn => {
  const { makeInfinitePath } = useUsersPaths({ search, organizationID });
  const { totalCount } = useUsersTotalCount({ search, organizationID });

  const { data, setSize, size, error } = useDashboardSWRInfinite<User>(
    makeInfinitePath,
    { persistSize: true },
  );

  const isLoadingInitialData = !data && !error;

  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 && data && typeof data[size - 1] === 'undefined');

  const loadMore = () => setSize(size + 1);

  const users: User[] = data ? [].concat(...data) : [];

  return {
    users,
    loadMore: loadMore,
    size,
    isLoading: isLoadingInitialData,
    isLoadingMore,
    totalCount,
  };
};

type UseUsersTotalCountReturn = {
  totalCount: number;
  refreshCache: () => void;
  isLoading: boolean;
};

type UseUsersTotalCountProps = {
  polling?: number;
} & UseUsersPathsProps;

export const useUsersTotalCount = ({
  search = '',
  organizationID = '',
  polling = 0,
}: UseUsersTotalCountProps = {}): UseUsersTotalCountReturn => {
  const { countPath } = useUsersPaths({ search, organizationID });

  const {
    data,
    mutate: mutateTotalCount,
    error,
  } = useDashboardSWR<{
    object: 'total_count';
    total_count: number;
  }>(countPath, { refreshInterval: polling });

  const refreshCache = () => {
    void mutateTotalCount();
  };

  return {
    isLoading: !data && !error,
    totalCount: data?.total_count || 0,
    refreshCache,
  };
};

type UseUserMembershipsFromDAPIProps = {
  userID: string;
};

type UseUserMembershipsFromDAPIReturn = {
  memberships: OrganizationMembershipFLatUserData[];
  refreshCache: () => void;
  isLoading: boolean;
};

export const useUserMembershipsFromDAPI = ({
  userID,
}: UseUserMembershipsFromDAPIProps): UseUserMembershipsFromDAPIReturn => {
  const { membershipsPath } = useUsersPaths({ userID });

  const { data, mutate, error } =
    useDashboardSWR<OrganizationMembershipResponse>(membershipsPath);

  const memberships = makeFlatMembership(data?.data || []);
  const isLoading = !data && !error;

  const refreshCache = () => {
    void mutate();
  };

  return { memberships, refreshCache, isLoading };
};

type UseUserMembershipsFromDAPIInfiniteReturn = {
  loadMore: () => void;
  isLoadingMore: boolean;
  totalCount: number;
  hasDataEnd: boolean;
} & UseUserMembershipsFromDAPIReturn;

export const useUserMembershipsFromDAPIInfinite = ({
  userID,
}: UseUsersPathsProps = {}): UseUserMembershipsFromDAPIInfiniteReturn => {
  const { makeMembershipsPathInfinite } = useUsersPaths({ userID });

  const { data, setSize, size, error, mutate } =
    useDashboardSWRInfinite<OrganizationMembershipResponse>(
      makeMembershipsPathInfinite,
      { persistSize: true },
    );

  const isLoadingInitialData = !data && !error;

  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 && data && typeof data[size - 1] === 'undefined');

  const loadMore = () => setSize(size + 1);

  const membershipsResponse: OrganizationMembershipResponse[] = data
    ? [].concat(...data)
    : [];

  const totalCount = data?.[0].total_count || 0;
  const memberships = makeFlatMembership(
    membershipsResponse.map(({ data }) => data).flat(),
  );

  const refreshCache = () => {
    void mutate();
  };

  return {
    refreshCache,
    memberships,
    loadMore: loadMore,
    isLoading: isLoadingInitialData,
    isLoadingMore,
    totalCount,
    hasDataEnd: memberships.length === totalCount,
  };
};

type UseUsersMutatorsReturn = {
  addUserToOrganization: ({
    organizationID,
    role,
  }: {
    organizationID: string;
    role: MembershipRole;
  }) => Promise<OrganizationMembershipFLatUserData>;
};

export const useUsersMutators = ({
  userID,
}: {
  userID: string;
}): UseUsersMutatorsReturn => {
  const { createMembership } = useCreateMembership();
  const { makeCreateMembershipPath } = useUsersPaths({
    userID,
  });

  const addUserToOrganization: UseUsersMutatorsReturn['addUserToOrganization'] =
    async ({ organizationID, role }) => {
      const path = makeCreateMembershipPath({ organizationID });
      const member = await createMembership(userID, role, path);
      return member;
    };

  return { addUserToOrganization };
};
