import React from 'react';
import { Center, Spinner } from '@chakra-ui/react';
import { CardSinglePanel } from '../common/Card/CardSinglePanel';
import { useDashboardSWR, useLocation } from '@hooks';
import { CardContainer } from '../common/Card/CardElements';
import { DomainDeployStatus } from '.';
import { ClerkServicesStatus } from './ClerkServicesStatus';

export function DeployStatus(): JSX.Element {
  const { instanceId } = useLocation();
  const { data: instance } = useDashboardSWR(`/v1/instances/${instanceId}`);

  if (!instance) {
    return (
      <CardContainer>
        <Center h='full' w='full'>
          <Spinner />
        </Center>
      </CardContainer>
    );
  }

  return (
    <CardSinglePanel title='Deploy status' titleContainerStyles={{ mb: 4 }}>
      <DomainDeployStatus />
      <ClerkServicesStatus />
    </CardSinglePanel>
  );
}
