import React from 'react';
import { useLocation } from './useLocation';

const LAST_ACTIVE_KEY = 'clerk-last-active';

type UseLastActive = (args?: { shouldTrack?: boolean }) => {
  getLastActive: () =>
    | {
        applicationId: string;
        instanceId: string;
      }
    | Record<string, never>;
};

export const useLastActive: UseLastActive = ({
  shouldTrack, // In case you only want to get the last active, leave this out
} = {}) => {
  const { applicationId, instanceId } = useLocation();
  const lastActive = React.useRef({ applicationId, instanceId });

  const saveToLocalStorage = () => {
    const { applicationId, instanceId } = lastActive.current;

    if (!applicationId || !instanceId) {
      return;
    }

    localStorage.setItem(
      LAST_ACTIVE_KEY,
      JSON.stringify({
        applicationId,
        instanceId,
      }),
    );
  };

  const getLastActive = () => {
    const lastActive = localStorage.getItem(LAST_ACTIVE_KEY);

    if (!lastActive) {
      return {};
    }

    return JSON.parse(lastActive);
  };

  // Add and remove focus listener
  React.useEffect(() => {
    if (shouldTrack) {
      window.addEventListener('focus', saveToLocalStorage);

      return () => {
        window.removeEventListener('focus', saveToLocalStorage);
      };
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Update when app or instance ID changes
  React.useEffect(() => {
    if (shouldTrack) {
      lastActive.current = { applicationId, instanceId };

      saveToLocalStorage();
    }
  }, [applicationId, instanceId, shouldTrack]);

  return {
    getLastActive,
  };
};
