import React from 'react';
import { Center, Spinner } from '@chakra-ui/react';

export function PageLoader(): JSX.Element {
  return (
    <Center h='50vh' w='full'>
      <Spinner />
    </Center>
  );
}
