import React from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import {
  RadioGroup as ChakraRadioGroup,
  RadioGroupProps as ChakraRadioGroupProps,
  StackProps,
  VStack,
} from '@chakra-ui/react';

type RadioGroupProps = {
  children: React.ReactNode;
} & StackProps &
  ChakraRadioGroupProps &
  UseControllerProps<any>;

export function RadioGroup(props: RadioGroupProps): JSX.Element {
  const { name, rules, control, children, isDisabled, ...stackProps } = props;
  const { field } = useController({ control, name, rules });

  return (
    <ChakraRadioGroup {...field} isDisabled={isDisabled}>
      <VStack {...stackProps}>{children}</VStack>
    </ChakraRadioGroup>
  );
}
