// 1Password suggests auto-complete in some input fields based on their `name` attribute: if its value contain some specific words, like "id" or "name" (e.g. `name:"user"` or `name: "project_id"`), 1Password's icon appears on the corner of the input field, causing the "ESC won't close modal" bug.
// A workaround found on their forum suggests adding the word "search" to id or name to prevent the auto-complete suggestion to appear.
// Workaround source: https://1password.community/discussion/comment/606453/#Comment_606453

type RemoveAutoComplete = {
  id: string;
  'aria-autocomplete': 'none';
};

export function removeAutoComplete(id: string): RemoveAutoComplete {
  return {
    id: `search-${id}`,
    'aria-autocomplete': 'none',
  };
}
