import React from 'react';

import { useLocation } from '@hooks';
import { useRouter } from 'next/router';

import { membershipSchema, MemberShipSchema } from './schema';

import {
  useGetMemberships,
  useMembershipsPathAndKeys,
} from '@components/organizations/memberships';
import { OrganizationMembershipFLatUserData } from '@components/organizations/types';
import { TableSortingDirections, TableSortingData } from '@types';
import { PAGE_SIZE } from '@components/organizations/constants';

const makeMembershipSchema = membershipSchema();

type UsersTableReturnType = {
  data: OrganizationMembershipFLatUserData[];
  schema: MemberShipSchema;
  page: number;
  query: string;
  path: string;
  isLoading: boolean;
  sortingState: TableSortingData;
  hasNextPage: boolean;
  onRowClicked: (member: OrganizationMembershipFLatUserData) => void;
  onPaginate: (value: number) => void;
  onSorted: (data: TableSortingData) => void;
};
const useTable = (): UsersTableReturnType => {
  const [sortingState, setSortingState] = React.useState<TableSortingData>({
    column: 'first_name',
    direction: TableSortingDirections.DESC, //TODO MAKE GET DEFAULT FROM QUERY PARAMS IF EXISTS
  });
  const { instanceId, applicationId, query } = useLocation();
  const router = useRouter();
  const { path } = useMembershipsPathAndKeys();
  const { organizationIDorSlug } = query;

  const { memberships, hasLoaded, totalCount } = useGetMemberships({
    organizationID: organizationIDorSlug as string,
  });

  const page = parseInt(router.query.members_page as string, 10) || 1;
  const userProfilePath = `/apps/${applicationId}/instances/${instanceId}/users`;

  const onRowClicked = React.useCallback(
    (member: OrganizationMembershipFLatUserData) => {
      void router.push({
        pathname: `${userProfilePath}/${member.user_id}`,
        query: {
          members_page: page,
        },
      });
    },
    [page, userProfilePath, router],
  );

  const onPaginate = (value: number) => {
    void router.push({ query: { ...router.query, members_page: value } });
  };

  const onSorted = (data: TableSortingData) => {
    setSortingState(data);
    void router.push({
      query: {
        ...router.query,
        order_by: `${sortingState.direction}${sortingState.column}`,
      },
    });
    if (page > 1) {
      onPaginate(1);
    }
  };

  const hasNextPage = React.useMemo(() => {
    if (!totalCount) {
      return false;
    }

    return totalCount > PAGE_SIZE * page;
  }, [page, totalCount]);

  return {
    data: memberships,
    isLoading: !hasLoaded,
    schema: makeMembershipSchema,
    page,
    query: (router.query.query as string) || '',
    onRowClicked,
    path,
    hasNextPage,
    sortingState,
    onPaginate,
    onSorted,
  };
};

export default useTable;
