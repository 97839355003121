import { useInstance } from '@hooks';
import { isProduction } from '@utils';

export function ShowInProdOnly({
  children,
}: {
  tooltipLabel?: string;
  children: JSX.Element;
}): JSX.Element {
  // TODO: A better abstraction for this
  // maybe a Context that includes the current app and instance etc
  const { instance } = useInstance();
  const isProd = instance && isProduction(instance.environment_type);

  if (isProd) {
    return children;
  }

  return null;
}
