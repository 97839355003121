import React from 'react';

import { Organization } from '@types';

import { useLocation, useDashboardSWR } from '@hooks';

import DeleteOrganization from './DeleteOrganization';
import { makeOrganizationPaths } from '../../utils';

const Tab = (): JSX.Element => {
  const { instanceId, query, applicationId } = useLocation();

  const { organizationIDorSlug } = query;

  const { basePath } = makeOrganizationPaths(
    instanceId,
    organizationIDorSlug as string,
  );

  const { data: organization, mutate } = useDashboardSWR<Organization>(() => {
    if (!instanceId || !organizationIDorSlug) {
      return null;
    }
    return basePath;
  });

  return (
    <DeleteOrganization
      deletePath={basePath}
      navigateTo={`/apps/${applicationId}/instances/${instanceId}/organizations`}
      onDelete={mutate}
      organizationName={organization.name}
    />
  );
};

export default Tab;
