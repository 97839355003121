import { Flex, Text, Box } from '@chakra-ui/layout';
import { Statuses } from '@types';
import React from 'react';
import { DEPLOY_STATUS } from '.';

export type StatusEntryProps = {
  entryTitle: string;
  entryStatus?: Statuses;
  entryDetails?: React.ReactNode;
};

/**
 * Layout for each deploy status entry https://www.figma.com/file/OyLCOl0flSjkRjby1FM1ts/Clerk-v01?node-id=17714%3A132990
 */
export function StatusEntry({
  entryTitle,
  entryStatus = Statuses.Failed,
  entryDetails,
}: StatusEntryProps): JSX.Element {
  return (
    <Box mb={4}>
      <Flex justify='space-between'>
        <Text
          textStyle='sm-medium'
          textOverflow='ellipsis'
          overflow='hidden'
          whiteSpace='nowrap'
          maxW='60%'
          color='gray.600'
        >
          {entryTitle || 'Not yet configured'}
        </Text>

        <Flex align='baseline'>
          <Box
            boxSize={2}
            bg={DEPLOY_STATUS[entryStatus].color}
            mr={1.5}
            rounded='full'
          />
          <Text textStyle='sm-normal' color='gray.500'>
            {DEPLOY_STATUS[entryStatus].message}
          </Text>
        </Flex>
      </Flex>
      {entryDetails}
    </Box>
  );
}
