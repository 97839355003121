import { EmailAddressConfigModal } from './EmailAddressConfigModal';
import { PhoneNumberConfigModal } from './PhoneNumberConfigModal';
import { UsernameConfigModal } from './UsernameConfigModal';
import { FirstNameConfigModal } from './FirstNameConfigModal';
import { PasswordConfigModal } from './PasswordConfigModal';

const ATTRIBUTE_MODAL_MAP = {
  email_address: EmailAddressConfigModal,
  phone_number: PhoneNumberConfigModal,
  username: UsernameConfigModal,
  first_name: FirstNameConfigModal,
  password: PasswordConfigModal,
};

export type AvailableAttributes = keyof typeof ATTRIBUTE_MODAL_MAP;

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  isProgressiveSignUp: boolean;
};

export function getAttributeDetailsModal(
  name: AvailableAttributes,
): (props: ModalProps) => JSX.Element {
  if (name) {
    return ATTRIBUTE_MODAL_MAP[name];
  }
  return null;
}
