import React from 'react';
import { Box, HStack, Icon, IconButton, Switch } from '@chakra-ui/react';
import { CogIcon } from '@heroicons/react/solid';
import { useFormContext, useWatch } from 'react-hook-form';
import { AttributeInfo } from '@components/common/Switch';

const ACTIVE_STYLE = { bg: 'gray.50' };
const INACTIVE_STYLE = { border: '1px solid' };
const SWITCH_WIDTH = '40px';
const SWITCH_HEIGHT = '22px';

export type SwitchInfoFieldProps = {
  name: string;
  accessKey: string;
  title: string | JSX.Element;
  description: string;
  isDisabled?: boolean;
  docsUrl?: string;
  badges?: React.ReactNode[];
  handleSwitchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCogClick?: (name: string) => void;
};

export const SwitchInfoField = ({
  name,
  accessKey,
  title,
  description,
  isDisabled,
  docsUrl,
  badges,
  handleSwitchChange,
  onCogClick,
}: SwitchInfoFieldProps): JSX.Element => {
  const { register, control, watch, setValue, getValues } = useFormContext();

  const field = register(accessKey);
  const isChecked = useWatch({ control, name: accessKey });

  // TODO We probably don't need this
  const isFieldEnabled = watch(accessKey);
  React.useEffect(() => {
    const authenticatableAccessKey = `${accessKey.slice(0, -7)}authenticatable`;

    if (
      isFieldEnabled &&
      isFieldEnabled !== getValues(authenticatableAccessKey)
    ) {
      setValue(authenticatableAccessKey, isFieldEnabled, { shouldDirty: true });
    }
  }, [isFieldEnabled]);

  return (
    <HStack
      key={name}
      spacing='4'
      pos='relative'
      align='flex-start'
      p='4'
      border={'1px solid transparent'}
      borderRadius={8}
      _hover={ACTIVE_STYLE}
      borderColor='gray.50'
      {...(isChecked ? ACTIVE_STYLE : INACTIVE_STYLE)}
    >
      <Box minW={12}>
        <Switch
          id={name}
          sx={{
            '--switch-track-height': SWITCH_HEIGHT,
            '--switch-track-width': SWITCH_WIDTH,
          }}
          mt={-2}
          isDisabled={isDisabled}
          isChecked={isChecked}
          value={accessKey}
          {...field}
          name={name}
          onChange={handleSwitchChange}
        />
      </Box>

      <AttributeInfo
        title={title}
        description={description}
        docsUrl={docsUrl}
        htmlFor={name}
        badges={badges}
      />

      {isChecked ? (
        <IconButton
          pos='absolute'
          top='4'
          right='4'
          aria-label={`Open config modal-${name}`}
          icon={<Icon as={CogIcon} boxSize={5} color='gray.300' />}
          color='gray.300'
          bg='none'
          h='auto'
          minW='none'
          _active={{ bg: 'none' }}
          _focus={{ boxShadow: 'none' }}
          _hover={{
            bg: 'none',
            '& svg': {
              color: 'primary.500',
            },
          }}
          onClick={() => onCogClick(name)}
        />
      ) : null}
    </HStack>
  );
};
