import React from 'react';
import cn from 'classnames';

// @ts-ignore
import styles from './Spinner.module.scss';

export type SpinnerProps = {
  inverted?: boolean;
};

export function Spinner({ inverted }: SpinnerProps): JSX.Element {
  return (
    <div className={cn(styles.spinner, { [styles.inverted]: inverted })} />
  );
}
