import React from 'react';
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
} from '@chakra-ui/react';
import { removeAutoComplete } from '@utils';
import { getModalFields } from './constants';
import { useFormContext } from 'react-hook-form';
import { OAuthProviderSettings } from '@types';

interface ProviderTextInputProps {
  oauthProviderSettings: OAuthProviderSettings;
  field: string;
  isExtraSetting: boolean;
  customProfile: boolean;
}

export function ProviderTextInput({
  oauthProviderSettings,
  field,
  isExtraSetting,
  customProfile,
}: ProviderTextInputProps): JSX.Element {
  const modalFields = getModalFields(oauthProviderSettings.provider);

  const {
    formState: { errors },
    register,
    setValue,
  } = useFormContext();

  const currentValue = isExtraSetting
    ? oauthProviderSettings.extra_settings[field]
    : oauthProviderSettings[field];

  if (!customProfile) {
    return <></>;
  }

  return (
    <Box key={field}>
      <FormControl isInvalid={!!errors[field]} {...removeAutoComplete(field)}>
        <FormLabel fontSize='sm' mb='0.5'>
          {modalFields[field].label}
        </FormLabel>

        <Text color='gray.500' textStyle='md-normal' w='full'>
          {modalFields[field].description}
        </Text>

        <Input
          defaultValue={oauthProviderSettings?.enabled ? currentValue : ''}
          {...register(field, {
            validate: value => {
              if (customProfile && !value) {
                return `${modalFields[field].label} is required`;
              }
              return true;
            },
            onBlur: e => setValue(field, e.target.value.trim()),
          })}
        />

        <FormErrorMessage>{errors[field]?.message}</FormErrorMessage>
      </FormControl>
    </Box>
  );
}
