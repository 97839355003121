import React from 'react';
import { formatRel } from '@components/users/utils';
import UserPublicInfo from './UserPublicInfo';

import { User } from './types';
import { BaseSchema } from '@types';

export const usersSchema = (): BaseSchema<User> => [
  {
    header: 'Name',
    cell: user => <UserPublicInfo user={user} />,
    key: 'name',
    tdProps: {
      color: 'gray.900',
      borderTopLeftRadius: 'md',
      borderBottomLeftRadius: 'md',
    },
  },
  {
    header: 'Joined',
    cell: user => formatRel(user.created_at),
    key: 'created_at',
  },
  {
    header: 'Last signed in',
    cell: user => user.last_sign_in_at && formatRel(user.last_sign_in_at),
    key: 'last_sign_in_at',
  },
];
