// textStyles cannot be used in custom theme overrides currently
// this is a reference of all the textStyles we use all over the app
// we will use this list once the following issue is fixed:
// https://github.com/chakra-ui/chakra-ui/issues/3884
// 'badge-sm': {
//   fontSize: 'xxs',
//   lineHeight: 'xxs',
//   fontWeight: 'semibold',
// },
// 'badge-md': {
//   fontSize: 'xs',
//   lineHeight: 'xs',
//   fontWeight: 'semibold',
// },

const xs = {
  fontSize: 'xxs',
  lineHeight: '3',
};

const sm = {
  fontSize: 'xs',
  lineHeight: '4',
};

const md = {
  fontSize: 'sm',
  lineHeight: '5',
};

const lg = {
  fontSize: 'md',
  lineHeight: '6',
};

export const textStyles = {
  h1: {
    fontSize: '3xl',
    fontWeight: 'medium',
    lineHeight: '9',
  },
  h2: {
    fontSize: 'xl',
    fontWeight: 'medium',
    lineHeight: '7',
  },
  h3: {},
  'xs-normal': {
    ...xs,
    fontWeight: 'normal',
  },
  'xs-semibold': {
    ...xs,
    fontWeight: 'semibold',
  },
  'sm-normal': {
    ...sm,
    fontWeight: 'normal',
  },
  'sm-medium': {
    ...sm,
    fontWeight: 'medium',
  },
  'md-normal': {
    ...md,
    fontWeight: 'normal',
  },
  'md-medium': {
    ...md,
    fontWeight: 'medium',
  },
  'md-semibold': {
    ...md,
    fontWeight: 'semibold',
  },
  'lg-medium': {
    ...lg,
    fontWeight: 'medium',
  },
};
