import { useRouter } from 'next/router';
import { useInstance } from '@hooks/useInstance';
import { isProduction } from '@utils';

export function useProductionOnlyGuard() {
  const { pathname, query, push } = useRouter();
  const { instance } = useInstance();

  if (!instance) {
    return;
  }

  const isProd = isProduction(instance.environment_type);
  if (!isProd) {
    void push({
      pathname: pathname.match(/.+?\[instanceId]/)[0],
      query,
    });
  }
}
