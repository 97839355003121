import React from 'react';
import { Flex, Link } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useLocation, usePlausible, useAnalytics } from '@hooks';
import { START_BUILDING_FRAMEWORKS } from '@constants';
import { Framework } from './Framework';
import { Framework as FrameworkType } from '@types';

type QuickstartGuideTilesProps = {
  hoverStart: (startItem: FrameworkType) => void;
  framework?: FrameworkType;
};

export function QuickstartGuideTiles({
  hoverStart,
  framework,
}: QuickstartGuideTilesProps): JSX.Element {
  const { instanceId } = useLocation();
  const plausible = usePlausible();
  const { track } = useAnalytics();

  return (
    <Flex gridGap={2}>
      {START_BUILDING_FRAMEWORKS.map((startItem: FrameworkType) => (
        <Link
          as={motion.a}
          onHoverStart={() => {
            hoverStart(startItem);
          }}
          key={startItem.name}
          href={startItem.guide_url}
          isExternal
          maxWidth='144px'
          w='full'
          _focus={{}}
          onClick={() => {
            plausible('Read guide', {
              props: {
                Framework: startItem.name as any,
              },
            });
            track(
              'Dashboard_Instance Overview_Start Building Card Link Clicked',
              {
                surface: 'Dashboard',
                location: 'Instance Overview',
                instanceId,
                clickedOn: startItem.name,
              },
            );
          }}
        >
          <Framework
            title={startItem.name}
            logo={startItem.logo}
            isActive={startItem.slug === framework?.slug}
          />
        </Link>
      ))}
    </Flex>
  );
}
