import type { ActiveSessionResource } from '@clerk/types';

type BuildURLOptions = {
  query?: string;
  session?: ActiveSessionResource;
};

export function buildURL(path = '', options: BuildURLOptions = {}) {
  const { query, session } = options;

  const url = new URL(path, process.env.NEXT_PUBLIC_DASHBOARD_API);

  if (session) {
    url.searchParams.append('_clerk_session_id', session.id);
  }

  if (query) {
    for (const [key, value] of Object.entries(query)) {
      url.searchParams.append(key, value as string);
    }
  }

  return url.toString();
}

export function request(
  url: RequestInfo,
  { method = 'GET', token = '', data = {} } = {},
): Promise<Response> {
  let body;
  const headers: HeadersInit = {};

  if (token) {
    headers['Authorization'] = token;
  }

  // Binary fields need to be left alone, in order to properly set the correct
  // Content-Type headers.
  if (data instanceof FormData) {
    body = data;
  } else {
    headers['Content-Type'] = 'application/json';
    body = method === 'GET' ? null : JSON.stringify(data);
  }

  return fetch(url, {
    headers,
    method,
    body,
  });
}

export const buildCacheKey = ({
  path,
  session,
  id,
}: {
  path: string;
  session: ActiveSessionResource;
  id?: string;
}): string => {
  const url = buildURL(path, { session: session });

  // This is a temporary fix that is being tracked here
  // https://linear.app/clerk/issue/JS-30/investigate-why-swr-does-not-reload-applications-on-org-switch
  const newUrl = id ? `${url}&organization_id=${id}` : url;

  return newUrl;
};
