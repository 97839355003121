export const CheckoutStatusNotice = Object.freeze({
  SUCCESS: 'success',
  CANCELED: 'canceled',
});

export const ErrorMessages = Object.freeze({
  checkout_locked:
    'Another payment is pending. Please contact support@clerk.com.',
  checkout_session_mismatch:
    'Invalid payment session. Please contact support@clerk.com.',
});
