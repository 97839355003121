import React from 'react';
import { AnalyticsBrowser } from '@segment/analytics-next';

export interface AnalyticsContextType {
  page: (
    category?: string | Record<string, unknown>,
    name?: string | Record<string, unknown>,
    properties?: any,
  ) => void;
  track: (event: string, properties?: any) => void;
  identify: (userId: string, traits?: any) => void;
}
const noop = () => {
  return;
};
export const AnalyticsContext = React.createContext<AnalyticsContextType>({
  page: noop,
  track: noop,
  identify: noop,
  // TODO: Add more spec items if needed
});

export const AnalyticsProvider = (
  props: React.PropsWithChildren<Record<never, never>>,
): JSX.Element => {
  const { children } = props;
  const analytics = React.useMemo(() => {
    if (!process.env.NEXT_PUBLIC_SEGMENT_WRITEKEY) {
      return {
        page: noop,
        track: noop,
        identify: noop,
        // TODO: Add more spec items if needed
      };
    }
    const analyticsBrowser = AnalyticsBrowser.load(
      {
        writeKey: process.env.NEXT_PUBLIC_SEGMENT_WRITEKEY,
        cdnURL: 'https://scdn.clerk.com',
      },
      {
        integrations: {
          'Segment.io': {
            apiHost: 'segapi.clerk.com/v1',
            protocol: 'https',
          },
        },
      },
    );
    return {
      page: analyticsBrowser.page,
      track: analyticsBrowser.track,
      identify: analyticsBrowser.identify,
    };
  }, []);
  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};
