import { useHTTPStatusCode } from '@hooks/useHTTPStatusCode';
import { Statuses } from '@types';
import React from 'react';
import { StatusEntry, StatusEntryProps } from './StatusEntry';

type ClerkServiceStatusProps = { serviceStatusUrl: string } & StatusEntryProps;

export function ClerkServiceStatus({
  serviceStatusUrl,
  ...statusEntryProps
}: ClerkServiceStatusProps): JSX.Element {
  let serviceStatus: Statuses = Statuses.Complete;
  const { statusCode, isLoading } = useHTTPStatusCode(serviceStatusUrl);

  if (statusCode >= 400 || (statusCode === null && !isLoading)) {
    serviceStatus = Statuses.Failed;
  }

  return <StatusEntry entryStatus={serviceStatus} {...statusEntryProps} />;
}
