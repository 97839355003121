import React from 'react';
import { useRouter } from 'next/router';

declare global {
  interface Window {
    dataLayer: {
      // eslint-disable-next-line no-empty-pattern
      push({});
    };
  }
}

const GTM_ENVIRONMENT_SNIPPETS = {
  local: {
    iframeSrc:
      'https://www.googletagmanager.com/ns.html?id=GTM-TMDK2XK&gtm_auth=x3B_A6kBD7WOYFClRvVMNQ&gtm_preview=env-16&gtm_cookies_win=x',
    scriptSrc: `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=x3B_A6kBD7WOYFClRvVMNQ&gtm_preview=env-16&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-TMDK2XK');
    `,
  },
  staging: {
    iframeSrc:
      'https://www.googletagmanager.com/ns.html?id=GTM-TMDK2XK&gtm_auth=IVllJSwUmAGoSdTZmLJ34Q&gtm_preview=env-17&gtm_cookies_win=x',
    scriptSrc: `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=IVllJSwUmAGoSdTZmLJ34Q&gtm_preview=env-17&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-TMDK2XK');
    `,
  },
  live: {
    iframeSrc:
      'https://www.googletagmanager.com/ns.html?id=GTM-TMDK2XK&gtm_auth=8NOcvRp9twWAf5mjCJx2wA&gtm_preview=env-1&gtm_cookies_win=x',
    scriptSrc: `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=8NOcvRp9twWAf5mjCJx2wA&gtm_preview=env-1&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-TMDK2XK');</script>
    `,
  },
};

export const GTM_ENVIRONMENT =
  process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ENVIRONMENT;

export const getGTMSnippets = () =>
  GTM_ENVIRONMENT_SNIPPETS[GTM_ENVIRONMENT] || {};

export const pageview = url => {
  if (GTM_ENVIRONMENT && window.dataLayer) {
    window.dataLayer.push({
      event: 'pageview',
      page: url,
    });
  }
};

export const GoogleTagManager = ({ children }): JSX.Element => {
  const router = useRouter();

  React.useEffect(() => {
    router.events.on('routeChangeComplete', pageview);
    return () => {
      router.events.off('routeChangeComplete', pageview);
    };
  }, [router.events]);

  return children;
};
