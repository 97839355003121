import React from 'react';
import {
  Box,
  Checkbox as ChakraCheckbox,
  Text,
  Badge,
  Flex,
  FlexProps,
} from '@chakra-ui/react';
import { HelperLink } from '@components/common';

export type CheckboxProps = {
  isChecked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  label: string;
  description?: string;
  subtitleLink?: string;
  isDisabled?: boolean;
  showTag?: boolean;
} & FlexProps;

export function Checkbox({
  isChecked,
  isDisabled,
  onChange,
  name,
  label,
  description,
  subtitleLink,
  showTag,
  ...rest
}: CheckboxProps): JSX.Element {
  return (
    <Box>
      <Flex alignItems='center' {...rest}>
        <ChakraCheckbox
          size='lg'
          name={name}
          isChecked={isChecked}
          isDisabled={!!isDisabled}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onChange(event)
          }
        >
          <Flex ml='2'>
            <Text textStyle='md-medium'>{label}</Text>
          </Flex>
        </ChakraCheckbox>
        {showTag && (
          <Badge ml='2' mb='1'>
            Premium
          </Badge>
        )}
      </Flex>
      {description && (
        <Text textStyle='sm-normal' color='gray.500' pl='9' mt='-0.5'>
          {description}
        </Text>
      )}
      {subtitleLink && (
        <HelperLink isExternal href={subtitleLink} pl='9'>
          Learn more
        </HelperLink>
      )}
    </Box>
  );
}
