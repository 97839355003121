import React from 'react';

import { OrganizationMembershipFLatUserData } from '@components/organizations/types';
import { ErrorCodes } from '@components/organizations';

import { Modal } from '@components/common';
import { useCreateMembership } from '@components/organizations/memberships';
import Form from './Form';
import MemberCreated from './MemberCreated';
import ChangePlanInfo from './ChangePlanInfo';

import { useDisclosure, Button } from '@chakra-ui/react';
import { UserAddIcon } from '@heroicons/react/solid';
import { useToast } from '@hooks';

import usePlanManagement from './useAddMemberPlan';
import { SelectedUserType } from './types';

import { useGetOrganization } from '@components/organizations/api';

const AddMemberModal = (): JSX.Element => {
  const { data: organization } = useGetOrganization();
  const { createMembership, mutate } = useCreateMembership();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { showErrorToast } = useToast();
  const { changePlanMessage, isAddMemberDisabled, changePlanLink } =
    usePlanManagement({ organizationID: organization?.id });

  const [createdMember, setCreatedMember] =
    React.useState<OrganizationMembershipFLatUserData>(null);
  const [isCreateMemberLoading, setIsCreateMemberLoading] =
    React.useState(false);

  const [selectedUser, setSelectedUser] =
    React.useState<SelectedUserType | null>({
      id: null,
      role: null,
      identifier: null,
    });

  const handleSelectedUser = ({ id, role, identifier }: SelectedUserType) => {
    setSelectedUser({ id, role, identifier });
  };

  const onAddMembership = async () => {
    try {
      setIsCreateMemberLoading(true);
      const member = await createMembership(selectedUser.id, selectedUser.role);
      setCreatedMember(member);
      mutate();
    } catch (err) {
      if (
        err.code === 400 &&
        ErrorCodes.AlreadyMember === err.globalErrors[0].code
      ) {
        showErrorToast(
          `User ${selectedUser.identifier} is already a member of the ${organization.name} organization.`,
        );
        return;
      }
      showErrorToast(err.globalErrors[0].message);
    } finally {
      setIsCreateMemberLoading(false);
    }
  };

  const onCloseAfterCreation = () => {
    setCreatedMember(null);
    onClose();
  };

  return (
    <>
      <Button
        aria-label='add member'
        leftIcon={<UserAddIcon width={16} height={16} color='white' />}
        onClick={onOpen}
      >
        Add Member
      </Button>
      <Modal
        size='md'
        closeOnEsc
        autoFocus={false}
        onClose={onCloseAfterCreation}
        isOpen={isOpen}
        title='Add member to organization'
      >
        {!createdMember && (
          <>
            <Modal.Body>
              <ChangePlanInfo
                message={changePlanMessage}
                changePlanLink={changePlanLink}
              />
              <Form
                organizationID={organization.id}
                updateSelectedUser={handleSelectedUser}
              />
            </Modal.Body>
            <Modal.ActionFooter
              isRightButtonLoading={isCreateMemberLoading}
              isRightButtonDisabled={!selectedUser.id || isAddMemberDisabled}
              leftButtonMessage='cancel'
              onLeftButtonClicked={onClose}
              onRightButtonClicked={onAddMembership}
              rightButtonMessage='Add'
            />
          </>
        )}
        {createdMember && (
          <>
            <Modal.Body>
              <MemberCreated
                member={createdMember}
                orgName={organization.name}
              />
            </Modal.Body>
            <Modal.ActionFooter
              onLeftButtonClicked={onCloseAfterCreation}
              leftButtonMessage='Close'
            />
          </>
        )}
      </Modal>
    </>
  );
};

export default AddMemberModal;
