import React from 'react';

export function useVisibleInViewport(
  ref: React.MutableRefObject<any>,
  opts: IntersectionObserverInit,
) {
  const [isVisible, setIsVisible] = React.useState(false);

  React.useEffect(() => {
    const header = ref.current;
    if (!header) {
      return;
    }
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(!entry?.isIntersecting);
    }, opts);
    observer.observe(header);
    return () => observer.unobserve(header);
  }, [ref]);

  return { isVisible };
}

