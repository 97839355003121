import React from 'react';
import { Button, Icon, Text } from '@chakra-ui/react';
import { ExclamationIcon } from '@heroicons/react/solid';
import { Modal } from '@components/common';

export type BanUserModalProps = {
  isOpen: boolean;
  handleCancel: () => void;
  handleConfirm: () => void;
  isUserBanned: boolean;
  isLoading: boolean;
};

const modalContent = {
  ban: {
    title: 'Ban user',
    message: 'Are you sure you want to ban this user?',
  },
  unban: {
    title: 'Unban user',
    message: 'Are you sure you want to unban this user?',
  },
};

export const BanUserModal = ({
  isOpen,
  handleCancel,
  handleConfirm,
  isUserBanned,
  isLoading,
}: BanUserModalProps): JSX.Element => {
  const content = isUserBanned ? 'unban' : 'ban';

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleCancel}
      title={modalContent[content].title}
    >
      <Modal.Body>
        <Text textStyle='md-normal'>{modalContent[content].message}</Text>

        <Text textStyle='md-normal'>
          This action is not permanent and can be undone.
        </Text>
      </Modal.Body>

      <Modal.Footer flexDir='row-reverse'>
        <Button
          onClick={handleConfirm}
          colorScheme='danger'
          leftIcon={<Icon as={ExclamationIcon} />}
          isLoading={isLoading}
        >
          {modalContent[content].title}
        </Button>

        <Button onClick={handleCancel} variant='ghost' autoFocus>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
