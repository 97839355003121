import React from 'react';

import { SelectMenu } from '@components/common';
import { useUpdateMembership } from '@components/organizations/memberships';
import { roles } from '@components/organizations';

import { MembershipRole } from '@clerk/types';
import { ErrorCodes } from '@components/organizations';

import { useToast } from '@hooks';

export type RoleCellProps = {
  role: MembershipRole;
  id: string;
  identifier: string;
};

const RoleCell = ({ role, id, identifier }: RoleCellProps): JSX.Element => {
  const [isUpdating, setIsUpating] = React.useState(false);
  const { showErrorToast, showSuccessToast } = useToast();
  const { updateMembership, mutate: mutateMemberships } = useUpdateMembership();
  const [updatedRole, setUpdatedRole] = React.useState(null);

  const handleSelectRole = async (roleValue: MembershipRole) => {
    try {
      setIsUpating(true);
      const { role, first_name, last_name, identifier } =
        await updateMembership(id, roleValue);
      mutateMemberships();
      setUpdatedRole(role);
      const updateUserIdentifier = first_name
        ? `${first_name} ${last_name}`
        : identifier;
      showSuccessToast(`Role has been updated for ${updateUserIdentifier}`);
    } catch (err) {
      if (
        err.code === 400 &&
        ErrorCodes.AtLeastOneAdmin === err.globalErrors[0].code
      ) {
        showErrorToast(
          `Cannot change role of ${identifier}. There has to be at least one admin in the organization.`,
        );
        return;
      }
      showErrorToast(`Couldn't update role of user with id  ${id}`);
    } finally {
      setIsUpating(false);
    }
  };

  return (
    <SelectMenu
      isLoading={isUpdating}
      items={roles}
      buttonProps={{ size: 'sm' }}
      value={updatedRole || role}
      onChange={handleSelectRole}
    />
  );
};

export default RoleCell;
