import React from 'react';

import { Button, Icon, Spacer } from '@chakra-ui/react';

import { EyeIcon } from '@heroicons/react/solid';

interface TemplateRestrictedCTAProps {
  onPreview: () => void;
  onUpgradePrompt: () => void;
}

const buttonStyles = {
  color: 'gray.300',
  variant: 'ghost',
  _focus: {
    boxShadow: 'none',
  },
  _hover: {
    color: 'unset',
    background: 'gray.50',
  },
  _active: {
    bg: 'unset',
  },
};

export function TemplateRestrictedCTAs({
  onPreview,
  onUpgradePrompt,
}: TemplateRestrictedCTAProps): JSX.Element {
  return (
    <>
      {/* TODO show as loading when preview endpoint is hit */}

      <Button
        aria-label='Preview'
        onClick={onPreview}
        textTransform='none'
        {...buttonStyles}
      >
        <Icon as={EyeIcon} boxSize='5' mr='1' />
        Preview
      </Button>

      <Spacer />

      <Button type='button' onClick={onUpgradePrompt}>
        Upgrade now
      </Button>
    </>
  );
}
