export const Checkbox = {
  baseStyle: {
    control: {
      _checked: {
        bg: 'primary.500',
        borderColor: 'primary.500',
      },
      _focus: {
        boxShadow: 'dropShadow1',
      },
    },
    label: {
      marginStart: 2,
      marginBottom: 1,
    },
  },
  sizes: {
    md: {
      control: {
        boxSize: '5',
        rounded: 'lg',
        borderColor: 'gray.100',
      },
      label: {
        fontSize: 'xs',
        lineHeight: '1rem',
      },
    },
  },
  variants: {
    normal: {
      control: {
        boxSize: '5',
        rounded: 'base',
        borderColor: 'gray.100',
      },
      label: {
        fontSize: '0.75rem',
        lineHeight: '1rem',
      },
    },
  },
};
