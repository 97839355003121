import _pick from 'lodash/pick';

/*
  allFields: The react hook form user_settings payload (auth config 2)
  dirtyFields: The dirty fields the react hook forms provides for the current form
  topLevelKey: The top level accessor of the user_settings payload we want to get the dirty values from

  Returns the dirty attributes of the allFields object for the given top level accessor
*/

export const getDirtyValues = (allFields, dirtyFields, topLevelKey: string) => {
  if (!dirtyFields[topLevelKey]) {
    return {};
  }
  const dirtyFieldsArray = Object.keys(dirtyFields[topLevelKey]);
  return dirtyFieldsArray.reduce(
    (acc, dirtyField) => ({
      [topLevelKey]: {
        ...acc[topLevelKey],
        [dirtyField]: _pick(
          // keep only changed attribute payload
          allFields[topLevelKey][dirtyField],
          Object.keys(dirtyFields[topLevelKey][dirtyField]),
        ),
      },
    }),
    { [topLevelKey]: {} },
  );
};
