import React, { useState } from 'react';

import { START_BUILDING_FRAMEWORKS } from '@constants';
import { Framework } from '@types';
import { useLocation, useAnalytics } from '@hooks';
import { CardSinglePanel } from '@components/common';

import { QuickstartGuideTiles } from './QuickstartGuideTiles';
import { QuickstartGuideTabs } from './QuickstartGuideTabs';

export function StartBuilding(): JSX.Element {
  const { track } = useAnalytics();
  const { instanceId } = useLocation();
  const [framework, setFramework] = useState<Framework>();
  const [isSnippetOpen, setIsSnippetOpen] = useState(false);

  const onHoverStart = (startItem: Framework) => {
    setIsSnippetOpen(true);
    setFramework(
      START_BUILDING_FRAMEWORKS.find(item => item.slug === startItem.slug),
    );
    track('Dashboard_Instance Overview_Start Building Card Link Hovered', {
      surface: 'Dashboard',
      location: 'Instance Overview',
      instanceId,
      clickedOn: startItem.name,
    });
  };

  return (
    <CardSinglePanel
      title='Quickstarts'
      subtitle='Choose your framework below. The average installation takes only 7 minutes.'
      subtitleStyles={{ mb: 6 }}
    >
      <QuickstartGuideTiles framework={framework} hoverStart={onHoverStart} />
      {framework && (
        <QuickstartGuideTabs
          framework={framework}
          snippetOpen={isSnippetOpen}
        />
      )}
    </CardSinglePanel>
  );
}
