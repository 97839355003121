import React from 'react';
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { removeAutoComplete } from '@utils';
import { OAuthProviderSettings } from '@types';
import { getModalFields } from './constants';

interface ClientSecretInputProps {
  oauthProviderSettings: OAuthProviderSettings;
  customProfile: boolean;
}

export function ClientSecretInput({
  oauthProviderSettings,
  customProfile,
}: ClientSecretInputProps): JSX.Element {
  const modalFields = getModalFields(oauthProviderSettings.provider);

  const {
    register,
    formState: { errors },
  } = useFormContext();

  const getInputType = () => {
    switch (oauthProviderSettings.strategy) {
      case 'oauth_apple':
        return Textarea;
      default:
        return Input;
    }
  };

  const InputType = getInputType();

  if (!customProfile) {
    return <></>;
  }

  return (
    <Box>
      <FormControl
        isInvalid={!!errors.client_secret}
        {...removeAutoComplete('client_secret')}
      >
        <FormLabel fontSize='sm' mb='0.5'>
          {modalFields.client_secret.label}
        </FormLabel>

        <Text color='gray.500' textStyle='md-normal' w='full'>
          {modalFields.client_secret.description}
        </Text>

        <InputType
          defaultValue={
            oauthProviderSettings?.custom_profile
              ? oauthProviderSettings?.client_secret
              : ''
          }
          {...register('client_secret', {
            validate: value => {
              if (customProfile && !value) {
                return `${modalFields.client_secret.label} is required`;
              }
              return true;
            },
          })}
        />

        <FormErrorMessage>{errors.client_secret?.message}</FormErrorMessage>
      </FormControl>
    </Box>
  );
}
