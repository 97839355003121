import React from 'react';

import { BaseSchema } from '@types';
import { Organization } from './types';

import { formatRel } from '@components/users/utils';

import { NameCell } from './Cells';

export type OrganizationSchema = BaseSchema<Organization>;

export const organizationSchema = (): OrganizationSchema => [
  {
    header: 'Name',
    cell: org => <NameCell organization={org} />,
    key: 'name',
    tdProps: {
      color: 'gray.900',
      borderTopLeftRadius: 'md',
      borderBottomLeftRadius: 'md',
    },
    enableSorting: false,
  },
  {
    header: 'ID',
    cell: org => org.id,
    key: 'id',
  },
  {
    header: 'Members',
    cell: org => org.members_count,
    key: 'members_count',
    enableSorting: false,
  },
  {
    header: 'Created',
    cell: org => formatRel(org.created_at),
    key: 'created_at',
    enableSorting: false,
  },
];
