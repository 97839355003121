import React from 'react';
import { Text } from '@chakra-ui/react';

type HintProps = {
  text: string | JSX.Element;
  isFaded?: boolean;
};

export function Hint({ text, isFaded }: HintProps): JSX.Element {
  return (
    <Text
      textStyle='sm-normal'
      color={isFaded ? 'gray.300' : 'gray.500'}
      mb={4}
    >
      {text}
    </Text>
  );
}
