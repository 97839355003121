import React from 'react';
import { useDashboardSWR } from '@hooks/useDashboardAPI';
import { useLocation } from '@hooks';
import { Flex, HStack, Text } from '@chakra-ui/react';
import { CardSinglePanel } from '@components/common';
import { UserList } from './UserList';
import { LoadingSpinner } from './LoadingSpinner';

export type User = {
  first_name: string;
  last_name: string;
  identifier: string;
  time?: string;
  /*
   * deprecated
   */
  profile_image_url?: string;
  image_url: string;
  user_id: string;
};

type LatestActivity = {
  signups: User[];
  signins: User[];
};

export function RecentActivity(): JSX.Element {
  const { instanceId } = useLocation();

  const {
    data = { signups: [], signins: [] },
    mutate,
    error,
    isValidating,
  } = useDashboardSWR<LatestActivity>(
    `/v1/instances/${instanceId}/analytics/latest_activity`,
  );

  return (
    <HStack spacing={7} align='stretch'>
      <CardSinglePanel title='Recent sign-ups' hasError={error} mutate={mutate}>
        {isValidating ? (
          <LoadingSpinner />
        ) : data.signups.length ? (
          <UserList users={data.signups} action='sign-up' />
        ) : (
          <NoRecentActivity>No recent sign-ups to show</NoRecentActivity>
        )}
      </CardSinglePanel>

      <CardSinglePanel title='Recent sign-ins' hasError={error} mutate={mutate}>
        {isValidating ? (
          <LoadingSpinner />
        ) : data.signins.length ? (
          <UserList users={data.signins} action='sign-in' />
        ) : (
          <NoRecentActivity>No recent sign-ins to show</NoRecentActivity>
        )}
      </CardSinglePanel>
    </HStack>
  );
}

type NoRecentActivityProps = {
  children: string;
};

const NoRecentActivity = ({ children }: NoRecentActivityProps): JSX.Element => (
  <Flex justify='center'>
    <Text textStyle='sm-normal' color='gray.500'>
      {children}
    </Text>
  </Flex>
);
