import React from 'react';
import { Code, Text } from '@chakra-ui/react';
import { OAuthProvider } from '@clerk/types';
import { ExtraOAuthSettings } from '@types';

const Medium = ({ children }) => (
  <Text as='span' textStyle='sm-medium'>
    {children}
  </Text>
);

type FieldProperties = {
  label: string;
  description: React.ReactNode;
};

type MandatoryKeys =
  | 'client_id'
  | 'client_secret'
  | 'redirect'
  | 'additional_scopes';
type KeysOfUnion<T> = T extends T ? keyof T : never;

type ModalFields = Record<MandatoryKeys, FieldProperties> &
  Partial<Record<KeysOfUnion<ExtraOAuthSettings>, FieldProperties>>;

export const AUTHENTICATABLE_LABEL = 'Used for sign-in';
export const DEV_MODE_LABEL = 'Development mode';

export const getModalFields = (provider: OAuthProvider): ModalFields => {
  switch (provider) {
    case 'google':
      return {
        client_id: {
          label: 'Client ID',
          description: (
            <>
              Enter the client ID from{' '}
              <Medium>
                Google API Console › APIs & Services › Credentials
              </Medium>
            </>
          ),
        },
        client_secret: {
          label: 'Client secret',
          description: (
            <>
              Enter the client secret from{' '}
              <Medium>
                Google API Console › APIs & Services › Credentials
              </Medium>
            </>
          ),
        },
        redirect: {
          label: 'Authorized redirect URI',
          description: (
            <>
              Copy and paste into{' '}
              <Medium>
                Google API Console › APIs & Services › Credentials
              </Medium>{' '}
              where it asks for <Code colorScheme='gray'>redirect_URI</Code>
            </>
          ),
        },
        additional_scopes: {
          label: 'Scopes',
          description: <>Enter additional OAuth scopes to be requested</>,
        },
      };

    case 'facebook':
      return {
        client_id: {
          label: 'Client ID',
          description: (
            <>
              Enter the client ID from{' '}
              <Medium>Facebook Login › Settings › Client OAuth Settings</Medium>
            </>
          ),
        },
        client_secret: {
          label: 'Client secret',
          description: (
            <>
              Enter the client secret from{' '}
              <Medium>Facebook Login › Settings › Client OAuth Settings</Medium>
            </>
          ),
        },
        redirect: {
          label: 'Authorized redirect URI',
          description: (
            <>
              Copy and paste into{' '}
              <Medium>Facebook Login › Settings › Client OAuth Settings</Medium>{' '}
              where it asks for Valid OAuth Redirect URIs
            </>
          ),
        },
        additional_scopes: {
          label: 'Scopes',
          description: <>Enter additional OAuth scopes to be requested</>,
        },
      };

    case 'tiktok':
      return {
        client_id: {
          label: 'Client key',
          description: (
            <>
              Enter the client key from the <Medium>TikTok Login Kit</Medium>
            </>
          ),
        },
        client_secret: {
          label: 'Client secret',
          description: (
            <>
              Enter the client secret from the <Medium>TikTok Login Kit</Medium>
            </>
          ),
        },
        redirect: {
          label: 'Authorized redirect URI',
          description: (
            <>
              Copy and paste into the <Medium>TikTok Login Kit</Medium> where it
              asks for <Code colorScheme='gray'>redirect_URI</Code>
            </>
          ),
        },
        additional_scopes: {
          label: 'Scopes',
          description: <>Enter additional OAuth scopes to be requested</>,
        },
      };

    case 'github':
      return {
        client_id: {
          label: 'Client ID',
          description: (
            <>
              Enter the client ID from the <Medium>GitHub OAuth</Medium>
            </>
          ),
        },
        client_secret: {
          label: 'Client secret',
          description: (
            <>
              Enter the client secret from the <Medium>GitHub OAuth</Medium>
            </>
          ),
        },
        redirect: {
          label: 'Authorized redirect URI',
          description: (
            <>
              Copy and paste into <Medium>GitHub OAuth</Medium> where it asks
              for <Code colorScheme='gray'>redirect_URI</Code>
            </>
          ),
        },
        additional_scopes: {
          label: 'Scopes',
          description: <>Enter additional OAuth scopes to be requested</>,
        },
      };

    case 'hubspot':
      return {
        client_id: {
          label: 'Client ID',
          description: (
            <>
              Enter the client ID from{' '}
              <Medium>HubSpot App Settings › Auth</Medium>
            </>
          ),
        },
        client_secret: {
          label: 'Client secret',
          description: (
            <>
              Enter the client secret from{' '}
              <Medium>HubSpot App Settings › Auth</Medium>
            </>
          ),
        },
        redirect: {
          label: 'Authorized redirect URI',
          description: (
            <>
              Copy and paste into <Medium>HubSpot App Settings › Auth</Medium>{' '}
              where it asks for <Code colorScheme='gray'>redirect_URI</Code>
            </>
          ),
        },
        additional_scopes: {
          label: 'Scopes',
          description: <>Enter additional OAuth scopes to be requested</>,
        },
      };

    case 'dropbox':
      return {
        client_id: {
          label: 'Client ID',
          description: (
            <>
              Enter the client ID from <Medium>Dropbox Developer site</Medium>
            </>
          ),
        },
        client_secret: {
          label: 'Client secret',
          description: (
            <>
              Enter the client secret from{' '}
              <Medium>Dropbox Developer site</Medium>
            </>
          ),
        },
        redirect: {
          label: 'Authorized redirect URI',
          description: (
            <>
              Copy and paste into <Medium>Dropbox Developer site</Medium> where
              it asks for <Code colorScheme='gray'>redirect_URI</Code>
            </>
          ),
        },
        additional_scopes: {
          label: 'Scopes',
          description: <>Enter additional OAuth scopes to be requested</>,
        },
      };

    case 'twitter':
      return {
        client_id: {
          label: 'API key',
          description: (
            <>
              Enter the API key from the <Medium>Twitter App Dashboard</Medium>
            </>
          ),
        },
        client_secret: {
          label: 'API secret key',
          description: (
            <>
              Enter the API secret key from the{' '}
              <Medium>Twitter App Dashboard</Medium>
            </>
          ),
        },
        redirect: {
          label: 'Authorized redirect URI',
          description: (
            <>
              Copy and paste into the <Medium>Twitter App Dashboard</Medium>{' '}
              where it asks for <Code colorScheme='gray'>redirect_URI</Code>
            </>
          ),
        },
        additional_scopes: {
          label: 'Scopes',
          description: <>Enter additional OAuth scopes to be requested</>,
        },
      };

    case 'discord':
      return {
        client_id: {
          label: 'Client ID',
          description: (
            <>
              Enter the client ID from the{' '}
              <Medium>Discord Developer portal</Medium>
            </>
          ),
        },
        client_secret: {
          label: 'Client secret',
          description: (
            <>
              Enter the client secret from the{' '}
              <Medium>Discord Developer portal</Medium>
            </>
          ),
        },
        redirect: {
          label: 'Authorized redirect URI',
          description: (
            <>
              Copy and paste into the{' '}
              <Medium>Discord Developer › Applications</Medium> where it asks
              for <Code colorScheme='gray'>redirect_URI</Code>
            </>
          ),
        },
        additional_scopes: {
          label: 'Scopes',
          description: <>Enter additional OAuth scopes to be requested</>,
        },
      };

    case 'gitlab':
      return {
        client_id: {
          label: 'Application ID',
          description: (
            <>
              Enter the application ID from the{' '}
              <Medium>
                Gitlab › Settings › Applications › Authorized applications
              </Medium>
            </>
          ),
        },
        client_secret: {
          label: 'Secret',
          description: (
            <>
              Enter the secret from the{' '}
              <Medium>
                Gitlab › Settings › Applications › Authorized applications
              </Medium>
            </>
          ),
        },
        redirect: {
          label: 'Authorized redirect URI',
          description: (
            <>
              Copy and paste into{' '}
              <Medium>
                Gitlab › Settings › Applications › Authorized applications
              </Medium>{' '}
              where it asks for <Code colorScheme='gray'>Redirect URI</Code>
            </>
          ),
        },
        additional_scopes: {
          label: 'Scopes',
          description: <>Enter additional OAuth scopes to be requested</>,
        },
      };

    case 'twitch':
      return {
        client_id: {
          label: 'Client ID',
          description: (
            <>
              Enter the client ID from the{' '}
              <Medium>Twitch Developer site</Medium>
            </>
          ),
        },
        client_secret: {
          label: 'Client secret',
          description: (
            <>
              Enter the client secret from the{' '}
              <Medium>Twitch Developer site</Medium>
            </>
          ),
        },
        redirect: {
          label: 'Authorized redirect URI',
          description: (
            <>
              Copy and paste into the <Medium>Twitch Developer site</Medium>{' '}
              where it asks for <Code colorScheme='gray'>redirect_URI</Code>
            </>
          ),
        },
        additional_scopes: {
          label: 'Scopes',
          description: <>Enter additional OAuth scopes to be requested</>,
        },
      };

    case 'linkedin':
      return {
        client_id: {
          label: 'Client ID',
          description: (
            <>
              Enter the client ID from the{' '}
              <Medium>LinkedIn Developer site</Medium>
            </>
          ),
        },
        client_secret: {
          label: 'Client secret',
          description: (
            <>
              Enter the client secret from the{' '}
              <Medium>LinkedIn Developer site</Medium>
            </>
          ),
        },
        redirect: {
          label: 'Authorized redirect URI',
          description: (
            <>
              Copy and paste into the <Medium>LinkedIn Developer site</Medium>{' '}
              where it asks for <Code colorScheme='gray'>redirect_URI</Code>
            </>
          ),
        },
        additional_scopes: {
          label: 'Scopes',
          description: <>Enter additional OAuth scopes to be requested</>,
        },
      };

    case 'atlassian':
      return {
        client_id: {
          label: 'Client ID',
          description: (
            <>
              Enter the client ID from the{' '}
              <Medium>Atlassian Developer site</Medium>
            </>
          ),
        },
        client_secret: {
          label: 'Client secret',
          description: (
            <>
              Enter the client secret from the{' '}
              <Medium>Atlassian Developer site</Medium>
            </>
          ),
        },
        redirect: {
          label: 'Authorized redirect URI',
          description: (
            <>
              Copy and paste into your{' '}
              <Medium>Atlassian app Authorization settings</Medium> where it
              asks for the <Code colorScheme='gray'>callback URL</Code>
            </>
          ),
        },
        additional_scopes: {
          label: 'Scopes',
          description: <>Enter additional OAuth scopes to be requested</>,
        },
      };

    case 'bitbucket':
      return {
        client_id: {
          label: 'Client ID',
          description: (
            <>
              Enter the client ID from the{' '}
              <Medium>Bitbucket Developer site</Medium>
            </>
          ),
        },
        client_secret: {
          label: 'Client secret',
          description: (
            <>
              Enter the client secret from the{' '}
              <Medium>Bitbucket Developer site</Medium>
            </>
          ),
        },
        redirect: {
          label: 'Authorized redirect URI',
          description: (
            <>
              Copy and paste into the{' '}
              <Medium>Bitbucket OAuth consumer configuration</Medium> where it
              asks for <Code colorScheme='gray'>callback URL</Code>
            </>
          ),
        },
        additional_scopes: {
          label: 'Scopes',
          description: <>Enter additional OAuth scopes to be requested</>,
        },
      };

    case 'microsoft':
      return {
        client_id: {
          label: 'Client ID',
          description: (
            <>
              Enter the client ID from the{' '}
              <Medium>Microsoft Developer site</Medium>
            </>
          ),
        },
        client_secret: {
          label: 'Client secret',
          description: (
            <>
              Enter the client secret from the{' '}
              <Medium>Microsoft Developer site</Medium>
            </>
          ),
        },
        redirect: {
          label: 'Authorized redirect URI',
          description: (
            <>
              Copy and paste into the <Medium>Microsoft Developer site</Medium>{' '}
              where it asks for <Code colorScheme='gray'>redirect_URI</Code>
            </>
          ),
        },
        additional_scopes: {
          label: 'Scopes',
          description: <>Enter additional OAuth scopes to be requested</>,
        },
      };

    case 'notion':
      return {
        client_id: {
          label: 'Client ID',
          description: (
            <>
              Enter the client ID from the{' '}
              <Medium>Notion Developer site</Medium>
            </>
          ),
        },
        client_secret: {
          label: 'Client secret',
          description: (
            <>
              Enter the client secret from the{' '}
              <Medium>Notion Developer site</Medium>
            </>
          ),
        },
        redirect: {
          label: 'Authorized redirect URI',
          description: (
            <>
              Copy and paste into the <Medium>Notion Developer site</Medium>{' '}
              where it asks for <Code colorScheme='gray'>Redirect URIs</Code>
            </>
          ),
        },
        additional_scopes: {
          label: 'Scopes',
          description: <>Enter additional OAuth scopes to be requested</>,
        },
      };

    case 'apple':
      return {
        client_id: {
          label: 'Apple Services ID',
          description: (
            <>
              Enter the Services ID as seen in your{' '}
              <Medium>Apple Developer account</Medium>
            </>
          ),
        },
        client_secret: {
          label: 'Apple Private Key',
          description: (
            <>
              Enter the private key from your{' '}
              <Medium>Apple Developer account</Medium>
            </>
          ),
        },
        redirect: {
          label: 'Return URL',
          description: (
            <>
              Copy and paste into the <Medium>Apple Developer portal</Medium>{' '}
              where it asks for <Code colorScheme='gray'>Return URL</Code>
            </>
          ),
        },
        additional_scopes: {
          label: 'Scopes',
          description: <>Enter additional OAuth scopes to be requested</>,
        },
        team_id: {
          label: 'Apple Team ID',
          description: (
            <>
              Enter the Team ID from your{' '}
              <Medium>Apple Developer account</Medium>
            </>
          ),
        },
        key_id: {
          label: 'Apple Key ID',
          description: (
            <>
              Enter the Key ID from your{' '}
              <Medium>Apple Developer account</Medium>
            </>
          ),
        },
        apple_email_source: {
          label: 'Email Source for Apple Private Email Relay',
          description: (
            <>
              Add this as an Email Source in your Apple Developer portal, under{' '}
              <Medium>Certificates, Identifiers & Profiles &gt; More</Medium>.
            </>
          ),
        },
      };
    case 'line':
      return {
        client_id: {
          label: 'Channel ID',
          description: (
            <>
              Enter the channel ID from the <Medium>LINE Developer site</Medium>
            </>
          ),
        },
        client_secret: {
          label: 'Channel secret',
          description: (
            <>
              Enter the channel secret from the{' '}
              <Medium>LINE Developer site</Medium>
            </>
          ),
        },
        redirect: {
          label: 'Authorized redirect URI',
          description: (
            <>
              Copy and paste into the <Medium>LINE Developer site</Medium> where
              it asks for <Code colorScheme='gray'>Callback URL</Code>
            </>
          ),
        },
        additional_scopes: {
          label: 'Scopes',
          description: <>Enter additional OAuth scopes to be requested</>,
        },
      };
    case 'instagram':
      return {
        client_id: {
          label: 'App ID',
          description: (
            <>
              Enter the Instagram App ID from the{' '}
              <Medium>Meta for Developers site</Medium>
            </>
          ),
        },
        client_secret: {
          label: 'App Secret',
          description: (
            <>
              Enter the Instagram App Secret from the{' '}
              <Medium>Meta for Developers site</Medium>
            </>
          ),
        },
        redirect: {
          label: 'Authorized redirect URI',
          description: (
            <>
              Copy and paste into the <Medium>Meta for Developers site</Medium>{' '}
              where it asks for{' '}
              <Code colorScheme='gray'>OAuth Redirect URIs</Code>
            </>
          ),
        },
        additional_scopes: {
          label: 'Scopes',
          description: <>Enter additional OAuth scopes to be requested</>,
        },
      };
    case 'coinbase':
      return {
        client_id: {
          label: 'Client ID',
          description: (
            <>
              Enter the Client ID from the <Medium>Coinbase site</Medium>
            </>
          ),
        },
        client_secret: {
          label: 'Client Secret',
          description: (
            <>
              Enter the Client Secret from the <Medium>Coinbase site</Medium>
            </>
          ),
        },
        redirect: {
          label: 'Authorized redirect URI',
          description: (
            <>
              Copy and paste into the <Medium>Coinbase site</Medium> where it
              asks for <Code colorScheme='gray'>Permitted Redirect URIs</Code>
            </>
          ),
        },
        additional_scopes: {
          label: 'Scopes',
          description: <>Enter additional OAuth scopes to be requested</>,
        },
      };
    case 'spotify':
      return {
        client_id: {
          label: 'Client ID',
          description: (
            <>
              Enter the Client ID from the{' '}
              <Medium>Spotify for Developers site</Medium>
            </>
          ),
        },
        client_secret: {
          label: 'Client Secret',
          description: (
            <>
              Enter the Client Secret from the{' '}
              <Medium>Spotify for Developers site</Medium>
            </>
          ),
        },
        redirect: {
          label: 'Redirect URIs',
          description: (
            <>
              Copy and paste into the{' '}
              <Medium>Spotify for Developers site</Medium> where it asks for{' '}
              <Code colorScheme='gray'>Redirect URIs</Code>
            </>
          ),
        },
        additional_scopes: {
          label: 'Scopes',
          description: <>Enter additional OAuth scopes to be requested</>,
        },
      };
    case 'box':
      return {
        client_id: {
          label: 'Client ID',
          description: (
            <>
              Enter the Client ID from the <Medium>Box Developers site</Medium>
            </>
          ),
        },
        client_secret: {
          label: 'Client Secret',
          description: (
            <>
              Enter the Client Secret from the{' '}
              <Medium>Box Developers site</Medium>
            </>
          ),
        },
        redirect: {
          label: 'Redirect URIs',
          description: (
            <>
              Copy and paste into the <Medium>Box Developers site</Medium> where
              it asks for <Code colorScheme='gray'>OAuth 2.0 Redirect URI</Code>
            </>
          ),
        },
        additional_scopes: {
          label: 'Scopes',
          description: <>Enter additional OAuth scopes to be requested</>,
        },
      };
    case 'xero':
      return {
        client_id: {
          label: 'Client ID',
          description: (
            <>
              Enter the Client ID from the <Medium>Xero Developer site</Medium>
            </>
          ),
        },
        client_secret: {
          label: 'Client Secret',
          description: (
            <>
              Enter the Client Secret from the{' '}
              <Medium>Xero Developer site</Medium>
            </>
          ),
        },
        redirect: {
          label: 'Redirect URIs',
          description: (
            <>
              Copy and paste into the <Medium>Xero Developer site</Medium> where
              it asks for <Code colorScheme='gray'>Redirect URIs</Code>
            </>
          ),
        },
        additional_scopes: {
          label: 'Scopes',
          description: <>Enter additional OAuth scopes to be requested</>,
        },
      };
    case 'slack':
      return {
        client_id: {
          label: 'Client ID',
          description: (
            <>
              Enter the Client ID from the <Medium>Slack Developer site</Medium>
            </>
          ),
        },
        client_secret: {
          label: 'Client Secret',
          description: (
            <>
              Enter the Client Secret from the{' '}
              <Medium>Slack Developer site</Medium>
            </>
          ),
        },
        redirect: {
          label: 'Redirect URIs',
          description: (
            <>
              Copy and paste into the <Medium>Slack Developer site</Medium>{' '}
              where it asks for <Code colorScheme='gray'>Redirect URLs</Code>
            </>
          ),
        },
        additional_scopes: {
          label: 'Scopes',
          description: <>Enter additional OAuth scopes to be requested</>,
        },
      };
    case 'linear':
      return {
        client_id: {
          label: 'Client ID',
          description: (
            <>
              Enter the Client ID from the <Medium>Linear API page</Medium>
            </>
          ),
        },
        client_secret: {
          label: 'Client Secret',
          description: (
            <>
              Enter the Client Secret from the <Medium>Linear API page</Medium>
            </>
          ),
        },
        redirect: {
          label: 'Redirect URIs',
          description: (
            <>
              Copy and paste into the <Medium>Linear API page</Medium> where it
              asks for <Code colorScheme='gray'>Callback URLs</Code>
            </>
          ),
        },
        additional_scopes: {
          label: 'Scopes',
          description: <>Enter additional OAuth scopes to be requested</>,
        },
      };
    default:
      return null;
  }
};
