import {
  useDashboardSWR,
  useDashboardCRUD,
  useDashboardSWRInfinite,
} from '@hooks';

import { OrganizationResponse, Organization } from './types';
import { useOrganizationsPathAndKeys } from './hooks';

import { useSWRConfig } from 'swr';

type UseGetOrganizationsReturn = {
  data: Organization[];
  hasLoaded: boolean;
  mutate: () => void;
  totalCount: number;
  error: any;
};

export const useGetOrganizationsFromDAPI = (): UseGetOrganizationsReturn => {
  const { pathWithParams } = useOrganizationsPathAndKeys();
  const {
    data,
    error,
    mutate: SWRMutate,
  } = useDashboardSWR<OrganizationResponse>(() => {
    if (!pathWithParams) {
      return null;
    }
    return pathWithParams;
  });

  const hasLoaded = !!data || error;

  const mutate = () => {
    void SWRMutate();
  };

  return {
    data: data?.data || [],
    error,
    hasLoaded,
    mutate,
    totalCount: data?.total_count || 0,
  };
};

type UseOrganizationMutatorsReturn = {
  create: ({
    created_by,
    slug,
    name,
    logo_image_id,
  }: {
    created_by: string;
    slug?: string;
    name: string;
    logo_image_id: string;
    logo_url: string;
  }) => Promise<Organization>;
  mutateCache: () => void;
};

export const useOrganizationMutators = (): UseOrganizationMutatorsReturn => {
  const { path, cacheKey } = useOrganizationsPathAndKeys();
  const { mutate: mutateSWR } = useSWRConfig();
  const { create, update } = useDashboardCRUD<Organization>();

  const updateLogo = async ({ organizationID, logo_image_id, logo_url }) => {
    await update(`${path}/${organizationID}/logo`, {
      logo_image_id,
      logo_url,
    });
  };

  const createOrganization: UseOrganizationMutatorsReturn['create'] = async ({
    created_by,
    slug,
    name,
    logo_image_id,
    logo_url,
  }) => {
    const organization = await create(path, { created_by, slug, name });
    await updateLogo({
      logo_image_id,
      logo_url,
      organizationID: organization.id,
    });

    return organization;
  };

  const mutateCache = () => {
    void mutateSWR(cacheKey);
  };

  return { create: createOrganization, mutateCache };
};

type UseGetOrganizationsInfiniteReturn = {
  loadMore: () => void;
  isLoading: boolean;
  isLoadingMore: boolean;
  organizations: Organization[];
  totalCount: number;
};

export const useGetOrganizationsInfinite = ({
  search = '',
  userID = '',
  revalidateOnMount = false,
}: {
  search?: string;
  userID?: string;
  revalidateOnMount?: boolean;
}): UseGetOrganizationsInfiniteReturn => {
  const { makeInfinitePath } = useOrganizationsPathAndKeys();

  const { data, setSize, size, error } =
    useDashboardSWRInfinite<OrganizationResponse>(
      makeInfinitePath(search, userID),
      {
        persistSize: true,
        revalidateOnMount,
      },
    );

  const isLoadingInitialData = !data && !error;

  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 && data && typeof data[size - 1] === 'undefined');

  const loadMore = () => setSize(size + 1);

  const organizationsResponse: OrganizationResponse[] = data
    ? [].concat(...data)
    : [];

  const totalCount = data?.[0].total_count || 0;
  const organizations = organizationsResponse.map(({ data }) => data).flat();

  return {
    organizations,
    loadMore: loadMore,
    isLoading: isLoadingInitialData,
    isLoadingMore,
    totalCount,
  };
};
