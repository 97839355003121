import React from 'react';
import { Statuses } from '@types';
import { useDeployStatus } from '@hooks';
import { isProduction } from '@utils';
import { StatusEntry } from './StatusEntry';
import { EmailAndTLSStatus } from './EmailAndTLSStatus';
import { Button } from '@chakra-ui/react';

const SERVICE_STATUS_POLLING_INTERVAL = 15000;

export function DomainDeployStatus(): JSX.Element {
  const { deployStatus, ssl, mail, environmentType } = useDeployStatus({
    refreshInterval: SERVICE_STATUS_POLLING_INTERVAL,
  });
  const isProdEnv = isProduction(environmentType);
  const domainStatus = isProdEnv ? deployStatus : Statuses.Complete;

  const hasDeployStarted = deployStatus !== Statuses.NotStarted;

  const [detailsVisible, setDetailsVisible] = React.useState(
    deployStatus !== Statuses.Complete,
  );

  return (
    <StatusEntry
      entryTitle='Frontend API'
      entryStatus={domainStatus}
      entryDetails={
        hasDeployStarted &&
        (detailsVisible ? (
          <EmailAndTLSStatus sslStatus={ssl.status} emailStatus={mail.status} />
        ) : (
          <ShowDetailsButton handleClick={() => setDetailsVisible(true)} />
        ))
      }
    />
  );
}

function ShowDetailsButton({ handleClick }): JSX.Element {
  return (
    <Button
      variant='link'
      size='xs'
      textTransform='none'
      padding='none'
      fontWeight='400'
      onClick={handleClick}
    >
      Show details
    </Button>
  );
}
