import React from 'react';
import { useRouter } from 'next/router';
import { Button, ButtonProps, Flex, useDisclosure } from '@chakra-ui/react';
import { useDeployStatus } from '@hooks/useDeployStatus';
import { isProduction } from '@utils';
import { ApplyProdChangesModal } from './ApplyProdChangesModal';
import { Statuses } from '@types';
import { ApplySetupWarningModal } from './ApplySetupWarningModal';

type FormSubmitButtonProps = {
  formId: string;
  isLoading?: boolean;
} & ButtonProps;

export const FormSubmitButton = ({
  formId,
  isLoading,
  ...rest
}: FormSubmitButtonProps) => (
  <Button
    type='submit'
    form={formId}
    isLoading={isLoading}
    role='submit'
    tabIndex={-1}
    {...rest}
  >
    Apply changes
  </Button>
);

type StickyBottomCTAProps = {
  isVisible: boolean;
  onReset: () => void;
  isSubmitting?: boolean;
  formId: string;
  warningMsg?: string;
};

// The Apply changes  confirmation modal should be visible for all production instance settings
// expect from user and organization update forms.
const IGNORE_PATH_PARTS_FOR_CONFIRMATION_MODAL = ['/users/', '/organizations/'];

export function StickyBottomCTA({
  isVisible,
  onReset,
  isSubmitting,
  formId,
  warningMsg,
}: StickyBottomCTAProps): JSX.Element {
  const router = useRouter();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { environmentType, deployStatus } = useDeployStatus();
  const showConfirmationModal =
    isProduction(environmentType) &&
    deployStatus === Statuses.Complete &&
    !IGNORE_PATH_PARTS_FOR_CONFIRMATION_MODAL.find(part =>
      router.pathname.includes(part),
    );

  return (
    <Flex
      shadow='dropShadow3'
      bg='white'
      borderTop='1px solid'
      borderColor='gray.200'
      justify='flex-end'
      pos='fixed'
      bottom='0'
      left='0'
      py={4}
      px={{ base: 6, md: 16 }}
      opacity={isVisible ? '1' : '0'}
      transition='0.2s ease'
      transform={`translateY(${isVisible ? '0' : '100%'})`}
      w='100vw'
      zIndex='overlay'
    >
      <Flex maxW='container.2xl' justify='flex-end' flexDir='row-reverse'>
        {showConfirmationModal ? (
          <>
            <ApplyProdChangesModal
              isOpen={isOpen}
              onClose={onClose}
              isLoading={isSubmitting}
              formId={formId}
              warningMsg={warningMsg}
            />
            <Button tabIndex={-1} onClick={onOpen} isLoading={isSubmitting}>
              Apply changes
            </Button>
          </>
        ) : warningMsg ? (
          <>
            <ApplySetupWarningModal
              isOpen={isOpen}
              onClose={onClose}
              isLoading={isSubmitting}
              formId={formId}
              warningMsg={warningMsg}
            />
            <Button tabIndex={-1} onClick={onOpen} isLoading={isSubmitting}>
              Apply changes
            </Button>
          </>
        ) : (
          <FormSubmitButton formId={formId} isLoading={isSubmitting} />
        )}

        <Button
          type='button'
          variant='ghost'
          tabIndex={-1}
          mr='4'
          onClick={onReset}
          isDisabled={isSubmitting}
        >
          Reset
        </Button>
      </Flex>
    </Flex>
  );
}
