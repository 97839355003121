import React from 'react';
import Link from 'next/link';
import { Center, Icon, Link as ChakraLink, Text } from '@chakra-ui/react';
import { PlusIcon } from '@heroicons/react/solid';
import { useAnalytics } from '@hooks/useAnalytics';

export function AddApplicationCard(): JSX.Element {
  const { track } = useAnalytics();
  return (
    <Link href='/apps/new' passHref>
      <ChakraLink
        _hover={{ color: 'initial' }}
        _focus={{ boxShadow: 'none' }}
        onClick={() => {
          track('Dashboard_Applications_New Application Card Clicked', {
            location: 'Applications',
            surface: 'Dashboard',
          });
        }}
      >
        <Center layerStyle='linkBoxCard' minH='364px' w='292px'>
          <Icon as={PlusIcon} boxSize={8} fill='primary.500' mb={9} />
          <Text textStyle='md-medium'>Add application</Text>
        </Center>
      </ChakraLink>
    </Link>
  );
}
