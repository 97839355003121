import {
  OrganizationMembershipFLatUserData,
  OrganizationMembership,
} from '@components/organizations/types';

import { dropdownFilter } from '@components/organizations';

export const makeFlatMembership = (
  membership: OrganizationMembership[],
): OrganizationMembershipFLatUserData[] => {
  return membership.map(({ public_user_data, ...rest }) => ({
    ...public_user_data,
    ...rest,
  }));
};

export const getRoleFiltersFromParams = role => {
  if (Array.isArray(role)) {
    return role.reduce(
      (prev, current) => {
        prev = { ...prev, [current]: { ...prev[current], value: true } };
        return prev;
      },
      { ...dropdownFilter },
    );
  }

  if (role) {
    const filters = {
      ...dropdownFilter,
      [role]: { ...dropdownFilter[role], value: true },
    };

    return filters;
  }

  return dropdownFilter;
};
