import React from 'react';
import { HStack, Img, Text } from '@chakra-ui/react';
import { svgUrl } from '@utils';
import { User } from '@types';
import { VerificationStatusBadge } from '@components/common';

type SocialAccountListProps = {
  user: User;
};

export function SocialAccountList({
  user,
}: SocialAccountListProps): JSX.Element {
  // Duplication since we don't have access to the User from @clerk/types
  const verifiedAccounts = user.external_accounts.filter(
    externalAccount => externalAccount.verification.status == 'verified',
  );

  if (verifiedAccounts.length === 0) {
    return (
      <Text textStyle='md-normal' color='gray.500'>
        (none)
      </Text>
    );
  }

  return (
    <>
      {verifiedAccounts.map(externalAccount => (
        <HStack key={externalAccount.provider} mb='2'>
          <Img src={svgUrl(externalAccount.provider.replace('oauth_', ''))} />

          <Text textStyle='md-normal'>
            {externalAccount.username || externalAccount.email_address}
            {externalAccount.label && ` (${externalAccount.label})`}
            &nbsp;
            <VerificationStatusBadge
              status={externalAccount?.verification.status}
              ml='1'
            />
          </Text>
        </HStack>
      ))}
    </>
  );
}
