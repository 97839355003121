import { useDashboardSWR } from '@hooks';
import { Instance } from '@types';
import { useLocation } from './useLocation';

export const useInstance = () => {
  const { instanceId } = useLocation();

  const {
    data: instance,
    error,
    isValidating,
    mutate,
  } = useDashboardSWR<Instance>(
    instanceId ? `/v1/instances/${instanceId}` : null,
  );

  return {
    error,
    instance,
    isValidating,
    mutate,
  };
};
