import React from 'react';
import { Flex } from '@chakra-ui/react';
import Link from 'next/link';
import NextImage from 'next/image';
import { useHeaderContext } from './headerContext';
import { useAnalytics } from '@hooks/useAnalytics';
import { useLocation } from '@hooks';

export function LogoLink(): JSX.Element {
  const { track } = useAnalytics();
  const { isSticky } = useHeaderContext();
  const { instanceId } = useLocation();

  return (
    <Flex cursor='pointer' transform={isSticky ? 'scale(0.75)' : 'scale(1)'}>
      <Link href='/' passHref>
        <NextImage
          src='/assets/logos/clerk.svg'
          alt="Clerk's logo"
          height='38px'
          width='38px'
          layout='fixed'
          onClick={() => {
            track('Dashboard_Top Nav_Menu Item Clicked', {
              surface: 'Dashboard',
              location: 'Top Nav',
              instanceId,
              clickedOn: 'Home',
            });
          }}
        />
      </Link>
    </Flex>
  );
}
