import {
  Box,
  Flex,
  FlexProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import React from 'react';
import { FieldError } from 'react-hook-form';

import { GenericBadge as Badge } from '@components/common/Badges';

import { QuestionMarkCircleIcon } from '@heroicons/react/solid';

export type InputBoxProps = {
  leftIcon?: React.FunctionComponent;
  label: string | JSX.Element;
  error?: FieldError | { message: string };
  description?: string;
  optional?: boolean;
  children?: React.ReactNode;
  htmlFor?: string;
  info?: string;
} & FlexProps;

export function InputBox({
  leftIcon,
  label,
  error,
  description = '',
  optional = false,
  children,
  htmlFor,
  info,
  ...rest
}: InputBoxProps): JSX.Element {
  return (
    <Flex py={4} pl={4} mb={2} {...rest}>
      {leftIcon && (
        <Box>
          <Icon
            as={leftIcon}
            color='gray.300'
            mr={4}
            position='relative'
            top='-2px'
            boxSize={5}
          />
        </Box>
      )}

      <Box w='full'>
        <FormControl isInvalid={!!error}>
          <Flex justifyContent='space-between'>
            <Flex position='relative'>
              <Flex flexDirection='column'>
                <FormLabel htmlFor={htmlFor} fontSize='sm' mb={2}>
                  {label}
                </FormLabel>
              </Flex>
              {info && (
                <Box right='-12px' top='-1px' position='absolute'>
                  <Tooltip label={info} placement='bottom'>
                    <span>
                      <Icon
                        as={QuestionMarkCircleIcon}
                        color='gray.300'
                        ml={1}
                        boxSize='18px'
                        mt='-1px'
                      />
                    </span>
                  </Tooltip>
                </Box>
              )}
            </Flex>

            {optional && (
              <Box>
                <Badge label='Optional' />
              </Box>
            )}
          </Flex>
          {description && (
            <Text textStyle='sm-normal' color='gray.500'>
              {description}
            </Text>
          )}
          {children}

          {error?.message && (
            <FormErrorMessage>{error?.message}</FormErrorMessage>
          )}
        </FormControl>
      </Box>
    </Flex>
  );
}

export type ReadonlyInputBoxProps = Pick<
  InputBoxProps,
  'leftIcon' | 'label' | 'children' | 'description'
>;

export function ReadonlyInputBox({
  leftIcon,
  label,
  children,
  description,
}: ReadonlyInputBoxProps): JSX.Element {
  return InputBox({
    leftIcon,
    label,
    children,
    error: null,
    description,
  });
}
