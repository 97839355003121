export function preventSubmitOnEnter(e: React.KeyboardEvent): void {
  const element = e.target as Element;
  const isMonacoEditor = element.className?.includes?.('monaco');

  if (isMonacoEditor) {
    return;
  }

  if (e.key === 'Enter' || e.keyCode === 13 || e.which === 13) {
    e.preventDefault();
    e.stopPropagation();
  }
}
