import {
  Badge,
  Button,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { EmailAddress, PhoneNumber, Web3Wallet } from '@types';
import { Modal, VerificationStatusBadge } from '@components/common';
import { VerificationStatus } from '@clerk/types';

type IdentificationItemProps = {
  identifier: string;
  isPrimary: boolean;
  verificationStatus?: VerificationStatus;
};

const IdentificationItem = ({
  identifier,
  isPrimary,
  verificationStatus,
}: IdentificationItemProps): JSX.Element => {
  return (
    <Text textStyle='md-normal'>
      {identifier}
      &nbsp;
      <VerificationStatusBadge
        status={verificationStatus || 'unverified'}
        ml='1'
        mr='1'
      />
      {isPrimary && <Badge size='sm'>Primary</Badge>}
    </Text>
  );
};

export type Identifier = EmailAddress | PhoneNumber | Web3Wallet;

export type IdentifierListProps = {
  identifiers: Identifier[];
  primaryId: string;
  isBanned?: boolean;
  handleUpdate?: (id: string) => void;
};

export const IdentifierList = ({
  identifiers,
  primaryId,
  handleUpdate,
}: IdentifierListProps): JSX.Element => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [newId, setNewId] = React.useState(primaryId);

  if (identifiers.length === 0) {
    return (
      <Text textStyle='md-normal' color='gray.500'>
        (none)
      </Text>
    );
  }

  identifiers.sort((a: Identifier, b: Identifier) => {
    // lower number => higher order
    let aOrder = a.verification?.status === 'verified' ? 0 : 1;
    let bOrder = b.verification?.status === 'verified' ? 0 : 1;
    aOrder += a.id === primaryId ? 0 : 10;
    bOrder += b.id === primaryId ? 0 : 10;
    if (aOrder != bOrder) {
      return aOrder - bOrder;
    }
    // fallback to alphabetic order of identifier
    return a[a.object].localeCompare(b[b.object]);
  });
  const verified = identifiers.filter(
    i => i.verification?.status === 'verified',
  );

  const label = identifiers[0].object.replace(/_/g, ' ');

  return (
    <>
      {identifiers.map(ident => (
        <IdentificationItem
          key={ident.id}
          identifier={ident[ident.object]}
          isPrimary={ident.id === primaryId}
          verificationStatus={ident.verification?.status}
        />
      ))}

      {verified.length > 1 && (
        <Button
          variant='link'
          textStyle='sm-normal'
          color='primary.500'
          textTransform='none'
          fontWeight='500'
          mt='3'
          onClick={onOpen}
        >
          Change primary
        </Button>
      )}

      <Modal
        onClose={onClose}
        isOpen={isOpen}
        title={`Change primary ${label} to ...`}
      >
        <Modal.Body>
          <RadioGroup onChange={setNewId} value={newId}>
            <Stack direction='column'>
              {verified.map(ident => (
                <Radio key={ident.id} value={ident.id}>
                  {ident[ident.object]}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        </Modal.Body>

        {typeof handleUpdate === 'function' && (
          <Modal.Footer flexDir='row-reverse'>
            <Button
              onClick={() => {
                handleUpdate(newId);
                onClose();
              }}
            >
              Save change
            </Button>
            <Button variant='ghost' onClick={onClose}>
              Cancel
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};
