import React from 'react';
import { Box, Flex, FlexProps, HStack, Text } from '@chakra-ui/react';

type VerticalInputBoxProps = {
  label: string | JSX.Element;
  htmlFor?: string;
  subLabel?: string;
  optional?: boolean;
  children: React.ReactNode;
} & FlexProps;

export function VerticalInputBox({
  label,
  subLabel,
  children,
  htmlFor,
  optional,
  ...rest
}: VerticalInputBoxProps): JSX.Element {
  return (
    <Flex direction='column' w='100%' {...rest}>
      <HStack mb={0.5} justify='space-between'>
        <Text as='label' htmlFor={htmlFor} textStyle='md-medium'>
          {label}
        </Text>
        {optional ? (
          <Text textStyle='sm-normal' color='gray.500'>
            Optional
          </Text>
        ) : null}
      </HStack>
      {subLabel ? (
        <Text textStyle='sm-medium' fontWeight='normal' color='gray.500'>
          {subLabel}
        </Text>
      ) : null}
      <Box mt='0.5em'>{children}</Box>
    </Flex>
  );
}
