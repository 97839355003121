import type { SWRConfiguration } from 'swr';
import { DeployStatuses, DNSRecords, ServiceStatus, Statuses } from '@types';
import { useDashboardSWR } from './useDashboardAPI';
import { useInstanceId } from './useInstanceId';

type UseDeployStatus = {
  deployStatus: Statuses;
  mail: ServiceStatus;
  ssl: ServiceStatus;
  dnsRecords: DNSRecords;
  environmentType: string;
  isValidating: boolean;
  hasError: boolean;
  mutateInstanceStatus: (
    data?: any,
    shouldRevalidate?: boolean,
  ) => Promise<any>;
};

/**
 * Determines the deploy status depending on the different environments
 * @param statusSwrOptions {SWRConfiguration} SWR possible options to be used for the status API call e.g. refreshInterval
 */
export function useDeployStatus(
  statusSwrOptions: SWRConfiguration = {},
): Partial<UseDeployStatus> {
  const { instanceId } = useInstanceId();
  const {
    data: { ssl, mail, dns: dnsRecords } = {},
    isValidating: isValidatingCertificateHealth,
    mutate: mutateInstanceStatus,
  } = useDashboardSWR(`/v1/instances/${instanceId}/status`, statusSwrOptions);

  const {
    data: { environment_type: environmentType } = {},
    isValidating: isValidatingEnvironmentType,
    error,
  } = useDashboardSWR(`/v1/instances/${instanceId}`);

  const isValidating =
    isValidatingCertificateHealth || isValidatingEnvironmentType;

  if (!environmentType || !ssl) {
    return {
      isValidating,
      hasError: !!error,
    };
  }

  const deployStatus = determineDeployStatus({ ssl, mail }, environmentType);

  return {
    ssl,
    mail,
    deployStatus,
    environmentType,
    isValidating,
    dnsRecords,
    mutateInstanceStatus,
    hasError: !!error,
  };
}

export function determineDeployStatus(
  { ssl, mail }: DeployStatuses,
  environmentType: string,
): Statuses {
  if (environmentType !== 'production') {
    return Statuses.Complete;
  }

  const serviceStatuses = [ssl, mail];

  if (findStatus(serviceStatuses, Statuses.NotStarted)) {
    return Statuses.NotStarted;
  }
  if (findStatus(serviceStatuses, Statuses.Failed)) {
    return Statuses.Failed;
  }
  if (findStatus(serviceStatuses, Statuses.InProgress)) {
    return Statuses.InProgress;
  }
  return Statuses.Complete;
}

function findStatus(statuses: ServiceStatus[], statusToCheck: Statuses) {
  return statuses.some(({ status }) => status === statusToCheck);
}
