import { IconButton, Box } from '@chakra-ui/react';
import { XIcon, CogIcon } from '@heroicons/react/solid';
import React from 'react';

type EditPathButtonProps = {
  isEditing: boolean;
  handleClick: () => void;
};

export function EditPathButton({
  isEditing,
  handleClick,
}: EditPathButtonProps): JSX.Element {
  return (
    <IconButton
      aria-label={isEditing ? 'cancel' : 'edit'}
      icon={<Box boxSize={5}>{isEditing ? <XIcon /> : <CogIcon />}</Box>}
      boxSize={4}
      bg='none'
      cursor='pointer'
      color='gray.300'
      justifyContent='flex-end'
      _hover={{ color: 'primary.500' }}
      _focus={{ boxShadow: 'none' }}
      _active={{ bg: 'none' }}
      onClick={handleClick}
    />
  );
}
