import qs from 'qs';
import { Integration } from '@types';

export function buildQueryString(params: Record<string, string> = {}): string {
  return qs.stringify(params, { addQueryPrefix: true });
}

export function getNextUrl(
  next: string | undefined | null,
  integration: Integration,
): string {
  if (next) {
    return next;
  }

  return `https://vercel.com/dashboard/integrations/${integration.metadata.configuration_id}/installed`;
}
