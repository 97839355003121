import { format } from 'date-fns';
import { ActiveUsersGraphProps } from './ActiveUsersGraph';

export type GetMetricDiffProps = {
  current_value: number;
  previous_value: number;
};

export const getMetricDiff = (
  { current_value = 0, previous_value = 0 }: GetMetricDiffProps,
  suffix = '',
): string | null => {
  const numericDiff = current_value - previous_value;
  if (!numericDiff) {
    return null;
  }
  const signedNumericDiff = new Intl.NumberFormat('en-US', {
    // @ts-ignore Until this is deployed https://github.com/microsoft/TypeScript/pull/46740/files
    signDisplay: 'exceptZero',
  }).format(numericDiff);
  const percentageDiff = previous_value
    ? ((numericDiff / previous_value) * 100).toLocaleString(undefined, {
        maximumFractionDigits: 2,
      })
    : 0;
  const percentageFormatted = percentageDiff ? ` (${percentageDiff}%)` : '';
  return `${signedNumericDiff}${percentageFormatted}${suffix}`;
};

export const getStatusClassName = (metricDiff: string): string => {
  if (metricDiff && metricDiff[0] === '-') {
    return 'error';
  }
  return 'success';
};

export const getAggregateDiff = activeUsers => {
  if (activeUsers && activeUsers.length > 1) {
    const current_value = activeUsers[activeUsers.length - 1].count;
    const previous_value = activeUsers[activeUsers.length - 2].count;
    return getMetricDiff({ current_value, previous_value });
  }
  return null;
};

export const getSinceRange = range => {
  const date = range.getDate();
  return date ? format(date, 'yyyy-MM-dd') : '';
};

export const getGraphData = (activeUsers, range): ActiveUsersGraphProps => {
  const noData = !activeUsers || !activeUsers.length;

  const xAxis = noData
    ? [formatDisplayDate(range.getDate()), formatDisplayDate(new Date())]
    : activeUsers.map(({ start_date, end_date }) => {
        if (start_date === end_date) {
          return formatDisplayDate(start_date);
        }
        return `${formatDisplayDate(start_date)} - ${formatDisplayDate(
          end_date,
        )}`;
      });

  const yAxis = noData ? [0, 0] : activeUsers.map(({ count }) => count);

  return {
    labels: xAxis,
    datasets: [
      {
        label: '# of Users',
        data: yAxis,
        fill: false,
        backgroundColor: '#6c47ff',
        borderColor: '#6c47ff',
      },
    ],
  };
};

export const formatDisplayDate = date => format(new Date(date), 'MM/dd/yyyy');
