import React from 'react';
import {
  formatEmail,
  formatIdentifier,
  formatPhone,
} from '@components/users/utils';
import { UserAvatar } from '@components/common';

import { Badge, Box, HStack, Text } from '@chakra-ui/react';

import { User } from './types';

const UserInfo = ({ user }: { user: User }): JSX.Element => {
  return (
    <HStack>
      <UserAvatar user={user} boxSize='32px' />
      <Box maxW='50ch'>
        <Text noOfLines={1} textStyle='sm-medium'>
          {formatIdentifier(user)}
        </Text>
        <Text noOfLines={1} mt='0px' textStyle='sm-normal' color='gray.500'>
          {formatEmail(user) || formatPhone(user)}
        </Text>
      </Box>
      {user.banned && (
        <Badge size='sm' colorScheme='red'>
          Banned
        </Badge>
      )}
    </HStack>
  );
};

export default UserInfo;
