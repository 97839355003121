import { textStyles } from '../foundations/textStyles';

export const Menu = {
  parts: ['list', 'item', 'groupTitle'],
  baseStyle: {
    list: {
      border: 'none',
      boxShadow: '0px 24px 48px rgba(0, 0, 0, 0.16)',
      outline: '0 !important',
      _focus: { boxShadow: '0px 24px 48px rgba(0, 0, 0, 0.16) !important' },
    },
    groupTitle: {
      color: 'gray.300',
      ...textStyles['xs-semibold'],
      textTransform: 'uppercase',
    },
    item: {
      _hover: { background: 'gray.50' },
      _focus: { boxShadow: 'none !important', background: 'white' },
      fontSize: 'sm',
    },
  },
};
