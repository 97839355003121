import { SUPPORTED_FEATURES } from '@constants';

export const maxThreeSocialAndWeb3 = (
  providerType: string,
  providerName: string,
  getValues: () => any,
): boolean => {
  const mergeObj = { ...getValues().web3, ...getValues().oauth };
  return (
    !getValues()[providerType][providerName] &&
    Object.keys(Object.entries(mergeObj).filter(([, value]) => value === true))
      .length >= 3
  );
};

export function isFeatureSupported(
  supportedFeatures: string[],
  feature: string,
): boolean {
  return supportedFeatures && supportedFeatures.includes(feature);
}

const SOCIAL_WEB3_LIMIT = 3;

export function hasReachedSocialAndWeb3Limit({
  social,
  web3,
}: {
  social: any;
  web3: string[];
}): boolean {
  const enabledSocialProviders = [];

  for (const key in social) {
    const provider = social[key];
    if (provider.enabled) {
      enabledSocialProviders.push(provider.strategy);
    }
  }

  return [...enabledSocialProviders, ...web3].length >= SOCIAL_WEB3_LIMIT;
}

export function shouldCheckMaxSocialAndWeb3(
  supportedFeatures: string[],
): boolean {
  const isSocialAndWeb3Unlimited =
    isFeatureSupported(
      supportedFeatures,
      SUPPORTED_FEATURES.unlimited_social,
    ) &&
    isFeatureSupported(supportedFeatures, SUPPORTED_FEATURES.unlimited_web3);

  return (
    !isSocialAndWeb3Unlimited &&
    isFeatureSupported(
      supportedFeatures,
      SUPPORTED_FEATURES.max_3_social_and_web3,
    )
  );
}
