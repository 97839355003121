import React from 'react';
import NextLink from 'next/link';
import {
  Badge,
  Box,
  LinkBox,
  LinkBoxProps,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useLocation } from '@hooks';
import { formatTimestamp } from '@utils';
import { Instance, Template } from '@types';

const cardDimensions: LinkBoxProps = {
  maxW: '350px',
  minW: '234px',
  minH: '128px',
};

interface TemplateCardProps {
  instance: Instance;
  template: Template;
}

export function TemplateCard({ template }: TemplateCardProps): JSX.Element {
  const { applicationId, instanceId } = useLocation();

  const editLink = `/apps/${applicationId}/instances/${instanceId}/customization/${template.template_type}/${template.slug}/edit`;

  return (
    <NextLink href={editLink} passHref>
      <LinkBox layerStyle='linkBoxCard' {...cardDimensions}>
        <Stack py={8} px={6} spacing='4'>
          <Text textStyle='lg-medium' noOfLines={1}>
            {template.name}
          </Text>

          <Text textStyle='sm-normal' color='gray.500'>
            {formatTimestamp(new Date(template.updated_at), { noTime: true })}
          </Text>

          <Box>
            {template.resource_type == 'system' ? (
              <Badge colorScheme='gray' size='sm' mr='2'>
                Default
              </Badge>
            ) : (
              <Badge size='sm' mr='2'>
                Edited
              </Badge>
            )}

            {!template.delivered_by_clerk && (
              <Badge colorScheme='yellow' size='sm' mr='2'>
                Self-delivered
              </Badge>
            )}
          </Box>
        </Stack>
      </LinkBox>
    </NextLink>
  );
}
