import React from 'react';

import { makeOrganizationMembershipsPath } from '@components/organizations/utils';

import { useLocation } from '@hooks';

import { OrganizationMembershipResponse } from '@components/organizations/types';

export type UseUsersPathsProps = {
  search?: string;
  organizationID?: string;
  userID?: string;
};

type UseUsersPathsReturn = {
  makeInfinitePath: (pageIndex: number, previousPageData: any) => string;
  makeMembershipsPathInfinite: (
    pageIndex: number,
    previousPageData: any,
  ) => string;
  makeCreateMembershipPath: ({
    organizationID,
  }: {
    organizationID: string;
  }) => string;
  countPath: string;
  membershipsPath: string;
};

const PAGE_SIZE = 10;

const useUsersPaths = ({
  search,
  organizationID,
  userID,
}: UseUsersPathsProps): UseUsersPathsReturn => {
  const { instanceId } = useLocation();
  const usersBase = `/v1/instances/${instanceId}/users`;
  const userCountPath = `/v1/instances/${instanceId}/users/count`;
  const membershipsPath = `${usersBase}/${userID}/organization_memberships`;

  /**
   *
   * @link https://swr.vercel.app/docs/pagination#example-1-index-based-paginated-api
   */
  const makeInfinitePath = React.useCallback(
    (pageIndex: number, previousPageData: any): string => {
      if (previousPageData && !previousPageData.length) {
        return '';
      }

      const params = new URLSearchParams({
        query: search || '',
        ...(organizationID ? { organization_id: `-${organizationID}` } : {}),
        offset: PAGE_SIZE * pageIndex + '',
      });
      return `${usersBase}?${params.toString()}`;
    },
    [search, organizationID, usersBase],
  );

  const makeCountPath = React.useMemo(() => {
    const params = new URLSearchParams({
      query: search || '',
      ...(organizationID ? { organization_id: `-${organizationID}` } : {}),
    });
    return `${userCountPath}?${params.toString()}`;
  }, [search, organizationID, userCountPath]);

  const makeMembershipsPathInfinite = React.useCallback(
    (
      pageIndex: number,
      previousPageData: OrganizationMembershipResponse,
    ): string => {
      if (previousPageData && !previousPageData?.data.length) {
        return '';
      }

      const params = new URLSearchParams({
        offset: PAGE_SIZE * (pageIndex || 0) + '',
      });
      return `${membershipsPath}?${params.toString()}`;
    },
    [membershipsPath],
  );

  const makeCreateMembershipPath = ({
    organizationID,
  }: {
    organizationID: string;
  }) => makeOrganizationMembershipsPath(instanceId, organizationID);

  return {
    makeInfinitePath,
    makeCreateMembershipPath,
    countPath: makeCountPath,
    membershipsPath: membershipsPath,
    makeMembershipsPathInfinite,
  };
};

export default useUsersPaths;
