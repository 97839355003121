import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Heading as ChakraHeading, HeadingProps } from '@chakra-ui/react';

// TODO: Make it responsive
const stylesMap = {
  h1: { size: 'xl' },
  h2: { size: 'lg' },
  h3: { size: 'md' },
  h4: { size: 'sm' },
};

export function Heading({ as, ...rest }: HeadingProps): JSX.Element {
  const styles = typeof as === 'string' && as in stylesMap ? stylesMap[as] : {};
  return <ChakraHeading as={as} {...styles} {...rest} />;
}
