import { useOrganization } from '@clerk/nextjs';
import { useDashboardSWR } from '@hooks';

export function useOrganizationSubscription(): any {
  const { membership, organization } = useOrganization();
  const isAdmin = membership?.role === 'admin';

  return useDashboardSWR(
    isAdmin && organization?.id
      ? `/v1/organizations/${organization?.id}/subscription`
      : null,
  );
}
