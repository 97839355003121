import React from 'react';
import { Flex, Switch, Text, VStack } from '@chakra-ui/react';
import { InformationBox } from '@components/common';
import { useFormContext } from 'react-hook-form';

type Info = {
  Icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  text: string | JSX.Element;
};

export type SwitchFieldProps = {
  name: string;
  label: string | React.ReactNode;
  description: string;
  info?: Info[];
};

export const SwitchField = ({
  name,
  label,
  description,
  info,
}: SwitchFieldProps): JSX.Element => {
  const { register } = useFormContext();
  return (
    <VStack spacing='1' w='full'>
      <Flex w='full' direction='row' justify='space-between'>
        <Text as='label' htmlFor={name} textStyle='lg-medium' display='block'>
          {label}
        </Text>
        <Switch id={name} {...register(name)} />
      </Flex>
      <Text color='gray.500' textStyle='md-normal' w='full'>
        {description}
      </Text>
      {info &&
        info.map(({ Icon, text }, index) => (
          <InformationBox icon={Icon} key={index}>
            <Text textStyle='sm-normal' display='inline-block' color='gray.500'>
              {text}
            </Text>
          </InformationBox>
        ))}
    </VStack>
  );
};
