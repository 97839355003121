export const comingSoonIntegrations = [
  {
    logo: '/assets/logos/intercom.svg',
    name: 'Intercom',
    websiteUrl: 'intercom.com',
  },
  {
    logo: '/assets/logos/mailchimp.svg',
    name: 'Mailchimp',
    websiteUrl: 'mailchimp.com',
  },
  {
    logo: '/assets/logos/mixpanel-logo.svg',
    name: 'Mixpanel',
    websiteUrl: 'mixpanel.com',
  },
  {
    logo: '/assets/logos/prisma.svg',
    name: 'Prisma',
    websiteUrl: 'prisma.io',
  },
  {
    logo: '/assets/logos/salesforce.svg',
    name: 'Salesforce',
    websiteUrl: 'salesforce.com',
  },
  {
    logo: '/assets/logos/segment.svg',
    name: 'Segment',
    websiteUrl: 'segment.com',
  },
  {
    logo: '/assets/logos/stripe.svg',
    name: 'Stripe',
    websiteUrl: 'stripe.com',
  },
  {
    logo: '/assets/logos/upvoty.svg',
    name: 'Upvoty',
    websiteUrl: 'upvoty.com',
  },
  {
    logo: '/assets/logos/zendesk.svg',
    name: 'Zendesk',
    websiteUrl: 'zendesk.com',
  },
] as const;
