import React from 'react';
import { Image, ImageProps } from '@chakra-ui/image';
import { Flex, Icon } from '@chakra-ui/react';
import { OfficeBuildingIcon } from '@heroicons/react/solid';

type OrganizationsAvatarProps = {
  organizationsLogo: string | any;
  size?: 'small' | 'medium' | 'x-small';
} & ImageProps;

const getAvatarSize = (size: OrganizationsAvatarProps['size']) => {
  switch (size) {
    case 'x-small':
      return {
        containerSize: { height: '26px', width: '26px' },
        avatarSize: { height: '18px', width: '18px' },
      };
    case 'small':
      return {
        containerSize: { height: '32px', width: '32px' },
        avatarSize: { height: '24px', width: '24px' },
      };
    case 'medium':
      return {
        containerSize: { height: '48px', width: '48px' },
        avatarSize: { height: '36px', width: '36px' },
      };
    default:
      return {
        containerSize: { height: '32x', width: '32px' },
        avatarSize: { height: '24px', width: '24px' },
      };
  }
};

export function OrganizationsAvatar({
  organizationsLogo,
  size = 'small',
  ...imageProps
}: OrganizationsAvatarProps): JSX.Element {
  const { containerSize, avatarSize } = getAvatarSize(size);

  if (!organizationsLogo) {
    return (
      <Flex
        borderRadius={4}
        width={containerSize.width}
        height={containerSize.height}
        background='blackAlpha.400'
        alignItems='center'
        justifyContent='center'
      >
        <Icon
          color='white'
          as={OfficeBuildingIcon}
          w={avatarSize.width}
          h={avatarSize.height}
        />
      </Flex>
    );
  }
  return (
    <Image
      {...imageProps}
      src={organizationsLogo}
      width={containerSize.width}
      height={containerSize.height}
      borderRadius={4}
      objectFit='contain'
    />
  );
}
