import { Attributes, AttributeSettings, OauthProviders } from '@types';

export const hasIdentificationFactors = (
  settings: AttributeSettings,
): boolean => {
  return (
    settings.attributes.email_address.used_for_first_factor ||
    settings.attributes.phone_number.used_for_first_factor ||
    settings.attributes.username.used_for_first_factor
  );
};

export const hasSocialAuthenticatable = (settings: OauthProviders): boolean =>
  Object.values(settings.social || {}).some(
    socialData => socialData.authenticatable,
  );

export const areContactInfoOff = (settings: AttributeSettings): boolean => {
  const { email_address, phone_number } = settings.attributes;
  return !email_address.enabled && !phone_number.enabled;
};

export const hasAuthFactorEnabled = (settings: AttributeSettings): boolean => {
  const { email_address, phone_number, password, web3_wallet } =
    settings.attributes;
  return (
    email_address.first_factors.length > 0 ||
    phone_number.first_factors.length > 0 ||
    web3_wallet.first_factors.length > 0 ||
    password.required
  );
};

export const filterAttributesFrom = (
  attributeNames: Attributes[],
  settings: AttributeSettings,
) =>
  attributeNames.reduce(
    (acc, key) => ({
      attributes: {
        ...acc.attributes,
        [key]: settings.attributes[key],
      },
    }),
    { attributes: {} },
  );
