import React from 'react';
import NextLink from 'next/link';
import { Badge, Link as ChakraLink } from '@chakra-ui/react';
import {
  useDashboardSWR,
  useDashboardCRUD,
  useLocation,
  useToast,
} from '@hooks';
import { ActiveIntegrationCard } from '../ActiveIntegrationCard';
import { Integration } from '../types';

export const HasuraCard = (): JSX.Element => {
  const { instanceId, applicationId } = useLocation();
  const { data, isValidating, mutate } = useDashboardSWR(
    `/v1/instances/${instanceId}/jwt_services`,
  );
  const { update } = useDashboardCRUD();
  const { showSuccessToast } = useToast();

  const isActive = !!data?.hasura;

  const handleSwitchChange = async e => {
    const isChecked = e.target.checked;

    try {
      const res = (await update(`/v1/instances/${instanceId}/jwt_services`, {
        hasura: {
          enabled: isChecked,
        },
      })) as Integration;

      void mutate(res, false);
      showSuccessToast('Hasura settings saved.');
    } catch (_) {
      return;
    }
  };

  return (
    <>
      <ActiveIntegrationCard
        name='Hasura'
        logo='/assets/logos/hasura.svg'
        websiteUrl='hasura.com'
        docsUrl='https://hasura.io/docs/latest/graphql/core/auth/authentication/jwt.html'
        description={
          <>
            Instant GraphQL with built-in authorization for your data. You can
            now use the{' '}
            <NextLink
              passHref
              href={`/apps/${applicationId}/instances/${instanceId}/jwt-templates`}
            >
              <ChakraLink
                textTransform='unset'
                color='primary.500'
                fontWeight='medium'
              >
                Hasura JWT Template
              </ChakraLink>
            </NextLink>{' '}
            for a fully customizable token.
          </>
        }
        switchValue={isActive}
        isLoading={isValidating}
        tags={
          <Badge colorScheme='warning' size='sm'>
            Deprecated
          </Badge>
        }
        onSwitchChange={handleSwitchChange}
      />
    </>
  );
};
