import React from 'react';
import { Box } from '@chakra-ui/react';

interface ClerkLogoProps {
  fill?: string;
  height?: string;
  width?: string;
}

export function ClerkLogo({
  fill = 'primary.500',
  height = '8',
  width = '8',
}: ClerkLogoProps): JSX.Element {
  return (
    <Box
      as='svg'
      h={height}
      w={width}
      viewBox='0 0 74 64'
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M6.48057 17.7637C5.56396 18.2966 5 19.2768 5 20.3371V43.7956C5 44.8559 5.56396 45.8361 6.48057 46.369L37 64.1129V64.0198L67.5194 46.276C68.436 45.7431 69 44.7628 69 43.7026V21.2115C69 20.0637 67.7557 19.3479 66.7635 19.9247L38.4805 36.3683C37.5639 36.9012 37 37.8815 37 38.9417V43.2756L7.48001 26.1129L37 8.95008V2.60682C37 1.45907 35.7558 0.743225 34.7635 1.32011L6.48057 17.7637ZM46.4864 39.2465C46.2573 39.3797 46.1163 39.6248 46.1163 39.8899V41.7917C46.1163 42.0787 46.4273 42.2576 46.6754 42.1134L61.7461 33.3513C61.9753 33.2181 62.1163 32.973 62.1163 32.708V30.8061C62.1163 30.5192 61.8052 30.3402 61.5572 30.4845L46.4864 39.2465Z'
        />
        <path d='M26.3333 32.7402C26.3333 31.6799 26.8973 30.6997 27.8139 30.1668L56.0969 13.7232C57.0891 13.1463 58.3333 13.8622 58.3333 15.0099V21.3532L26.3333 39.9578V32.7402Z' />
        <path d='M15.6667 26.5387C15.6667 25.4784 16.2306 24.4982 17.1472 23.9653L45.4302 7.52167C46.4224 6.94479 47.6667 7.66063 47.6667 8.80838V15.1516L15.6667 33.7563V26.5387Z' />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='74' height='64' fill='white' />
        </clipPath>
      </defs>
    </Box>
  );
}
