import React from 'react';
import { Box, Button, Image, Link as ChakraLink, Text } from '@chakra-ui/react';
import { CardContainer } from '@components/common';
import {
  useDeployStatus,
  useInstance,
  useInterval,
  usePlausible,
  useTracking,
} from '@hooks';
import { stripProtocol } from '@utils';
import { useAnalytics } from '@hooks/useAnalytics';

const HEALTHY_STATUS = 'healthy';

function getButtonLoadingText(elapsedTimeInMs: number) {
  if (elapsedTimeInMs > 30000) {
    return 'Finalizing setup (4/4)';
  } else if (elapsedTimeInMs > 25000) {
    return 'Uploading SSL certificate (3/4)';
  } else if (elapsedTimeInMs > 15000) {
    return 'Generating SSL certificate (2/4)';
  } else if (elapsedTimeInMs > 10000) {
    return 'Creating sign-up page (1/4)';
  }
  return 'Loading';
}

function doctorFetcher(host) {
  return fetch(`https://${host}/v1/health`).then(r => {
    if (r.ok) {
      return r.json();
    } else {
      throw new Error('FAPI health check failed');
    }
  });
}

export function TrySignUpAndSignIn({
  isApplicationJustCreated,
  instanceHasUsers = false,
  isUsersPage = false,
}: {
  isApplicationJustCreated: boolean;
  instanceHasUsers?: boolean;
  isUsersPage?: boolean;
}): JSX.Element {
  const { track } = useAnalytics();
  const { instance } = useInstance();
  const plausible = usePlausible();
  const { trackEvent } = useTracking();
  const [isHealthyCounter, setIsHealthyCounter] = React.useState(0);
  const { ssl } = useDeployStatus();
  const isSSLComplete = ssl?.status === 'complete';
  // Ideally the SSL status check will be always `complete`, even when the app is loaded for the first time.
  // If not, and the instance home page is loaded for the first time, we check thrice for FAPI and Accounts health.
  // Otherwise, for any subsequent visit to the instance home page check once.
  const isHealthy =
    isSSLComplete ||
    (isApplicationJustCreated ? isHealthyCounter > 2 : isHealthyCounter > 0);

  const [elapsedTimeInMs] = useInterval(
    () => {
      const checkHealth = async () => {
        const fapiHost = stripProtocol(
          instance?.active_domain.frontend_api_url,
        );
        const accountsHost = stripProtocol(
          instance?.active_domain.accounts_portal_url,
        );
        try {
          const [fapiHostHealth, accountsHostHealth] = await Promise.all([
            doctorFetcher(fapiHost),
            doctorFetcher(accountsHost),
          ]);

          if (
            fapiHostHealth.status === HEALTHY_STATUS &&
            accountsHostHealth.status === HEALTHY_STATUS
          ) {
            setIsHealthyCounter(isHealthyCounter + 1);
          }
        } catch (_) {
          // noop
        }
      };

      void checkHealth();
    },
    isHealthy ? null : 3000,
    true,
  );

  return instanceHasUsers ? null : (
    <CardContainer position='relative'>
      <Box py={3}>
        <Text textStyle='h2' mb={5}>
          Your sign-up and sign-in are ready to use!
        </Text>

        <Button
          as={ChakraLink}
          href={`${instance?.active_domain.accounts_portal_url}/preview/sign-up`}
          target='_blank'
          isLoading={!isHealthy}
          loadingText={getButtonLoadingText(
            isApplicationJustCreated ? elapsedTimeInMs : 0,
          )}
          _hover={{
            bg: 'primary.700',
            color: 'white',
          }}
          pointerEvents={isHealthy ? 'auto' : 'none'}
          className='try-sign-up'
          onClick={() => {
            plausible('Component preview', {
              props: {
                CTA: isUsersPage ? 'Users - Try it now' : 'Home - Try it now',
              },
            });
            void trackEvent('preview_viewed');
            track('Dashboard_Instance Overview_Try It Now Button Clicked', {
              surface: 'Dashboard',
              location: 'Instance Overview',
              instanceId: instance.id,
              environmentType: instance.environment_type,
            });
          }}
        >
          Try it now
        </Button>

        <Image
          src='/assets/components.png'
          pos='absolute'
          top='0'
          right='0'
          height='full'
          rounded='2xl'
        />
      </Box>
    </CardContainer>
  );
}
