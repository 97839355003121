import React from 'react';

import { CardDualPanel } from '@components/common';

import { Button, Icon } from '@chakra-ui/react';
import useChangeDomain from './useChangeDomain';
import { ConfirmationModal } from '@components/modal';
import { noop } from '@utils';
import { ExclamationIcon } from '@heroicons/react/solid';

const ChangeDomain = ({
  instanceId,
  deployedOn,
  onDomainChange,
}: {
  applicationName: string;
  domain: string;
  instanceId: string;
  deployedOn: string;
  onDomainChange: () => void;
}): JSX.Element => {
  const {
    isSubmitting,
    message,
    handleClose,
    getButtonProps,
    isOpen,
    formId,
    isConfirmDisabled,
    verification,
  } = useChangeDomain({
    instanceId,
    onDomainChangeConfirmed: onDomainChange,
  });

  return (
    <CardDualPanel
      alignItems='center'
      rightPanelStyles={{ justifyContent: 'space-between' }}
      title='Change domain'
      subtitle={`Currently deployed on ${deployedOn}`}
    >
      <Button
        leftIcon={<Icon as={ExclamationIcon} />}
        fontWeight='bold'
        fontSize='xs'
        ml='auto'
        {...getButtonProps()}
        colorScheme='red'
      >
        Change domain
      </Button>

      {isOpen && (
        <ConfirmationModal
          disableConfirmation={isConfirmDisabled}
          isConfirmationLoading={isSubmitting}
          alertDialogProps={{ width: '30rem' }}
          isOpen={isOpen}
          handleCancelation={handleClose}
          handleConfirmation={noop}
          confirmationMessage='Change domain'
          cancelationMessage='Cancel'
          confirmButtonIcon={ExclamationIcon}
          message={message}
          headerTitle='Change domain'
          verification={verification}
          confirmationButtonProps={{ form: formId, type: 'submit' }}
          clearOnConfirmation={false}
        />
      )}
    </CardDualPanel>
  );
};

export default React.memo(ChangeDomain);
