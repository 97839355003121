import { format, formatDistance } from 'date-fns';

type Options = {
  noTime?: boolean;
};

export function formatTimestamp(date: Date, options: Options = {}): string {
  if (!date) {
    return null;
  }

  const distance = formatDistance(date, new Date(), { addSuffix: true });
  if (options.noTime) {
    return `Updated ${distance}`;
  }

  const time = format(date, 'HH:mm');
  return `Updated ${distance}, ${time}`;
}
