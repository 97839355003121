import React from 'react';

import { Text, Flex } from '@chakra-ui/react';

import { NameCell as MembershipDisplay } from '@components/organizations/organizationDetails/Members/Table/Cells';

import { OrganizationMembershipFLatUserData } from '@components/organizations/types';

const MemberCreated = ({
  member,
  orgName,
}: {
  member: OrganizationMembershipFLatUserData;
  orgName: string;
}): JSX.Element => {
  return (
    <Flex flexDirection='column'>
      <Flex>
        <Text textStyle='sm-medium'>
          The following user has been successfully added to{' '}
          <Text as='span' fontWeight='bold'>
            {orgName}
          </Text>{' '}
          organization.
        </Text>
        <Text></Text>
      </Flex>

      <Flex mt={4} height={12}>
        <MembershipDisplay member={member} />
      </Flex>
    </Flex>
  );
};

export default MemberCreated;
