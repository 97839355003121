import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Stack } from '@chakra-ui/react';
import { PageLoader, StickyBottomCTA } from '@components/common';
import {
  useDashboardCRUD,
  useDashboardSWR,
  useLocation,
  useToast,
} from '@hooks';
import { AttributeSettings, Settings, SignInData, UserSettings } from '@types';
import { getDirtyValues, isEmptyObj } from '@utils';
import { MultifactorFactorsForm } from './MultifactorFactorsForm';
import {
  hasIdentificationFactors,
  hasSocialAuthenticatable,
} from '@utils/attributes';

export const FORM_ID = 'multifactor_authentication_form';

type FormType = SignInData & AttributeSettings;

export function MultifactorAuthentication(): JSX.Element {
  const { instanceId } = useLocation();
  const { data: settings, mutate } = useDashboardSWR<Settings>(
    `/v1/instances/${instanceId}/user_settings`,
  );
  const { update } = useDashboardCRUD();
  const { showSuccessToast } = useToast();
  const [allFirstFactorsDisabled, setAllFirstFactorsDisabled] =
    React.useState(false);

  const formMethods = useForm<FormType>({ mode: 'onChange' });
  const {
    handleSubmit,
    formState: { isSubmitting, dirtyFields },
    reset,
  } = formMethods;

  const resetForm = () => {
    reset({
      sign_in: settings.sign_in,
      attributes: settings.attributes,
    });
  };

  React.useEffect(() => {
    if (!settings) {
      return;
    }

    if (
      !hasIdentificationFactors(settings) &&
      !hasSocialAuthenticatable(settings)
    ) {
      setAllFirstFactorsDisabled(true);
    }

    resetForm();
  }, [settings, reset]);

  const persistDirtyFormFields = async (formData: AttributeSettings) => {
    const dirtyAttributeFields = getDirtyValues(
      formData,
      dirtyFields,
      'attributes',
    );
    const dirtySignInFields = getDirtyValues(formData, dirtyFields, 'sign_in');
    const dirtyPayload = {
      ...dirtyAttributeFields,
      ...dirtySignInFields,
    } as Pick<UserSettings, 'attributes' | 'sign_in'>;

    try {
      const newUserSettings = await update(
        `/v1/instances/${instanceId}/user_settings`,
        dirtyPayload,
      );
      await mutate(newUserSettings as Settings, false);
      showSuccessToast('Multifactor authentication settings saved.');
    } catch (err) {
      return;
    }
  };

  if (!settings) {
    return <PageLoader />;
  }

  return (
    <FormProvider {...formMethods}>
      <form id={FORM_ID} onSubmit={handleSubmit(persistDirtyFormFields)}>
        <Stack spacing='8'>
          {/* <CardWithSwitch
            title='Require multifactor authentication'
            subtitle='Increase security by forcing all users to provide an additional factor when signing in'
          >
            <Switch
              {...register('sign_in.second_factor.required')}
              isChecked={isMultifactorRequired}
            />
          </CardWithSwitch> */}

          <MultifactorFactorsForm
            allFirstFactorsDisabled={allFirstFactorsDisabled}
          />
        </Stack>

        <StickyBottomCTA
          isVisible={!isEmptyObj(dirtyFields)}
          onReset={() => reset()}
          isSubmitting={isSubmitting}
          formId={FORM_ID}
        />
      </form>
    </FormProvider>
  );
}
