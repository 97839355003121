// Use this hook to trigger Plausible events.
// The function is called in the following format:
// plausible('Read guide', {
//   props: {
//     Framework: 'JavaScript',
//   },
// })

import { usePlausible as useNextPlausible } from 'next-plausible';

type Frameworks =
  | 'JavaScript'
  | 'React'
  | 'Next.js'
  | 'Gatsby'
  | 'Expo'
  | 'RedwoodJS';

type Events = {
  'Component preview': {
    CTA:
      | 'Home - Try it now'
      | 'Users - Try it now'
      | 'Sidebar - Sign-Up & Sign-In'
      | 'Sidebar - User Profile';
  };
  'External links': {
    CTA: 'Sidebar - Organizations';
  };
  'Read guide': { Framework: Frameworks };
};

// eslint-disable-next-line
export function usePlausible() {
  return useNextPlausible<Events>();
}
