import React from 'react';

import { useDisclosure, Text } from '@chakra-ui/react';
import { ConfirmationModal } from '@components/modal';

import { MenuItem } from '@chakra-ui/react';
import { useDeleteMembership } from '@components/organizations/memberships';

import { useToast } from '@hooks';
import { ErrorCodes } from '@components/organizations';

type RemoveMemberActionProps = {
  memberForDeletion: string;
  id: string;
  actionText?: string;
  onRemoveMember?: () => void;
  organizationID?: string;
  confirmationModalTitle?: string;
  confirmationMessage?: string | React.ReactElement;
  organizationName?: string;
};

const RemoveMemberAction = ({
  memberForDeletion,
  id,
  onRemoveMember,
  actionText = 'Remove member',
  organizationID,
  confirmationMessage,
  organizationName,
}: RemoveMemberActionProps): JSX.Element => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { showErrorToast, showSuccessToast } = useToast();
  const [isDeleting, setIsDeleting] = React.useState(false);

  const { deleteMembership, mutate } = useDeleteMembership({ organizationID });

  const onConfirmation = async () => {
    try {
      setIsDeleting(true);
      const { first_name, last_name, identifier } = await deleteMembership(id);
      const updateUserIdentifier = first_name
        ? `${first_name} ${last_name}`
        : identifier;
      showSuccessToast(
        `Member ${updateUserIdentifier} has been successfully removed ${
          organizationName ? `from ${organizationName}` : ''
        }`,
      );
      mutate();
      onRemoveMember?.();
      onClose();
    } catch (err) {
      if (
        err.code === 400 &&
        ErrorCodes.AtLeastOneAdmin === err.globalErrors[0].code
      ) {
        showErrorToast(
          `Cannot remove ${memberForDeletion}. There has to be at least one admin in the organization.`,
        );
        return;
      }
      showErrorToast(`Couldn't remove member ${memberForDeletion}`);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <MenuItem color='red' onClick={onOpen}>
      {actionText}
      <ConfirmationModal
        isConfirmationLoading={isDeleting}
        isOpen={isOpen}
        handleCancelation={onClose}
        handleConfirmation={onConfirmation}
        confirmationMessage='Remove'
        cancelationMessage='Cancel'
        message={
          confirmationMessage || (
            <Text textStyle='md-normal'>
              Are you sure you want to remove{' '}
              <Text textStyle='md-normal' as='span' fontWeight='bold'>
                {memberForDeletion}
              </Text>
              ?
            </Text>
          )
        }
        headerTitle='Remove member from organization'
      />
    </MenuItem>
  );
};

export default RemoveMemberAction;
