import React from 'react';
import {
  SimpleGrid,
  Box,
  Stack,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import { editor, IRange } from 'monaco-editor';
import Editor from '@monaco-editor/react';
import { JsonVariables } from './JsonVariables';
import { useFormContext, useWatch } from 'react-hook-form';
import { InformationBox, VerticalInputBox } from '../common';
import { ExclamationIcon } from '@heroicons/react/solid';

const MAX_SIZE = 2048;
const SUGGESTED_SIZE = 256;

const disallowedClaimKeys = ['iat', 'nbf', 'exp', 'iss', 'sub', 'azp'];

const editorOptions: editor.IStandaloneEditorConstructionOptions = {
  formatOnType: true,
  formatOnPaste: true,
  autoIndent: 'full',
  codeLens: false,
  minimap: { enabled: false },
  padding: { top: 16, bottom: 16 },
  scrollBeyondLastLine: false,
  // hide *all* suggestions
  quickSuggestions: false,
  acceptSuggestionOnEnter: 'off',
  acceptSuggestionOnCommitCharacter: false,
  tabCompletion: 'off',
  // Set height
  fontSize: 16,
  dimension: { height: 380, width: 200 },
  wordBasedSuggestions: false,
  parameterHints: { enabled: false },
  suggestOnTriggerCharacters: false,
  scrollbar: {
    vertical: 'auto',
  },
  lineNumbers: 'off',
};

export function JwtJsonEditor(): JSX.Element {
  const editorRef = React.useRef<editor.ICodeEditor>(null);
  const {
    control,
    register,
    formState: { errors, isSubmitting },
    setError,
    setValue,
  } = useFormContext();
  const errorMessageRef = React.useRef(null);
  const [warningMessage, setWarningMessage] = React.useState('');
  register('claims', {
    required: true,
    validate: () => {
      if (errors.claims) {
        errorMessageRef.current.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        });
        return errors.claims?.message;
      }
      return true;
    },
  });

  const editorValue = useWatch({ control, name: 'claims' });
  const setEditorValue = value =>
    setValue('claims', value, { shouldDirty: true });

  React.useEffect(() => {
    return () => editorRef.current?.dispose();
  }, []);

  // Research on editor custom autocomplete
  // function handleEditorWillMount(monaco) {
  //   // here is the monaco instance
  //   // do something before editor is mounted
  //   // monaco.languages.typescript.javascriptDefaults.setEagerModelSync(true);

  //   monaco.languages.registerCompletionItemProvider('json', {
  //     triggerCharacters: [':'],
  //     provideCompletionItems: (model, position, token) => {
  //       const word = model.getWordUntilPosition(position);
  //       const range = {
  //         startLineNumber: position.lineNumber,
  //         endLineNumber: position.lineNumber,
  //         startColumn: word.startColumn,
  //         endColumn: word.endColumn
  //       };

  //       return {
  //         suggestions: [
  //           { insertText: '"{{user.created_at}}"', kind: 4, label: 'user.created_at', range },
  //           { insertText: '"{{user.first_name}}"', kind: 4, label: 'user.first_name', range },
  //           { insertText: '"{{user.updates_at}}"', kind: 4, label: 'user.updates_at', range },
  //           { insertText: '"{{user.last_name}}"', kind: 4, label: 'user.last_name', range }
  //         ]
  //       }
  //     }
  //   })
  // }

  const handleMount = (editor: editor.IStandaloneCodeEditor) => {
    editorRef.current = editor;

    editor
      .getModel()
      .setValue(JSON.stringify(JSON.parse(editorValue), null, '\t'));
  };

  const handleChange = (value: string) => {
    if (isSubmitting) {
      return;
    }
    if (!value) {
      return setEditorValue('{}');
    }

    try {
      const providedKeys = Object.keys(JSON.parse(value));
      const invalidKeys = providedKeys.filter(k =>
        disallowedClaimKeys.includes(k),
      );

      if (invalidKeys.length > 0) {
        return setError('claims', {
          type: 'manual-change',
          message: `You can't use the reserved claim: ${invalidKeys[0]}`,
        });
      }
    } catch (e) {
      // We intentionally ignore the error if we can't parse the JSON
    }

    if (errors.claims?.type === 'manual-change') {
      setError('claims', null);
    }

    setEditorValue(value);
  };

  const handleValidate = (markers: editor.IMarker[]) => {
    const marker = markers[0];
    if (marker) {
      return setError('claims', {
        type: 'manual-validate',
        message: `Error on line ${marker.startLineNumber}: ${marker.message}`,
      });
    }
    const size = editorValue.length;
    if (size > MAX_SIZE) {
      return setError('claims', {
        type: 'manual-validate',
        message: `Max allowed size is ${MAX_SIZE} bytes (current size: ${size})`,
      });
    }

    if (errors.claims?.type === 'manual-validate') {
      setError('claims', null);
    }

    if (size > SUGGESTED_SIZE) {
      setWarningMessage('Bigger JSON objects increase the network overhead');
      return;
    }
    if (warningMessage) {
      setWarningMessage('');
    }
  };

  const addTextToCursor = (text: string) => {
    if (isSubmitting) {
      return;
    }
    const { lineNumber, column } = editorRef.current.getPosition();
    const range: IRange = {
      startColumn: column,
      startLineNumber: lineNumber,
      endColumn: column,
      endLineNumber: lineNumber,
    };
    const op = {
      identifier: { major: 1, minor: 1 },
      range,
      text,
      forceMoveMarkers: true,
    };
    editorRef.current.executeEdits('my-source', [op]);
  };

  return (
    <SimpleGrid columns={2} templateColumns='60fr 40fr' spacing='4'>
      <Stack spacing='2'>
        <VerticalInputBox label='Claims'>
          <Box rounded='xl' overflow='hidden' h='380px'>
            <Editor
              theme='vs-dark'
              language='json'
              options={editorOptions}
              // beforeMount={handleEditorWillMount}
              onMount={handleMount}
              onChange={handleChange}
              onValidate={handleValidate}
            />
          </Box>
        </VerticalInputBox>

        <FormControl as={Stack} spacing='2' isInvalid={!!errors.claims}>
          <FormErrorMessage ref={errorMessageRef}>
            {errors.claims?.message && (
              <InformationBox
                icon={ExclamationIcon}
                iconColor='danger.300'
                bgColor='danger.50'
              >
                {errors.claims?.message}
              </InformationBox>
            )}
          </FormErrorMessage>
          {warningMessage ? (
            <InformationBox
              icon={ExclamationIcon}
              iconColor='warning.300'
              bgColor='warning.50'
              width='fit-content'
            >
              {warningMessage}
            </InformationBox>
          ) : null}
        </FormControl>
      </Stack>

      <JsonVariables handleVariableClick={addTextToCursor} />
    </SimpleGrid>
  );
}
