import React from 'react';
import {
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
} from '@chakra-ui/react';
import _capitalize from 'lodash/capitalize';
import { ChevronDownIcon } from '@heroicons/react/solid';

const ActionLabel = ({ identifiers }: { identifiers: string[] }) => {
  return (
    <Text color='primary.500' textStyle='sm-medium' mb={1}>
      {identifiers[0] === 'phone_number' ? 'Use username' : 'Use phone'}
    </Text>
  );
};

const IdentifierField = ({
  identifiers,
}: {
  identifiers: string[];
}): JSX.Element => {
  const label =
    identifiers[0] === 'phone_number'
      ? identifiers[0]
      : identifiers.filter(e => e !== 'phone_number').join(' or ');

  return (
    <>
      <Flex justifyContent='space-between'>
        <Text color='hsla(0, 0%, 8%, 1)' textStyle='sm-medium' mb={1}>
          {_capitalize(label.replace(/_/g, ' '))}
        </Text>
        {identifiers.includes('phone_number') && identifiers.length > 1 && (
          <ActionLabel identifiers={identifiers} />
        )}
      </Flex>
      {identifiers[0] === 'phone_number' ? (
        <InputGroup>
          <InputLeftAddon bg='rgba(0, 0, 0, 0.04)' paddingRight={2}>
            <Text color='gray.600' fontSize='xs'>
              🇺🇸 +1
            </Text>
            <Icon as={ChevronDownIcon} boxSize={4} color='gray.600' ml={1} />
          </InputLeftAddon>
          <Input
            borderLeftWidth={0}
            disabled
            _disabled={{ borderColor: 'blackAlpha.300' }}
            borderColor='blackAlpha.300'
            type='number'
            autoComplete='off'
            autoCapitalize='off'
          />
        </InputGroup>
      ) : (
        <Input
          disabled
          _disabled={{ borderColor: 'blackAlpha.300' }}
          borderColor='blackAlpha.300'
        />
      )}
    </>
  );
};

export default IdentifierField;
