import React from 'react';
import { Link } from '@chakra-ui/layout';
import { ClerkServiceStatus } from './ClerkServiceStatus';

const { hostname: dapiHostname } = new URL(
  process.env.NEXT_PUBLIC_DASHBOARD_API || '',
);
const BAPI_HEALTH_ENDPOINT = `https://api.${dapiHostname
  .split(/\./)
  .slice(-2)
  .join('.')}/v1/health`;

const WEBSITE_ENDPOINT = 'https://clerk.com';

export function ClerkServicesStatus(): JSX.Element {
  return (
    <>
      <ClerkServiceStatus
        entryTitle='Backend API'
        serviceStatusUrl={BAPI_HEALTH_ENDPOINT}
      />
      {/* Should we do any check for the website */}
      <ClerkServiceStatus
        entryTitle={'Clerk Systems'}
        serviceStatusUrl={WEBSITE_ENDPOINT}
        entryDetails={
          <Link
            textStyle='sm-normal'
            target='_blank'
            color='primary.500'
            href='https://status.clerk.com/'
          >
            View status page
          </Link>
        }
      />
    </>
  );
}
