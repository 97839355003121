import { Switch } from '@chakra-ui/react';
import { CardWithSwitch } from '@components/common';
import React from 'react';
import { Template } from '@types';
import { useController, useFormContext } from 'react-hook-form';

interface DeliveredByClerkSwitchProps {
  type: 'email' | 'sms';
  template: Template;
}

export function DeliveredByClerkSwitch({
  type,
  template,
}: DeliveredByClerkSwitchProps): JSX.Element {
  const {
    control,
    formState: { isSubmitting },
  } = useFormContext();

  const { field: deliveredField } = useController({
    control,
    name: 'delivered_by_clerk',
    defaultValue: template.delivered_by_clerk,
  });
  const { value: value, ...rest } = deliveredField;

  const subtitle =
    type == 'email'
      ? "If enabled, Clerk will use SendGrid to deliver this email. If disabled, Clerk will not send this email and you must listen to the 'emails' webhook to send it on your own."
      : "If enabled, Clerk will use Twilio to deliver this email. If disabled, Clerk will not send this SMS and you must listen to the 'sms' webhook to send it on your own";

  return (
    <CardWithSwitch
      title='Delivered by Clerk'
      subtitle={subtitle}
      docLink={{
        subject: 'Delivered by Clerk',
        url: 'https://clerk.com/docs/troubleshooting/email-deliverability#managing-your-own-email-sms-delivery',
      }}
    >
      <Switch isDisabled={isSubmitting} isChecked={value} {...rest} />
    </CardWithSwitch>
  );
}
