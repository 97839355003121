import { popoverAnatomy as parts } from '@chakra-ui/anatomy';
import { PartsStyleObject } from '@chakra-ui/theme-tools';
import { Menu } from './menu';

const baseStyle: PartsStyleObject<typeof parts> = {
  content: { ...Menu.baseStyle.list, textStyle: 'sm-normal' },
};

export const Popover = {
  parts: parts.keys,
  baseStyle,
};
