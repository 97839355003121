import React from 'react';
import { noop } from '@utils';
import debounce from 'lodash/debounce';

export const useDebounceCallback = (
  callback: (...args: any) => void,
  delay = 300,
) => {
  const ref = React.useRef(noop);

  React.useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = React.useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, delay);
  }, []);

  return debouncedCallback;
};
