import React from 'react';

import {
  Collapse,
  FormControl,
  FormErrorMessage,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputRightAddon,
  Select,
} from '@chakra-ui/react';

import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { VerticalInputBox } from '../common';
import { JwtCustomSigningKeySwitch } from './JwtCustomSigningKeySwitch';
import { JwtSigningKeyInput } from './JwtSigningKeyInput';

const templateNameRegex = new RegExp('^[a-zA-Z0-9-_]+$');

import {
  JwtSigningAlgorithm,
  JwtTemplateFormFields,
  JwtTemplateSessionTokenName,
} from '@types';

interface JwtTemplateFormProps {
  hasStoredKey: boolean;
  disableNameInput: boolean;
  showLifetimeInput: boolean;
  showClockSkewInput: boolean;
  showCustomKeySwitch: boolean;
}

export function JwtTemplateForm({
  hasStoredKey,
  disableNameInput,
  showLifetimeInput,
  showClockSkewInput,
  showCustomKeySwitch,
}: JwtTemplateFormProps): JSX.Element {
  const {
    register,
    formState: { errors, isSubmitting },
    control,
  } = useFormContext<JwtTemplateFormFields>();
  const jwtSigningAlgorithms = Object.values(JwtSigningAlgorithm);

  const customSigningKeyField = register('custom_signing_key');
  const customSigningKey = useWatch({ control, name: 'custom_signing_key' });

  const validateName = (value: string): boolean | string => {
    if (!templateNameRegex.test(value)) {
      return 'The template name may only contain letters, numbers, underscores and hyphens';
    }
    // For non session template, we don't allow to use the reserved name
    if (value === JwtTemplateSessionTokenName && !disableNameInput) {
      return "You can't use '__session' as template name as it is reserved by the system.";
    }
    return true;
  };

  return (
    <Grid templateColumns='repeat(2, 1fr)' gap={6}>
      <GridItem as={VerticalInputBox} label='Name' htmlFor='name' colSpan={2}>
        <FormControl isInvalid={!!errors.name}>
          <Input
            autoFocus
            id='name'
            {...register('name', {
              required: 'Required',
              validate: validateName,
            })}
            disabled={isSubmitting || disableNameInput}
          />
          <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
        </FormControl>
      </GridItem>

      {showLifetimeInput && (
        <VerticalInputBox optional label='Token lifetime' htmlFor='lifetime'>
          <FormControl isInvalid={!!errors.lifetime}>
            <Controller
              control={control}
              name='lifetime'
              render={({ field }) => (
                <InputGroup borderRadius='md'>
                  <Input
                    type='number'
                    {...field}
                    onChange={e => field.onChange(e.target.valueAsNumber || '')}
                    textAlign='right'
                    id='lifetime'
                    disabled={isSubmitting}
                    borderRight={0}
                  />
                  <InputRightAddon bg='gray.50' color='gray.500' m={0}>
                    seconds
                  </InputRightAddon>
                </InputGroup>
              )}
              rules={{
                validate: value =>
                  value >= 30 && value <= 315360000
                    ? true
                    : 'Lifetime must be between 30 and 315360000 seconds',
              }}
            />
            <FormErrorMessage>{errors.lifetime?.message}</FormErrorMessage>
          </FormControl>
        </VerticalInputBox>
      )}

      {showClockSkewInput && (
        <VerticalInputBox
          optional
          label='Allowed clock skew'
          htmlFor='allowed_clock_skew'
        >
          <FormControl isInvalid={!!errors.allowed_clock_skew}>
            <Controller
              control={control}
              name='allowed_clock_skew'
              render={({ field }) => (
                <InputGroup borderRadius='md'>
                  <Input
                    type='number'
                    {...field}
                    onChange={e => field.onChange(e.target.valueAsNumber || '')}
                    textAlign='right'
                    id='allowed_clock_skew'
                    disabled={isSubmitting}
                    borderRight={0}
                  />
                  <InputRightAddon bg='gray.50' color='gray.500' m={0}>
                    seconds
                  </InputRightAddon>
                </InputGroup>
              )}
              rules={{
                validate: value =>
                  value > 0 && value < 300
                    ? true
                    : 'Allowed clock skew must be between 0 and 300 seconds',
              }}
            />
            <FormErrorMessage>
              {errors.allowed_clock_skew?.message}
            </FormErrorMessage>
          </FormControl>
        </VerticalInputBox>
      )}

      {showCustomKeySwitch && (
        <JwtCustomSigningKeySwitch field={customSigningKeyField} />
      )}

      <GridItem as={Collapse} in={customSigningKey}>
        <VerticalInputBox label='Signing algorithm' htmlFor='signing_algorithm'>
          <FormControl isInvalid={!!errors.signing_algorithm}>
            <Select
              id='signing_algorithm'
              color='gray.500'
              {...register('signing_algorithm', {
                required: 'Required',
                validate: value =>
                  Object.values(JwtSigningAlgorithm).includes(value) ||
                  `Please choose one of: ${jwtSigningAlgorithms.join(', ')}`,
              })}
              disabled={isSubmitting}
            >
              {jwtSigningAlgorithms.map(sa => (
                <option key={sa} value={sa}>
                  {sa}
                </option>
              ))}
            </Select>
            <FormErrorMessage>
              {errors.signing_algorithm?.message}
            </FormErrorMessage>
          </FormControl>
        </VerticalInputBox>
      </GridItem>

      <GridItem as={Collapse} in={customSigningKey} colStart={2} mt={-3}>
        <JwtSigningKeyInput hasStoredKey={hasStoredKey} />
      </GridItem>
    </Grid>
  );
}
