import React from 'react';
import cn from 'classnames';
import { useDetectClickOutside } from '@hooks/useDetectClickOutside';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';

// @ts-ignore
import styles from './Dropdown.module.scss';
import { DropdownList } from './DropdownList';
import {
  defaultComparator,
  findSelectedOptionIndex,
  getOptionLabel,
  makeComparator,
} from './util';
import type { DropdownComparator, DropdownOption } from './types';
import { ExpansionIcon } from '../expansionIcon';
import { Button, Icon as IconContainer, Text } from '@chakra-ui/react';

const getNativeOption = (o: DropdownOption) => {
  if (typeof o === 'string') {
    return (
      <option value={o} key={'option-' + o}>
        {o}
      </option>
    );
  }
  return o.nativeOption;
};

export type DropdownSelection = {
  name: string;
  value: string;
  type: 'dropdown';
};

export type DropdownProps = {
  /** Should be unique to the attribute that is aiming to change. Translated to #id for the dropdown list */
  name?: string;
  options: DropdownOption[];
  selectedOption?: string;
  selectedOptionClassname?: string;
  defaultSelectedIndex?: number;
  active?: boolean;
  Icon?: React.ReactNode;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  showCaret?: boolean;
  style?: any;
  /** Enable input filtered dropdown list behaviour */
  searchable?: boolean;
  handleChange?: (el: DropdownSelection) => void;
  handleDropdownClosed?: () => void;
  /** Custom compare function for the list filtering */
  customComparator?: DropdownComparator;
  labelClassname?: string;
  tertiary?: boolean;
  Select?: 'select' | React.FunctionComponent;
};

export function DropdownV2({
  name,
  options = [],
  selectedOption,
  selectedOptionClassname,
  defaultSelectedIndex,
  active,
  Icon,
  placeholder,
  disabled,
  className,
  showCaret = true,
  style,
  searchable = false,
  handleChange,
  handleDropdownClosed,
  customComparator,
  Select = 'select',
}: DropdownProps): JSX.Element {
  const dropdownRef = React.useRef<HTMLUListElement>(null);
  const selectionRef = React.useRef<HTMLLIElement>(null);
  const searchInputRef = React.useRef<HTMLInputElement>(null);

  const nativeOptions = React.useMemo(
    () => options.map(getNativeOption).filter(o => o),
    [options],
  );

  const [selectedIndex, setSelectedIndex] = React.useState(() => {
    if (defaultSelectedIndex != null && defaultSelectedIndex >= 0) {
      return defaultSelectedIndex;
    }

    return options.findIndex(option => {
      const preSelectedOption =
        typeof option === 'string' ? option : option.value;
      return preSelectedOption === selectedOption;
    });
  });

  const [isActive, setIsActive] = useDetectClickOutside(dropdownRef, !!active);
  const [isSearchInputActive, setSearchInputActive] = useDetectClickOutside(
    searchInputRef,
    false,
  );
  const [searchValue, setSearchValue] = React.useState('');

  const displayLabel = getOptionLabel(options[selectedIndex], placeholder);

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchValue = e.currentTarget.value;
    setSearchValue(newSearchValue);
    setSelectedIndex(0);
  };

  const comparator = makeComparator(
    searchValue,
    customComparator || defaultComparator,
  );
  const filteredOptions = searchValue ? options.filter(comparator) : options;

  React.useEffect(() => {
    if (isActive || !handleDropdownClosed) {
      return;
    }
    handleDropdownClosed();
  }, [isActive]);

  React.useEffect(() => {
    if (
      name &&
      selectedIndex != null &&
      selectedIndex >= 0 &&
      options.length > 0 &&
      typeof handleChange === 'function'
    ) {
      const selectedOption = options[selectedIndex];
      const value =
        typeof selectedOption === 'string'
          ? selectedOption
          : selectedOption.value;

      handleChange({
        name,
        value,
        type: 'dropdown',
      });
    }
  }, [selectedIndex]);

  React.useLayoutEffect(() => {
    if (
      !isActive ||
      !selectionRef.current?.scrollIntoView ||
      selectedIndex == undefined
    ) {
      return;
    }
    selectionRef.current.scrollIntoView({ block: 'nearest' });
  }, [isActive, selectedIndex, searchValue]);

  const onTriggerClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsActive(!isActive);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Escape') {
      e.preventDefault();
      setIsActive(false);
      return;
    }

    if (e.key === 'ArrowUp') {
      e.preventDefault();
      if (isActive) {
        setSelectedIndex((i = 0) =>
          i - 1 < 0 || i - 1 > filteredOptions.length
            ? filteredOptions.length - 1
            : i - 1,
        );
      } else {
        setIsActive(true);
      }
      return;
    }

    if (e.key === 'ArrowDown') {
      e.preventDefault();
      if (isActive) {
        setSelectedIndex(i => ((i || 0) + 1) % filteredOptions.length);
      } else {
        setIsActive(true);
      }
      return;
    }

    if (e.key === 'Enter') {
      setSelectedIndex(i => {
        return i >= filteredOptions.length
          ? findSelectedOptionIndex(options, filteredOptions[0])
          : findSelectedOptionIndex(options, filteredOptions[selectedIndex]);
      });
      searchInputRef.current?.blur();
      setSearchInputActive(false);
      setSearchValue('');
      e.preventDefault();
      setIsActive(!isActive);
    }
  };

  const handleListSelection = (selectedOption: DropdownOption) => {
    searchInputRef.current?.blur();
    const selectedIndex = findSelectedOptionIndex(options, selectedOption);
    setSearchValue('');
    setSearchInputActive(false);
    setSelectedIndex(selectedIndex);
    setIsActive(false);
  };

  const onSearchInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setSearchInputActive(true);
    searchInputRef.current?.focus();
    e.preventDefault();
    e.stopPropagation();
    setIsActive(true);
  };

  const onSearchInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    e.stopPropagation();
    setIsActive(false);
    setSearchValue('');
    setSearchInputActive(false);
  };

  return (
    <div
      className={cn(
        styles.container,
        { [styles.disabled]: disabled },
        className,
      )}
      style={style}
      onKeyDown={onKeyDown}
      onBlur={() => isActive && setIsActive(false)}
    >
      {searchable ? (
        <div
          onFocus={onSearchInputFocus}
          tabIndex={0}
          className={cn(styles.trigger)}
        >
          {Icon}
          <input
            className={cn(styles.searchInput)}
            ref={searchInputRef}
            value={searchValue}
            type='text'
            autoComplete='off'
            aria-haspopup='listbox'
            aria-autocomplete='list'
            aria-controls={name}
            role='combobox'
            aria-expanded={isSearchInputActive}
            tabIndex={0}
            onChange={handleSearchInput}
            onBlur={onSearchInputBlur}
            data-no-focus={true}
          />
          {!isSearchInputActive && (
            <span
              className={cn(
                styles.label,
                styles.searchDisplay,
                selectedOptionClassname,
                {
                  [styles.placeholder]: !options[selectedIndex],
                },
              )}
            >
              {displayLabel}
            </span>
          )}
          {showCaret && <ExpansionIcon isExpanded={isActive} />}
        </div>
      ) : (
        <Button
          onClick={onTriggerClick}
          aria-haspopup='true'
          aria-expanded={isActive}
          type='button'
          variant='outline'
          color='black'
          borderColor='gray.200'
          w='100%'
          p='10px 10px 10px 16px'
          data-active={isActive ? true : undefined}
          _active={{
            border: '1px solid #99ADF8',
            outline: 0,
            boxShadow: 'none !important',
          }}
          _hover={{
            background: 'none',
          }}
          _focus={{
            boxShadow: 'none',
          }}
        >
          {Icon}
          <Text
            fontSize='1em'
            lineHeight='0.75em'
            w='100%'
            textAlign='left'
            textTransform='none'
            fontWeight='normal'
            letterSpacing='normal'
          >
            {displayLabel}
          </Text>
          {showCaret ? (
            <IconContainer
              as={isActive ? ChevronUpIcon : ChevronDownIcon}
              color='gray.300'
              w='20px'
              h='20px'
            />
          ) : null}
        </Button>
      )}
      {isActive && (
        <DropdownList
          dropdownRef={dropdownRef}
          selectionRef={selectionRef}
          options={filteredOptions}
          handleListSelection={handleListSelection}
          selectedIndex={selectedIndex}
          listId={name}
        />
      )}
      {nativeOptions.length > 0 && (
        <Select
          name='native-dropdown-picker'
          onChange={e => setSelectedIndex(e.target.selectedIndex)}
        >
          {nativeOptions}
        </Select>
      )}
    </div>
  );
}
