import { useEffect, useRef } from 'react';

import { DisplayThemeJSON } from '@clerk/types';

import { html as beautify } from 'js-beautify';

declare global {
  interface Window {
    Revolvapp?: typeof import('public/revolvapp-dist/revolvapp');
  }
}

type UseRevolvappOptions = {
  theme: DisplayThemeJSON;
  variables: string[];
  markup: string;
  onChange: (template: string, html: string) => void;
  disabled?: boolean;
};

type ContentType = 'template' | 'html';

// TODO Find a way to reinstate an empty template when user clears the template markup
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const emptyTemplate = `<re-html>
<re-head>
</re-head>
<re-body>
    <re-header>
    </re-header>
    <re-main>
    </re-main>
    <re-footer>
    </re-footer>
</re-body>
</re-html>`;

const brandedLinkSelector = '.cl-branded-link';
const brandedButtonSelector = '.cl-branded-button';

export interface UseRevolvappReturn {
  updateMarkup: (newMarkup: string) => void;
}

const beautifyOptions = {
  indent_size: 4,
  indent_char: ' ',
  max_preserve_newlines: 5,
  preserve_newlines: false,
  keep_array_indentation: false,
  break_chained_methods: false,
  indent_scripts: 'normal',
  brace_style: 'collapse',
  space_before_conditional: true,
  unescape_strings: false,
  jslint_happy: false,
  end_with_newline: false,
  wrap_line_length: 0,
  indent_inner_html: true,
  comma_first: false,
  e4x: false,
  indent_empty_lines: false,
  inline: [],
};

export function useRevolvapp(
  selector: string,
  options: UseRevolvappOptions,
): UseRevolvappReturn {
  const { theme, variables, markup, onChange, disabled } = options;
  const revolvapp = useRef(null);

  useEffect(() => {
    async function importLibs() {
      await import('public/revolvapp-dist/revolvapp');

      await Promise.all([
        // @ts-ignore
        import('public/revolvapp-dist/plugins/variable/variable'),
        // @ts-ignore
        import('public/revolvapp-dist/plugins/reorder/reorder'),
      ]);
    }

    const emitChanges = () => {
      console.log(`emitting changes from ${selector}`);

      if (revolvapp.current) {
        onChange(
          formatForAPI(
            revolvapp.current.editor.getTemplate(),
            theme,
            'template',
          ),
          formatForAPI(revolvapp.current.editor.getHtml(), theme, 'html'),
        );
      }
    };

    async function startEditor() {
      await importLibs();

      // This will probably never happen
      if (revolvapp.current) {
        console.log(`updating editor contents on ${selector}`);
        revolvapp.current.editor.setTemplate(formatForEditor(markup, theme));
        return;
      }

      console.log(`starting editor on ${selector}`);

      revolvapp.current = window.Revolvapp(selector, {
        plugins: ['variable', 'reorder'],
        variable: {
          items: variables,
          template: {
            start: '{{',
            end: '}}',
          },
        },
        content: formatForEditor(markup, theme),
        editor: {
          path: '/revolvapp-dist/',
          delimiters: {
            '{{': ['{{', '}}'],
          },
          viewOnly: !!disabled,
        },
        subscribe: {
          'editor.change': emitChanges,
        },
        styles: {
          button: {
            color: theme.buttons.font_color,
            'background-color': theme.general.color,
          },
          link: {
            color: theme.general.color,
          },
        },
      });
    }

    function stopEditor() {
      if (revolvapp.current) {
        console.log(`stopping editor on ${selector}`);
        revolvapp.current.destroy();
        revolvapp.current = null;
      }
    }

    void startEditor();

    return () => stopEditor();
  }, [selector]);

  const updateMarkup = (newMarkup: string) => {
    if (revolvapp.current) {
      console.log(`updating editor contents on ${selector}`);
      revolvapp.current.editor.setTemplate(formatForEditor(newMarkup, theme));
    }
  };

  // API needs color variables instead of their hex values so that they keep
  // working even if the brand colors change
  function formatForAPI(
    input: string,
    theme: DisplayThemeJSON,
    contentType: ContentType,
  ) {
    const inputWithColorVars = colorsToVars(input, theme, contentType);

    if (contentType == 'template') {
      return inputWithColorVars;
    }

    return beautify(inputWithColorVars, beautifyOptions);
  }

  // For the editor to be able to show brand colors properly,
  // replace color variables with their respective values
  function formatForEditor(input: string, theme: DisplayThemeJSON) {
    return varsToColors(input, theme);
  }

  // Replace html color codes with matching variables within links & buttons
  // To be used before emitting changes to the parent form
  function colorsToVars(
    input: string,
    theme: DisplayThemeJSON,
    contentType: ContentType,
  ): string {
    let output;

    // Input may contain rgb colors, replace them with hex values using
    // editor's color plugin first
    const inputWithHexColors = revolvapp.current.color.replaceRgbToHex(input);

    // markup: target
    // <a class="cl-branded-link">
    // <re-link class="cl-branded-link">
    // <re-button class="cl-branded-button">

    // html: target
    // <a class="cl-branded-link">
    // parent table of <a class="branded-button">

    const templateDOM = new DOMParser().parseFromString(
      inputWithHexColors,
      'text/html',
    );

    const primaryColorRegex = new RegExp(theme.general.color, 'ig');
    const buttonTextColorRegex = new RegExp(theme.buttons.font_color, 'ig');

    // Branded links
    Array.from(templateDOM.querySelectorAll(brandedLinkSelector)).forEach(
      el => {
        el.outerHTML = el.outerHTML.replace(
          primaryColorRegex,
          '{{theme.primary_color}}',
        );
      },
    );

    // Branded buttons
    Array.from(templateDOM.querySelectorAll(brandedButtonSelector)).forEach(
      el => {
        if (contentType == 'template') {
          let newHTML = el.outerHTML;
          newHTML = newHTML.replace(
            primaryColorRegex,
            '{{theme.primary_color}}',
          );
          newHTML = newHTML.replace(
            buttonTextColorRegex,
            '{{theme.button_text_color}}',
          );
          el.outerHTML = newHTML;
        } else {
          const container = el.closest('table');
          let newHTML = container.outerHTML;
          newHTML = newHTML.replace(
            primaryColorRegex,
            '{{theme.primary_color}}',
          );
          newHTML = newHTML.replace(
            buttonTextColorRegex,
            '{{theme.button_text_color}}',
          );
          container.outerHTML = newHTML;
        }
      },
    );

    if (contentType == 'template') {
      // Unwrap from outer <html> inserted by DOMParser
      output = templateDOM.querySelector('re-html')?.outerHTML || '';
    } else {
      const doctype = new XMLSerializer().serializeToString(
        templateDOM.doctype,
      );
      output = doctype + '\n' + templateDOM.documentElement.outerHTML;
    }

    // Replace &gt; (escaped >) in Handlebars {{> Partials}}
    // TODO replace with regex capture & non-greedy matching
    return output.replace(/\{\{&gt;/g, '{{>');
  }

  // Replace variables with their corresponding html color codes
  // To be used before passing template markup to the editor
  function varsToColors(input: string, theme: DisplayThemeJSON): string {
    const primaryColorRegex = /\{\{theme\.primary_color\}\}/g;
    const buttonTextColorRegex = /\{\{theme\.button_text_color\}\}/g;

    return input
      .replace(primaryColorRegex, theme.general.color)
      .replace(buttonTextColorRegex, theme.buttons.font_color);
  }

  return { updateMarkup };
}
