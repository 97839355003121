import React from 'react';

import { SearchWithDropdown, InputBox, SelectMenu } from '@components/common';
import { Box, Flex } from '@chakra-ui/react';
import UserPublicInfo from '@components/users/Table/UserPublicInfo';
import SelectedUser from './SelectedUser';

import { useDashboardSWR, useLocation } from '@hooks';

import { User } from '@types';
import { MembershipRole } from '@components/organizations/types';
import { SelectedUserType } from './types';

import { roles } from '@components/organizations';

import { formatIdentifier } from '@components/users/utils';
//TODO SEARCH HERE NEEDS INFINITY LOADER
//TODO LOOK FOR KEEP PREVIOUS DATA AND REPLACE ISVALIDATING WIHT ISLOADING https://swr.vercel.app/docs/advanced/understanding#combining-with-isloading-and-isvalidating-for-better-ux

type FormProps = {
  updateSelectedUser: ({ id, role, identifier }: SelectedUserType) => void;
  organizationID: string;
};

const Form = ({
  updateSelectedUser,
  organizationID,
}: FormProps): JSX.Element => {
  const [search, setSearch] = React.useState<string>('');
  const [role, setRole] = React.useState<MembershipRole>('basic_member');
  const [selectedUser, setSelectedUser] = React.useState<User | null>(null);

  const { instanceId } = useLocation();

  const usersBase = `/v1/instances/${instanceId}/users`;

  const { data: users, isValidating } = useDashboardSWR<User[]>(() => {
    const params = new URLSearchParams({
      query: search || '',
      organization_id: `-${organizationID}`,
    });
    return `${usersBase}?${params.toString()}`;
  });

  const onSearchChange = (value: string) => {
    setSearch(value);
  };

  const handleUpdateSelected = (user: User, role: MembershipRole) => {
    updateSelectedUser({
      role,
      id: user.id,
      identifier: formatIdentifier(user),
    });
  };

  const handleChangeRole = (role: MembershipRole) => {
    setRole(role);
    if (selectedUser) {
      handleUpdateSelected(selectedUser, role);
    }
  };

  const handleSelectedUser = (user: User) => {
    setSelectedUser(user);
    handleUpdateSelected(user, role);
  };

  const clearSelectedUser = () => {
    setSelectedUser(null);
    updateSelectedUser({
      role: null,
      id: null,
      identifier: null,
    });
  };

  return (
    <Flex columnGap={3} width='100%' flexDirection='column'>
      <InputBox p={0} label='Search for user'>
        <SearchWithDropdown
          autoFocusInput
          isLoading={isValidating}
          data={users || []}
          onItemClick={handleSelectedUser}
          onChange={onSearchChange}
          ListItem={user => (
            <Box>
              <UserPublicInfo user={user} />
            </Box>
          )}
        />
      </InputBox>

      <SelectedUser
        clearSelectedUser={clearSelectedUser}
        selectedUser={selectedUser}
      />

      <Flex mt={2} width='6.5rem'>
        <InputBox width='100%' p={0} label='Role'>
          <SelectMenu
            buttonVariant='select'
            value={role}
            items={roles}
            onChange={handleChangeRole}
            buttonProps={{ size: 'sm' }}
          />
        </InputBox>
      </Flex>
    </Flex>
  );
};

export default Form;
