import React from 'react';

type HeaderContextValue = { isSticky: boolean };

export const HeaderContext = React.createContext<
  HeaderContextValue | undefined
>(undefined);

export const HeaderContextProvider = (props: {
  children: React.ReactNode;
  value: HeaderContextValue;
}) => {
  return <HeaderContext.Provider {...props} />;
};

export const useHeaderContext = () => {
  const ctx = React.useContext(HeaderContext);
  if (!ctx) {
    throw new Error(
      'You can only use useHeaderContext inside a component wrapped by HeaderContextProvider',
    );
  }
  return ctx;
};
