import { Statuses } from '@types';

export const DEPLOY_STATUS = Object.freeze({
  [Statuses.NotStarted]: {
    message: 'Not deployed',
    color: 'gray.200',
  },
  [Statuses.InProgress]: {
    message: 'Deploying',
    color: 'warning.500',
  },
  [Statuses.Complete]: {
    message: 'Operational',
    color: 'success.500',
  },
  [Statuses.Failed]: {
    message: 'Issue detected',
    color: 'danger.500',
  },
  unconfigured: {
    color: 'orange.500',
  },
});
